import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

export const ReactQueryProvider = ({ children }: PropsWithChildren) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 2,
        retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 3000),
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        staleTime: 10 * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {/* {import.meta.env.DEV && (
        <div dir="ltr">
          <ReactQueryDevtools />
        </div>
      )} */}
    </QueryClientProvider>
  );
};
