import { AdapterDateFnsJalali } from "@mui/x-date-pickers/AdapterDateFnsJalali";
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { PropsWithChildren } from "react";
import { faIR } from "@mui/x-date-pickers/locales";

export const DatePickerProvider = ({ children }: PropsWithChildren) => {
  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFnsJalali}
      dateFormats={{ monthShort: "MMMM", weekdayShort: "EEEE" }}
      localeText={
        faIR.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      {children}
    </LocalizationProvider>
  );
};

export function DatePicker<TDate>({
  sx,
  slotProps,
  ...props
}: DatePickerProps<TDate> & React.RefAttributes<HTMLDivElement>) {
  return (
    <MuiDatePicker
      // @ts-ignore
      sx={{
        mt: "0.5rem",
        width: "100%",
        ".MuiInputBase-root": {
          pr: "0.75rem",
          "&:not(.Mui-focused) fieldset": {
            border: "none",
            borderRadius: "0.5rem",
          },
          input: {
            py: "0.5rem",
          },
          py: "0.1rem",
          bgcolor: "primary.A100",
          borderRadius: "0.5rem",
          color: "neutral.A700",
          ...sx,
        },
      }}
      slotProps={{
        ...slotProps,
        field: {
          clearable: true,
          ...slotProps?.field,
        },
      }}
      {...props}
    />
  );
}
