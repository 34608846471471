import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fa from "./locales/fa.json";

export const resources = {
  fa: {
    translation: fa,
  },
} as const

i18n.use(initReactI18next).init({
  lng: "fa",
  debug: false,
  resources,
});

export default i18n;
