import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack } from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import style from "./style.module.css";

export default function AuthLayout({ children }: PropsWithChildren) {
  const isMdUp = useBreakpoint("md");

  const isGAuthPage = window.location.pathname.search("/ga") !== -1;

  const isCondition = useMemo(
    () => ["/otp", "ga"].some((e) => window.location.pathname.search(e) !== -1),
    [window.location.pathname]
  );

  const renderAnimate = () => {
    if (isCondition) {
      if (!isGAuthPage) {
        return style.toUp;
      }
    } else {
      if (!isGAuthPage) {
        return style.toDown;
      }
    }
  };

  return (
    <Stack
      sx={{
        bgcolor: { xs: "white", md: "unset" },
        height: "100dvh",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <Stack
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100dvh",
          maxWidth: { md: "420px" },
          maxHeight: { md: "44.8125rem" },
          mx: "auto",
          backgroundColor: "white",
          borderRadius: { md: "1.125rem" },
          overflow: "hidden",
        }}
      >
        {!(!isMdUp && isCondition) && (
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: "100%",
              height: "349px",
              background: "top no-repeat url(images/auth.svg)",
              backgroundSize: "100% 100%",
              mt: "-230px",
            }}
            className={renderAnimate()}
          >
            <Stack
              component="img"
              src="images/logo-name.svg"
              sx={{
                mt: "-230px",
                backgroundSize: "100% 100%",
              }}
              className={renderAnimate()}
            />
          </Stack>
        )}
        <Stack
          width={"100%"}
          // mt={"19px"}
          flexGrow={1}
          px="24px"
          alignItems="center"
          sx={{
            mt: { md: isCondition ? "-29px" : "0" },
          }}
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
}
