import MarketFilterAllIcon from "@assets/icons/MarketFilterAllIcon";
import MarketFilterGreenIcon from "@assets/icons/MarketFilterGreenIcon";
import MarketFilterRedIcon from "@assets/icons/MarketFilterRedIcon";
import Tabs from "@components/common/tabs";
import { TabItem } from "@components/common/tabs/types";
import { useBreakpoint } from "@hooks/useBreakpoint";
import {
  TrendingDown as TrendingDownIcon,
  TrendingUp as TrendingUpIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { useOrdersStore } from "@store/orders";
import { numUtils } from "@utils/num-helpers";
import { useState } from "react";
import useCurrencyUpdate from "../../_hooks/useCurrencyUpdate";
import useGetLastTrade from "../../_hooks/useGetLastTrade";
import useGetOrderBooks from "../../_hooks/useGetOrderBooks";
import RecentTrades from "../trade/_components/recent_trade";
import { sortOrders } from "./helperOrderBook";
import { FilterTypeIcon } from "./types";

const OrderBook = () => {
  const [filter, setFilter] = useState<FilterTypeIcon>("All");
  const { commaformatter } = numUtils;

  const setOrderValue = useOrdersStore((state) => state.setValue);

  const { data, currentCurrency } = useGetOrderBooks();
  // console.log({ data, currentCurrency }, "here");

  const { lastPriceCoin } = useGetLastTrade();

  const headerData = useCurrencyUpdate();

  const isSellSide = lastPriceCoin?.side === "sell";

  const isTable = useBreakpoint("lg");

  const isIRT = currentCurrency?.quoteCurrency === "IRT";

  const RenderOrderBook = () => {
    return (
      <Paper
        sx={{
          padding: "0.5rem",
          height: isTable ? "100%" : 925,
          borderRadius: 1,
        }}
        elevation={0}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            justifyContent: { xs: "flex-end", lg: "space-between" },
          }}
        >
          {isTable && <Typography>دفتر سفارشات</Typography>}
          <Stack direction="row" alignItems="center" spacing={1}>
            <IconButton
              sx={{
                height: "24px",
                width: "24px",

                opacity: filter === "Red" ? 0.9 : 0.5,
              }}
              onClick={() => {
                setFilter("Red");
              }}
            >
              <MarketFilterRedIcon />
            </IconButton>
            <IconButton
              sx={{
                height: "24px",
                width: "24px",
                opacity: filter === "Green" ? 1 : 0.5,
              }}
              onClick={() => {
                setFilter("Green");
              }}
            >
              <MarketFilterGreenIcon />
            </IconButton>
            <IconButton
              sx={{
                height: "24px",
                width: "24px",
                opacity: filter === "All" ? 0.9 : 0.5,
              }}
              onClick={() => {
                setFilter("All");
              }}
            >
              <MarketFilterAllIcon />
            </IconButton>
            {/* {isTable && <RefreshIcon />} */}
          </Stack>
        </Stack>
        <Box>
          <List
            sx={{
              mt: 2,
              height: 43,
            }}
          >
            <ListItem
              disablePadding
              sx={{
                mb: 1,
                "&.MuiListItem-root": {
                  padding: 0,
                },
              }}
            >
              <ListItemText
                primary={`قیمت (${currentCurrency?.quoteCurrency})`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "left",
                  width: 50,
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />

              <ListItemText
                primary={`مقدار (${currentCurrency?.baseCurrency})`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "center",
                  ml: 1,
                  // width: 50,
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />

              <ListItemText
                primary={`قیمت کل (${currentCurrency?.quoteCurrency})`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "right",
                  // width: 50,
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />
            </ListItem>
            <Divider flexItem />
          </List>
        </Box>
        <Grid container height={770}>
          <Box
            width={"100%"}
            display={filter === "All" || filter === "Red" ? "flex" : "none"}
            flexDirection={filter === "All" ? "column-reverse" : "column"}
            flexWrap={"nowrap"}
            height={filter === "Red" ? "880px" : "340px"}
            // maxHeight={340}
            // overflow={filter === "Red" ? "auto" : "hidden"}
            overflow={"hidden"}
          >
            {data &&
              sortOrders(data?.asks || [], "asc", filter === "All" ? 14 : 28)
                ?.filter((item) => Number(item.total) > 0)
                ?.map((ask, index) => (
                  <ListItem
                    key={`${index}-${ask.price}-${new Date().getTime()}`}
                    onClick={() => setOrderValue(ask.price)}
                    sx={{
                      height: 24,
                      position: "relative",
                      cursor: "pointer",

                      "&.MuiListItem-root": {
                        padding: 0,
                        // px: "0.25rem",
                      },
                      ":hover": {
                        backgroundColor: "neutral.50",
                      },
                    }}
                  >
                    <ListItemText
                      primary={commaformatter.toCurrency(ask?.price)}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "left",
                        width: 50,
                        zIndex: 1,
                      }}
                      primaryTypographyProps={{
                        color: "error.main",
                        fontSize: "0.75rem",
                      }}
                    />
                    <ListItemText
                      primary={commaformatter.toCurrency(ask.amount)}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "center",
                        width: 50,
                        zIndex: 1,
                      }}
                      primaryTypographyProps={{
                        color: "neutral.700",
                        fontSize: "0.75rem",
                      }}
                    />
                    <ListItemText
                      primary={commaformatter.toCurrency(
                        Number(ask.total).toFixed(
                          currentCurrency?.scale?.[
                            currentCurrency?.quoteCurrency
                          ]?.toRoundScale
                        )
                      )}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "right",
                        width: 50,
                        zIndex: 1,
                      }}
                      primaryTypographyProps={{
                        color: "neutral.700",
                        fontSize: "0.75rem",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        backgroundColor: "error.50",
                        width: `${ask.filledPercentage}%`,
                        height: "100%",
                        zIndex: 0,
                      }}
                    />
                  </ListItem>
                ))}
          </Box>

          <Stack
            width={"100%"}
            display={filter !== "All" ? "none" : "flex !important"}
          >
            <Divider flexItem />
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-around"}
              alignItems={"center"}
              height={64}
            >
              {/* {!currentCurrency?.id?.includes("USDT") && (
                <Typography color="neutral.700" variant="Body">
                  USDT{" "}
                  {commaformatter.toCurrency(
                    Number(currentCurrency?.todayPrice).toFixed(
                      currentCurrency.fromRoundScale ?? 0
                    )
                  )}
                  <AlmostEqualIcon fontSize={"inherit"} />
                </Typography>
              )} */}
              <Typography
                color={isSellSide ? "error.500" : "success.500"}
                variant="Body"
              >
                {isSellSide ? (
                  <TrendingDownIcon
                    sx={{
                      color: "error.500",
                      mr: 0.5,
                      mb: -1,
                    }}
                  />
                ) : (
                  <TrendingUpIcon
                    sx={{
                      color: "success.500",
                      mr: 0.5,
                      mb: -1,
                    }}
                  />
                )}
                {commaformatter.toCurrency(headerData?.price)}
              </Typography>
            </Stack>
            <Divider flexItem />
          </Stack>

          <Box
            width={"100%"}
            display={
              filter === "All" || filter === "Green"
                ? "block"
                : "none !important"
            }
            height={filter === "Green" ? "880px" : "365px"}
            // overflow={filter === "Green" ? "auto" : "hidden"}
            overflow={"hidden"}
            marginBottom={3}
          >
            <List
              sx={{
                "&.MuiList-root": {
                  pt: 0,
                },
              }}
            >
              {data &&
                sortOrders(data?.bids || [], "desc", filter === "All" ? 17 : 28)
                  ?.filter((item) => Number(item.total) > 0)
                  ?.map((bid, index) => (
                    <ListItem
                      key={`${index}-${bid.price}-${new Date().getTime()}`}
                      onClick={() => setOrderValue(bid.price)}
                      sx={{
                        height: 24,
                        position: "relative",
                        cursor: "pointer",

                        "&.MuiListItem-root": {
                          padding: 0,
                          // px: "0.25rem",
                        },

                        ":hover": {
                          backgroundColor: "neutral.50",
                        },
                      }}
                    >
                      <ListItemText
                        primary={commaformatter.toCurrency(
                          // Number(bid.price).toFixed(
                          //   isIRT
                          //     ? currentCurrency?.toRoundScale?.IRT
                          //     : currentCurrency?.toRoundScale?.USDT
                          // )
                          bid.price
                        )}
                        sx={{
                          textWrap: "nowrap",
                          textAlign: "left",
                          width: 50,
                          zIndex: 1,
                        }}
                        primaryTypographyProps={{
                          color: "success.main",
                          fontSize: "0.75rem",
                        }}
                      />
                      <ListItemText
                        primary={commaformatter.toCurrency(bid.amount)}
                        sx={{
                          textWrap: "nowrap",
                          textAlign: "center",
                          width: 50,
                          zIndex: 1,
                        }}
                        primaryTypographyProps={{
                          color: "neutral.700",
                          fontSize: "0.75rem",
                        }}
                      />
                      <ListItemText
                        primary={commaformatter.toCurrency(
                          Number(bid.total).toFixed(
                            currentCurrency?.scale?.[
                              currentCurrency?.quoteCurrency
                            ]?.toRoundScale
                          )
                        )}
                        sx={{
                          textWrap: "nowrap",
                          textAlign: "right",
                          width: 50,
                          zIndex: 1,
                        }}
                        primaryTypographyProps={{
                          color: "neutral.700",
                          fontSize: "0.75rem",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          backgroundColor: "success.50",
                          width: `${bid.filledPercentage}%`,
                          height: "100%",
                          zIndex: 0,
                        }}
                      />
                    </ListItem>
                  ))}
            </List>
          </Box>
        </Grid>
      </Paper>
    );
  };

  const tabs: TabItem[] = [
    {
      label: "دفتر سفارشات",
      content: <RenderOrderBook />,
    },
    { label: "معاملات اخیر", content: <RecentTrades height={"805px"} /> },
  ];

  return (
    <Grid item xs>
      <Box
        sx={{
          display: { xs: "block", lg: "none" },
          backgroundColor: "white",
          paddingY: "0.5rem",
        }}
      >
        <Tabs tabs={tabs} variant="standard" isButtonType />
      </Box>
      {isTable && <RenderOrderBook />}
    </Grid>
  );
};

export default OrderBook;
