import { useEffect, useState } from "react";

const useTabFocus = () => {
  const [isTabFocused, setIsTabFocused] = useState(!document.hidden);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabFocused(!document.hidden);
    };

    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return isTabFocused;
};

export default useTabFocus;
