import SwiperList from "@components/shared/swiper-list";
import { useGetReportTrade } from "@services/queries/dashboard/queries";
import { IReportItem } from "@services/queries/dashboard/types";
import ReportItem from "./ReportItem";
import { LabelValue } from "../types";

interface ReportCardProps {
  isMdUp: boolean;
  baseOn: LabelValue | null;
  isHidePrice?: boolean;
}

const ReportCard = ({ isMdUp, baseOn, isHidePrice }: ReportCardProps) => {
  const reportSlider = useGetReportTrade();

  return (
    <>
      {reportSlider?.isSuccess && (
        <SwiperList<{ mode: string; data: IReportItem }>
          renderComponent={({ mode, data }) => (
            <ReportItem
              mode={mode === "WEEKLY" ? "WEEKLY" : "MONTHLY"}
              data={data}
              isMdUp={isMdUp}
              baseOn={baseOn}
              isHidePrice={isHidePrice}
            />
          )}
          swiperList={[
            { mode: "WEEKLY", data: reportSlider?.data?.result?.day7Ago },
            { mode: "MONTHLY", data: reportSlider?.data?.result?.day30Ago },
          ]}
          minWidth={230}
          spaceBetween={16}
          loading={reportSlider?.isLoading}
          count={Object.keys(reportSlider?.data?.result || [])?.length}
          swiperStyle={{
            padding: "0.25rem",
          }}
        />
      )}
    </>
  );
};

export default ReportCard;
