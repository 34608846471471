import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { useState } from "react";
import { G2VStep1 } from "./_components/g2v-step-1";
import { ConfirmOtpGoogleVerificationRes } from "@services/queries/settings/types";
import { G2VStep2 } from "./_components/g2v-step-2";
import { useUserStore } from "@store/user";
import { G2VDisabled } from "./_components/g2v-disabled";

export const GoogleVerification = () => {
  const isMdUp = useBreakpoint("md");
  const user = useUserStore((state) => state.user);

  const [step, setStep] = useState<"disabled" | number>(
    user?.twoFactorAuthEnabled ? "disabled" : 0
  );
  // prettier-ignore
  const [dataTwoStepGoogle, setDataTwoStepGoogle] = useState<ConfirmOtpGoogleVerificationRes | null>(null);
  return (
    <Wrapper
      sxContainer={{
        py: { md: "2rem" },
        flex: 1,
        bgcolor: { xs: "white", md: "transparent" },
      }}
      isFullWidth={!isMdUp}
      isScreenHeight
      hideBottomNavigation={!isMdUp}
      title="کد ورود دوعاملی"
      backMode
    >
      {step !== "disabled" ? (
        <>
          {step === 0 && (
            <G2VStep1
              onSuccess={(data) => {
                setStep(1);
                setDataTwoStepGoogle(data);
              }}
            />
          )}
          {step === 1 && dataTwoStepGoogle && (
            <G2VStep2
              onSuccess={() => setStep("disabled")}
              data={dataTwoStepGoogle}
            />
          )}
        </>
      ) : (
        <G2VDisabled onSuccess={() => setStep(0)} />
      )}
    </Wrapper>
  );
};
