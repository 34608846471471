import { Button } from "@components/common/button";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { GoogleTwoStepVerificationModal } from "@components/shared/google-two-step-verification-modal";
import { ConfirmOtpGoogleVerificationRes } from "@services/queries/settings/types";
import QRCode from "react-qr-code";
import { ArrowRight2, TickCircle } from "iconsax-react";
import { toClipboard } from "@utils/common";
import { CopyIcon } from "@components/shared/copy-icon";
import { Image } from "@components/common/image";
import { useEnableGoogleCode } from "@services/queries/settings/mutations";
import { Modal } from "@components/common/modal";
import { IconTypography } from "@components/common/icon-typography";

interface G2VStep2Props {
  onSuccess: () => void;
  data: ConfirmOtpGoogleVerificationRes;
}

export const G2VStep2 = ({ onSuccess, data }: G2VStep2Props) => {
  const isMdUp = useBreakpoint("md");
  const theme = useTheme();

  const [isGoogleTwoStepVerification, setIsGoogleTwoStepVerification] =
    useState(false);
  const [isNetworkAddressCopied, setIsNetworkAddressCopied] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleEnableGoogleCode = useEnableGoogleCode();

  return (
    <>
      {isSuccessModalOpen && (
        <Modal
          open={isSuccessModalOpen}
          title="فعال‌سازی کد دو عاملی"
          onClose={onSuccess}
        >
          <Stack pt="1.5rem" gap="1rem" width="100%">
            <IconTypography
              bgColor="success.A100"
              icon={
                <TickCircle
                  color={theme?.palette?.success?.[700]}
                  variant="Bold"
                  fontSize="1.97rem"
                />
              }
              title={
                <Typography color="success.900" variant="Body">
                  کد دو عاملیتان فعال شد
                </Typography>
              }
            />

            <Button variant="outlined" fullWidth onClick={onSuccess}>
              <Typography variant="BodyMedium">متوجه شدم</Typography>
            </Button>
          </Stack>
        </Modal>
      )}

      {isGoogleTwoStepVerification && (
        <GoogleTwoStepVerificationModal
          onSubmit={(code) => {
            handleEnableGoogleCode.mutate(code, {
              onSuccess: () => setIsSuccessModalOpen(true),
            });
          }}
          loading={handleEnableGoogleCode?.isPending}
          onClose={() => setIsGoogleTwoStepVerification(false)}
        />
      )}

      <Stack
        bgcolor="white"
        sx={{ p: { xs: "1.5rem", md: "3rem" } }}
        borderRadius="0.625rem"
        gap="1.5rem"
        height="100%"
      >
        <Typography variant="BodyMedium" color="neutral.900">
          اپلیکیشن google Authenticator را از کافه بازار، google play یا App
          Store دانلود و نصب کنید.
        </Typography>

        <Stack
          direction={isMdUp ? "row" : "column"}
          gap="1rem"
          justifyContent="center"
          alignItems="center"
        >
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="/v2/images/apple-store.png"
              width="200px"
              height="55px"
              alt="apple-store"
              sx={{
                cursor: "pointer",
                height: "40px",
                width: "150px",
              }}
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="/v2/images/google-store.png"
              width="200px"
              height="55px"
              alt="google-store"
              sx={{
                cursor: "pointer",
                height: "40px",
                width: "150px",
              }}
            />
          </a>
          <a
            href="https://cafebazaar.ir/app/com.google.android.apps.authenticator2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image
              src="/v2/images/bazzar-store.png"
              width="200px"
              height="55px"
              alt="apple-store"
              sx={{
                cursor: "pointer",
                height: "40px",
                width: "150px",
              }}
            />
          </a>
        </Stack>

        <Typography variant="BodyMedium" color="neutral.900">
          در اپلیکیشن google Authenticator یا بارکد (QR Code) را اسکن کنید یا از
          طریق وارد کردن رمزینه (Setup Key)، شناسه دوعاملی را فعال کنید.
        </Typography>

        <Stack gap="0.5rem">
          <Typography variant="BodyBold" color="neutral.A700">
            رمزینه
          </Typography>
          <Stack
            direction="row"
            gap="0.5rem"
            justifyContent="flex-end"
            p="0.7rem"
            bgcolor="neutral.50"
            borderRadius="0.625rem"
          >
            <Typography
              variant="ExtendedBodyBold"
              color="primary.700"
              sx={{
                cursor: "pointer",
                wordBreak: "break-all",
                direction: "rtl",
              }}
              onClick={async () =>
                await toClipboard({
                  text: data?.secret?.base32 || "",
                  cb: (isCopied) => setIsNetworkAddressCopied(isCopied),
                })
              }
            >
              {data?.secret?.base32}
            </Typography>
            <CopyIcon
              isCopied={isNetworkAddressCopied}
              onChange={(isCopied) => setIsNetworkAddressCopied(isCopied)}
              text={data?.secret?.base32}
            />
          </Stack>
        </Stack>

        <QRCode
          size={256}
          style={{
            height: "auto",
            maxWidth: "200px",
            width: "100%",
            aspectRatio: "1/1",
            marginInline: "auto",
          }}
          value={data?.secret?.otpauth_url}
        />

        <Stack
          width="100%"
          p="0.5rem"
          sx={{
            bgcolor: "white",
            // mx: "auto",
            // maxWidth: { md: "350px" },
            position: { xs: "sticky", md: "static" },
            bottom: 0,
            left: 0,
          }}
        >
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setIsGoogleTwoStepVerification(true)}
            tailStartIcon={<ArrowRight2 size="1.25rem" />}
          >
            <Typography variant="BodyBold">ادامه</Typography>
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
