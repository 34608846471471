import { Stack, SxProps } from "@mui/material";
import { Button } from "../button";
import { useState } from "react";
import { Typography } from "../typography";

interface SwitchTab {
  sx?: SxProps;
  list: {
    label: string;
    value: string | number;
  }[];
  defaultTab?: {
    label: string;
    value: string | number;
  } | null;
  onChange: (data: { label: string; value: string | number }) => void;
}

export const SwitchTab = ({ list, defaultTab, onChange, sx }: SwitchTab) => {
  const [activeTab, setActiveTab] = useState<{
    label: string;
    value: string | number;
  } | null>(defaultTab || list[0] || null);

  return (
    <Stack
      direction="row"
      borderRadius="0.5625rem"
      bgcolor="colorfulGray.A100"
      p="2.5px"
      sx={sx}
    >
      {list?.map((item, i) => (
        <Button
          fullWidth
          key={`switch-${item?.value}`}
          bgColor={activeTab?.value === item?.value ? "white" : "transparent"}
          textColor="primary.700"
          onClick={() => {
            setActiveTab(item);
            onChange(item);
          }}
        >
          <Typography variant="CaptionMedium" color="primary.700">
            {item?.label}
          </Typography>
        </Button>
      ))}
    </Stack>
  );
};
