import { useSearchParams } from "react-router-dom";

interface ISetParams {
  name: string;
  value: string;
  reset?: boolean;
}

export const useFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const setParams = ({ name, value, reset = false }: ISetParams) => {
    setSearchParams((params) => {
      if (value.length > 0) {
        // if (reset) params.set("page", "1");
        params.set(name, value);
      } else {
        params.delete(name);
      }
      return params;
    });
  };

  return { searchParams, setParams };
};
