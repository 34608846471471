import { Typography } from "@components/common/typography";
import { Stack } from "@mui/material";
import { LoginHistoryRes } from "@services/queries/settings/types";
import { convertToPersianDate } from "@utils/common";

interface LoginHistoryCardProps {
  data: LoginHistoryRes;
}

export const LoginHistoryCard = ({ data }: LoginHistoryCardProps) => {
  return (
    <Stack
      component="li"
      gap="0.7rem"
      borderBottom={1}
      borderColor="primary.50"
      py="1rem"
      px="1.5rem"
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap="0.5rem" alignItems="center">
          <Typography
            smVariant="CaptionBold"
            variant="BodyBold"
            color="colorfulGray.800"
          >
            {convertToPersianDate(data?.updatedAt, "time")}
          </Typography>
          <Typography
            smVariant="CaptionBold"
            variant="BodyBold"
            color="colorfulGray.800"
          >
            {convertToPersianDate(data?.updatedAt)}
          </Typography>
        </Stack>
        <Typography
          smVariant="CaptionBold"
          variant="BodyBold"
          color="colorfulGray.800"
        >
          
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography smVariant="Caption" variant="Body" color="colorfulGray.500">
          دستگاه
        </Typography>
        <Typography
          smVariant="CaptionBold"
          variant="BodyBold"
          color="colorfulGray.800"
        >
          {data?.os || ""} {data?.browser || ""}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography smVariant="Caption" variant="Body" color="colorfulGray.500">
          آدرس IP
        </Typography>
        <Typography
          smVariant="CaptionBold"
          variant="BodyBold"
          color="colorfulGray.800"
        >
          {data?.ip}
        </Typography>
      </Stack>
    </Stack>
  );
};
