import { useBreakpoint } from "@hooks/useBreakpoint";
import { Tabs as MTabs, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import { useState } from "react";
import TabPanel from "./panel";
import { TabsProps } from "./types";

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const Tabs = ({
  tabs,
  initialTabIndex = 0,
  variant,
  wrapperStyle,
  isButtonType,
  leftAction,
  tabPanelStyle,
  rightAction,
  setCurrentTab,
}: TabsProps) => {
  const [value, setValue] = useState<number>(initialTabIndex);

  const handleChange = (newValue: number) => {
    setValue(newValue);
    if (setCurrentTab) {
      setCurrentTab(newValue || 0);
    }
  };
  const isMdUp = useBreakpoint("md");
  return (
    <Stack height="100%">
      <Box
        {...(leftAction && {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        })}
        sx={{
          ...(!isButtonType && {
            borderBottom: 1,
            borderColor: "divider",
          }),
          ...wrapperStyle,
        }}
      >
        <MTabs
          value={value}
          onChange={(_, val) => handleChange(val)}
          aria-label="bali tabs"
          variant={variant || "scrollable"}
          scrollButtons
          allowScrollButtonsMobile
          {...(isButtonType && {
            TabIndicatorProps: {
              style: { display: "none" },
            },
          })}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              {...a11yProps(index)}
              disabled={tab?.tabDisabled}
              sx={{
                ...tabPanelStyle,
                ...(isButtonType && {
                  textTransform: "none",
                  minHeight: "34px",
                  height: "24px",
                  width: !isMdUp ? "35%" : "unset",
                  backgroundColor: "primary.700",
                  borderRadius: "5px",
                  fontSize: "12px",
                  boxShadow: "none",
                  marginX: "6px",
                  fontWeight: "bold",
                  "&.MuiTabs-indicator": {
                    display: "none",
                  },
                  "&.Mui-selected": {
                    color: "white",
                  },
                  "&.Mui-disabled": {
                    color: "white",
                    backgroundColor: "grey",
                  },
                  "&[aria-selected='false']": {
                    backgroundColor: "primary.50",
                    color: "colorfulGray.700",
                  },

                  ...tabPanelStyle,
                }),
              }}
            />
          ))}
          {rightAction}
        </MTabs>
        {leftAction}
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </Stack>
  );
};

export default Tabs;
