import {
  Button,
  CircularProgress,
  IconButton,
  Table as MTable,
  Stack,
  SvgIcon,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { numUtils } from "@utils/num-helpers";
import * as React from "react";
import NotFoundItem from "../not-found";
import { Typography } from "../typography";
import { Pagination } from "./Pagination";
import EnhancedTableHead from "./header";
import { getComparator, stableSort } from "./helperTable";
import { HeadAction, HeadCell, Order, TableProps } from "./types";

export default function Table<T>({
  rows,
  headCells,
  isLoading,
  height = 320,
  dense,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  totalData,
}: TableProps<T>) {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof T>();
  const [selected, setSelected] = React.useState<readonly number[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof T
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // @ts-ignore
      const newSelected = rows?.map((n) => n?.id) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangeRowsPerPage = (page: number, perPage: number) => {
    if (setRowsPerPage && setPage) {
      setRowsPerPage(perPage || 5);
      setPage(page - 1 || 0);
    }
  };

  const visibleRows = React.useMemo(
    () =>
      // @ts-ignore
      stableSort(rows, getComparator(order, orderBy)),
    [order, orderBy, rows]
  );

  const { convertToPersianDate, commaformatter, toEnglish } = numUtils;

  function renderActions(items: HeadAction<T>, row: T) {
    return (
      <Stack direction={"row"} gap={1}>
        {items.actions?.map((action) =>
          action?.icon ? (
            <IconButton
              onClick={() => action.onClick(row as T)}
              sx={{ padding: "0" }}
            >
              <SvgIcon
                sx={{ transition: "0.5s", "&:hover": { color: "neutral.700" } }}
              >
                {action.icon}
              </SvgIcon>
            </IconButton>
          ) : (
            <Button key={action.label} onClick={() => action.onClick(row as T)}>
              {action.icon}
              {action.label}
            </Button>
          )
        )}
      </Stack>
    );
  }

  function renderCell(key: keyof T, row: T) {
    const findHeadCell = headCells?.find((headCell) => headCell.id === key);

    switch (findHeadCell?.type) {
      case "date":
        return dense
          ? toEnglish(
              convertToPersianDate(row[key] as string, "short-date-time")
            )
          : toEnglish(convertToPersianDate(row[key] as string, "date-time"));
      case "currency":
        return commaformatter.toCurrency(row[key] as number);
      case "persianNumber":
        return (
          <Typography className="persian-num">{row[key] as string}</Typography>
        );
      default:
        return row[key];
    }
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{ width: "100%", mb: 2, position: "relative", minHeight: height }}
        elevation={0}
      >
        {isLoading && (
          <Stack
            position="absolute"
            width="100%"
            height="100%"
            justifyContent="center"
            py="2rem"
            alignItems="center"
            bgcolor="rgba(255,255,255,0.5)"
            sx={{
              backdropFilter: "blur(3px)",
            }}
          >
            <CircularProgress color="primary" size={24} />
          </Stack>
        )}
        {!isLoading && (
          <TableContainer sx={{ marginBottom: "1rem" }}>
            <MTable
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy as string}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows?.length || 0}
                headCells={headCells as HeadCell<T>[]}
              />
              {visibleRows?.length > 0 ? (
                <TableBody>
                  {visibleRows?.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        // @ts-ignore
                        onClick={(event) => handleClick(event, row?.id)}
                        role="checkbox"
                        tabIndex={-1}
                        // @ts-ignore
                        key={row?.id as keyof T}
                        sx={{ cursor: "pointer" }}
                      >
                        {/* @ts-ignore */}
                        {Object?.keys(row)
                          ?.filter((key) => key !== "id")
                          ?.map((key) => (
                            <TableCell
                              component={key === "id" ? "th" : "td"}
                              id={key === "id" ? labelId : undefined}
                              scope={key === "id" ? "row" : undefined}
                              key={key}
                            >
                              {
                                renderCell(
                                  key as keyof T,
                                  row as T
                                ) as React.ReactNode
                              }
                            </TableCell>
                          ))}
                        {headCells?.some((x) => x.id === "actions") && (
                          <TableCell>
                            {renderActions(
                              headCells?.find(
                                (x) => x.id === "actions"
                              ) as HeadAction<T>,
                              row as T
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
              ) : (
                <TableBody>
                  <TableCell colSpan={100} sx={{ borderBottom: "none" }}>
                    <NotFoundItem />
                  </TableCell>
                </TableBody>
              )}
            </MTable>
          </TableContainer>
        )}
      </Paper>
      {!!totalData && rowsPerPage < totalData && (
        <Pagination
          totalData={totalData}
          onChange={handleChangeRowsPerPage}
          page={page}
          perPage={rowsPerPage}
          // @ts-expect-error
          setPage={setPage}
          // @ts-expect-error
          setPerPage={setRowsPerPage}
        />
      )}
    </Box>
  );
}
