import { Modal } from "@components/common/modal";
import { Box, Slide, Stack } from "@mui/material";
import { useState } from "react";
import Spot from "..";
import { Typography } from "@components/common/typography";
import { useNavigate } from "react-router-dom";
import { useGetMarketCurrency } from "@services/queries/markets/queries";
import useCurrentCurrency from "../../../_hooks/useCurrentCurrency";
import { IMarket } from "@services/queries/markets/types";
import { SelectMarketModal } from "../../select-market-modal";
import { Button } from "@components/common/button";
import { ArrowDown2, CloseSquare } from "iconsax-react";
import OrderBook from "../../order-book/sm-order-book";
import OpenOrders from "../../orders/open-orders";
import { TabItem } from "@components/common/tabs/types";

interface SpotModalProps {
  onClose: () => void;
  open: boolean;
  type: "buy" | "sell" | "";
}

const tabs: TabItem[] = [
  { label: "سفارشات باز", content: <OpenOrders dense /> },
];
const SpotMobileModal = ({ onClose, open, type }: SpotModalProps) => {
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const getMarketCurrencyQuery = useGetMarketCurrency();
  const currencies: IMarket[] =
    getMarketCurrencyQuery?.data?.result?.items || [];

  const currentCurrency = useCurrentCurrency();

  // if (!currentCurrency) {
  //   navigate(`/v2/dashboard/markets`);
  //   return <></>;
  // }

  const handleSelectCoin = (coin: IMarket) => {
    navigate(`/v2/markets/${coin.baseCurrency}`);
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        sx={{
          p: 0,
          "& .MuiStack-root": {
            borderRadius: 0,
            height: "100%",
          },
        }}
      >
        <Stack>
          <Slide direction="down" in={open}>
            <Stack gap="0.5rem">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  sx={{ width: "fit-content" }}
                  variant="text"
                  textColor="primary.500"
                  onClick={() => setOpenModal(true)}
                  endIcon={<ArrowDown2 variant="Bold" size="1rem" />}
                >
                  <Typography variant="BodyMedium" color="primary.500">
                    {currentCurrency?.localizedTitle}
                  </Typography>
                </Button>
                <CloseSquare
                  onClick={onClose}
                  size="1.5rem"
                  //   color={theme.palette.colorfulGray[500]}
                />
              </Box>

              <Stack height="100%" width="100%" direction="row">
                <Stack width="60%">
                  <Spot isMobile type={type} />
                </Stack>
                <Stack width="40%">
                  <OrderBook />
                </Stack>
              </Stack>
              <Stack width="90vw">
                <Stack my={1}>
                  <Typography>سفارشات باز</Typography>
                </Stack>
                <OpenOrders />
              </Stack>
            </Stack>
          </Slide>
        </Stack>
      </Modal>
      <SelectMarketModal
        title="انتخاب بازار"
        searchable={true}
        open={openModal}
        list={currencies}
        onSelectCoin={(coin) => handleSelectCoin(coin)}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
};

export default SpotMobileModal;
