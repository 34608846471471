import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { numUtils } from "@utils/num-helpers";
import useGetOrderBooks from "../../../_hooks/useGetOrderBooks";
import { sortOrders } from "../helperOrderBook";

interface Props {
  height?: string;
  maxHeight?: string;
}

const MediumOrderBook = ({ height, maxHeight }: Props) => {
  const { commaformatter } = numUtils;
  const { data, currentCurrency } = useGetOrderBooks();

  const isIRT = currentCurrency?.quoteCurrency === "IRT";

  return (
    <Paper elevation={0}>
      <Stack
        display={"flex"}
        flexDirection={"row"}
        mx={0.7}
        sx={{
          height: height || 660,
          maxHeight: maxHeight || 660,
        }}
      >
        <Box width={"50%"}>
          <List
            sx={{
              maxHeight: height || 660,
              height: height || 660,
              overflow: "hidden",
              px: 0.5,
            }}
          >
            <ListItem
              disablePadding
              sx={{
                mb: 1,
              }}
            >
              <ListItemText
                primary={`مقدار (${currentCurrency?.baseCurrency})`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "left",
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />
              <ListItemText
                primary={`قیمت`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "right",
                  marginRight: "4px",
                  // width: 50,
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />
            </ListItem>
            <Divider flexItem />
            {data &&
              sortOrders(data?.asks || [], "desc")
                ?.filter((item) => Number(item.total) > 0)
                .map((ask, index) => (
                  <ListItem
                    key={`${index}-${ask.price}-${new Date().getTime()}`}
                    sx={{
                      height: 24,
                      position: "relative",
                      paddingLeft: 0,
                      paddingRight: 0,
                      px: 0.5,

                      "& :first-child": {
                        // mt: 1,
                      },
                    }}
                  >
                    <ListItemText
                      primary={commaformatter.toCurrency(
                        Number(ask.amount).toFixed(
                          currentCurrency?.scale?.[
                            currentCurrency?.quoteCurrency
                          ]?.toRoundScale ?? 0
                        )
                      )}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "left",
                        width: 50,
                        zIndex: 1,
                      }}
                      primaryTypographyProps={{
                        color: "neutral.700",
                        fontSize: "0.75rem",
                      }}
                    />
                    <ListItemText
                      primary={commaformatter.toCurrency(
                        // Number(ask.price).toFixed(
                        //   isIRT
                        //     ? currentCurrency?.toRoundScale?.IRT
                        //     : currentCurrency?.toRoundScale?.USDT
                        // )
                        ask.price
                      )}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "right",
                        width: 50,
                        zIndex: 1,
                        marginRight: "3px",
                      }}
                      primaryTypographyProps={{
                        color: "error.main",
                        fontSize: "0.75rem",
                      }}
                    />
                    <Box
                      sx={{
                        position: "absolute",
                        backgroundColor: "error.50",
                        width: `${ask.filledPercentage}%`,
                        height: "100%",
                        zIndex: 0,
                        right: 0,
                      }}
                    />
                  </ListItem>
                ))}
          </List>
        </Box>
        <Box width={"50%"}>
          <List
            sx={{
              maxHeight: height || 660,
              height: height || 660,
              overflow: "hidden",
              // px: 0.5,
            }}
          >
            <ListItem
              disablePadding
              sx={{
                mb: 1,
              }}
            >
              <ListItemText
                primary={`(${currentCurrency?.quoteCurrency})`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "left",
                  marginLeft: "10px",
                  // width: 50,
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />
              <ListItemText
                primary={`مقدار (${currentCurrency?.baseCurrency})`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "right",
                  // width: 50,
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />
            </ListItem>
            <Divider flexItem />
            {data &&
              sortOrders(data?.bids || [], "desc")
                ?.filter((item) => Number(item.total) > 0)
                ?.map((bid, index) => (
                  <ListItem
                    key={`${index}-${bid.price}-${new Date().getTime()}`}
                    sx={{
                      height: 24,
                      position: "relative",
                      // paddingLeft: 0,
                      paddingRight: 0,
                      "&.MuiListItem-root": {
                        paddingLeft: 0,
                      },
                      // "& :first-child": {
                      //   mt: 1,
                      // },
                    }}
                  >
                    <ListItemText
                      primary={commaformatter.toCurrency(
                        // Number(bid.price).toFixed(
                        //   isIRT
                        //     ? currentCurrency?.toRoundScale?.IRT
                        //     : currentCurrency?.toRoundScale?.USDT
                        // )
                        bid.price
                      )}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "left",
                        width: 50,
                        zIndex: 1,
                        marginLeft: "3px",
                      }}
                      primaryTypographyProps={{
                        color: "success.main",
                        fontSize: "0.75rem",
                      }}
                    />
                    <ListItemText
                      primary={commaformatter.toCurrency(
                        Number(bid.amount).toFixed(
                          currentCurrency?.scale?.[
                            currentCurrency?.quoteCurrency
                          ]?.toRoundScale ?? 0
                        )
                      )}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "right",
                        width: 50,
                        zIndex: 1,
                      }}
                      primaryTypographyProps={{
                        color: "neutral.600",
                        fontSize: "0.75rem",
                      }}
                    />

                    <Box
                      sx={{
                        position: "absolute",
                        backgroundColor: "success.50",
                        width: `${bid.filledPercentage}%`,
                        height: "100%",
                        zIndex: 0,
                      }}
                    />
                  </ListItem>
                ))}
          </List>
        </Box>
      </Stack>
    </Paper>
  );
};

export default MediumOrderBook;
