import {
  InfiniteData,
  useInfiniteQuery,
  useQuery,
} from "@tanstack/react-query";
import { Response } from "~types/index";
import {
  MyBankAccountItem,
  CompanyBankAccountItem,
  GetMyWalletArgs,
  WalletItem,
  SumDailyDepositRes,
  DepositAutoRes,
  DailyMonthlyWithdrawRes,
  WalletItemInfoRes,
  GetLastWithdrawTransactionRes,
  GetLastWithdrawTransactionArgs,
  GetEstimateWalletRes,
  FeeListItemRes,
  GetAssetsRes,
} from "./types";
import {
  getCompanyBankAccount,
  getMyBankAccount,
  getMyWallet,
  getSumDailyDeposit,
  getDepositAuto,
  getDailyMonthlyWithdraw,
  getWalletItemInfo,
  getLastWithdrawTransaction,
  getSpecificWalletItem,
  getEstimateWallet,
  getFeeDepositList,
  getEstimateAssets,
} from "./api";
import { DEFAULT_PER_PAGE } from "@constants/index";

export const useInfiniteGetMyWallet = (data: GetMyWalletArgs) =>
  useInfiniteQuery<Response<{ items: WalletItem[]; total: number }>, void>({
    queryKey: ["my_wallet", { search: data?.search }],
    queryFn: ({ pageParam }) =>
      getMyWallet({
        page: Number(pageParam ?? 0),
        search: data?.search,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.result?.items?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
  });

export const useGetMyWallet = (data: GetMyWalletArgs) =>
  useQuery<Response<{ items: WalletItem[]; total: number }>, void>({
    queryKey: ["my_wallet", data?.search],
    queryFn: () => getMyWallet(data),
    staleTime: 0,
  });

export const useGetMyEstimateWallet = () =>
  useQuery<Response<GetEstimateWalletRes[]>, void>({
    queryKey: ["my_estimate_wallet"],
    queryFn: getEstimateWallet,
    staleTime: 0,
  });

export const useGetMyBankAccount = () => {
  return useQuery<
    Response<{ items: MyBankAccountItem[]; total: number }>,
    void
  >({
    queryKey: ["my_bank_account"],
    queryFn: getMyBankAccount,
  });
};

export const useGetCompanyBankAccount = () => {
  return useQuery<
    Response<{ items: CompanyBankAccountItem[]; total: number }>,
    void
  >({
    queryKey: ["company_bank_account"],
    queryFn: getCompanyBankAccount,
  });
};

export const useGetSumDailyDeposit = () => {
  return useQuery<Response<SumDailyDepositRes>, void>({
    queryKey: ["sum-daily-deposit"],
    queryFn: getSumDailyDeposit,
  });
};

export const useGetFeeDepositList = () => {
  return useQuery<Response<FeeListItemRes>, void>({
    queryKey: ["fee-deposit-list"],
    queryFn: getFeeDepositList,
  });
};

export const useGetDepositAuto = () => {
  return useQuery<Response<DepositAutoRes>, void>({
    queryKey: ["deposit-auto"],
    queryFn: getDepositAuto,
  });
};

export const useGetDailyMonthlyWithdraw = ({
  currencyId,
  enabled,
}: {
  enabled?: boolean;
  currencyId?: string;
}) => {
  return useQuery<Response<DailyMonthlyWithdrawRes>, void>({
    queryKey: ["daily-monthly-withdraw"],
    queryFn: () => getDailyMonthlyWithdraw(currencyId),
    enabled,
  });
};

export const useGetLastWithdrawTransaction = (
  data: GetLastWithdrawTransactionArgs
) => {
  return useQuery<Response<GetLastWithdrawTransactionRes>, void>({
    queryKey: ["last-transaction", data?.query],
    queryFn: () => getLastWithdrawTransaction(data),
  });
};

export const useGetWalletItemInfo = (id: string) => {
  return useQuery<Response<WalletItemInfoRes[]>, void>({
    queryKey: ["wallet-item-info", id],
    queryFn: () => getWalletItemInfo(id),
  });
};

export const useGetSpecificWalletItem = ({
  coinId,
  staleTime,
}: {
  coinId: string;
  staleTime?: number;
}) => {
  return useQuery<Response<WalletItem>, void>({
    queryKey: ["wallet-item", coinId],
    queryFn: () => getSpecificWalletItem({ coinId }),
    enabled: !!coinId?.length,
    staleTime: staleTime || 0,
  });
};

export const useGetEstimateAssets = () =>
  useQuery<Response<GetAssetsRes>, void>({
    queryKey: ["my_assets_wallet"],
    queryFn: () => getEstimateAssets(),
    staleTime: 0,
  });
