import ButtonTabs from "@components/common/tabs/buttonTabs";
import { TabItem } from "@components/common/tabs/types";
import { Box, Stack } from "@mui/material";
import useCurrentCurrency from "@pages/dashborad/markets/market-details/_hooks/useCurrentCurrency";
import { IMarket, IUserBalance } from "@services/queries/markets/types";
import { Dispatch, SetStateAction, useState } from "react";
import DepositModal from "../deposit-modal";
import Buy from "./buy";
import Sell from "./sell";
import { useGetUserBalance } from "@services/queries/markets/queries";

function tabsFunc(
  currentCurrency: IMarket,
  setOpenModal: Dispatch<SetStateAction<boolean>>,
  userBalanceData: IUserBalance | undefined
): TabItem[] {
  return [
    {
      label: "خرید",
      content: <Buy {...{ currentCurrency, setOpenModal, userBalanceData }} />,
    },
    {
      label: "فروش",
      content: <Sell {...{ currentCurrency, setOpenModal, userBalanceData }} />,
    },
  ];
}

interface BazzarProps {
  setCurrentBtnTab?: Dispatch<SetStateAction<number>>;
  currentBtnTab?: number;
}

const Bazzar = ({ setCurrentBtnTab, currentBtnTab }: BazzarProps) => {
  const currentCurrency = useCurrentCurrency();

  const [openModal, setOpenModal] = useState(false);

  // const orderValue = useOrdersStore((state) => state.value);

  const { data } = useGetUserBalance(currentCurrency?.id);
  const userBalanceData = data?.result;

  const table = tabsFunc(currentCurrency, setOpenModal, userBalanceData);

  return (
    <>
      <Box display={{ xs: "none", lg: "block" }}>
        <Stack gap={2} display={"flex"} flexDirection={"row"} px={1.5}>
          <Box width={"50%"}>{table[0].content}</Box>
          <Box width={"50%"}>{table[1].content}</Box>
        </Stack>
      </Box>

      <Box display={{ xs: "block", lg: "none" }} px={1.5}>
        <ButtonTabs
          tabs={table}
          variant="fullWidth"
          setCurrentBtnTab={setCurrentBtnTab}
          initialTabIndex={currentBtnTab}
        />
      </Box>
      <DepositModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        title="افزایش موجودی"
      />
    </>
  );
};

export default Bazzar;
