import { banks } from "@constants/index";
import { toast } from "@utils/toast";
import { format } from "date-fns-jalali";
import enUS from "date-fns/locale/en-US";
import unknowcoin from "@assets/images/unknown-coin.svg";
import { OrdersStatus, QueryType, TransactionsStatus } from "~types/index";

export const formatTimeMinutesSeconds = (time: number): string => {
  let strMinutes: string;
  let strSeconds: string;
  let minutes = Math.floor(time / 60);
  let seconds = Math.floor(time - minutes * 60);

  strMinutes = minutes.toString();
  strSeconds = seconds.toString();
  if (minutes < 10) strMinutes = "0" + minutes.toString();
  if (seconds < 10) strSeconds = "0" + seconds.toString();

  return strMinutes + ":" + strSeconds;
};

export const getCoinIconURL = (currencyId: string) => {
  if (currencyId === "") {
    return unknowcoin;
  }
  return `${import.meta.env.VITE_APP_API_URL}/icons/svg/color/${(
    currencyId || ""
  ).toLowerCase()}.svg`;
};

export function getColorByValue(value: number) {
  switch (true) {
    case value >= 0:
      return "success.500";
    case value < 0:
      return "error.600";
    default:
      return "secondary.400";
  }
}

interface toClipboardArgs {
  text: string;
  withToast?: boolean;
  cb?: (isCopied: boolean) => void;
}

export const toClipboard = async ({
  text,
  cb = () => {},
  withToast = true,
}: toClipboardArgs) => {
  try {
    await navigator.clipboard.writeText(text);
    withToast && toast.neutral("با موفقیت کپی شد.");
    cb(true);
    setTimeout(() => {
      cb(false);
    }, 1000);
  } catch (err) {
    console.error("Failed to copy text: ", err);
  }
};

export const bankInfo = (
  bankNumber: string,
  mode?: "Sheba" | "Card"
): { logo: string; name: string } | null => {
  if (mode === "Card") {
    return banks?.find((bank) => bankNumber?.search(bank?.code) !== -1) || null;
  }

  return (
    banks?.find(
      (bank) => bankNumber?.slice(2, 8)?.search(bank?.bank_code) !== -1
    ) || null
  );
};

export const formatCardBank = (number: string) => {
  return number?.replace(/(\d{4})(?=\d)/g, "$1 ");
};

export function convertToPersianDate(
  dateString: string,
  mode: "time" | "date" = "date"
) {
  const date = new Date(dateString);
  // @ts-ignore
  if (mode === "time") return format(date, "HH:mm", { locale: enUS });

  // @ts-ignore
  return format(date, "yyyy/MM/dd", { locale: enUS });
}

export const replaceHalfSpaceWithSpace = (input: string): string => {
  return input.replace(/\u200C/g, " ");
};

export const removeConflictingFilters = (filters: QueryType[]): QueryType[] => {
  const filterMap: { [key: string]: Set<string> } = {};

  // Populate the filterMap with field and value pairs
  filters.forEach((filter) => {
    const key = `${filter.field}:${filter.value}`;
    if (!filterMap[key]) {
      filterMap[key] = new Set();
    }
    filterMap[key].add(filter.operator);
  });

  // Filter out conflicting filters
  return filters.filter((filter) => {
    const key = `${filter.field}:${filter.value}`;
    const operators = filterMap[key];
    return !(operators.has("eq") && operators.has("!eq"));
  });
};

export const mergeFilters = (filters: QueryType[]): QueryType[] => {
  const filterMap: { [key: string]: Set<string> } = {};

  // Populate the filterMap with field and operator pairs
  filters.forEach((filter) => {
    const key = `${filter.field}:${filter.operator}`;
    if (!filterMap[key]) {
      filterMap[key] = new Set();
    }
    filterMap[key].add(filter.value as string);
  });

  // Merge values
  const mergedFilters: QueryType[] = [];
  Object.keys(filterMap).forEach((key) => {
    const [field, operator] = key.split(":");
    const values = Array.from(filterMap[key]);
    mergedFilters.push({
      field,
      operator,
      value: values.length === 1 ? values[0] : values,
    });
  });

  return mergedFilters;
};

export const transactionsStatusPersianMap: {
  [key in TransactionsStatus]: string;
} = {
  [TransactionsStatus.Created]: "ایجاد شده",
  [TransactionsStatus.OperatorCheck]: "بررسی اپراتور",
  [TransactionsStatus.Rejected]: "رد شده",
  [TransactionsStatus.Approved]: "تایید شده",
  [TransactionsStatus.Holding]: "در حال نگهداری",
  [TransactionsStatus.Processing]: "در حال پردازش",
  [TransactionsStatus.Sending]: "در حال ارسال",
  [TransactionsStatus.Error]: "خطا",
  [TransactionsStatus.Success]: "موفق",
  [TransactionsStatus.Expired]: "منقضی شده",
  [TransactionsStatus.Initialized]: "مقدمه",
  [TransactionsStatus.Cancelling]: "در حال لغو",
  [TransactionsStatus.Cancelled]: "لغو شده",
  [TransactionsStatus.InProgress]: "در حال پیشرفت",
  [TransactionsStatus.Transferred]: "منتقل شده",
  [TransactionsStatus.Failed]: "ناموفق",
  [TransactionsStatus.WaitingForUser]: "در انتظار کاربر",
  [TransactionsStatus.VerificationForWaiting]: "در انتظار تایید",
  [TransactionsStatus.Verified]: "تایید شده",
  [TransactionsStatus.Reviewing]: "در حال بررسی",
};

const ordersStatusPersianMap: Record<OrdersStatus, string> = {
  [OrdersStatus.New]: "جدید",
  [OrdersStatus.Open]: "باز",
  [OrdersStatus.Cancelling]: "در حال لغو",
  [OrdersStatus.Cancelled]: "لغو شده",
  [OrdersStatus.Filled]: "تکمیل شده",
};

export const getPersianEquivalent = (
  status: TransactionsStatus | OrdersStatus
): string => {
  if (status in transactionsStatusPersianMap) {
    return transactionsStatusPersianMap[status as TransactionsStatus];
  } else if (status in ordersStatusPersianMap) {
    return ordersStatusPersianMap[status as OrdersStatus];
  } else {
    return "نامشخص";
  }
};

type EnvironmentMode = "WebView" | "StandalonePWA" | "RegularBrowser";

declare global {
  interface Window {
    isWebView?: boolean;
    isPWA?: boolean;
    isBrowser?: boolean;
  }
}

export function detectEnv(): EnvironmentMode {
  const userAgent = navigator.userAgent;

  // Check for WebView (Android & iOS)
  const isWebView = /wv/.test(userAgent) || /WebView/.test(userAgent);

  // Check for iOS standalone mode
  const isIOSStandalone = (window.navigator as any).standalone === true;

  // Check for PWA standalone mode across platforms
  const isPWAStandalone = window.matchMedia(
    "(display-mode: standalone)"
  ).matches;

  if (isWebView) {
    console.log("Running inside a WebView");
    window.isWebView = true;
    return "WebView";
  } else if (isIOSStandalone || isPWAStandalone) {
    window.isPWA = true;
    return "StandalonePWA";
  } else {
    window.isBrowser = true;
    console.log("Running in a regular browser");
    return "RegularBrowser";
  }
}
