import { Refresh } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

const RefreshIcon = () => {
  return (
    <Tooltip title="بروزرسانی" arrow placement="top">
      <Refresh
        sx={{
          color: "neutral.500",
        }}
      />
    </Tooltip>
  );
};

export default RefreshIcon;
