import { SyncAlt as SyncAltIcon } from "@mui/icons-material/";

import { Box, IconButton, List, Stack } from "@mui/material";
import { AllPropsTabs } from "../types";
import CryptoHeader from "./header";
import RowTitle from "./rowTitle";
import CurrencyListItem from "./currency-list-item";
import { useState } from "react";

function All({
  filterCurrency,
  setBtnFilter,
  btnFilter,
  setOnFavorite,
  setSortState,
  sortState,
}: AllPropsTabs) {
  const [isCapacity, setIsCapacity] = useState(false);

  return (
    <Box>
      <CryptoHeader setBtnFilter={setBtnFilter} btnFilter={btnFilter} />

      <Stack
        direction="row"
        alignItems={"center"}
        justifyContent={"space-between"}
        px={0.5}
      >
        <RowTitle
          name="نام ارز"
          listStyle={{
            marginLeft: 1.8,
          }}
          sortState={sortState}
          setSortState={setSortState}
        />
        <RowTitle
          name="آخرین قیمت"
          // listStyle={{ marginLeft: 6.8 }}
          sortState={sortState}
          setSortState={setSortState}
        />
        {isCapacity ? (
          <RowTitle
            name="تغییر 24h"
            listItemTextStyle={{ width: 50 }}
            sortState={sortState}
            setSortState={setSortState}
          >
            <Box display={"flex"} alignItems={"center"} ml={0.8}>
              <IconButton onClick={() => setIsCapacity(!isCapacity)}>
                <SyncAltIcon sx={{ color: "neutral.600", fontSize: "14px" }} />
              </IconButton>
            </Box>
          </RowTitle>
        ) : (
          <RowTitle
            name="حجم"
            listItemTextStyle={{
              width: 50,
              textAlign: "right",
            }}
            sortState={sortState}
            setSortState={setSortState}
          >
            <Box display={"flex"} alignItems={"center"} ml={0.8}>
              <IconButton onClick={() => setIsCapacity(!isCapacity)}>
                <SyncAltIcon sx={{ color: "neutral.600", fontSize: "16px" }} />
              </IconButton>
            </Box>
          </RowTitle>
        )}
      </Stack>

      <List
        sx={{
          px: 0.5,
          overflow: "scroll",
          height: 238,
          "& :hover": {
            backgroundColor: "neutral.100",
            cursor: "pointer",
          },
        }}
        dense
      >
        {filterCurrency?.map((value) => (
          <CurrencyListItem
            value={value}
            isCapacity={isCapacity}
            setOnFavorite={setOnFavorite}
            btnFilter={btnFilter}
          />
        ))}
      </List>
    </Box>
  );
}

export default All;
