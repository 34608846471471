import Table from "@components/common/table";
import { HeadCell } from "@components/common/table/types";
import { useGetMyOrders } from "@services/queries/markets/queries";
import useCurrentCurrency from "../../../_hooks/useCurrentCurrency";
import { OrderHistory } from "@services/queries/markets/types";
import { useTheme } from "@mui/material";
import { HistoryTableData, createHistoryTableData } from "../types";
import { useState } from "react";

const createHeadCells = (): readonly HeadCell<HistoryTableData>[] => {
  return [
    {
      id: "_id",
      numeric: false,
      disablePadding: true,
      label: "شماره سفارش",
      type: "persianNumber",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "زمان سفارش",
      type: "date",
      sortable: true,
    },
    {
      id: "productId",
      numeric: false,
      disablePadding: true,
      label: "بازار",
    },
    {
      id: "side",
      numeric: false,
      disablePadding: false,
      label: "سمت",
      type: "custom",
      sortable: true,
    },
    {
      id: "type",
      numeric: false,
      disablePadding: false,
      label: "نوع",
    },
    {
      id: "filledSize",
      numeric: true,
      disablePadding: false,
      label: "مقدار پرشده",
      type: "currency",
    },
    {
      id: "size",
      numeric: true,
      disablePadding: false,
      label: "مقدار سفارش",
      sortable: true,
      type: "persianNumber",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "قیمت واحد",
      sortable: true,
      type: "currency",
    },
    {
      id: "avgPrice",
      numeric: true,
      disablePadding: false,
      label: "میانگین قیمت",
      sortable: true,
      type: "currency",
    },
    {
      id: "totalPrice",
      numeric: true,
      disablePadding: false,
      label: "مبلغ کل",
      type: "currency",
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "وضعیت",
    },
  ];
};

const OrderHistoryTab = ({ dense }: { dense?: boolean }) => {
  const theme = useTheme();
  const currentCurrency = useCurrentCurrency();
  const isIRT = currentCurrency?.quoteCurrency === "IRT";

  const [page, setPage] = useState(5);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const headCells = createHeadCells();

  const { data, isFetching } = useGetMyOrders(
    currentCurrency?.id,
    page,
    "status[]=filled&status[]=cancelled"
  );
  const renderStatus = (status: string) => {
    switch (status) {
      case "filled":
        return (
          <span style={{ color: theme.palette.success[500] }}>پر شده</span>
        );
      case "cancelled":
        return <span style={{ color: theme.palette.error[600] }}>لغو شده</span>;
      case "open":
        return <span>باز</span>;
      default:
        return "نامشخص";
    }
  };

  // @ts-expect-error
  const handleRows = (data) => {
    if (!data) return [];

    return data?.items?.map((item: OrderHistory) => {
      const totalPrice = String(Number(item?.size) * Number(item?.price));
      const type = item?.type === "limit" ? "محدود" : "بازار";
      const side =
        item?.side === "buy" ? (
          <span style={{ color: theme.palette.success[500] }}>خرید</span>
        ) : (
          <span style={{ color: theme.palette.error[600] }}>فروش</span>
        );
      const status = renderStatus(item?.status);

      return createHistoryTableData(
        item?._id,
        item?.createdAt,
        item?.productId,
        side,
        type,
        Number(item?.filledSize)?.toFixed(
          currentCurrency?.scale?.[currentCurrency?.baseCurrency]?.toRoundScale
        ),
        Number(item?.size)?.toFixed(
          currentCurrency?.scale?.[currentCurrency?.baseCurrency]?.toRoundScale
        ),
        item?.price,
        item?.avgPrice,
        Number(totalPrice).toFixed(
          currentCurrency?.scale?.[currentCurrency?.quoteCurrency]
            ?.fromRoundScale
        ),
        status
      );
    });
    // .reverse();
  };

  return (
    <Table
      headCells={headCells}
      rows={handleRows(data)}
      isLoading={isFetching}
      dense={dense}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      // @ts-expect-error
      totalData={data?.total}
    />
  );
};

export default OrderHistoryTab;
