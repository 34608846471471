import MarketFilterAllIcon from "@assets/icons/MarketFilterAllIcon";
import MarketFilterGreenIcon from "@assets/icons/MarketFilterGreenIcon";
import MarketFilterRedIcon from "@assets/icons/MarketFilterRedIcon";
import { Typography } from "@components/common/typography";
import {
  TrendingDown as TrendingDownIcon,
  TrendingUp as TrendingUpIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
} from "@mui/material";
import { useOrdersStore } from "@store/orders";
import { numUtils } from "@utils/num-helpers";
import { useState } from "react";
import useCurrencyUpdate from "../../../_hooks/useCurrencyUpdate";
import useGetLastTrade from "../../../_hooks/useGetLastTrade";
import useGetOrderBooks from "../../../_hooks/useGetOrderBooks";
import { sortOrders } from "../helperOrderBook";
import { FilterTypeIcon } from "../types";

const MobileOrderBook = () => {
  const { commaformatter } = numUtils;
  const { data, currentCurrency } = useGetOrderBooks();

  const headerData = useCurrencyUpdate();

  const { lastPriceCoin } = useGetLastTrade();
  const isSellSide = lastPriceCoin?.side === "sell";

  const [filter, setFilter] = useState<FilterTypeIcon>("All");

  const setOrderValue = useOrdersStore((state) => state.setValue);

  const isIRT = currentCurrency?.quoteCurrency === "IRT";

  return (
    <Paper elevation={0}>
      <Stack
        display={"flex"}
        flexDirection={"column"}
        mx={0.7}
        sx={{
          height: 475,
          maxHeight: 475,
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            justifyContent: { xs: "flex-start" },
          }}
        >
          <Stack direction="row-reverse" alignItems="center" spacing={1}>
            <IconButton
              sx={{
                height: "24px",
                width: "24px",

                opacity: filter === "Red" ? 0.9 : 0.5,
              }}
              onClick={() => {
                setFilter("Red");
              }}
            >
              <MarketFilterGreenIcon />
            </IconButton>
            <IconButton
              sx={{
                height: "24px",
                width: "24px",
                opacity: filter === "Green" ? 1 : 0.5,
              }}
              onClick={() => {
                setFilter("Green");
              }}
            >
              <MarketFilterRedIcon />
            </IconButton>
            <IconButton
              sx={{
                height: "24px",
                width: "24px",
                opacity: filter === "All" ? 0.9 : 0.5,
              }}
              onClick={() => {
                setFilter("All");
              }}
            >
              <MarketFilterAllIcon />
            </IconButton>
          </Stack>
        </Stack>
        <Box width={"100%"}>
          <List
            sx={{
              minHeight: 230,
              maxHeight: filter === "Green" ? 470 : 230,
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <ListItem
              disablePadding
              sx={{
                mb: 1,
              }}
            >
              <ListItemText
                primary={`قیمت (${currentCurrency?.baseCurrency})`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "left",
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />
              <ListItemText
                primary={`مقدار`}
                sx={{
                  textWrap: "nowrap",
                  textAlign: "right",
                  marginRight: "4px",
                  // width: 50,
                }}
                primaryTypographyProps={{
                  color: "neutral.600",
                  fontSize: "0.75rem",
                }}
              />
            </ListItem>
            <Divider flexItem />
            <Stack component="ul" justifyContent="flex-end" mt="auto">
              {data &&
                sortOrders(data?.asks || [], "desc")
                  ?.filter((item) => Number(item.total) > 0)
                  .map((ask, index) => (
                    <ListItem
                      key={`${index}-${ask.price}-${new Date().getTime()}`}
                      onClick={() => setOrderValue(ask.price)}
                      sx={{
                        height: 24,
                        position: "relative",
                        paddingLeft: 0,
                        paddingRight: 0,
                        display: filter === "Red" ? "none" : "flex",

                        "& :first-child": {
                          // mt: 1,
                        },
                      }}
                    >
                      <ListItemText
                        primary={commaformatter.toCurrency(
                          // Number(ask.price).toFixed(
                          //   isIRT
                          //     ? currentCurrency?.toRoundScale?.IRT
                          //     : currentCurrency?.toRoundScale?.USDT
                          // )
                          ask.price
                        )}
                        sx={{
                          textWrap: "nowrap",
                          textAlign: "left",
                          width: 50,
                          zIndex: 1,
                        }}
                        primaryTypographyProps={{
                          color: "error.main",
                          fontSize: "0.75rem",
                        }}
                      />
                      <ListItemText
                        primary={commaformatter.toCurrency(
                          Number(ask.amount).toFixed(
                            currentCurrency?.scale?.[
                              currentCurrency?.quoteCurrency
                            ]?.toRoundScale ?? 0
                          )
                        )}
                        sx={{
                          textWrap: "nowrap",
                          textAlign: "right",
                          width: 50,
                          zIndex: 1,
                          marginRight: "3px",
                        }}
                        primaryTypographyProps={{
                          color: "neutral.700",
                          fontSize: "0.75rem",
                        }}
                      />
                      <Box
                        sx={{
                          position: "absolute",
                          backgroundColor: "error.50",
                          width: `${ask.filledPercentage}%`,
                          height: "100%",
                          zIndex: 0,
                          right: 0,
                        }}
                      />
                    </ListItem>
                  ))}
            </Stack>
          </List>
        </Box>

        <Stack
          width={"100%"}
          display={filter !== "All" ? "none" : "flex !important"}
          mt={data?.asks?.length ?? 0 > 0 ? 0 : 19.5}
        >
          <Divider flexItem />
          <Stack
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-around"}
            flexWrap={"wrap-reverse"}
            alignItems={"center"}
            height={`50px !important`}
          >
            {/* {!currentCurrency?.id?.includes("USDT") && (
              <Typography color="neutral.700" variant="Body">
                USDT {commaformatter.toCurrency(currentCurrency?.todayPrice)}
                <AlmostEqualIcon fontSize={"inherit"} />
              </Typography>
            )} */}
            <Typography
              color={isSellSide ? "error.500" : "success.500"}
              variant="Body"
            >
              {isSellSide ? (
                <TrendingDownIcon
                  sx={{
                    color: "error.500",
                    mr: 0.5,
                    mb: -1,
                  }}
                />
              ) : (
                <TrendingUpIcon
                  sx={{
                    color: "success.500",
                    mr: 0.5,
                    mb: -1,
                  }}
                />
              )}
              {commaformatter.toCurrency(headerData?.price)}
            </Typography>
          </Stack>
          <Divider flexItem />
        </Stack>

        <Box width={"100%"} display={filter === "Green" ? "none" : "block"}>
          <List
            sx={{
              maxHeight: filter === "Red" ? 430 : 190,
              height: filter === "Red" ? 430 : 190,
              overflow: "hidden",
              marginTop: filter === "All" ? "0" : "-1rem",

              // px: 0.5,
            }}
          >
            {data &&
              sortOrders(data?.bids || [], "desc")
                ?.filter((item) => Number(item.total) > 0)
                ?.map((bid, index) => (
                  <ListItem
                    key={`${index}-${bid.price}-${new Date().getTime()}`}
                    onClick={() => setOrderValue(bid.price)}
                    sx={{
                      height: 24,
                      position: "relative",
                      paddingRight: 0,

                      "&.MuiListItem-root": {
                        paddingLeft: 0,
                      },
                      "& :first-child": {},
                    }}
                  >
                    <ListItemText
                      primary={commaformatter.toCurrency(
                        // Number(bid.price).toFixed(
                        //   isIRT
                        //     ? currentCurrency?.toRoundScale?.IRT
                        //     : currentCurrency?.toRoundScale?.USDT
                        // )
                        bid.price
                      )}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "left",
                        width: 50,
                        zIndex: 1,
                        marginLeft: "3px",
                      }}
                      primaryTypographyProps={{
                        color: "success.main",
                        fontSize: "0.75rem",
                      }}
                    />
                    <ListItemText
                      primary={commaformatter.toCurrency(
                        Number(bid.amount).toFixed(
                          currentCurrency?.scale?.[
                            currentCurrency?.quoteCurrency
                          ]?.toRoundScale ?? 0
                        )
                      )}
                      sx={{
                        textWrap: "nowrap",
                        textAlign: "right",
                        width: 50,
                        zIndex: 1,
                      }}
                      primaryTypographyProps={{
                        color: "neutral.600",
                        fontSize: "0.75rem",
                      }}
                    />

                    <Box
                      sx={{
                        position: "absolute",
                        backgroundColor: "success.50",
                        width: `${bid.filledPercentage}%`,
                        height: "100%",
                        zIndex: 0,
                      }}
                    />
                  </ListItem>
                ))}
          </List>
        </Box>
      </Stack>
    </Paper>
  );
};

export default MobileOrderBook;
