import { SvgIcon, SvgIconProps } from "@mui/material";

export const StarPattern = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="93"
        height="34"
        viewBox="0 0 93 34"
        fill="none"
      >
        <path
          d="M1 18.5L1.35267 19.0146L1.95106 19.191L1.57063 19.6854L1.58779 20.309L1 20.1L0.412215 20.309L0.429366 19.6854L0.0489435 19.191L0.647329 19.0146L1 18.5Z"
          fillOpacity="0.5"
        />
        <path
          d="M18 0.5L18.3527 1.01459L18.9511 1.19098L18.5706 1.68541L18.5878 2.30902L18 2.1L17.4122 2.30902L17.4294 1.68541L17.0489 1.19098L17.6473 1.01459L18 0.5Z"
          fillOpacity="0.5"
        />
        <path
          d="M33.5 31L34.029 31.7719L34.9266 32.0365L34.356 32.7781L34.3817 33.7135L33.5 33.4L32.6183 33.7135L32.644 32.7781L32.0734 32.0365L32.971 31.7719L33.5 31Z"
          fillOpacity="0.4"
        />
        <path
          d="M91.5 14.5L92.029 15.2719L92.9266 15.5365L92.356 16.2781L92.3817 17.2135L91.5 16.9L90.6183 17.2135L90.644 16.2781L90.0734 15.5365L90.971 15.2719L91.5 14.5Z"
          fillOpacity="0.6"
        />
        <path
          d="M8.5 29L9.38168 30.2865L10.8776 30.7275L9.92658 31.9635L9.96946 33.5225L8.5 33L7.03054 33.5225L7.07342 31.9635L6.12236 30.7275L7.61832 30.2865L8.5 29Z"
          fillOpacity="0.5"
        />
        <path
          d="M51.5 0L52.029 0.771885L52.9266 1.03647L52.356 1.77812L52.3817 2.71353L51.5 2.4L50.6183 2.71353L50.644 1.77812L50.0734 1.03647L50.971 0.771885L51.5 0Z"
          fillOpacity="0.5"
        />
        <path
          d="M40.5 2.5L41.029 3.27188L41.9266 3.53647L41.356 4.27812L41.3817 5.21353L40.5 4.9L39.6183 5.21353L39.644 4.27812L39.0734 3.53647L39.971 3.27188L40.5 2.5Z"
          fillOpacity="0.5"
        />
        <path
          d="M41.5 7L42.3817 8.28647L43.8776 8.72746L42.9266 9.96353L42.9695 11.5225L41.5 11L40.0305 11.5225L40.0734 9.96353L39.1224 8.72746L40.6183 8.28647L41.5 7Z"
          fillOpacity="0.5"
        />
      </svg>
    </SvgIcon>
  );
};
