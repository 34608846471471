import { SvgIcon, SvgIconProps } from "@mui/material";

export const TomanIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M21.7504 8.81578V10.5718H17.3912V8.81578H21.7504Z" />
        <path d="M21.5299 15.4239L21.4735 17.4727H16.9189L17.2372 15.4239H21.5299Z" />
        <path d="M17.3916 15.4237C17.4532 15.4237 17.4273 15.7165 17.4273 16.7023C17.4273 17.6265 17.4532 17.4724 17.3916 17.4724H16.1747C15.9591 19.7829 14.4649 21.1538 12.2006 21.1538H10.0903L9.9209 19.1051H12.3238C13.5407 19.1051 14.2493 18.4582 14.2493 17.4724H13.171C10.9221 17.4724 10.0749 16.5481 10.0749 14.5765C10.0749 12.574 11.3072 11.1877 13.171 11.1877C15.0503 11.1877 16.2055 12.497 16.2055 14.6689V15.4237H17.3916ZM12.0158 14.4995C12.0158 15.1464 12.3084 15.4391 13.2481 15.4391H14.2493V14.6381C14.2493 13.6369 13.8334 13.1286 13.1094 13.1286C12.3855 13.1286 12.0158 13.7139 12.0158 14.4995Z" />
        <path d="M4.36703 15.8509C4.93696 15.8509 5.15261 15.6352 5.15261 15.1269V14.6956C5.15261 12.6932 6.38488 11.4609 8.32573 11.4609C10.2666 11.4609 11.3448 12.6161 11.3448 14.711C11.3448 16.7289 10.1895 17.8995 8.24871 17.8995C7.20127 17.8995 6.52352 17.6069 6.21545 17.1602C5.83036 17.6839 5.27583 17.8995 4.36703 17.8995H3.96654C3.90492 17.8995 3.8125 17.8533 3.8125 16.9137C3.8125 15.9279 3.90492 15.8509 3.96654 15.8509H4.36703ZM9.40397 14.711C9.40397 13.8484 9.03429 13.4171 8.24871 13.4171C7.46313 13.4171 7.09345 13.8484 7.09345 14.711C7.09345 15.435 7.46313 15.8663 8.24871 15.8663C9.03429 15.8663 9.40397 15.4966 9.40397 14.711Z" />
        <path d="M3.96294 15.8492C4.03995 15.8492 4.11697 15.9262 4.11697 16.9121C4.11697 17.8363 4.03995 17.8979 3.96294 17.8979H3.40841C1.65241 17.8979 0.774414 16.8351 0.774414 15.0174V7.97804H2.74606V14.8788C2.74606 15.5412 2.99252 15.8492 3.54704 15.8492H3.96294Z" />
        <path d="M6.018 2.71147H3.90772V0.955475H6.018V2.71147ZM4.92435 8.84207C2.22874 8.84207 0.75 7.2093 0.75 4.48288V1.40218H2.70624V4.43666C2.70624 5.97701 3.384 6.7934 4.92435 6.7934H5.54049C7.01922 6.7934 7.91263 5.97702 7.91263 4.5907V0.955475H9.86887V4.43666C9.86887 7.25551 8.11287 8.84207 5.54049 8.84207H4.92435Z" />
      </svg>
    </SvgIcon>
  );
};
