import { AuthLevelCard } from "@components/common/auth-level-card";
import { Button } from "@components/common/button";
import { Typography } from "@components/common/typography";
import { Stack, SvgIcon } from "@mui/material";
import { useUserStore } from "@store/user";
import {
  ArrangeVertical,
  ArrowLeft2,
  Cards,
  DocumentText,
  Gift,
  Headphone,
  People,
  ReceiptDiscount,
  ReceiptText,
  Setting2,
  Verify,
} from "iconsax-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutModal } from "../logout-modal";

const listActions = [
  {
    id: 1,
    title: "تاریخچه تراکنش‌ها",
    href: `/v2/dashboard/financial/transactions`,
    icon: <ReceiptText variant="Bold" color="white" />,
    isLinkOutSource: false,
  },
  {
    id: 2,
    title: "تنظیمات",
    href: `/v2/dashboard/settings`,
    icon: <Setting2 variant="Bold" color="white" />,
    isLinkOutSource: false,
  },
  {
    id: 3,
    title: "حساب‌های بانکی",
    href: "/v2/dashboard/profile/banks",
    icon: <Cards variant="Bold" color="white" />,
    isLinkOutSource: false,
  },
  {
    id: 4,
    title: "محدودیت‌های واریز و برداشت",
    href: "/v2/dashboard/profile",
    icon: <ArrangeVertical variant="Bold" color="white" />,
    isLinkOutSource: false,
  },
  {
    id: 5,
    title: "کارت هدیه",
    href: `/v2/dashboard/gift/redeem`,
    icon: <Gift variant="Bold" color="white" />,
    isLinkOutSource: false,
  },
  {
    id: 6,
    title: "دعوت دوستان",
    href: `/v2/dashboard/referral`,
    icon: <People variant="Bold" color="white" />,
    isLinkOutSource: false,
  },
  {
    id: 7,
    title: "پشتیبانی",
    href: "SUPPORT",
    icon: <Headphone variant="Bold" color="white" />,
    isLinkOutSource: false,
  },
  {
    id: 8,
    title: "بلاگ",
    href: "https://balinex.com/mag",
    icon: <DocumentText variant="Bold" color="white" />,
    isLinkOutSource: true,
  },
  {
    id: 9,
    title: "کارمزدها",
    href: "/v2/dashboard/profile",
    icon: <ReceiptDiscount variant="Bold" color="white" />,
    isLinkOutSource: false,
  },
];

interface ProfileDropdownNavigationProps {
  listOfDisplayId: number[] | "ALL";
  isExitButton?: boolean;
}

export const ProfileDropdownNavigation = ({
  listOfDisplayId,
  isExitButton,
}: ProfileDropdownNavigationProps) => {
  const user = useUserStore((state) => state?.user);
  const navigate = useNavigate();

  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const authenticationLevel: "BASE" | "NOT_VERIFIED" | "PRO" = useMemo(() => {
    if (
      !!user?.IDverification &&
      !!user?.mobileVerification &&
      !!user?.personalAttachmentsVerification
    )
      return "PRO";

    if (!!user?.IDverification && !!user?.mobileVerification) return "BASE";

    return "NOT_VERIFIED";
  }, [user]);

  return (
    <Stack p="1rem" gap="1.25rem">
      {/* Profile Actions  */}
      <Stack
        onClick={() => navigate("/v2/dashboard/profile")}
        sx={{ cursor: "pointer" }}
        px="0.75rem"
        p="1rem"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        borderRadius="0.625rem"
        bgcolor="white"
      >
        <Stack>
          <Stack direction="row" alignItems="center" gap="0.25rem">
            {authenticationLevel === "PRO" && (
              <SvgIcon sx={{ color: (theme) => theme.palette.success[700] }}>
                <Verify variant="Bold" size="1.5rem" />
              </SvgIcon>
            )}
            <Typography>
              {user?.firstName && user?.lastName
                ? `${user?.firstName} ${user?.lastName}`
                : "کاربر مهمان"}
            </Typography>
            {authenticationLevel !== "PRO" && (
              <Stack
                component="span"
                px="0.5rem"
                py="0.25rem"
                bgcolor="error.A100"
                borderRadius="6.25rem"
              >
                <Typography variant="CaptionMedium" color="error.800">
                  {authenticationLevel === "NOT_VERIFIED" && "احراز نشده"}
                  {authenticationLevel === "BASE" && "احراز پایه"}
                </Typography>
              </Stack>
            )}
          </Stack>
          {!!user?.mobileNumber && (
            <Typography color="colorfulGray.500" variant="ExtendedBodyMedium">
              {user?.mobileNumber}
            </Typography>
          )}
        </Stack>
        <SvgIcon sx={{ color: (theme) => theme.palette.colorfulGray[500] }}>
          <ArrowLeft2 size="1.5rem" />
        </SvgIcon>
      </Stack>

      {/* Your Current Withdrawal */}
      {authenticationLevel !== "PRO" && <AuthLevelCard />}

      {/* Other Actions */}
      <Stack
        bgcolor="white"
        borderRadius="0.625rem"
        component="ul"
        py="1rem"
        px="0.75rem"
        gap="2rem"
      >
        {listActions?.map(
          (item) =>
            (listOfDisplayId === "ALL" ||
              listOfDisplayId?.includes(item?.id)) && (
              <Stack
                component="li"
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (item.href === "SUPPORT") return window.Goftino.toggle();

                  if (item?.isLinkOutSource) {
                    return (window.location.href = item?.href);
                  }

                  return navigate(item?.href);
                }}
              >
                <Stack direction="row" gap="0.75rem" alignItems="center">
                  <Stack
                    component="span"
                    p="0.5rem"
                    borderRadius="50%"
                    bgcolor="primary.500"
                    sx={{ aspectRatio: "1/1" }}
                  >
                    {item?.icon}
                  </Stack>
                  <Typography variant="ExtendedBody">{item?.title}</Typography>
                </Stack>
                <SvgIcon
                  sx={{ color: (theme) => theme.palette.colorfulGray[500] }}
                >
                  <ArrowLeft2 size="1.25rem" />
                </SvgIcon>
              </Stack>
            )
        )}
      </Stack>

      {!!isExitButton && (
        <>
          <Button
            onClick={() => setIsLogoutModal(true)}
            variant="outlined"
            textColor="error.500"
          >
            <Typography variant="BodyBold">خروج از حساب کاربری</Typography>
          </Button>

          <LogoutModal
            onClose={() => setIsLogoutModal(false)}
            open={isLogoutModal}
          />
        </>
      )}
    </Stack>
  );
};
