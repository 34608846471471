import { CircularProgress, Stack } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { Typography } from "../typography";

interface InfiniteScrollProps {
  fetchMore: () => Promise<void>;
  children: React.ReactNode;
  loadingComponent?: React.ReactNode;
}

export const InfiniteScroll = ({
  children,
  fetchMore,
  loadingComponent = (
    <Stack alignItems="center" gap="1rem">
      <CircularProgress color="inherit" />
      <Typography>لطفا منتظر بمانید ...</Typography>
    </Stack>
  ),
}: InfiniteScrollProps) => {
  const [isFetching, setIsFetching] = useState(false);
  const observer = useRef<IntersectionObserver>();
  const panel = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.7,
    };

    observer.current = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !isFetching) {
          setIsFetching(true);
          fetchMore().then(() => {
            setIsFetching(false);
          });
        }
      });
    }, options);

    if (observer.current) {
      observer.current.observe(panel.current as Element);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, [fetchMore, isFetching]);

  return (
    <Stack>
      {children}
      <Stack ref={panel} sx={{ height: "10px" }}></Stack>
      {isFetching && loadingComponent}
    </Stack>
  );
};
