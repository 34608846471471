import { SvgIcon, SvgIconProps } from "@mui/material";

interface BallonPatternProps extends SvgIconProps {
  mode: "light" | "dark";
}

export const BallonPattern = (props: BallonPatternProps) => {
  if (props.mode === "light") {
    return (
      <SvgIcon {...props}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="133"
          height="149"
          viewBox="0 0 133 149"
          fill="none"
        >
          <ellipse
            cx="101.5"
            cy="107.5"
            rx="101.5"
            ry="107.5"
            transform="matrix(-1 0 0 1 133 0)"
            fill="url(#paint0_radial_40_6900)"
            fill-opacity="0.1"
          />
          <defs>
            <radialGradient
              id="paint0_radial_40_6900"
              cx="0"
              cy="0"
              r="1"
              gradientUnits="userSpaceOnUse"
              gradientTransform="translate(130.882 73.5526) rotate(121.49) scale(136.019 132.692)"
            >
              <stop stop-color="white" />
              <stop offset="1" />
            </radialGradient>
          </defs>
        </svg>
      </SvgIcon>
    );
  }

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="184"
        height="173"
        viewBox="0 0 184 173"
        fill="none"
      >
        <g filter="url(#filter0_d_40_6886)">
          <ellipse
            cx="105.5"
            cy="111.5"
            rx="105.5"
            ry="111.5"
            transform="matrix(-1 0 0 1 231 -77)"
            fill="url(#paint0_radial_40_6886)"
            fill-opacity="0.1"
            shape-rendering="crispEdges"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_40_6886"
            x="0"
            y="-77"
            width="271"
            height="283"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="10" dy="30" />
            <feGaussianBlur stdDeviation="15" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_40_6886"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_40_6886"
              result="shape"
            />
          </filter>
          <radialGradient
            id="paint0_radial_40_6886"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(136.039 76.2895) rotate(121.544) scale(141.161 137.842)"
          >
            <stop stop-color="white" />
            <stop offset="1" />
          </radialGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};
