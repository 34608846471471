import { Button } from "@components/common/button";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Divider, Stack } from "@mui/material";
import { useSendLimitOrder } from "@services/queries/markets/mutations";
import {
  IOrderPreview,
  IOrderPreviewArgs,
} from "@services/queries/markets/types";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "@utils/toast";
import { useMemo } from "react";

interface VerifyModalProps {
  title: string;
  data?: IOrderPreview;
  onClose: () => void;
  open: boolean;
  resetForm: () => void;
}

const VerifyModalBazzar = ({
  open,
  onClose,
  title,
  data,
  resetForm,
}: VerifyModalProps) => {
  const previewData = useMemo(() => data, [data]);

  const orderMutation = useSendLimitOrder();
  const queryClient = useQueryClient();

  const isMarket = data?.type === "market";
  const isBuy = data?.side === "buy";

  const unit = useMemo(() => (isBuy ? data?.source : data?.target), [data]);

  const marketMutateData = {
    productId: previewData?.productId ?? "",
    funds: data?.funds ?? 0,
    side: previewData?.side ?? "",
    size: data?.size ?? 0,
    type: previewData?.type ?? "",
    price: previewData?.price ?? 0,
    timeInForce: new Date().toISOString(),
  };

  const handleOrder = () => {
    const orderData = marketMutateData;
    if ("price" in orderData) {
      orderMutation.mutate(orderData as IOrderPreviewArgs, {
        onSuccess: (res) => {
          if (res.status === 400) {
            toast.error(res?.message?.fa);
            // data?.resetForm();
            return onClose();
          }
          queryClient.invalidateQueries({
            queryKey: ["my_order", data?.source],
          });
          resetForm();
          onClose();
        },
        onError: (error) => {
          console.log("date mutation error", error);
        },
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose} title={title}>
      <Stack width="100%" sx={{ borderRadius: { xs: "2rem 0 2rem 0" } }}>
        <Divider flexItem sx={{ mb: 1 }} />
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Typography>بازار</Typography>
          <Typography>{previewData?.productId}</Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Typography>سمت</Typography>
          <Typography color={isBuy ? "success.500" : "error"}>
            {isBuy ? "خرید" : "فروش"}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Typography>نوع</Typography>
          <Typography>{!isMarket ? "محدود" : "بازار"}</Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Typography variant="body2">قیمت</Typography>
          <Stack direction={"row"} gap={1}>
            {isMarket ? (
              <Typography variant="body2" dir="ltr">
                بهترین قیمت بازار
              </Typography>
            ) : (
              <Typography variant="body2" dir="ltr">
                {previewData?.priceFormatted}
              </Typography>
            )}
          </Stack>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Typography variant="body2">مقدار</Typography>
          <Stack direction={"row"} gap={1}>
            <Typography variant="body2" dir="ltr">
              {`${previewData?.sizeFormatted} ${unit}`}
            </Typography>
          </Stack>
        </Stack>

        {/* <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Typography variant="body2">قیمت کل</Typography>
          <Stack direction={"row"} gap={1}>
            <Typography variant="body2" dir="ltr">
              {previewData?.totalPayFormatted}
            </Typography>
          </Stack>
        </Stack> */}
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Typography>کارمزد</Typography>
          <Typography dir="ltr">
            {previewData?.feeFormatted} {unit}
          </Typography>
        </Stack>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          my={1}
        >
          <Typography>دریافت تقریبی</Typography>
          <Typography dir="ltr">
            {previewData?.totalReceiveFormatted} {unit}
          </Typography>
        </Stack>
        <Divider flexItem sx={{ my: 1 }} />
        <Stack direction={"row"} gap={1} mt={1}>
          <Button
            fullWidth
            variant="outlined"
            sx={{ height: 40 }}
            onClick={onClose}
          >
            انصراف
          </Button>
          <Button
            fullWidth
            sx={{ height: 40 }}
            onClick={handleOrder}
            disabled={orderMutation.isPending}
          >
            تایید
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default VerifyModalBazzar;
