import { Button } from "@components/common/button";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { ArrowDropDown, DownloadSharp } from "@mui/icons-material";
import { InputAdornment, Stack } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { OtcFormSchema } from "../../_validation";
import { z } from "zod";
import { Image } from "@components/common/image";
import { toast } from "@utils/toast";
import { GetPairDetailsRes } from "@services/queries/otc/types";
import { OtcMethod } from "../../_types";
import { numUtils } from "@utils/num-helpers";
import { Link } from "react-router-dom";

interface ReceiveFormProps {
  onSelectCoin: () => void;
  balance: string | number;
  unit_en: string;
  unit_fa: string;
  unit_icon: string;
  dataDetails?: GetPairDetailsRes;
  method: OtcMethod;
}

export const PaymentForm = ({
  balance,
  onSelectCoin,
  unit_en,
  unit_fa,
  unit_icon,
  dataDetails,
  method,
}: ReceiveFormProps) => {
  const { control, setValue } =
    useFormContext<z.output<typeof OtcFormSchema>>();

  const handleReceiveInput = (val: number, total?: boolean) => {
    const price = dataDetails?.price;
    if (price) {
      const receive = method === OtcMethod.Buy ? val / price : val * price;

      if (!total) {
        setValue(
          "receive",
          (receive || 0)?.toFixed(dataDetails?.toRoundScale).toString()
        );
      } else {
        setValue("receive", (receive || 0).toString());
      }
    }
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mt="1rem"
      >
        <Typography variant="body1" color="primary.800">
          پرداخت می‌کنم:
        </Typography>
        {/* prettier-ignore */}
        <Link to="/v2/dashboard/wallet/toman/deposit">
          <Stack id={`add-money-${method === OtcMethod.Buy ? "buy" : "sell"}`} direction="row" alignItems="center" gap="0.5rem">
            <Typography id={`add-money-${method === OtcMethod.Buy ? "buy" : "sell"}`} variant="caption" color="secondary.600">
              افزایش موجودی
            </Typography>
            <DownloadSharp
              id={`add-money-${method === OtcMethod.Buy ? "buy" : "sell"}`}
              sx={{
                fontSize: "0.9rem",
                color: (theme) => theme?.palette?.secondary?.[600],
              }}
              />
            </Stack>
          </Link>
      </Stack>

      <Controller
        control={control}
        name="payment"
        render={({ field: { value, onChange, ...otherFields } }) =>
          // prettier-ignore
          <TextField
            className="persian-num"
            inputMode="numeric"
            placeholder={dataDetails ? `${numUtils.commaformatter.toCurrency(dataDetails?.minAmount || "")} - ${numUtils.commaformatter.toCurrency(dataDetails?.maxAmount || "")} ${unit_fa}` : ""}
            inputProps={{ maxLength: 15 }}
            value={numUtils.commaformatter.toCurrency(value || "")}
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              const val = numUtils.commaformatter.toRaw(e.target.value);
              if (val === "" || regex.test(val)) {
                onChange(val);
                handleReceiveInput(+val);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Stack borderRight="1px solid" borderColor="neutral.400">
                    <Button onClick={onSelectCoin} variant="text" size="small">
                      <Stack direction="row" alignItems="center">
                        <Typography variant="body2" color="neutral.600">
                          {unit_en}
                        </Typography>
                        <Image
                          src={unit_icon}
                          width="1.5rem"
                          height="1.5rem"
                          alt={unit_en}
                          sx={{ ml: "0.5rem" }}
                        />
                        <ArrowDropDown
                          sx={{
                            fontSize: "1.5rem",
                            color: (theme) => theme?.palette?.primary?.[800],
                          }}
                        />
                      </Stack>
                    </Button>
                  </Stack>
                </InputAdornment>
              ),
            }}
            {...otherFields}
          />
        }
      />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="caption" color="primary.800">
          موجودی من:
        </Typography>
        <Typography
          className="persian-num"
          onClick={() => {
            if (Number(balance) <= 0) {
              return toast.error("موجودی شما کافی نیست.");
            }
            setValue("payment", dataDetails?.accounts[0]?.available || "0");
            handleReceiveInput(
              +(dataDetails?.accounts[0]?.available || 0, true)
            );
          }}
          variant="caption"
          color="primary.800"
        >
          {balance} {unit_fa}
        </Typography>
      </Stack>
    </>
  );
};
