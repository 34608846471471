import {
  Backdrop,
  CircularProgress,
  Grid,
  Stack,
  SxProps,
  Typography,
} from "@mui/material";
import {
  TopNavigation,
  TopNavigationProps,
} from "@components/layout/top-navigation";
import { BottomNavigation } from "../bottom-navigation";
import { useBreakpoint } from "@hooks/useBreakpoint";
import Container from "@components/common/container";
import { SideNavigation } from "@components/common/side-navigation";

interface IWrapperProps {
  loading?: boolean;
  children?: React.ReactNode;
  hideTopNavigation?: boolean;
  hideBottomNavigation?: boolean;
  hideSideNavigation?: boolean;
  isFullWidth?: boolean;
  sxContainer?: SxProps;
  isNoPaddingModeContainer?: boolean;
  isScreenHeight?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export const Wrapper = ({
  loading,
  children,
  hideTopNavigation,
  hideBottomNavigation,
  hideSideNavigation,
  isFullWidth,
  sxContainer,
  isNoPaddingModeContainer,
  maxWidth,
  isScreenHeight,
  ...navigationProps
}: IWrapperProps & TopNavigationProps) => {
  const isMdUp = useBreakpoint("md");

  const renderComponent = () => (
    <Grid flex={1} container columnSpacing="1.5rem">
      <Grid
        sx={{
          display: hideSideNavigation ? "none" : { xs: "none", md: "flex" },
        }}
        item
        xs={3}
      >
        <SideNavigation />
      </Grid>
      <Grid item xs={12} flex={1} md={hideSideNavigation ? 12 : 9}>
        {children}
      </Grid>
    </Grid>
  );

  return (
    <Stack
      minHeight={!isScreenHeight ? "auto" : "100dvh"}
      display="flex"
      position="relative"
      pb={!hideBottomNavigation && !isMdUp ? "60px" : 0}
    >
      {!hideTopNavigation && (
        <TopNavigation maxWidth={maxWidth} {...navigationProps} />
      )}
      {/*  */}
      <Backdrop sx={{ zIndex: 999, color: "#fff" }} open={!!loading}>
        <Stack alignItems="center" gap="1rem">
          {!!loading && <CircularProgress color="inherit" />}
          <Typography>لطفا منتظر بمانید ...</Typography>
        </Stack>
      </Backdrop>
      <Stack sx={{ ...sxContainer }}>
        {isFullWidth ? (
          renderComponent()
        ) : (
          <Container
            sx={{ flex: 1 }}
            maxWidth={maxWidth}
            isNoPaddingMode={!!isNoPaddingModeContainer}
          >
            {renderComponent()}
          </Container>
        )}
      </Stack>
      {!isMdUp && !hideBottomNavigation && <BottomNavigation />}
    </Stack>
  );
};
