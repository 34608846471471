import { useGetOrderBook } from "@services/queries/markets/queries";
import { OrderBook, OrderDetails } from "@services/queries/markets/types";
import { useEffect, useState } from "react";
import useCurrentCurrency from "./useCurrentCurrency";
import { useSocket } from "@context/scoket";
import { useParams } from "react-router-dom";

interface SocketOrderBook {
  Price: string;
  ProductId: string;
  Seq: number;
  Side: "sell" | "buy";
  Size: string;
}

const useGetOrderBooks = () => {
  const { currency: currencyParams } = useParams();
  const currentCurrency = useCurrentCurrency() ?? {
    toRoundScale: 0,
    feeRoundScale: 0,
    fromRoundScale: 0,
  };

  const { socket } = useSocket();
  const [data, setData] = useState<OrderBook>({
    asks: [],
    bids: [],
    sequence: "", // Add sequence if needed, or adjust as per your data structure
  });

  const orderBookData = useGetOrderBook(currencyParams as string);

  useEffect(() => {
    if (!currencyParams) return; // Check for undefined currencyParams

    const handleSocketData = (socketData: SocketOrderBook) => {
      let tmp = { ...data }; // Clone current data for updates

      const isSell = socketData.Side === "buy";

      const result = {
        price: socketData.Price,
        amount: socketData.Size,
        total: (Number(socketData.Price) * Number(socketData.Size)).toString(),
        count: 0,
        filledPercentage: 0,
      };

      try {
        if (!isSell) {
          const index = tmp.asks.findIndex((ask) => ask.price === result.price);
          if (index > -1) {
            if (Number(result.amount) > 0) {
              tmp.asks[index].amount = result.amount.toString();
              tmp.asks[index].total = result.total;
            } else {
              tmp.asks = tmp.asks.filter((ask) => ask.price !== result.price);
            }
          } else {
            tmp.asks = [...tmp.asks, result];
          }
        } else {
          const index = tmp.bids.findIndex((bid) => bid.price === result.price);
          if (index > -1) {
            if (Number(result.amount) > 0) {
              tmp.bids[index].amount = result.amount.toString();
              tmp.bids[index].total = result.total;
            } else {
              tmp.bids = tmp.bids.filter((bid) => bid.price !== result.price);
            }
          } else {
            tmp.bids = [result, ...tmp.bids];
          }
        }
      } catch (error) {
        console.error("Error updating orderbook =>", error);
      }

      setData(tmp);
    };

    socket.on(`level2:${currencyParams}`, handleSocketData);

    return () => {
      socket.off(`level2:${currencyParams}`, handleSocketData);
    };
  }, [currencyParams, socket, data]); // Removed unnecessary dependency on 'data'

  useEffect(() => {
    if (orderBookData?.data?.result) {
      setData(orderBookData.data.result);
    }
  }, [orderBookData?.isFetching]);

  return { data, currentCurrency };
};

export default useGetOrderBooks;
