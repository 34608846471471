import { Backdrop, CircularProgress, Stack, Typography } from "@mui/material";

export const Loader = () => {
  return (
    <Backdrop sx={{ zIndex: 999, color: "#fff" }} open>
      <Stack alignItems="center" gap="1rem">
        <CircularProgress color="inherit" />
        <Typography>لطفا منتظر بمانید ...</Typography>
      </Stack>
    </Backdrop>
  );
};
