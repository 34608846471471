import useTabFocus from "@hooks/useInTab";
import { createContext, useContext, useEffect, useState } from "react";
import io, { Socket } from "socket.io-client";

const socket = io(import.meta.env.VITE_APP_IO_URL || "https://io.balinex.com", {
  autoConnect: false,
  transports: ["websocket", "polling"],
  withCredentials: true,
});

interface SocketContextInterface {
  socket: Socket;
  handleJoinRoom: (room: string) => void;
  handleLeaveRoom: (room: string) => void;
}

const SocketContext = createContext<SocketContextInterface>({
  socket,
  handleJoinRoom: () => {},
  handleLeaveRoom: () => {},
});

export const SocketProvider = ({ children }: { children: React.ReactNode }) => {
  const isTabFocused = useTabFocus(); // Detect tab focus
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [room, setRoom] = useState(""); // Active room state
  const [previousRoom, setPreviousRoom] = useState(""); // Store previous room
  const [isSocketReconnected, setIsSocketReconnected] = useState(false); // Track reconnection state

  const handleJoinRoom = (room: string) => {
    setRoom(room);
    socket.emit("joinRoom", room);
  };

  const handleLeaveRoom = (room: string) => {
    socket.emit("leaveRoom", room);
    setPreviousRoom(room); // Save the room before leaving
  };

  const handleBeforeUnload = () => {
    handleLeaveRoom(room);
  };

  useEffect(() => {
    if (isTabFocused && !isConnected) {
      socket.connect();
    }

    socket.on("connect", () => {
      setIsConnected(true);
      setIsSocketReconnected(true); // Mark the socket as reconnected

      // Rejoin the room after reconnect
      if (room) {
        socket.emit("joinRoom", room);
      } else if (previousRoom) {
        socket.emit("joinRoom", previousRoom);
      }
    });

    socket.on("disconnect", () => {
      setIsConnected(false);
    });

    socket.io.reconnection(true);

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      socket.off("connect");
      socket.off("disconnect");
      socket.off("message");
    };
  }, [isTabFocused, isConnected, room, previousRoom]); // Re-run when tab focus or socket state changes

  useEffect(() => {
    if (!isTabFocused && room) {
      handleLeaveRoom(room);
    }

    if (isTabFocused && room && isConnected) {
      handleJoinRoom(room);
    }
  }, [isTabFocused, room, isConnected]);

  return (
    <SocketContext.Provider
      value={{
        socket,
        handleJoinRoom,
        handleLeaveRoom,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useSocket = () => {
  return useContext(SocketContext);
};
