import { useBreakpoint } from "@hooks/useBreakpoint";
import {
  Alert,
  LinearProgress,
  PaletteColor,
  Snackbar,
  Stack,
  useTheme,
} from "@mui/material";
import { eventEmitter } from "@services/event-emitter";
import type { IToast } from "@utils/toast";
import {
  CloseCircle,
  CopySuccess,
  Danger,
  Headphone,
  TickCircle,
  Warning2,
} from "iconsax-react";
import { useEffect, useState } from "react";
import { Typography } from "../typography";

type SeverityType = "success" | "info" | "warning" | "error" | "neutral";

export interface ISnackbar extends IToast {
  open?: boolean;
  severity?: SeverityType;
}

export function SnackBar() {
  const [snackbar, setSnackbar] = useState<ISnackbar>({
    message: "",
  });

  useEffect(() => {
    eventEmitter.on("toast", ({ detail: snackbar }: any) => {
      setSnackbar(snackbar);
    });
  }, []);

  const handleClose = () => {
    setSnackbar((snackbar) => ({ ...snackbar, open: false }));
  };
  const duration = snackbar.duration || 3000;

  const theme = useTheme();

  const isMdUp = useBreakpoint("md");

  const getColor = (severity?: SeverityType) => {
    return theme.palette?.[severity || "neutral"] as PaletteColor;
  };

  return (
    <Snackbar
      open={snackbar.open}
      autoHideDuration={duration}
      onClose={handleClose}
      action={snackbar.action}
      anchorOrigin={{ horizontal: "left", vertical: "top" }}
      sx={{
        width: "fit-content",
        mt: "3rem",
        borderRadius: "0.25rem",
      }}
    >
      <Alert
        onClose={handleClose}
        severity={snackbar.severity}
        className="persian-num"
        closeText="بستن"
        slots={{
          closeIcon: () => {
            if (
              typeof snackbar?.customIcon === "string" &&
              snackbar?.customIcon.search("SUPPORT") !== -1
            )
              return renderCustomSupportIcon(
                snackbar?.customIcon?.split("_")?.[1]
              );

            return (
              snackbar?.customIcon ?? (
                <Stack direction={"row"} gap={0.5}>
                  <Typography color="white" variant="CaptionMedium">
                    بستن
                  </Typography>
                  <CloseCircle size="32" color="#FFFFFF" variant="Bold" />
                </Stack>
              )
            );
          },
        }}
        iconMapping={{
          success: (
            <TickCircle
              style={{
                width: "1em",
                height: "1em",
                fill: getColor("success")[100],
              }}
            />
          ),
          neutral: (
            <CopySuccess
              variant="Bold"
              style={{
                width: "1em",
                height: "1em",
                fill: "#fff",
              }}
            />
          ),
          warning: (
            <Danger
              variant="Bold"
              style={{
                width: "1em",
                height: "1em",
                fill: "transparent",
                color: getColor("warning")[100],
              }}
            />
          ),
          error: (
            <Warning2
              variant="Bold"
              style={{
                width: "1em",
                height: "1em",
                color: getColor("error")[100],
              }}
            />
          ),
        }}
        sx={{
          bgcolor: getColor(snackbar.severity)[700],
          color: "white",
          alignItems: "center",
          maxWidth: "80%",
          minWidth: "340px",
          minHeight: !isMdUp ? "42px" : "46px",
          borderRadius: "10px",
          overflow: "hidden",
          position: "relative",
          "& svg": {
            width: "1em",
            height: "1em",
            fill: getColor(snackbar.severity)[100],
          },
        }}
      >
        <Typography color="white" variant="BodyMedium">
          {snackbar.message}
        </Typography>
        <LinearProgress
          sx={{
            "@keyframes widthZeroToHundred": {
              from: {
                width: 0,
              },
              to: {
                width: "100%",
              },
            },
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: getColor(snackbar.severity)[100],
            ".MuiLinearProgress-bar": {
              backgroundColor: getColor(snackbar.severity)[800],
              animation: `widthZeroToHundred ${duration}ms linear forwards`,
            },
          }}
          variant="determinate"
          // value={0}
        />
      </Alert>
    </Snackbar>
  );
}

const renderCustomSupportIcon = (xReqId: string) => (
  <Stack
    onClick={() => {
      window?.Goftino?.sendMessage({
        text: `xReqId: ${xReqId || "نامشخص"}`,
      });
    }}
    direction={"row"}
    gap={0.5}
  >
    <Headphone size="32" color="#FFFFFF" variant="Bold" />
    <Typography color="white" variant="CaptionMedium">
      پشتیبانی
    </Typography>
  </Stack>
);
