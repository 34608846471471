import { TomanIcon } from "@assets/icons/TomanIcon";
import { InfiniteScroll } from "@components/common/infinite-scroll";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Grid, Stack } from "@mui/material";
import { useGetProfitMembers } from "@services/queries/referral/queries";
import { numUtils } from "@utils/num-helpers";
import { useMemo } from "react";

interface InvitedFriendsModalProps {
  onClose: () => void;
  open: boolean;
}

export const InvitedFriendsModal = ({
  onClose,
  open,
}: InvitedFriendsModalProps) => {
  const getProfitMembers = useGetProfitMembers({});

  const profitMembersList = useMemo(
    () => getProfitMembers?.data?.pages?.flatMap((e) => e?.result) || [],
    [getProfitMembers?.isFetchingNextPage, getProfitMembers?.isFetching]
  );

  return (
    <Modal
      maxWidth="400px"
      onClose={onClose}
      title="لیست دوستان دعوتیتان"
      open={open}
      loading={getProfitMembers?.isLoading}
    >
      <Stack maxHeight="600px" overflow="auto" width="100%">
        <Grid
          container
          textAlign="center"
          position="sticky"
          top="0px"
          bgcolor="white"
          pb="0.5rem"
        >
          {/* Headers */}
          <Grid item xs={5}>
            <Typography
              variant="ExtendedBodyBold"
              smVariant="BodyBold"
              color="colorfulGray.800"
            >
              دوست دعوتی
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="ExtendedBodyBold"
              smVariant="BodyBold"
              color="colorfulGray.800"
            >
              وضعیت
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography
              variant="ExtendedBodyBold"
              smVariant="BodyBold"
              color="colorfulGray.800"
            >
              پاداش
            </Typography>
          </Grid>
        </Grid>

        {/* Items */}
        {!!profitMembersList?.length ? (
          <InfiniteScroll
            fetchMore={async () => {
              !getProfitMembers?.isFetchingNextPage &&
              getProfitMembers?.hasNextPage
                ? await getProfitMembers.fetchNextPage()
                : await 0;
            }}
          >
            <Grid container textAlign="center" rowSpacing="1rem">
              {profitMembersList?.map((item) => (
                <>
                  <Grid item xs={5}>
                    <Typography
                      dir="ltr"
                      variant="BodyMedium"
                      color="colorfulGray.700"
                    >
                      {item?.memberMaskedMobileNo}
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Typography
                      variant="Body"
                      color={item?.isKYCVerified ? "success.700" : "error.700"}
                      bgcolor={
                        item?.isKYCVerified ? "success.A100" : "error.A100"
                      }
                      py="0.25rem"
                      px="0.5rem"
                    >
                      {item?.isKYCVerified ? " احراز شده" : "احراز نشده"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      gap="0.2rem"
                    >
                      <Typography variant="BodyBold" color="colorfulGray.800">
                        {numUtils?.commaformatter?.toCurrency(
                          Number(item?.totalProfitByMember).toFixed(0)
                        )}
                      </Typography>
                      <TomanIcon
                        sx={{
                          width: "0.85rem",
                          fill: (theme) => theme?.palette?.colorfulGray?.[500],
                        }}
                      />
                    </Stack>
                  </Grid>
                </>
              ))}
            </Grid>
          </InfiniteScroll>
        ) : (
          <></>
        )}
      </Stack>
    </Modal>
  );
};
