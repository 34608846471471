import {
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
  ThemeProvider,
  useTheme,
} from "@mui/material";

export const LinearProgress = ({ sx, ...props }: MuiLinearProgressProps) => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={{ ...theme, direction: "ltr" }}>
      <MuiLinearProgress sx={{ borderRadius: "1rem", ...sx }} {...props} />
    </ThemeProvider>
  );
};
