import { Badge, Popover, Stack, SvgIcon, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Typography } from "../../common/typography";
import {
  ArrowDown2,
  ArrowLeft,
  Headphone,
  Notification,
  SearchNormal1,
  UserSquare,
} from "iconsax-react";
import Container from "@components/common/container";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { SearchBox } from "@components/common/search-box";
import { useFilter } from "@hooks/useFilter";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { LogoType } from "@components/common/logo-type";
import { Button } from "@components/common/button";
import { ProfileDropdownNavigation } from "@components/shared/profile-dropdown-navigation";
import { toast } from "@utils/toast";

export interface TopNavigationProps {
  icon?: React.ReactNode;
  title?: string | React.ReactNode;
  actions?: React.ReactNode;
  backMode?: boolean;
  onClickBackArrow?: () => void;
  withSearch?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

export const TopNavigation = ({
  title,
  actions,
  backMode,
  icon,
  withSearch,
  maxWidth,
  onClickBackArrow,
}: TopNavigationProps) => {
  const theme = useTheme();
  const isMdUp = useBreakpoint("md");
  const navigate = useNavigate();
  const { setParams } = useFilter();

  // prettier-ignore
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isMobileSearchBoxOpen, setIsMobileSearchBoxOpen] = useState(false);

  const SearchComponent = () => (
    <Stack position="relative">
      <Stack
        overflow="hidden"
        sx={{ transition: "0.3s", transformOrigin: "right" }}
        position="absolute"
        right="0"
        top="-50%"
        zIndex={100}
        width={
          isMobileSearchBoxOpen
            ? isMdUp
              ? "450px"
              : "calc(100vw - 30px)"
            : "0px"
        }
      >
        <SearchBox
          withDebounce
          onResetValueOnClick={() => {
            setParams({ name: "search", value: "" });
            setIsMobileSearchBoxOpen(false);
          }}
          onDebounceTrigger={(value) => setParams({ name: "search", value })}
          isCloseCircleShow
        />
      </Stack>
      <SvgIcon onClick={() => setIsMobileSearchBoxOpen(true)}>
        <SearchNormal1
          size={"1.5rem"}
          color={theme?.palette?.colorfulGray?.[500]}
        />
      </SvgIcon>
    </Stack>
  );

  return (
    <Stack
      id="TOP_NAVIGATION"
      borderBottom={1}
      borderColor="primary.50"
      bgcolor="white"
      position="sticky"
      top={0}
      zIndex={20}
      justifyContent="center"
      sx={{
        height: "57px",
        py: { xs: "0.75rem", md: "1rem" },
      }}
    >
      <Container maxWidth={maxWidth}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          {isMdUp ? (
            <LogoType
              onClick={() => navigate("/v2/dashboard")}
              sx={{
                cursor: "pointer",
                fill: (theme) => theme?.palette.secondary[600],
                width: "8rem",
              }}
            />
          ) : (
            !!title && (
              <Stack justifyContent="center">
                {
                  <motion.div
                    initial={{ transform: "translateX(100%)", opacity: 0 }}
                    animate={{ transform: "translateX(0)", opacity: 1 }}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "0.5rem",
                    }}
                  >
                    {icon}
                    {typeof title === "string" ? (
                      <Typography variant="BodyBold" color="colorfulGray.700">
                        {title}
                      </Typography>
                    ) : (
                      title
                    )}
                  </motion.div>
                }
              </Stack>
            )
          )}
          <Stack direction="row" alignItems="center" gap="1rem">
            {!!actions ? (
              actions
            ) : (
              <Stack direction="row" gap="1rem">
                <Badge
                  color="secondary"
                  // variant="dot"
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <SvgIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => toast.info("این قسمت در حال توسعه است")}
                  >
                    <Notification
                      size="1.5rem"
                      color={theme.palette.colorfulGray[500]}
                    />
                  </SvgIcon>
                </Badge>
                <Headphone
                  id="SUPPORT_ICON_GOFTINO"
                  onClick={() => {
                    window.Goftino.toggle();
                  }}
                  style={{ cursor: "pointer" }}
                  size="1.5rem"
                  variant="Bold"
                  color={theme.palette.colorfulGray[500]}
                />
              </Stack>
            )}

            {!!isMdUp && (
              <>
                <Button
                  aria-describedby="profile-dropdown-menu"
                  onClick={(e) => setAnchorEl(e.currentTarget)}
                  variant="text"
                  endIcon={
                    <ArrowDown2
                      style={{
                        transform: Boolean(anchorEl)
                          ? "rotateX(180deg)"
                          : "rotateX(0)",
                        transition: "0.3s",
                      }}
                      size="1.25rem"
                    />
                  }
                  startIcon={<UserSquare variant="Bold" size="1.5rem" />}
                >
                  پروفایل
                </Button>
                <Popover
                  aria-describedby="profile-dropdown-menu"
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  sx={{
                    ".MuiPaper-root": {
                      boxShadow: "0px 2px 2px 0px rgba(0, 0, 0, 0.15)",
                      p: 0,
                      borderRadius: "1.125rem",
                    },
                  }}
                >
                  <ProfileDropdownNavigation listOfDisplayId={[2, 3, 4]} />
                </Popover>
              </>
            )}

            {withSearch && SearchComponent()}

            {!isMobileSearchBoxOpen && !!backMode && (
              <ArrowLeft
                onClick={
                  onClickBackArrow ? onClickBackArrow : () => navigate(-1)
                }
                color={theme?.palette?.colorfulGray?.[600]}
                size="1.5rem"
              />
            )}
          </Stack>
        </Stack>
      </Container>
    </Stack>
  );
};
