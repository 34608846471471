import { Box, Stack } from "@mui/material";
import { BaliTabPanelProps } from "./types";

const TabPanel = ({ children, value, index }: BaliTabPanelProps) => {
  return (
    <Stack
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      flex={1}
    >
      {value === index && children}
    </Stack>
  );
};

export default TabPanel;
