import { TomanIcon } from "@assets/icons/TomanIcon";
import { Button } from "@components/common/button";
import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { Divider, Stack, SvgIcon } from "@mui/material";
import { GetEstimateWalletOptional } from "@services/queries/wallet/types";
import { getCoinIconURL } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { ArrowDown2, ArrowUp2, Tether } from "iconsax-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LabelValue } from "../types";

interface BasketAssetProps {
  baseOn: LabelValue | null;
  dataWallet: GetEstimateWalletOptional[];
  isHidePrice?: boolean;
}

const BasketAsset = ({ baseOn, dataWallet, isHidePrice }: BasketAssetProps) => {
  const navigation = useNavigate();

  const [assetsData, setAssetsData] = useState(dataWallet?.slice(0, 3));

  const { commaformatter } = numUtils;

  const isUSDT = baseOn?.value === "USDT";

  function initEstimateMoreAssets() {
    const assetsData2 = {
      balanceTotalFormatted: "",
      balanceTotal: 0,
      currency: "",
      prettyNameLocalized: "بقیه موارد",
      balanceEstimate: {
        IRT: {
          hold: 0,
          available: 0,
          total: 0,
          holdString: "0",
          availableString: "0",
          totalString: "0",
        },
        USDT: {
          hold: 0,
          available: 0,
          total: 0,
          holdString: "0",
          availableString: "0",
          totalString: "0",
        },
      },
      perecent: 0,
      perecentString: "0",
      perecentGrow: {
        IRT: "0",
        USDT: "0",
      },
    };

    if (dataWallet?.length > 3) {
      dataWallet?.slice(3)?.forEach((item) => {
        assetsData2.balanceEstimate.IRT.total +=
          item?.balanceEstimate?.IRT?.total;
        assetsData2.balanceEstimate.USDT.total +=
          item?.balanceEstimate?.USDT?.total;

        assetsData2.balanceEstimate.IRT.totalString = commaformatter.toCurrency(
          assetsData2.balanceEstimate.IRT.total?.toFixed(0)
        );
        assetsData2.balanceEstimate.USDT.totalString =
          commaformatter.toCurrency(
            assetsData2.balanceEstimate.USDT.total.toFixed(2)
          );

        assetsData2.perecent += item?.perecent;
        assetsData2.perecentString = `${assetsData2.perecent.toFixed(1)}%`;
      });
      // @ts-ignore
      setAssetsData([...(dataWallet?.slice(0, 3) ?? []), assetsData2]);
    }
  }

  useEffect(() => {
    if (dataWallet?.length > 0) initEstimateMoreAssets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWallet]);

  return (
    <Stack
      borderRadius="1.125rem"
      bgcolor="white"
      boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.16)"
      py={"1.5rem"}
      px={"0.75rem"}
      overflow="hidden"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography variant="BodyBold" color="colorfulGray.800">
          سبد داریی شما
        </Typography>
        <Button
          variant="text"
          endIcon={<ArrowDown2 size="1.2rem" style={{ rotate: "90deg" }} />}
          onClick={() => navigation("/v2/dashboard/wallet")}
          sx={{ fontWeight: "bold" }}
        >
          پرتفولیو
        </Button>
      </Stack>
      <Typography variant="Body" color="colorfulGray.700">
        محاسبه شده در دیروز
      </Typography>
      <Stack mt="0.88rem">
        {assetsData?.map((item, idx) => {
          const percent = Number(
            item?.perecentGrow?.[isUSDT ? "USDT" : "IRT"] ?? "0"
          );

          const lastItem = idx !== assetsData?.length - 1;

          return (
            <>
              <Stack direction="row" justifyContent="space-between" py="0.5rem">
                <Stack direction="row" gap="0.5rem" alignItems="center">
                  <Image
                    alt="Coin-Image"
                    height="2rem"
                    width="2rem"
                    src={getCoinIconURL(item?.currency)}
                  />
                  <Stack>
                    <Typography variant="BodyMedium" color="colorfulGray.800">
                      {item?.prettyNameLocalized}
                    </Typography>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: { sm: "column", md: "row" },
                      }}
                      gap="0.3rem"
                    >
                      <Typography variant="BodyMedium" color="colorfulGray.500">
                        {item?.perecentString} از دارایی
                      </Typography>
                      {item?.balanceEstimate?.[isUSDT ? "USDT" : "IRT"]?.total >
                        0 && (
                        <Stack
                          sx={{
                            display: { xs: "none", md: "flex" },
                          }}
                        >
                          {!lastItem ? "" : "|"}
                        </Stack>
                      )}
                      <Typography variant="BodyMedium" color="colorfulGray.500">
                        {isHidePrice
                          ? "********"
                          : item?.balanceTotalFormatted ?? 0}{" "}
                        {item?.currency}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack direction="column" alignItems="center">
                  <Stack
                    direction="row-reverse"
                    gap="0.4rem"
                    alignItems="center"
                  >
                    <SvgIcon
                      sx={{
                        color: (theme) => theme.palette.colorfulGray[300],
                        width: "0.75rem",
                        height: "0.75rem",
                      }}
                    >
                      {isUSDT ? <Tether /> : <TomanIcon />}
                    </SvgIcon>
                    <Typography
                      variant="ExtendedBodyBold"
                      color="colorfulGray.800"
                    >
                      {isHidePrice
                        ? "********"
                        : item?.balanceEstimate?.[isUSDT ? "USDT" : "IRT"]
                            ?.totalString}
                    </Typography>

                    {lastItem && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          variant="BodyMedium"
                          smVariant="SmallCaption"
                          color={percent > 0 ? "info.600" : "error.500"}
                          dir={percent > 0 ? "rtl" : "ltr"}
                        >
                          {commaformatter.toCurrency(percent || 0)}
                        </Typography>
                        {percent > 0 ? (
                          <SvgIcon
                            sx={{
                              color: (theme) => theme.palette.info[600],
                              width: { xs: "1rem", md: "1.125rem" },
                              height: { xs: "1rem", md: "1.125rem" },
                            }}
                          >
                            <ArrowUp2 variant="Bold" />
                          </SvgIcon>
                        ) : (
                          <SvgIcon
                            sx={{
                              color: (theme) => theme.palette.error[500],
                              width: { xs: "1rem", md: "1.125rem" },
                              height: { xs: "1rem", md: "1.125rem" },
                              transform: "rotate(180deg)",
                            }}
                          >
                            <ArrowUp2 variant="Bold" />
                          </SvgIcon>
                        )}
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </Stack>
              {lastItem && <Divider flexItem sx={{ my: "0.69rem" }} />}
            </>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default BasketAsset;
