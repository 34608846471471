import { SvgIcon, SvgIconProps } from "@mui/material";

export const ShareIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path d="M14.625 12.541C13.5895 12.541 12.75 13.3805 12.75 14.416C12.75 15.4515 13.5895 16.291 14.625 16.291C15.6605 16.291 16.5 15.4515 16.5 14.416C16.5 13.3805 15.6605 12.541 14.625 12.541ZM11.5 14.416C11.5 12.6901 12.8991 11.291 14.625 11.291C16.3509 11.291 17.75 12.6901 17.75 14.416C17.75 16.1419 16.3509 17.541 14.625 17.541C12.8991 17.541 11.5 16.1419 11.5 14.416Z" />
          <path d="M14.625 1.70801C13.5895 1.70801 12.75 2.54747 12.75 3.58301C12.75 4.61854 13.5895 5.45801 14.625 5.45801C15.6605 5.45801 16.5 4.61854 16.5 3.58301C16.5 2.54747 15.6605 1.70801 14.625 1.70801ZM11.5 3.58301C11.5 1.85712 12.8991 0.458008 14.625 0.458008C16.3509 0.458008 17.75 1.85712 17.75 3.58301C17.75 5.3089 16.3509 6.70801 14.625 6.70801C12.8991 6.70801 11.5 5.3089 11.5 3.58301Z" />
          <path d="M3.16663 7.54102C2.13109 7.54102 1.29163 8.38048 1.29163 9.41602C1.29163 10.4515 2.13109 11.291 3.16663 11.291C4.20216 11.291 5.04163 10.4515 5.04163 9.41602C5.04163 8.38048 4.20216 7.54102 3.16663 7.54102ZM0.041626 9.41602C0.041626 7.69013 1.44074 6.29102 3.16663 6.29102C4.89252 6.29102 6.29163 7.69013 6.29163 9.41602C6.29163 11.1419 4.89252 12.541 3.16663 12.541C1.44074 12.541 0.041626 11.1419 0.041626 9.41602Z" />
          <path d="M12.9175 5.52109L5.66661 8.99935L5.08239 7.89427L12.3333 4.41602L12.9175 5.52109Z" />
          <path d="M5.10884 10.353C5.26455 10.0449 5.64051 9.92139 5.94857 10.0771L12.0574 13.1602C12.3655 13.3159 12.489 13.6919 12.3333 14C12.1776 14.308 11.8016 14.4315 11.4935 14.2758L5.38471 11.1927C5.07664 11.037 4.95313 10.661 5.10884 10.353Z" />
        </g>
      </svg>
    </SvgIcon>
  );
};
