import { Button } from "@components/common/button";
import { Slider } from "@components/common/slider";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { useBreakpoint } from "@hooks/useBreakpoint";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, InputAdornment, Stack } from "@mui/material";
import useCurrencyUpdate from "@pages/dashborad/markets/market-details/_hooks/useCurrencyUpdate";
import useWalletUser from "@pages/dashborad/markets/market-details/_hooks/useWalletUser";
import { useSetLimitOrderPreview } from "@services/queries/markets/mutations";
import {
  IMarket,
  IOrderPreview,
  IUserBalance,
} from "@services/queries/markets/types";
import { useUserStore } from "@store/user";
import { numUtils } from "@utils/num-helpers";
import { toast } from "@utils/toast";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { Response } from "~types/index";
import VerifyModal from "../verify-modal";
import { LimitOrderBuySchema } from "./_validation";

interface BuyProps {
  currentCurrency: IMarket;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  orderValue: number | string;
  userBalanceData: IUserBalance | undefined;
}

const Buy = ({
  currentCurrency,
  setOpenModal,
  orderValue,
  userBalanceData,
}: BuyProps) => {
  const user = useUserStore((state) => state.user);

  const headerData = useCurrencyUpdate();

  const location = useLocation();

  const [sliderValue, setSliderValue] = useState(0);
  const [openVerify, setOpenVerify] = useState<{
    open: boolean;
    data: IOrderPreview | undefined;
  }>({
    open: false,
    data: undefined,
  });

  const zObject = LimitOrderBuySchema({
    priceCoin: currentCurrency?.quoteCurrency,
    amountMin: Number(userBalanceData?.buy?.min),
    amountMax: Number(userBalanceData?.buy?.max),
  });

  const formMethods = useForm<z.output<typeof zObject>>({
    mode: "all",
    resolver: zodResolver(zObject),
    defaultValues: {
      price: headerData?.price || "",
      amount: "",
      total: "",
    },
  });

  useEffect(() => {
    if (formMethods?.getValues("price") === "") {
      formMethods.setValue("price", headerData?.price || "");
    }
  }, [location, headerData?.price]);

  useEffect(() => {
    formMethods.setValue("price", "");
    formMethods.setValue("amount", "");
    formMethods.setValue("total", "");
    setSliderValue(0);
  }, [location]);

  // prettier-ignore
  const balance = useMemo(()=> Number(userBalanceData?.quoteBalance) ?? 0,[userBalanceData])
  const amount = Number(formMethods.getValues("amount"));
  const price = formMethods.watch("price");

  const handleSliderChange = (event: Event, newValue: number) => {
    setSliderValue(newValue);

    if (balance <= 0) {
      formMethods.setValue("price", "");
      formMethods.setValue("amount", "");
      return;
    }
    if (price <= "0") return;

    const percent = (balance * newValue) / 100;

    formMethods.setValue("amount", (percent / Number(price)).toString());
  };

  useEffect(() => {
    if (orderValue) {
      formMethods.setValue("price", orderValue.toString());
    }
  }, [orderValue]);

  useEffect(() => {
    // @ts-ignore
    const total = amount * price;
    // prettier-ignore
    if (Number(amount) > 0 && Number(price) > 0) {
      formMethods.setValue("total",total.toString());
    }
  }, [amount, price]);

  useEffect(() => {
    if (Object.keys(formMethods.formState.errors).length > 0) {
      Object.values(formMethods.formState.errors)?.map((error) =>
        toast.error(error?.message ?? "")
      );
    }
  }, [formMethods.formState.errors]);

  // const isIRT = currentCurrency?.quoteCurrency === "IRT";

  const setPreviewMutate = useSetLimitOrderPreview();

  const onSubmit: SubmitHandler<z.output<typeof zObject>> = () => {
    const roundedAmount = Number(
      amount.toFixed(
        currentCurrency?.scale?.[currentCurrency?.baseCurrency]?.toRoundScale ||
          0
      )
    );
    setPreviewMutate.mutate(
      {
        productId: currentCurrency?.id,
        size: roundedAmount,
        price: Number(formMethods.watch("price")),
        side: "buy",
        type: "limit",
        timeInForce: new Date().toISOString(),
        source: currentCurrency?.baseCurrency,
      },
      {
        onSuccess: (data: Response<IOrderPreview>) => {
          setOpenVerify({ open: true, data: data.result });
        },
      }
    );
  };
  const CalFee = (
    (user?.levelInfo[0]?.takerWage ?? 0) *
    (Number(formMethods.watch("amount")) || 0)
  ).toFixed(
    currentCurrency?.scale?.[currentCurrency?.baseCurrency]?.feeRoundScale
  );

  const isMdUp = useBreakpoint("md");

  const userBalanceSocket = useWalletUser({
    currencies: [currentCurrency?.quoteCurrency],
  });

  const userBalance = userBalanceSocket?.find(
    (item) => item.currencyCode === currentCurrency?.quoteCurrency
  )?.available;

  return (
    <>
      <Stack
        gap={!isMdUp ? "0.75rem" : 0}
        component={"form"}
        onSubmit={formMethods.handleSubmit(onSubmit)}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mb={-1}
        >
          <Typography variant="caption" color="neutral.500">
            قابل خرید
          </Typography>
          <Box display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => setOpenModal(true)}>
              <AddCircleIcon
                sx={{
                  color: "secondary.700",
                }}
                fontSize="small"
              />
            </IconButton>
            <Stack gap={1} direction={"row"} alignItems={"center"}>
              <Typography fontSize={14} color={"primary.700"}>
                {currentCurrency?.quoteCurrency}
              </Typography>
              <Typography
                fontSize={14}
                color={"primary.700"}
                fontWeight={"bold"}
              >
                {userBalance
                  ? numUtils?.commaformatter.toCurrency(
                      Number(userBalance)?.toFixed(
                        currentCurrency?.scale?.[currentCurrency?.quoteCurrency]
                          ?.toRoundScale
                      )
                    )
                  : userBalanceData?.quoteBalanceFormatted}
              </Typography>
            </Stack>
          </Box>
        </Box>

        <Stack gap="0.75rem">
          <TextField
            {...formMethods.register("price")}
            sx={{ height: 40, mb: -1 }}
            numberInput
            thousandSeparator
            // decimalScale={
            //   currentCurrency?.toRoundScale[
            //     currentCurrency?.quoteCurrency as "IRT" | "USDT"
            //   ] || 0
            // }
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    width={40}
                    textAlign={"right"}
                    color={"neutral.500"}
                  >
                    {currentCurrency?.quoteCurrency}
                  </Typography>
                </InputAdornment>
              ),
              startAdornment: isMdUp && (
                <InputAdornment position="start">
                  <Typography sx={{ color: "neutral.500", fontSize: 13 }}>
                    قیمت
                  </Typography>
                </InputAdornment>
              ),
            }}
            placeholder={!isMdUp ? "قیمت" : ""}
            value={formMethods.watch("price")}
            inputProps={{
              style: { direction: "ltr", marginLeft: 10 },
            }}
          />

          <TextField
            {...formMethods.register("amount")}
            numberInput
            thousandSeparator
            decimalScale={
              currentCurrency?.scale?.[currentCurrency?.baseCurrency]
                ?.toRoundScale || 0
            }
            sx={{ height: 40, mb: -1 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body1"
                    textAlign={"right"}
                    color={"neutral.500"}
                  >
                    {currentCurrency?.baseCurrency}
                  </Typography>
                </InputAdornment>
              ),
              startAdornment: isMdUp && (
                <InputAdornment position="start">
                  <Typography sx={{ color: "neutral.500", fontSize: 13 }}>
                    مقدار
                  </Typography>
                </InputAdornment>
              ),
            }}
            placeholder={!isMdUp ? "مقدار" : ""}
            value={formMethods.watch("amount")}
            inputProps={{
              style: { direction: "ltr", marginLeft: 10 },
            }}
          />

          <Stack pl="0.75rem" height={35} mx={1}>
            <Slider
              side="Buy"
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `${val}%`}
              getAriaLabel={(val) => `${val}%`}
              // @ts-ignore
              onChange={handleSliderChange}
              onChangeCommitted={(e, val) => {
                if (Number(price) <= 0) {
                  setSliderValue(0);
                  return toast.error("لطفا فیلد قیمت را وارد کنید.");
                }

                if (balance === 0) {
                  toast.error("لطفا موجودی پرداختی خود را شارژ کنید.");
                  return setSliderValue(0);
                }
              }}
              value={sliderValue}
            />
          </Stack>

          <TextField
            disabled={!formMethods?.watch("price")}
            {...formMethods.register("total")}
            numberInput
            thousandSeparator
            decimalScale={
              currentCurrency?.scale?.[currentCurrency?.baseCurrency]
                ?.fromRoundScale || 0
            }
            sx={{ height: 40 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Box component={"span"} display={"flex"} gap={"0.25rem"}>
                    <span>{currentCurrency?.quoteCurrency}</span>
                  </Box>
                </InputAdornment>
              ),
              startAdornment: isMdUp && (
                <InputAdornment position="start">
                  <Typography sx={{ color: "neutral.500", fontSize: 13 }}>
                    مبلغ کل سفارش
                  </Typography>
                </InputAdornment>
              ),
            }}
            placeholder={!isMdUp ? "مبلغ کل سفارش" : ""}
            inputProps={{
              style: { direction: "ltr", marginLeft: 10 },
              dir: "ltr",
            }}
            value={formMethods.watch("total")}
            onChange={(e) => {
              const value = e.target.value;
              const price = formMethods.watch("price");

              if (Number(price) <= 0) {
                return toast.error("لطفا فیلد قیمت را وارد کنید.");
              }

              if (balance === 0) {
                toast.error("لطفا موجودی پرداختی خود را شارژ کنید.");
                return;
              }

              formMethods.setValue(
                "amount",
                (Number(value) / Number(price)).toString()
              );
            }}
          />
        </Stack>

        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography variant="caption" color="neutral.500">
            کارمزد تقریبی
          </Typography>
          <Stack gap={1} direction={"row"}>
            <Typography variant="caption">
              {currentCurrency?.baseCurrency}
            </Typography>
            <Typography variant="caption" fontWeight={"bold"}>
              {isNaN(parseFloat(CalFee)) ? 0 : CalFee}
            </Typography>
          </Stack>
        </Box>

        <Button
          variant="contained"
          bgColor="success.main"
          fullWidth
          sx={{
            height: 40,
            my: 2,
          }}
          type="submit"
          disabled={setPreviewMutate?.isPending}
        >
          <Typography color={"white"} fontSize={14}>
            خرید
          </Typography>
        </Button>
      </Stack>
      {openVerify.open && (
        <VerifyModal
          open={openVerify.open}
          data={openVerify.data}
          onClose={() => setOpenVerify({ open: false, data: undefined })}
          title="تایید خرید"
          resetForm={formMethods.reset as () => void}
        />
      )}
    </>
  );
};

export default Buy;
