// import initMain from "@public/charting_library/datafeeds/main.js";
// import { useEffect } from "react";
// import { useParams } from "react-router-dom";
// import "./index.css";

// const Chart = () => {
//   const params = useParams();
//   useEffect(() => {
//     initMain();
//   }, [params.currency]);

//   return (
//     <>
//       <div className={"TVChartContainer"} id="tv_chart_container" />
//     </>
//   );
// };

// export default Chart;

import { Box } from "@mui/material";
import { memo, useEffect, useRef } from "react";

interface Props {
  symbol?: string;
}

function Chart({ symbol }: Props) {
  const container: any = useRef();

  useEffect(() => {
    if (!symbol || symbol.length === 0) return;

    container.current.innerHTML = "";
    const lastParam = symbol
      ?.replace("IRTUSDT", "USDCUSDT")
      .replace("USDTIRT", "USDCUSDT")
      .replace("IRT", "USDT");

    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = JSON.stringify({
      autosize: true,
      symbol: `BYBIT:${lastParam}`,
      interval: "60",
      timezone: "Asia/Tehran",
      theme: "light",
      style: "1",
      locale: "en",
      withdateranges: true,
      hide_side_toolbar: false,
      allow_symbol_change: false,
      calendar: false,
      hide_volume: true,
      support_host: "https://www.tradingview.com",
    });
    container.current.appendChild(script);
  }, [symbol]);

  return (
    <Box className="TVChartContainer" ref={container}>
      <div
        className="tradingview-widget-container"
        ref={container}
        style={{ height: "100%", width: "100%" }}
      >
        <div
          className="tradingview-widget-container__widget"
          style={{ height: "calc(100% - 32px)", width: "100%" }}
        ></div>
      </div>
    </Box>
  );
}

export default memo(Chart);
