import { Button } from "@components/common/button";
import { Checkbox } from "@components/common/checkbox";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { CodeVerificationModal } from "../code-verification-modal";
import { IconTypography } from "@components/common/icon-typography";
import { TickCircle, Trash } from "iconsax-react";
import { Image } from "@components/common/image";
import { useDisabledGoogleCode } from "@services/queries/settings/mutations";
import { GoogleTwoStepVerificationModal } from "@components/shared/google-two-step-verification-modal";

interface G2VDisabledProps {
  onSuccess: () => void;
}

export const G2VDisabled = ({ onSuccess }: G2VDisabledProps) => {
  const isMdUp = useBreakpoint("md");
  const theme = useTheme();

  const [isConfirmRoles, setIsConfirmRoles] = useState(false);
  const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
  const [isGoogleTwoStepVerification, setIsGoogleTwoStepVerification] =
    useState(false);
  const [isConfirmToDeleteModal, setIsConfirmToDeleteModal] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleDisableOtpGoogleVerification = useDisabledGoogleCode();

  return (
    <>
      {isSuccessModalOpen && (
        <Modal
          open={isSuccessModalOpen}
          title="غیر فعال‌سازی کد دو عاملی"
          onClose={onSuccess}
        >
          <Stack pt="1.5rem" gap="1rem" width="100%">
            <IconTypography
              bgColor="success.A100"
              icon={
                <TickCircle
                  color={theme?.palette?.success?.[700]}
                  variant="Bold"
                  fontSize="1.97rem"
                />
              }
              title={
                <Typography color="success.900" variant="Body">
                  کد دو عاملیتان غیر فعال شد
                </Typography>
              }
            />

            <Button variant="outlined" fullWidth onClick={onSuccess}>
              <Typography variant="BodyMedium">متوجه شدم</Typography>
            </Button>
          </Stack>
        </Modal>
      )}

      {isGoogleTwoStepVerification && (
        <GoogleTwoStepVerificationModal
          onSubmit={(otp) => {
            handleDisableOtpGoogleVerification.mutate(otp, {
              onSuccess: () => {
                setIsSuccessModalOpen(true);
              },
            });
          }}
          loading={handleDisableOtpGoogleVerification?.isPending}
          onClose={() => setIsGoogleTwoStepVerification(false)}
        />
      )}

      <Modal
        open={isConfirmToDeleteModal}
        title="حذف کد دوعاملی"
        onClose={() => setIsConfirmToDeleteModal(false)}
      >
        <Stack gap="1rem">
          <Typography maxWidth="700px" variant="BodyBold" color="neutral.A700">
            مطئنید می خواهید ورود دوعاملی خود را حذف کنید.
          </Typography>

          <Stack width="100%" direction="row" alignItems="center" gap="0.75rem">
            <Button
              fullWidth
              bgColor="primary.A100"
              textColor="primary.900"
              onClick={() => setIsConfirmToDeleteModal(false)}
            >
              <Typography variant="BodyBold" color="primary.500">
                انصراف
              </Typography>
            </Button>
            <Button
              fullWidth
              variant="outlined"
              textColor="error.500"
              onClick={() => setIsGoogleTwoStepVerification(true)}
              startIcon={<Trash size="1rem" variant="Bold" />}
            >
              <Typography variant="BodyBold">حذف</Typography>
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        open={isOpenRolesModal}
        title="شرایط و قوانین کد دوعاملی"
        onClose={() => setIsOpenRolesModal(false)}
      >
        <Typography maxWidth="700px" variant="Body" color="colorfulGray.700">
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
          استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
          ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
          کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی
          در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می
          طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
          الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این
          صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و
          شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای
          اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده
          قرار گیرد.
        </Typography>
      </Modal>

      <Stack
        bgcolor="white"
        sx={{ p: { xs: "1.5rem", md: "3rem" } }}
        borderRadius="0.625rem"
        gap="1.5rem"
        height="100%"
      >
        {isMdUp && (
          <Typography color="colorfulGray.800" variant="TitleBold">
            کد ورود دوعاملی
          </Typography>
        )}

        <Stack alignItems="center" gap="1.5rem" py="1rem">
          <Image
            src="/v2/images/google-two-step-icon.png"
            width="6rem"
            height="6rem"
            alt="google-verification"
            sx={{ aspectRatio: "1/1" }}
          />
          <Typography
            color="colorfulGray.700"
            textAlign="center"
            variant="BodyMedium"
          >
            کد ورود دوعاملی برای شما تنظیم شده و به <br />
            google Authenticator وصل هستید.
          </Typography>
        </Stack>

        <Stack
          sx={{ px: { md: "1.5rem" } }}
          gap="2rem"
          flex={1}
          justifyContent="space-between"
        >
          <Stack gap="1.5rem"></Stack>

          <Stack gap="1rem">
            <Checkbox
              formControlProps={{
                label: (
                  <Typography variant="BodyBold" color="colorfulGray.700">
                    <Typography
                      onClick={() => setIsOpenRolesModal(true)}
                      component="span"
                      px="0.25rem"
                      display="inline-flex"
                      color="info.600"
                    >
                      شرایط و قوانین
                    </Typography>
                    کد دو عاملی را می دانم.
                  </Typography>
                ),
                onChange: (_, checked) => setIsConfirmRoles(checked),
              }}
              isOutline
            />
            <Button
              onClick={() => setIsConfirmToDeleteModal(true)}
              disabled={!isConfirmRoles}
              textColor="error.500"
              variant="outlined"
            >
              <Typography variant="BodyBold">
                غیر فعالسازی کد دو‌عاملی
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
