import { SvgIcon } from "@mui/material";
import { SvgIconProps } from "@mui/material";

export const Logo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 240 240"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M120 0C53.73 0 0 53.73 0 120C0 186.27 53.73 240 120 240C186.27 240 240 186.28 240 120C240 53.72 186.27 0 120 0ZM170.86 197.12C167.38 208.75 133.54 208.91 95.29 197.48C57.04 186.04 28.85 167.34 32.35 155.71C35.83 144.08 69.66 143.92 107.92 155.35C146.16 166.79 174.35 185.49 170.86 197.12ZM124.5 121.21C82.24 136.93 41.46 132.23 33.44 110.73C25.41 89.23 53.17 59.06 95.44 43.34C137.71 27.63 178.48 32.33 186.51 53.83C194.53 75.33 166.77 105.5 124.5 121.21Z" />
      </svg>
    </SvgIcon>
  );
};
