export interface Response<T> {
  message: {
    en: string;
    fa: string;
  };
  result: T;
  status: number;
  success: boolean;
}

export interface Coin {
  name: string;
  prettyName: string;
  prettyNameLocalized: string;
  _id: string;
  items?: Coin[];
}

export enum FilterMarkets {
  NEWEST = "NEWEST",
  MOST_VOLUME = "MOST_VOLUME",
  MOST_PROFIT = "MOST_PROFIT",
  MOST_LOSSES = "MOST_LOSSES",
}
export type Favorite = boolean;
export type Search = string;
export enum BaseCoin {
  TOMAN = "TOMAN",
  USDT = "USDT",
}

export enum TransactionsStatus {
  Created = "CREATED",
  OperatorCheck = "OPERATOR_CHECK",
  Rejected = "REJECTED",
  Approved = "APPROVED",
  Holding = "HOLDING",
  Processing = "PROCESSING",
  Sending = "SENDING",
  Error = "ERROR",
  Success = "SUCCESS",
  Expired = "EXPIRED",
  Initialized = "INITIALIZED",
  Cancelling = "CANCELLING",
  Cancelled = "CANCELLED",
  InProgress = "IN_PROGRESS",
  Transferred = "TRANSFERRED",
  Failed = "FAILED",
  WaitingForUser = "WAITING_FOR_USER",
  VerificationForWaiting = "VERIFICATION_FOR_WAITING",
  Verified = "VERIFIED",
  Reviewing = "REVIEWING",
}

export enum OrdersStatus {
  New = "new",
  Open = "open",
  Cancelling = "cancelling",
  Cancelled = "cancelled",
  Filled = "filled",
}

export interface QueryType {
  field: string;
  operator: string;
  value: string | string[];
}

declare global {
  interface Window {
    ReactNativeWebView: any;
    isRetry: boolean;
  }
}
