import { TomanIcon } from "@assets/icons/TomanIcon";
import { Typography } from "@components/common/typography";
import { Stack, SvgIcon, useTheme } from "@mui/material";
import { LineChart } from "@mui/x-charts";
import { IReportItem } from "@services/queries/dashboard/types";
import { numUtils } from "@utils/num-helpers";
import { ArrowUp2, Tether } from "iconsax-react";
import { useMemo } from "react";
import { LabelValue } from "../types";

interface ReportItemProps {
  isMdUp: boolean;
  data: IReportItem;
  mode: "MONTHLY" | "WEEKLY";
  baseOn: LabelValue | null;
  isHidePrice?: boolean;
}

const ReportItem = ({
  isMdUp,
  data,
  baseOn,
  mode,
  isHidePrice,
}: ReportItemProps) => {
  const theme = useTheme();
  const { convertToPersianDate, commaformatter } = numUtils;

  const isUSDT = useMemo(() => baseOn?.value === "USDT", [baseOn]);
  const isWeekly = useMemo(() => mode === "WEEKLY", [mode]);

  const percent = useMemo(
    () =>
      Number(
        (isUSDT
          ? data?.percentageUSDT ?? "0"
          : data?.percentageIRT ?? "0"
        )?.replaceAll("%", "")
      ),
    [data, baseOn]
  );

  const list = useMemo(
    () =>
      data?.items?.filter(
        (item) => item?.currency === (isUSDT ? "USDT" : "IRT")
      ),
    [data, isUSDT]
  );

  const isLast = list?.[list?.length - 1];

  return (
    <Stack
      height={{ xs: "105px", md: "135px" }}
      width={"100%"}
      borderRadius="1.125rem"
      bgcolor="white"
      boxShadow="0px 0px 8px 0px rgba(0, 0, 0, 0.16)"
      p={{ xs: "0.75rem", md: "1.12rem" }}
    >
      <Typography variant="TitleMedium" smVariant="CaptionMedium">
        {isWeekly ? "7 روز گذشته" : "30 روز گذشته"}
      </Typography>
      <Typography
        variant="BodyMedium"
        smVariant="SmallCaption"
        color="colorfulGray.500"
      >
        محاسبه شده در ساعت{" "}
        {isLast?.issueDate
          ? convertToPersianDate(isLast?.issueDate, "time", "en")
          : ""}
      </Typography>
      <Stack
        direction="row"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack>
          <LineChart
            dataset={list?.map((item) => {
              return { date: new Date(item?.issueDate), dl: item?.value };
            })}
            sx={{
              "& .MuiAreaElement-series-Germany": {
                fill:
                  percent >= 0
                    ? "url('#myGradientInfo')"
                    : "url('#myGradientError')",
              },
              "& .MuiLineElement-root": {
                stroke:
                  percent >= 0
                    ? theme.palette.info[600]
                    : theme.palette.error[500],
                strokeWidth: 2,
              },
              // bottomAxis Line Styles
              "& .MuiChartsAxis-bottom .MuiChartsAxis-line": {
                strokeWidth: 0.0,
              },
              // leftAxis Line Styles
              "& .MuiChartsAxis-left .MuiChartsAxis-line": {
                strokeWidth: 0.0,
              },
            }}
            xAxis={[
              {
                id: "Years",
                dataKey: "date",
                scaleType: "utc",
                sx: {
                  display: "none",
                },
              },
            ]}
            series={[
              {
                id: "Germany",
                dataKey: "dl",
                // stack: "total",
                area: true,
                showMark: false,
              },
            ]}
            margin={{
              left: 0,
              top: 10,
              right: 0,
              bottom: 0,
            }}
            width={isMdUp ? 180 : 120}
            height={isMdUp ? 80 : 70}
          >
            <defs>
              <linearGradient
                id="myGradientInfo"
                gradientTransform="rotate(90)"
              >
                <stop offset="5%" stopColor={theme.palette.info[600]} />
                <stop offset="95%" stopColor="#fff" />
              </linearGradient>
            </defs>
            <defs>
              <linearGradient
                id="myGradientError"
                gradientTransform="rotate(90)"
              >
                <stop offset="5%" stopColor={theme.palette.error[500]} />
                <stop offset="95%" stopColor="#fff" />
              </linearGradient>
            </defs>
          </LineChart>
        </Stack>
        <Stack>
          <Stack direction="row" gap="0.25rem" alignItems="center">
            <Typography variant="ExtendTitle" smVariant="Body">
              {isHidePrice
                ? "********"
                : commaformatter?.toCurrency(
                    isUSDT
                      ? isLast?.value?.toFixed(2)
                      : isLast?.value?.toFixed(0)
                  )}
            </Typography>
            <SvgIcon
              sx={{
                color: (theme) => theme.palette.colorfulGray[300],
                width: { xs: "0.75rem", md: "1.5rem" },
                height: { xs: "0.75rem", md: "1.5rem" },
              }}
            >
              {isUSDT ? <Tether /> : <TomanIcon />}
            </SvgIcon>
          </Stack>
          <Stack
            direction="row"
            gap="0.25rem"
            alignItems="center"
            justifyContent={"flex-end"}
          >
            <Typography
              variant="BodyMedium"
              smVariant="SmallCaption"
              color={percent >= 0 ? "info.600" : "error.500"}
              dir="ltr"
            >
              {(isUSDT ? data?.percentageUSDT : data?.percentageIRT) || 0}
            </Typography>

            <SvgIcon
              sx={{
                color: (theme) =>
                  percent >= 0
                    ? theme.palette.info[600]
                    : theme.palette.error[500],
                width: { sm: "0.75rem", md: "1.125rem" },
                height: { sm: "0.75rem", md: "1.125rem" },
                transform: percent >= 0 ? "rotate(0deg)" : "rotate(180deg)",
                transition: "0.3s",
              }}
            >
              <ArrowUp2 variant="Bold" />
            </SvgIcon>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ReportItem;
