import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { Stack, SvgIcon, SxProps } from "@mui/material";
import { formatCardBank } from "@utils/common";
import { toast } from "@utils/toast";
import { Back, Trash } from "iconsax-react";
import { useEffect, useState } from "react";
import { ApproveDeleteBankModal } from "../approve-delete-bank-modal";

interface BankCardItemProps {
  title: string;
  subtitle?: string;
  imgSrc: string;
  alt: string;
  onClick?: () => void;
  onDelete?: () => void;
  mode?: "account" | "card";
  sx?: SxProps;
  noSubtitle?: boolean;
  isSheba?: boolean;
}

export const BankCardItem = ({
  alt,
  title,
  subtitle,
  imgSrc,
  mode = "card",
  sx,
  noSubtitle,
  isSheba,
  onClick = () => {},
  onDelete,
}: BankCardItemProps) => {
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    if (isDeleting && onDelete) {
      const setTimeOut = setTimeout(() => {
        onDelete();
      }, 5000);
      setTimeout(() => {
        setIsDeleting(false);
      }, 7000);
      toast.neutral("کارت بانکیاتان حذف شد", {
        duration: 5000,
        customIcon: (
          <Stack
            onClick={() => {
              clearTimeout(setTimeOut);
              setIsDeleting(false);
            }}
            direction={"row"}
            gap={0.5}
          >
            <Typography color="white" variant="CaptionMedium">
              بازگشت
            </Typography>
            <Back size="32" color="#FFFFFF" variant="Bold" />
          </Stack>
        ),
      });
    }
  }, [isDeleting]);

  return (
    <Stack
      onClick={onClick}
      py="0.94rem"
      px="0.75rem"
      bgcolor="neutral.50"
      borderRadius="0.5rem"
      gap="0.75rem"
      direction="row"
      justifyContent="space-between"
      border={1}
      borderColor="transparent"
      sx={{
        cursor: "pointer",
        "&:hover": {
          borderColor: "primary.500",
        },
        display: isDeleting ? "none" : "flex",
        ...sx,
      }}
    >
      <ApproveDeleteBankModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        data={{ card: title, iban: title }}
        isSheba={isSheba}
        onDelete={() => {
          setIsDeleteModalOpen(false);
          setIsDeleting(true);
        }}
      />

      <Stack direction="row" gap="0.5rem">
        <Image src={imgSrc} width="1.5rem" height="1.5rem" alt={alt} />
        <Stack>
          <Typography variant="BodyMedium" color="neutral.A700" dir="ltr">
            {mode === "card" ? formatCardBank(title) : title}
          </Typography>
          {!noSubtitle && (
            <Typography variant="CaptionMedium" color="colorfulGray.500">
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>

      {onDelete && (
        <SvgIcon
          onClick={() => setIsDeleteModalOpen(true)}
          sx={{
            fontSize: "1.25rem",
            color: (theme) => theme.palette.colorfulGray[400],
          }}
        >
          <Trash variant="Bold" />
        </SvgIcon>
      )}
    </Stack>
  );
};
