import Tabs from "@components/common/tabs";
import { TabItem } from "@components/common/tabs/types";
import { Grid, Paper } from "@mui/material";
import RecentTrades from "./_components/recent_trade";
import MyRecentTrades from "./_components/my_recent_trade";

const Trade = () => {
  const tabs: TabItem[] = [
    {
      label: "معاملات اخیر",
      content: <RecentTrades />,
    },
    { label: "معاملات اخیر من", content: <MyRecentTrades /> },
  ];
  return (
    <Grid item xs mt={2}>
      <Paper
        elevation={0}
      >
        <Tabs
          tabs={tabs}
          variant="fullWidth"
          tabPanelStyle={{ fontSize: 12 }}
        />
      </Paper>
    </Grid>
  );
};

export default Trade;
