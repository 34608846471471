import { ManageSearchOutlined } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

const NotFoundItem = () => {
  return (
    <Stack
      alignItems="center"
      py="2rem"
      gap="1rem"
      height={"100%"}
      width={"100%"}
    >
      <ManageSearchOutlined sx={{ fontSize: "5rem", color: "neutral.400" }} />
      <Typography
        variant="body1"
        color="neutral.600"
        fontWeight={500}
        textAlign="center"
      >
        موردی یافت نشد
      </Typography>
    </Stack>
  );
};

export default NotFoundItem;
