export const steps = [
  {
    title: "اشتراک‌گذاری لینک دعوت",
    subtitle:
      "لینک دعوت اختصاصی خود را با دوستان و یا در شبکه‌های اجتماعی، به اشتراک بگذارید.",
  },
  {
    title: "ثبت‌نام در بالینکس",
    subtitle:
      "دوستان با کد دعوت اختصاصی شما ثبت نام، احراز هویت خود را تکمیل و شروع به معامله می‌کنند.",
  },
  {
    title: "دریافت پاداش!",
    subtitle:
      "به ازای هر معامله، تا %۴۵ از کارمزد تراکنش دوستان خود را پاداش دریافت می‌کنید.",
  },
];

export const referralLevels = [
  {
    title: "زیر ۱۰ نفر",
    percent: "25%",
    min: 0,
    max: 9,
  },
  {
    title: "۱۰ - ۴۰ نفر",
    percent: "30%",
    min: 10,
    max: 40,
  },
  {
    title: "۴۰ تا ۲۰۰ نفر",
    percent: "35%",
    min: 41,
    max: 200,
  },
  {
    title: "۲۰۰ - ۱۰۰۰ نفر",
    percent: "40%",
    min: 201,
    max: 1000,
  },
  {
    title: "بالای ۱۰۰۰ نفر",
    percent: "45%",
    min: 1001,
    max: Infinity,
  },
];
