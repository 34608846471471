import { SvgIcon, SvgIconProps } from "@mui/material";

export const GiftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="129"
        height="133"
        viewBox="0 0 129 133"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M81.3266 69.0051V81.6248C81.3266 86.2731 77.5617 90.0379 72.9134 90.0379H56.0872C51.4389 90.0379 47.674 86.2731 47.674 81.6248V69.0051C47.674 67.8483 48.6205 66.9018 49.7773 66.9018H53.9208C55.0776 66.9018 56.0241 67.8483 56.0241 69.0051V75.6094C56.0241 77.1658 56.8864 78.5961 58.2535 79.3322C58.8635 79.6687 59.5365 79.837 60.2306 79.837C61.0299 79.837 61.8291 79.6056 62.5232 79.1429L64.5213 77.8389L66.3722 79.0798C67.6552 79.9422 69.2958 80.0473 70.6629 79.3112C72.0511 78.575 72.9134 77.1658 72.9134 75.5884V69.0051C72.9134 67.8483 73.8599 66.9018 75.0167 66.9018H79.2233C80.3801 66.9018 81.3266 67.8483 81.3266 69.0051Z"
          fill="#4D35D7"
        />
        <path
          d="M84.4815 58.4886V60.5919C84.4815 62.9055 83.3668 64.7985 80.275 64.7985H48.7257C45.5076 64.7985 44.5191 62.9055 44.5191 60.5919V58.4886C44.5191 56.175 45.5076 54.282 48.7257 54.282H80.275C83.3668 54.282 84.4815 56.175 84.4815 58.4886Z"
          fill="#4D35D7"
        />
        <path
          d="M63.7432 54.2821H52.133C51.4179 53.5039 51.4389 52.305 52.1961 51.5478L55.1828 48.5611C55.961 47.7829 57.244 47.7829 58.0222 48.5611L63.7432 54.2821Z"
          fill="#F37A43"
        />
        <path
          d="M76.8465 54.2821H65.2364L70.9573 48.5611C71.7355 47.7829 73.0185 47.7829 73.7968 48.5611L76.7834 51.5478C77.5406 52.305 77.5616 53.5039 76.8465 54.2821Z"
          fill="#F37A43"
        />
        <path
          d="M68.6438 66.9018C69.8006 66.9018 70.7471 67.8483 70.7471 69.0051V75.5884C70.7471 77.271 68.8752 78.2806 67.487 77.3341L65.594 76.0721C64.9 75.6094 63.9955 75.6094 63.2804 76.0721L61.3033 77.3762C59.9152 78.3016 58.0643 77.292 58.0643 75.6304V69.0051C58.0643 67.8483 59.0107 66.9018 60.1676 66.9018H68.6438Z"
          fill="#F37A43"
        />
        <path
          d="M72.5002 0.203125L74.877 6.62633L81.3002 9.00313L74.877 11.3799L72.5002 17.8031L70.1234 11.3799L63.7002 9.00313L70.1234 6.62633L72.5002 0.203125Z"
          fill="#FCD7C7"
        />
        <path
          d="M118.9 80.2031L121.277 86.6263L127.7 89.0031L121.277 91.3799L118.9 97.8031L116.523 91.3799L110.1 89.0031L116.523 86.6263L118.9 80.2031Z"
          fill="#C8C1F3"
        />
        <path
          d="M14.0998 48.2031L15.3962 52.2906L18.8998 53.8031L15.3962 55.3156L14.0998 59.4031L12.8034 55.3156L9.2998 53.8031L12.8034 52.2906L14.0998 48.2031Z"
          fill="#FCD7C7"
        />
        <path
          d="M81.3001 94.6016L82.5966 98.6891L86.1001 100.202L82.5966 101.714L81.3001 105.802L80.0037 101.714L76.5001 100.202L80.0037 98.6891L81.3001 94.6016Z"
          fill="#FCD7C7"
        />
        <path
          d="M24.5004 11.4023L26.0129 14.9059L30.1004 16.2023L26.0129 17.4988L24.5004 21.0023L22.9879 17.4988L18.9004 16.2023L22.9879 14.9059L24.5004 11.4023Z"
          fill="#B0A5ED"
        />
      </svg>
    </SvgIcon>
  );
};
