import { Button } from "@components/common/button";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { InputAdornment, Stack } from "@mui/material";
import { ChoosePaymentMethod } from "@pages/dashborad/wallet/_components/choose-payment-method";
import {
  ArrowRight,
  Information,
  LampCharge,
  SecurityUser,
} from "iconsax-react";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Hint from "@components/common/hint";
import { useUserStore } from "@store/user";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { TomanDepositSchema } from "@pages/dashborad/wallet/_validations";
import {
  useGetFeeDepositList,
  useGetSumDailyDeposit,
} from "@services/queries/wallet/queries";
import { numUtils } from "@utils/num-helpers";
import { TomanIcon } from "@assets/icons/TomanIcon";
import { numberToWords } from "@utils/num-to-word";

export default function TomanDeposit() {
  const isMdUp = useBreakpoint("md");
  const navigate = useNavigate();

  const [step, setStep] = useState<"AMOUNT" | "CHOOSE_PAYMENT">("AMOUNT");

  const getSumDailyDepositQuery = useGetSumDailyDeposit();
  const getFeeDepositList = useGetFeeDepositList();

  const { register, watch } = useForm<z.output<typeof TomanDepositSchema>>({
    mode: "onTouched",
    resolver: zodResolver(TomanDepositSchema),
  });

  const user = useUserStore((state) => state?.user);

  const isDepositWithCardEnabled = useMemo(
    () =>
      !watch("amount")?.length ||
      Number(watch("amount")) < (import.meta.env.VITE_MIN_DEPOSIT_TOMAN || 0) ||
      Number(watch("amount") || 0) >
        (getSumDailyDepositQuery?.data?.result?.available || 0),
    [watch("amount")]
  );

  const feeAmount = useMemo(() => {
    const result = getFeeDepositList?.data?.result;

    const price =
      Math.ceil(Number(watch("amount")) / (result?.minAmount || 0)) *
      (result?.minAmount || 0);

    const fee = result?.list?.find((item) => item?.amount === price)?.fee;

    return fee;
  }, [watch("amount")]);

  return (
    <Wrapper
      hideBottomNavigation
      backMode
      loading={getSumDailyDepositQuery?.isLoading}
      title="واریز پول"
      onClickBackArrow={() => {
        if (step === "CHOOSE_PAYMENT") {
          setStep("AMOUNT");
        } else {
          navigate("/v2/dashboard/wallet");
        }
      }}
      isNoPaddingModeContainer
      sxContainer={{ pt: { md: "2rem" } }}
    >
      <Stack
        sx={{
          p: "1.5rem",
          bgcolor: "white",
          flex: 1,
          borderRadius: { md: "1.125rem" },
        }}
      >
        <Stack
          width="100%"
          sx={{
            minHeight: { xs: "calc(100dvh - 90px)", md: "auto" },
            gap: "2rem",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Stack
            sx={{ flex: { md: 1 } }}
            gap="1rem"
            justifyContent="space-between"
          >
            {user?.isSuspended ? (
              <Hint
                severity="error"
                IconComponent={Information}
                content={
                  // prettier-ignore
                  <Typography variant="Caption" color="error.700">
                    {`شما مجاز به واریز یا برداشت نیستید، لطفا با پشتیبانی تماس بگیرید.`}
                  </Typography>
                }
              />
            ) : !(!!user?.IDverification && !!user?.mobileVerification) ? (
              <Hint
                severity="warning"
                IconComponent={SecurityUser}
                content={
                  <Typography variant="Body" color="warning.900">
                    برای واریز و برداشت تومان نیاز به احراز هویت است
                  </Typography>
                }
                action={
                  <Button
                    href="/v2/dashboard/authentication"
                    bgColor="white"
                    textColor="primary.700"
                    sx={{ border: 1.5, borderColor: "primary.700" }}
                    startIcon={<SecurityUser size="1.25rem" />}
                  >
                    <Typography variant="BodyMedium">احراز هویت</Typography>
                  </Button>
                }
              />
            ) : (
              <>
                {(step === "AMOUNT" || isMdUp) && (
                  <>
                    <TextField
                      numberInput
                      thousandSeparator
                      {...register("amount")}
                      label="وایز تومان به کیف‌پول"
                      placeholder="مبلغ واریز"
                      helperText={
                        <Stack>
                          {!!watch("amount")?.length && (
                            <Typography
                              variant="CaptionMedium"
                              color="colorfulGray.500"
                            >
                              {numberToWords(watch("amount") || 0)?.toString()}{" "}
                              تومان
                            </Typography>
                          )}
                          {feeAmount && (
                            <Typography
                              variant="SmallCaption"
                              color="colorfulGray.500"
                            >
                              (کارمزد:{" "}
                              {numUtils.commaformatter.toCurrency(feeAmount)}{" "}
                              تومان)
                            </Typography>
                          )}
                        </Stack>
                      }
                      InputProps={{
                        inputMode: "numeric",
                        endAdornment: (
                          <InputAdornment position="end">
                            <TomanIcon
                              sx={{
                                fill: (theme) =>
                                  theme.palette.colorfulGray[400],
                                width: "1.25rem",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </>
                )}

                {(step === "CHOOSE_PAYMENT" || isMdUp) && (
                  <Stack gap="1rem" sx={{ mt: { md: "1.5rem" } }}>
                    <ChoosePaymentMethod
                      navigateOptions={{
                        state: {
                          amount: watch("amount"),
                          totalAvailable:
                            getSumDailyDepositQuery?.data?.result?.available ||
                            0,
                        },
                      }}
                      href="/v2/dashboard/wallet/toman/deposit/card"
                      disabled={isDepositWithCardEnabled}
                      title="پرداخت با کارت بانکی"
                      description={
                        Number(watch("amount") || 0) >
                        (getSumDailyDepositQuery?.data?.result?.available || 0)
                          ? `برای بالای ${numberToWords(
                              getSumDailyDepositQuery?.data?.result
                                ?.available || 0
                            )?.toString()} تومان، از روش پرداخت با شناسه استفاده کنید`
                          : "پرداخت از طریق درگاه بانک و شتابی و شارژ کیف پول آنی"
                      }
                    />
                    <ChoosePaymentMethod
                      disabled={
                        !watch("amount") ||
                        Number(watch("amount")) <
                          (import.meta.env.VITE_MIN_DEPOSIT_TOMAN || 0) ||
                        Number(watch("amount")) > 999999999999
                      }
                      href="/v2/dashboard/wallet/toman/deposit/sheba"
                      title="پرداخت با شناسه"
                      description="پرداخت با شناسه واریز و شماره شبا به روش پایا"
                    />
                  </Stack>
                )}
              </>
            )}
          </Stack>
          <Stack sx={{ flex: 1 }} justifyContent="space-between">
            <Hint
              severity="info"
              IconComponent={LampCharge}
              content={
                <Stack gap="0.62rem">
                  <Typography variant="Caption" color="info.700">
                    {`حداقل مبلغ واریز با کارت ${numUtils?.commaformatter?.toCurrency(
                      import.meta.env.VITE_MIN_DEPOSIT_TOMAN
                    )}  تومان و حداکثر ${numUtils?.commaformatter?.toCurrency(
                      getSumDailyDepositQuery?.data?.result?.available || 0
                    )} تومان می باشد.  همینطور می‌توانید برای واریز، تا سقف ۲۵ میلیون تومان روزانه، از طریق پرداخت با شناسه‌ اقدام کنید.`}
                  </Typography>
                  {/* <Typography variant="Caption" color="info.700">
                    {`برای واریز مبالغ بالای ${numUtils?.commaformatter?.toCurrency(
                      getSumDailyDepositQuery?.data?.result?.available || 0
                    )} تومان از گزینه پرداخت با شناسه استفاده کنید.`}
                  </Typography> */}
                  <Typography variant="Caption" color="info.700">
                    
                  </Typography>
                </Stack>
              }
            />
            {step === "AMOUNT" && (
              <Button
                disabled={
                  !watch("amount")?.length ||
                  Number(watch("amount")) <
                    (import.meta.env.VITE_MIN_DEPOSIT_TOMAN || 0) ||
                  Number(watch("amount")) > 999999999999
                }
                size="large"
                onClick={() => {
                  setStep("CHOOSE_PAYMENT");
                }}
                sx={{ display: { xs: "flex", md: "none" } }}
                tailStartIcon={<ArrowRight size="1.25rem" />}
              >
                <Typography variant="BodyBold">ادامه</Typography>
              </Button>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  );
}
