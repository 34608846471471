import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { Stack, useTheme } from "@mui/material";
import { IMarket } from "@services/queries/markets/types";
import { getCoinIconURL } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { ArrowUp2 } from "iconsax-react";
import { Link } from "react-router-dom";

interface MarketsSelectedCardProps {
  title: string;
  data: IMarket;
}

export const MarketsSelectedCard = ({
  title,
  data,
}: MarketsSelectedCardProps) => {
  const theme = useTheme();

  return (
    <Stack
      width="100%"
      p="0.75rem 1rem"
      borderRadius="1.125rem"
      bgcolor="white"
    >
      <Link to={`/v2/dashboard/markets/${data?._id}`}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            direction="row"
            gap="0.25rem"
            alignItems="center"
            flexWrap="wrap"
          >
            <Typography
              variant="ExtendedBodyBold"
              smVariant="BodyBold"
              color="primary.700"
            >
              {title}
            </Typography>
            <Typography dir="ltr" variant="Caption" color="colorfulGray.300">
              24 h
            </Typography>
          </Stack>

          <Stack direction="row" gap="0.5rem">
            <Stack gap="0.15rem">
              <Typography
                variant="Body"
                smVariant="Caption"
                textAlign="right"
                color="colorfulGray.600"
                whiteSpace="nowrap"
              >
                {data?.localizedTitle?.split(" / ")[0] || ""}
              </Typography>
              <Typography
                variant="Body"
                smVariant="Caption"
                color="colorfulGray.600"
              >
                ({data?.baseCurrency})
              </Typography>
            </Stack>
            <Image
              alt="coin-image"
              src={getCoinIconURL(data?.baseCurrency)}
              height="2.625rem"
              width="2.625rem"
            />
          </Stack>
        </Stack>

        <Stack
          mt="0.3rem"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" gap="0.25rem" alignItems="center">
            <Typography
              variant="TitleMedium"
              color={
                Number(data?.percentChange24h || 0) > 0
                  ? "info.600"
                  : "error.500"
              }
            >
              {Number(data?.percentChange24h?.replaceAll("-", "")) || 0}%
            </Typography>
            <ArrowUp2
              variant="Bold"
              size="0.75rem"
              color={
                Number(data?.percentChange24h || 0) > 0
                  ? theme?.palette?.info?.[600]
                  : theme?.palette?.error?.[500]
              }
              style={{
                transform:
                  Number(data?.percentChange24h || 0) > 0
                    ? "rotateZ(0)"
                    : "rotateZ(180deg)",
              }}
            />
          </Stack>

          <Stack gap="0.25rem">
            <Stack
              direction="row"
              gap="0.25rem"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography variant="SmallCaptionMedium" color="colorfulGray.300">
                {data?.quoteCurrency}
              </Typography>
              <Typography variant="CaptionMedium" color="neutral.700">
              {numUtils.commaformatter.toCurrency(Number(data?.todayPrice).toFixed(data?.quoteScale || 0))}
              </Typography>
            </Stack>
            <Stack direction="row" gap="0.25rem">
              <Typography variant="SmallCaptionMedium" color="colorfulGray.300">
                {data?.baseCurrency || ""}
              </Typography>
              <Typography variant="SmallCaptionMedium" color="colorfulGray.500">
              {numUtils.commaformatter.toCurrency(Number(data?.yesterdayVolume).toFixed(data?.baseScale || 0))}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Link>
    </Stack>
  );
};
