import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { getOrders, getOTC, getSpecificTransaction, getTransactions } from "./apis";
import { DEFAULT_PER_PAGE } from "@constants/index";
import {
  OrderItemRes,
  OrdersArgs,
  OTCItemRes,
  TransactionsArgs,
  TransactionsItemRes,
} from "./types";
import { Response } from "~types/index";
import { mergeFilters, removeConflictingFilters } from "@utils/common";

export const useTransactionsInfiniteList = (data: TransactionsArgs) =>
  useInfiniteQuery<
    Response<{ items: TransactionsItemRes[]; total: number }>,
    void
  >({
    queryKey: ["transactions-financial", { query: data?.query }],
    queryFn: ({ pageParam }) =>
      getTransactions({
        ...data,
        query: mergeFilters(removeConflictingFilters(data?.query)),
        page: Number(pageParam ?? 0),
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.result?.items?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
  });

export const useOTCInfiniteList = (data: OrdersArgs) =>
  useInfiniteQuery<{ items: OTCItemRes[]; total: number }, void>({
    queryKey: ["otc-financial", { query: data?.query }],
    queryFn: ({ pageParam }) =>
      getOTC({
        ...data,
        query: mergeFilters(removeConflictingFilters(data?.query)),
        page: Number(pageParam ?? 0),
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.items?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
  });

export const useOrdersInfiniteList = (data: OrdersArgs) =>
  useInfiniteQuery<{ items: OrderItemRes[]; total: number }, void>({
    queryKey: ["orders-financial", { query: data?.query }],
    queryFn: ({ pageParam }) =>
      getOrders({
        ...data,
        query: mergeFilters(removeConflictingFilters(data?.query)),
        page: Number(pageParam ?? 0),
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.items?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
  });

export const useSpecificTransaction = ({ orderId }: { orderId: string }) =>
  useQuery({
    queryKey: ["specific-transaction", { orderId }],
    queryFn: () => getSpecificTransaction({ orderId }),
  });
