import { Stack, SxProps } from "@mui/material";
import React from "react";

interface IconTypographyProps {
  bgColor?: string;
  width?: string;
  icon: React.ReactNode;
  title: React.ReactNode;
  sx?: SxProps;
}

export const IconTypography = ({
  bgColor,
  icon,
  width = "5rem",
  title,
  sx,
}: IconTypographyProps) => {
  return (
    <Stack alignItems="center" gap="0.75rem" sx={sx}>
      <Stack
        bgcolor={bgColor || ""}
        width={width}
        sx={{ aspectRatio: "1/1" }}
        borderRadius="50%"
        justifyContent="center"
        alignItems="center"
      >
        {icon}
      </Stack>

      {title}
    </Stack>
  );
};
