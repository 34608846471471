import { TomanIcon } from "@assets/icons/TomanIcon";
import { LinearProgress } from "@components/common/linear-progress";
import { Typography } from "@components/common/typography";
import { Stack } from "@mui/material";
import { numUtils } from "@utils/num-helpers";

interface MaxWithdrawalItemProps {
  title: string;
  titlePrice: number | string;
  subtitle: string;
  subtitlePrice: number | string;
  amount?: number;
}

export const MaxWithdrawalItem = ({
  subtitle,
  subtitlePrice,
  title,
  titlePrice,
  amount,
}: MaxWithdrawalItemProps) => {
  return (
    <Stack gap="0.75rem">
      <Stack direction="row" justifyContent="space-between">
        <Typography smVariant="Caption" variant="Body" color="colorfulGray.700">
          {title}
        </Typography>

        <Stack direction="row" gap="0.25rem" alignItems="center">
          <Typography
            smVariant="CaptionMedium"
            variant="BodyMedium"
            color="colorfulGray.600"
          >
            {numUtils.commaformatter.toCurrency(titlePrice)}
          </Typography>
          <TomanIcon
            sx={{
              fontSize: { xs: "0.75rem", md: "1rem" },
              fill: (theme) => theme.palette.colorfulGray[400],
            }}
          />
        </Stack>
      </Stack>

      <LinearProgress
        variant="buffer"
        valueBuffer={(Number(subtitlePrice) * 100) / Number(titlePrice)}
        value={
          ((Number(subtitlePrice) + (amount || 0)) * 100) / Number(titlePrice) >
          100
            ? 100
            : ((Number(subtitlePrice) + (amount || 0)) * 100) /
              Number(titlePrice)
        }
        sx={{
          ".MuiLinearProgress-dashed": { animation: "none", backgroundSize: 0 },
          ".MuiLinearProgress-bar1Buffer": {
            bgcolor:
              ((Number(subtitlePrice) + (amount || 0)) * 100) /
                Number(titlePrice) >
              100
                ? "error.500"
                : "secondary.400",
            zIndex:
              ((Number(subtitlePrice) + (amount || 0)) * 100) /
                Number(titlePrice) >
              100
                ? 3
                : 1,
          },
          ".MuiLinearProgress-bar2Buffer": {
            bgcolor: "primary.400",
            zIndex: 2,
          },
          bgcolor: "colorfulGray.50",
        }}
      />

      <Stack direction="row" justifyContent="space-between">
        <Typography smVariant="Caption" variant="Body" color="colorfulGray.600">
          {subtitle}
        </Typography>

        <Stack direction="row" gap="0.25rem" alignItems="center">
          <Typography
            smVariant="CaptionMedium"
            variant="BodyMedium"
            color="colorfulGray.600"
          >
            {numUtils.commaformatter.toCurrency(subtitlePrice)}
          </Typography>
          <TomanIcon
            sx={{
              fontSize: { xs: "0.75rem", md: "1rem" },
              fill: (theme) => theme.palette.colorfulGray[400],
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
