import {
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
} from "@mui/material";
import { numUtils } from "@utils/num-helpers";
import useGetLastTrade from "../../../_hooks/useGetLastTrade";
import NotFoundItem from "@components/common/not-found";

interface RecentTradeProps {
  height?: string;
}

const RecentTrades = ({ height }: RecentTradeProps) => {
  const { recentTrade: data, currentCurrency, isLoading } = useGetLastTrade();
  const isIRT = currentCurrency?.quoteCurrency === "IRT";

  const { commaformatter, convertToPersianDate, toEnglish } = numUtils;

  return (
    <>
      <List
        sx={{
          overflow: "overlay",
          px: 0.5,
        }}
        dense
      >
        <ListItem disablePadding sx={{ px: 1 }}>
          <ListItemText
            primary={"قیمت"}
            primaryTypographyProps={{
              color: "neutral.600",
              textAlign: "left",
              fontSize: "0.75rem",
            }}
          />
          <ListItemText
            primary={`مقدار (${currentCurrency?.baseCurrency})`}
            primaryTypographyProps={{
              color: "neutral.600",
              textAlign: "center",
              fontSize: "0.75rem",
            }}
          />
          <ListItemText
            primary={"زمان"}
            primaryTypographyProps={{
              color: "neutral.600",
              textAlign: "right",
              fontSize: "0.75rem",
            }}
          />
        </ListItem>
        <Divider flexItem sx={{ mt: 1 }} />
      </List>
      <List
        sx={{
          px: 1,
          overflow: "overlay",
          height: height || 309,

          "& :hover": {
            backgroundColor: "neutral.50",
            cursor: "pointer",
          },
        }}
        dense
      >
        {isLoading && (
          <Stack alignItems="center" gap="1rem">
            <CircularProgress />
          </Stack>
        )}
        {data?.length > 0 ? (
          data?.map((value, index) => (
            <ListItem
              disablePadding
              key={index}
              sx={{
                px: 0.5,
              }}
            >
              <ListItemText
                primary={commaformatter.toCurrency(
                  // Number(value.price).toFixed(
                  //   isIRT
                  //     ? currentCurrency?.toRoundScale?.IRT
                  //     : currentCurrency?.toRoundScale?.USDT
                  // )
                  Number(value.price)
                )}
                primaryTypographyProps={{
                  color: value.side === "sell" ? "error.main" : "success.main",
                  textAlign: "left",
                  fontSize: "0.75rem",
                }}
              />
              <ListItemText
                primary={Number(value.size).toFixed(
                  currentCurrency?.scale?.[currentCurrency?.quoteCurrency]
                    ?.toRoundScale ?? 0
                )}
                primaryTypographyProps={{
                  color: "neutral.600",
                  textAlign: "center",
                  fontSize: "0.75rem",
                }}
              />
              <ListItemText
                primary={toEnglish(
                  convertToPersianDate(value.time, "ISOtime-to-localtime")
                )}
                primaryTypographyProps={{
                  color: "neutral.600",
                  textAlign: "right",
                  fontSize: "0.75rem",
                }}
              />
            </ListItem>
          ))
        ) : (
          <NotFoundItem />
        )}
      </List>
    </>
  );
};

export default RecentTrades;
