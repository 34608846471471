import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack, SvgIcon } from "@mui/material";
import { OrderItemRes } from "@services/queries/financial/types";
import { getCoinIconURL, getPersianEquivalent } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import {
  format,
  formatDistanceToNow,
  isBefore,
  subDays,
} from "date-fns-jalali";
import { faIR } from "date-fns-jalali/locale";
import { ArrowLeft2 } from "iconsax-react";
import { useState } from "react";
import { OrderDetails } from "../orders-details";
import { OrdersStatus } from "~types/index";

interface OrderCardProps {
  data: OrderItemRes;
}

const formatDate = (dateString: string): string => {
  if (!dateString) return "نامشخص";

  const date = new Date(dateString);
  const oneDayAgo = subDays(new Date(), 1);

  if (isBefore(date, oneDayAgo)) {
    return format(date, "yyyy/MM/dd - HH:mm", { locale: faIR });
  } else {
    return `${formatDistanceToNow(date, { addSuffix: true, locale: faIR })}`;
  }
};

export const OrderCard = ({ data }: OrderCardProps) => {
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  return (
    <>
      <Modal
        title="تاریخچه معاملات"
        open={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
      >
        <OrderDetails
          onClose={() => setIsDetailsModalOpen(false)}
          data={data}
        />
      </Modal>
      <Stack
        component="li"
        px="1rem"
        pr={0}
        py="1.5rem"
        borderBottom={1}
        borderColor="primary.50"
        sx={{ cursor: "pointer", "&:last-child": { borderBottom: 0 } }}
        onClick={() => setIsDetailsModalOpen(true)}
      >
        <Stack direction="row" gap="0.5rem">
          <Stack flex={1} gap="0.6rem">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" gap="0.5rem">
                <Typography
                  py="0.25rem"
                  px="0.5rem"
                  bgcolor={data?.side === "buy" ? "info.A100" : "error.A100"}
                  color={data?.side === "buy" ? "info.700" : "error.500"}
                  variant="CaptionBold"
                  smVariant="SmallCaptionBold"
                  borderRadius="0.25rem"
                >
                  {data?.side === "buy" ? "خرید" : "فروش"}
                </Typography>
                <Image
                  height="1.5rem"
                  width="1.5rem"
                  alt={data?.baseCurrency}
                  src={getCoinIconURL(data?.baseCurrency)}
                />
                <Typography
                  smVariant="BodyMedium"
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.800"
                >
                  <Typography
                    component="span"
                    px="0.25rem"
                    color="colorfulGray.500"
                  >
                    {data?.quoteCurrency} /
                  </Typography>
                  {data?.baseCurrency}
                </Typography>
              </Stack>
              <Typography
                variant="CaptionMedium"
                smVariant="SmallCaptionMedium"
                color="colorfulGray.400"
              >
                {formatDate(data?.createdAt)}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                قیمت ثبت شده
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.800"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.price))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.5rem"
                  color="colorfulGray.500"
                >
                  {data?.quoteCurrency}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                مقدار
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.800"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.size))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.5rem"
                  color="colorfulGray.500"
                >
                  {data?.baseCurrency}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                وضعیت
              </Typography>
              <Typography
                py="0.25rem"
                px="0.5rem"
                bgcolor="neutral.50"
                color={
                  data?.status === "open"
                    ? "info.700"
                    : data?.status === "filled"
                    ? "success.700"
                    : "error.600"
                }
                variant="CaptionBold"
                smVariant="SmallCaptionBold"
                borderRadius="0.25rem"
              >
                {getPersianEquivalent(data?.status as OrdersStatus)}
              </Typography>
            </Stack>
          </Stack>
          <Stack justifyContent="center">
            <SvgIcon sx={{ color: (theme) => theme.palette.colorfulGray[500] }}>
              <ArrowLeft2 size="1.25rem" />
            </SvgIcon>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
