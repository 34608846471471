import Cookies from "js-cookie";

export function setCookie(
  name: string,
  value: string,
  days: number,
  domain?: string,
  sameSite?: "lax" | "strict" | "none",
  secure?: boolean
) {
  Cookies.set(name, value, {
    expires: days,
    path: "/",
    domain,
    sameSite,
    secure,
  });
}

export function getCookie(name: string) {
  return Cookies.get(name);
}
