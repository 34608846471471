import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, useTheme } from "@mui/material";
import { CardAdd } from "iconsax-react";
import { useState } from "react";
import { AddBankModal } from "../add-bank-modal";

interface AddBankButtonProps {
  titleBtn: string;
  iconBtn?: React.ReactNode;
  onSuccess?: () => void;
}

export const AddBankButton = ({
  titleBtn,
  iconBtn,
  onSuccess = () => {},
}: AddBankButtonProps) => {
  const theme = useTheme();
  const isMdUp = useBreakpoint("md");

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Stack
        mt="1rem"
        borderRadius="0.875rem"
        py="1rem"
        px="0.75rem"
        gap="0.38rem"
        onClick={() => setIsOpen(true)}
        sx={{
          cursor: "pointer",
          borderWidth: "2px",
          borderColor: "primary.300",
          borderStyle: "dashed",
        }}
        alignItems="center"
        bgcolor="primary.A100"
      >
        {iconBtn || (
          <CardAdd
            variant="Bold"
            color={theme?.palette?.primary[700]}
            size={isMdUp ? "1.75rem" : "1.25rem"}
          />
        )}
        <Typography
          variant="ExtendedBodyBold"
          smVariant="BodyBold"
          color="primary.700"
        >
          {titleBtn}
        </Typography>
      </Stack>

      <AddBankModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onSuccess={() => {
          setIsOpen(false);
          onSuccess();
        }}
      />
    </>
  );
};
