import {
  CircularProgress,
  ListItem,
  ListItemText,
  useTheme,
} from "@mui/material";
import useCurrentCurrency from "@pages/dashborad/markets/market-details/_hooks/useCurrentCurrency";
import { useHandleFavorite } from "@services/queries/markets/mutations";
import { IMarket } from "@services/queries/markets/types";
import { numUtils } from "@utils/num-helpers";
import { set } from "date-fns-jalali";
import { Star1 } from "iconsax-react";
import { useEffect, useState } from "react";
import { Link, LinkProps } from "react-router-dom";

interface CurrencyListItemProps {
  value: IMarket;
  isCapacity: boolean;
  setOnFavorite?: (id: string) => void;
  btnFilter?: string;
}

const CurrencyListItem = ({
  value,
  isCapacity,
  setOnFavorite,
  btnFilter,
}: CurrencyListItemProps) => {
  const theme = useTheme();
  const { commaformatter } = numUtils;

  const [isFave, setIsFave] = useState(value?.isFave);

  const favoriteMutation = useHandleFavorite();

  const handleFavorite = (id: string) => favoriteMutation?.mutate(id);

  useEffect(() => {
    if (favoriteMutation?.isSuccess) {
      setIsFave(!isFave);
      setOnFavorite?.(value?._id);
    }
  }, [favoriteMutation?.isPending]);

  const LinkMarket = (props: LinkProps) => {
    return <Link {...props} />;
  };
  const currentCurrency = useCurrentCurrency();
  const isIRT = btnFilter === "IRT";
  return (
    <ListItem
      disablePadding
      sx={{
        px: 0.5,
      }}
      key={value?._id}
      component={LinkMarket}
      to={`/v2/dashboard/markets/${value?._id}`}
    >
      {favoriteMutation?.isPending ? (
        <CircularProgress color="primary" size={12} />
      ) : (
        <Star1
          onClick={() => {
            handleFavorite(value?._id);
          }}
          size={14}
          style={{ cursor: "pointer", marginLeft: "0.25rem" }}
          color={theme?.palette?.primary?.[700]}
          variant={value.isFave ? "Bold" : "Outline"}
        />
      )}

      <ListItemText
        primary={value?.localizedTitle
          ?.replace(" / تومان", "")
          .replace(" / تتر", "")}
        sx={{
          width: 30,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        primaryTypographyProps={{
          color: "neutral.600",
          textAlign: "left",
          fontSize: "0.75rem",
        }}
      />
      <ListItemText
        primary={value?.todayPrice}
        sx={{
          textWrap: "nowrap",
          textAlign: "left",
          marginLeft: 4,
        }}
        primaryTypographyProps={{
          color: "neutral.600",
          fontSize: "0.75rem",
        }}
      />
      {isCapacity ? (
        <ListItemText
          primary={commaformatter.toCurrency(value.percentChange24h ?? 0)}
          sx={{
            textWrap: "nowrap",
            textAlign: "right",
            width: 60,
          }}
          primaryTypographyProps={{
            color: "neutral.400",
            fontSize: "0.75rem",
          }}
        />
      ) : (
        <ListItemText
          primary={commaformatter.toCurrency(value?.yesterdayVolume ?? 0)}
          sx={{
            textWrap: "nowrap",
            textAlign: "right",
            width: 60,
          }}
          primaryTypographyProps={{
            color: "neutral.400",
            fontSize: "0.75rem",
          }}
        />
      )}
    </ListItem>
  );
};

export default CurrencyListItem;
