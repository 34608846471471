import { DropDown } from "@components/common/dropdown";
import Hint from "@components/common/hint";
import { IconTypography } from "@components/common/icon-typography";
import { Image } from "@components/common/image";
import { SearchBox } from "@components/common/search-box";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { CopyIcon } from "@components/shared/copy-icon";
import { transferTunnelInfo } from "@constants/index";
import { useBreakpoint } from "@hooks/useBreakpoint";
import {
  Collapse,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  SvgIcon,
  useTheme,
} from "@mui/material";
import { useCreateWalletNetwork } from "@services/queries/wallet/mutations";
import {
  useGetMyWallet,
  useGetSpecificWalletItem,
} from "@services/queries/wallet/queries";
import { WalletNetworkItem } from "@services/queries/wallet/types";
import { useUserStore } from "@store/user";
import { getCoinIconURL, toClipboard } from "@utils/common";
import {
  CloseSquare,
  Coin,
  Information,
  LampCharge,
  ScanBarcode,
  Warning2,
} from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import { useNavigate, useParams } from "react-router-dom";
import { LastCoinDepositTransaction } from "./_components/last-coin-deposit-transaction";

export default function CoinDeposit() {
  const user = useUserStore((state) => state?.user);
  const isMdUp = useBreakpoint("md");
  const theme = useTheme();
  const navigate = useNavigate();
  const { coinId } = useParams();

  const [search, setSearch] = useState("");
  const [tunnel, setTunnel] = useState<WalletNetworkItem | null>(null);
  const [isNetworkAddressCopied, setIsNetworkAddressCopied] = useState(false);
  const [isMemoCodeCopied, setIsMemoCodeCopied] = useState(false);
  const [isQrCodeOpen, setIsQrCodeOpen] = useState(false);

  // prettier-ignore
  const getSpecificWalletItemQuery = useGetSpecificWalletItem({coinId: coinId || ""});
  const getAllWalletItemQuery = useGetMyWallet({ perPage: 200 });
  const createWalletNetworkMutation = useCreateWalletNetwork();

  const currentCoin = useMemo(
    () => getSpecificWalletItemQuery?.data?.result,
    [getSpecificWalletItemQuery?.isFetching]
  );

  const isDepositEnabled = useMemo(
    () => !(tunnel && tunnel?.depositEnable !== true),
    [tunnel]
  );

  useEffect(() => {
    if (!!tunnel && !tunnel?.walletAddress?.length && !!currentCoin) {
      createWalletNetworkMutation?.mutate(tunnel?.currencyId, {
        onSuccess: (data) => {
          getSpecificWalletItemQuery?.refetch();
        },
      });
    }
  }, [tunnel]);

  useEffect(() => {
    if (getSpecificWalletItemQuery?.isSuccess) {
      if (currentCoin?.currency === "IRT")
        return navigate("/v2/dashboard/wallet/toman/deposit", {
          replace: true,
        });

      setTunnel(
        currentCoin?.wallets?.find((e) => e.networkId === tunnel?.networkId) ||
          null
      );
    }
  }, [getSpecificWalletItemQuery?.isFetching]);

  return (
    <Wrapper
      loading={
        getSpecificWalletItemQuery?.isFetching ||
        getAllWalletItemQuery?.isFetching ||
        createWalletNetworkMutation?.isPending
      }
      title="واریز رمز ‌ارز"
      onClickBackArrow={() => navigate("/v2/dashboard/wallet")}
      hideBottomNavigation
      isNoPaddingModeContainer
      sxContainer={{ pt: { md: "2rem" } }}
      backMode
    >
      <Stack
        sx={{
          minHeight: { xs: "calc(100dvh - 90px)", md: "auto" },
          p: "1.5rem",
          flex: 1,
          bgcolor: "white",
          borderRadius: { md: "1.125rem" },
          display: "flex",
        }}
      >
        <Stack
          width="100%"
          sx={{
            gap: "2rem",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Stack sx={{ flex: 1, maxWidth: { md: "50%" } }} gap="2.75rem">
            <DropDown
              titleModal="انتخاب رمز ارز"
              placeholder="برای مثال تتر"
              label="رمز ارز دریافتیان را انتخاب کنید"
              value={
                currentCoin
                  ? `${currentCoin?.prettyNameLocalized}/(${currentCoin?.currency})`
                  : ""
              }
              InputProps={{
                startAdornment: currentCoin && (
                  <InputAdornment position="start">
                    <Image
                      src={getCoinIconURL(currentCoin?.currency || "")}
                      width="1.5rem"
                      height="1.5rem"
                      alt={currentCoin?.currency || ""}
                    />
                  </InputAdornment>
                ),
              }}
            >
              <Stack gap="1.5rem">
                <SearchBox
                  withDebounce
                  placeholder="جست‌وجو ..."
                  onDebounceTrigger={(search) =>
                    setSearch(search.toUpperCase())
                  }
                  onResetValueOnClick={() => setSearch("")}
                />
                {!!getAllWalletItemQuery?.data?.result?.items?.filter(
                  (e) =>
                    e?.currency?.search(search) !== -1 ||
                    e?.prettyNameLocalized?.search(search) !== -1 ||
                    e?.prettyName?.search(search) !== -1
                )?.length ? (
                  <List
                    disablePadding
                    sx={{ maxHeight: "350px", overflow: "auto" }}
                  >
                    {getAllWalletItemQuery?.data?.result?.items
                      ?.filter(
                        (e) =>
                          e?.currency?.search(search) !== -1 ||
                          e?.prettyNameLocalized?.search(search) !== -1 ||
                          e?.prettyName?.search(search) !== -1
                      )
                      ?.map((item, i) => (
                        <ListItem
                          key={`coin-${item?._id}`}
                          disablePadding
                          sx={{ mb: "0.5rem", gap: "1rem" }}
                        >
                          <ListItemButton
                            onClick={() =>
                              navigate(
                                `/v2/dashboard/wallet/coin/${item?.currency}/deposit`
                              )
                            }
                            sx={{
                              borderRadius: "0.5rem",
                              "&:hover": { bgcolor: "primary.A100" },
                            }}
                          >
                            <Image
                              src={getCoinIconURL(item?.currency)}
                              width="1.5rem"
                              height="1.5rem"
                              alt={item?.currency}
                              sx={{ mr: "1rem" }}
                            />
                            <ListItemText
                              primary={`${item?.prettyNameLocalized}/(${item?.currency})`}
                            />
                          </ListItemButton>
                        </ListItem>
                      ))}
                  </List>
                ) : (
                  <IconTypography
                    sx={{ py: "1.5rem" }}
                    bgColor="primary.A100"
                    title={
                      <Typography
                        variant="ExtendedBody"
                        color="colorfulGray.700"
                      >
                        موردی یافت نشد.
                      </Typography>
                    }
                    icon={
                      <Coin size="3rem" color={theme.palette.primary[50]} />
                    }
                  />
                )}
              </Stack>
            </DropDown>
            {!!currentCoin && !user?.isSuspended && (
              <DropDown
                titleModal="انتخاب شبکه"
                placeholder="برای مثال ERC20"
                label="شبکه‌ی انتقال‌تان را انتخاب کنید"
                value={tunnel?.networkId || ""}
              >
                <Stack gap="1.5rem">
                  <Typography
                    sx={{ display: { xs: "none", md: "block" } }}
                    variant="BodyBold"
                    color="colorfulGray.700"
                  >
                    انتخاب شبکه
                  </Typography>
                  <Stack gap="0.75rem">
                    {currentCoin?.wallets?.map((item, i) => (
                      <Stack
                        onClick={() => setTunnel(item)}
                        sx={{
                          cursor: "pointer",
                          p: "0.75rem",
                          borderRadius: "0.5rem",
                          "&:hover": {
                            bgcolor: theme.palette.primary.A100,
                          },
                        }}
                        key={`tunnel-${item?.networkId}`}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="TitleBold"
                          color="colorfulGray.700"
                        >
                          {item?.networkId}
                        </Typography>
                        <Stack>
                          <Typography
                            variant="CaptionMedium"
                            color="colorfulGray.600"
                          >
                            زمانی تقریبی واریز:{" "}
                            {transferTunnelInfo?.find(
                              (e) => e.label === item?.networkId
                            )?.estimate_time || "نامشخص"}{" "}
                            دقیقه
                          </Typography>
                          <Typography
                            variant="Caption"
                            color="colorfulGray.400"
                          >
                            نیاز به تایید{" "}
                            {transferTunnelInfo?.find(
                              (e) => e.label === item?.networkId
                            )?.blocks || "نامشخص"}{" "}
                            بلاک شبکه
                          </Typography>
                        </Stack>
                      </Stack>
                    ))}
                  </Stack>
                </Stack>
              </DropDown>
            )}

            {!!currentCoin && isDepositEnabled && !!tunnel?.walletAddress && (
              <Stack gap="0.5rem">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="ExtendedBodyMedium" color="neutral.900">
                    {`آدرس واریز ${currentCoin?.prettyNameLocalized} در شبکه ${tunnel?.networkId}`}
                  </Typography>
                  <SvgIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIsQrCodeOpen(!isQrCodeOpen)}
                  >
                    {!isQrCodeOpen ? (
                      <ScanBarcode
                        size="1.5rem"
                        color={theme?.palette?.primary?.[700]}
                      />
                    ) : (
                      <CloseSquare
                        size="1.5rem"
                        color={theme?.palette?.primary?.[700]}
                      />
                    )}
                  </SvgIcon>
                </Stack>
                <Stack
                  direction="row"
                  gap="0.5rem"
                  justifyContent="flex-end"
                  p="0.7rem"
                  bgcolor="neutral.50"
                  borderRadius="0.625rem"
                >
                  <Typography
                    variant="ExtendedBodyBold"
                    color="primary.700"
                    sx={{
                      cursor: "pointer",
                      wordBreak: "break-all",
                      direction: "rtl",
                    }}
                    onClick={async () =>
                      await toClipboard({
                        text: tunnel?.walletAddress || "",
                        cb: (isCopied) => setIsNetworkAddressCopied(isCopied),
                      })
                    }
                  >
                    {tunnel?.walletAddress}
                  </Typography>
                  <CopyIcon
                    isCopied={isNetworkAddressCopied}
                    onChange={(isCopied) => setIsNetworkAddressCopied(isCopied)}
                    text={tunnel?.walletAddress || ""}
                  />
                </Stack>
                <Collapse in={isQrCodeOpen}>
                  <Stack alignItems="center" mt="1rem">
                    <QRCode
                      size={256}
                      style={{
                        height: "auto",
                        maxWidth: "250px",
                        width: "100%",
                        aspectRatio: "1/1",
                      }}
                      value={tunnel?.walletAddress}
                    />
                  </Stack>
                </Collapse>
              </Stack>
            )}

            {!!currentCoin && isDepositEnabled && !!tunnel?.memoSupport && (
              <Stack gap="0.5rem">
                <Typography variant="ExtendedBodyMedium" color="neutral.900">
                  ممو کد
                </Typography>
                <Stack
                  direction="row"
                  gap="0.5rem"
                  justifyContent="flex-end"
                  p="0.7rem"
                  bgcolor="neutral.50"
                  borderRadius="0.625rem"
                >
                  <Typography
                    variant="ExtendedBodyBold"
                    color="primary.700"
                    sx={{ cursor: "pointer" }}
                    maxWidth="400px"
                    overflow="hidden"
                    onClick={async () =>
                      await toClipboard({
                        text: tunnel?.memoAddress || "",
                        cb: (isCopied) => setIsMemoCodeCopied(isCopied),
                      })
                    }
                  >
                    {tunnel?.memoAddress}
                  </Typography>
                  <CopyIcon
                    isCopied={isMemoCodeCopied}
                    onChange={(isCopied) => setIsMemoCodeCopied(isCopied)}
                    text={tunnel?.memoAddress || ""}
                  />
                </Stack>
              </Stack>
            )}
            {user?.isSuspended && (
              <Hint
                severity="error"
                IconComponent={Information}
                content={
                  // prettier-ignore
                  <Typography variant="Caption" color="error.700">
                    {`شما مجاز به واریز یا برداشت نیستید، لطفا با پشتیبانی تماس بگیرید.`}
                  </Typography>
                }
              />
            )}
            {!isDepositEnabled && (
              <Hint
                severity="error"
                IconComponent={Information}
                content={
                  // prettier-ignore
                  <Typography variant="Caption" color="error.700">
                    {`واریز ارز ${currentCoin?.prettyNameLocalized}/(${currentCoin?.currency}) بر روی شبکه ${tunnel?.networkId} در حال حاضر غیر فعال می‌باشد. لطفا در صورت بروز مشکل با پشتیبانی تماس بگیرید.`}
                  </Typography>
                }
              />
            )}
          </Stack>
          <Stack sx={{ flex: { md: 1 } }} gap="0.75rem">
            <Hint
              severity="info"
              IconComponent={LampCharge}
              content={
                <Stack gap="1rem">
                  <Typography variant="Caption" color="info.700">
                    برای امنیت بیشتر و حفظ حریم خصوصی شما در شبکه بلاک‌چین،
                    آدرس‌های کیف‌پول به صورت دوره‌ای تغییر خواهد یافت.
                  </Typography>

                  <Typography variant="Caption" color="info.700">
                    مبلغ واریزی بعد از تأیید از سمت شبکه، واریز خواهد شد.
                  </Typography>

                  {currentCoin && (
                    <Typography variant="Caption" color="info.700">
                      {`ارسال کوین غیر از ${currentCoin?.prettyNameLocalized}/ ${currentCoin?.currency} به این آدرس ممکن است که
                      منجر به از دست دادن آن برای همیشه شود.`}
                    </Typography>
                  )}
                </Stack>
              }
            />

            <Hint
              severity="warning"
              IconComponent={Warning2}
              content={
                <Typography variant="Caption" color="warning.900">
                  از واریز مستقیم از صرافی‌های بین‌المللی، به‌خصوص صرافی‌های
                  آمریکایی مانند CoinBase و Kraken که کاربران ایرانی را محدود
                  می‌کنند، بپرهیزید. اگر نیاز داشتید از کیف‌پول شخصی، آدرس‌های
                  یک‌بار مصرف یا روش‌های انتقال چند لایه استفاده کنید. این برای
                  بالا بردن و حفط امنیت دارایی‌هایتان است.
                </Typography>
              }
            />
          </Stack>
        </Stack>
      </Stack>
      {isMdUp &&
        !!user?.mobileVerification &&
        !!user?.IDverification &&
        !!user?.twoFactorAuthEnabled && (
          <Stack
            sx={{
              gap: { xs: "0.75rem", md: "2rem" },
              p: "1.5rem",
              flex: { xs: 1, md: 0 },
              bgcolor: "white",
              borderRadius: { md: "1.125rem" },
              my: "1.75rem",
            }}
          >
            <Typography variant="TitleBold">آخرین واریزهای رمزارزی</Typography>

            <LastCoinDepositTransaction
              currency={currentCoin?.currency ?? ""}
              localName={currentCoin?.prettyNameLocalized ?? ""}
            />
          </Stack>
        )}
    </Wrapper>
  );
}
