import { getCookie, setCookie } from "@utils/cookie";
import { useEffect } from "react";

export default function useUTMService() {
  function getUTMParameters(url: string) {
    const urlParams = new URLSearchParams(url);
    const utmData = {
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_term: urlParams.get("utm_term"),
      utm_content: urlParams.get("utm_content"),
    };
    return utmData;
  }
  useEffect(() => {
    if (!getCookie("utm_data")?.length && !!window?.location?.search?.length) {
      setCookie(
        "utm_data",
        JSON.stringify(getUTMParameters(window?.location?.search)),
        30,
        import.meta.env.DEV
          ? ".localhost"
          : import.meta.env.VITE_COOKIE_DOMAIN,
        import.meta.env.DEV ? "lax" : "none",
        import.meta.env.DEV ? false : true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCookie("utm_data")]);
  return null;
}
