import { useQuery } from "@tanstack/react-query";
import { IReportCard } from "./types";
import { getReportCard } from "./apis";
import type { Response } from "~types/index";

export const useGetReportTrade = () =>
  useQuery<Response<IReportCard>, void>({
    queryKey: ["report_trade"],
    queryFn: getReportCard,
  });
