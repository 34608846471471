import { Button } from "@components/common/button";
import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { CircularProgress, Stack, useTheme } from "@mui/material";
import { useHandleFavorite } from "@services/queries/markets/mutations";
import { IMarket } from "@services/queries/markets/types";
import { getCoinIconURL } from "@utils/common";
import { ArrowUp2, Star1 } from "iconsax-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface MarketsItemCardProps {
  item: IMarket;
}

export const MarketsItemCard = ({ item }: MarketsItemCardProps) => {
  const theme = useTheme();

  const isMdUp = useBreakpoint("md");

  const [isFave, setIsFave] = useState(item?.isFave);

  const favoriteMutation = useHandleFavorite();

  const handleFavorite = (id: string) => favoriteMutation?.mutate(id);

  useEffect(() => {
    if (favoriteMutation?.isSuccess) setIsFave(!isFave);
  }, [favoriteMutation?.isPending]);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{ gap: { xs: "0.75rem", md: "1rem" } }}
        width="300px"
      >
        {favoriteMutation?.isPending ? (
          <CircularProgress color="primary" size={24} />
        ) : (
          <Star1
            onClick={() => handleFavorite(item?._id)}
            size={24}
            style={{ cursor: "pointer" }}
            color={theme?.palette?.primary?.[700]}
            variant={isFave ? "Bold" : "Outline"}
          />
        )}
        <Link to={`/v2/dashboard/markets/${item?._id}`}>
          <Image
            alt="Coin-Image"
            height="3rem"
            width="3rem"
            sx={{
              width: { xs: "2rem", md: "3rem" },
              height: { xs: "2rem", md: "3rem" },
            }}
            src={getCoinIconURL(item?.baseCurrency)}
          />
        </Link>

        <Stack sx={{ alignItems: { md: "center" } }}>
          <Link to={`/v2/dashboard/markets/${item?._id}`}>
            <Stack direction="row" gap="0.25rem" flexWrap="wrap">
              <Typography
                variant="ExtendedBody"
                smVariant="Body"
                color="colorfulGray.800"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {item?.localizedTitle?.split(" / ")[0] || ""}
              </Typography>
              <Typography
                variant="ExtendedBody"
                smVariant="Body"
                color="colorfulGray.400"
              >
                ‌({item?.baseCurrency})
              </Typography>
            </Stack>
          </Link>
          <Stack
            direction="row"
            gap="0.25rem"
            alignItems="center"
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <Typography variant="Caption" color="colorfulGray.400">
              {item?.price || 0}
            </Typography>
            <Typography smVariant="SmallCaption" color="colorfulGray.300">
              {item?.quoteCurrency || ""}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        gap="0.2rem"
        alignItems="center"
        flex={0.2}
        justifyContent="flex-start"
        sx={{ display: { xs: "none", md: "flex" } }}
      >
        <Typography smVariant="Caption" color="colorfulGray.300">
          {item?.quoteCurrency || ""}
        </Typography>
        <Typography variant="ExtendedBody" color="colorfulGray.700">
          {item?.price || 0}
        </Typography>
      </Stack>
      <Stack
        sx={{ width: { xs: "100px", md: "300px" } }}
        justifyContent="flex-end"
        direction="row"
        gap="2rem"
        alignItems="center"
      >
        <Stack alignItems="flex-end">
          <Stack direction="row" alignItems="center" gap="0.2rem">
            <Typography variant="Caption" color="colorfulGray.300">
              {item?.quoteCurrency || ""}
            </Typography>
            <Typography
              variant="TitleBold"
              smVariant="ExtendedBodyMedium"
              color="colorfulGray.800"
            >
              {item?.todayPrice || 0}
            </Typography>
          </Stack>
          <Stack direction="row" gap="0.25rem" alignItems="center">
            <Typography
              variant="TitleMedium"
              dir="ltr"
              color={
                Number(item?.percentChange24h || 0) > 0
                  ? "info.600"
                  : "error.500"
              }
            >
              {Number(item?.percentChange24h?.replaceAll("-", "")) || 0}%
            </Typography>
            <ArrowUp2
              variant="Bold"
              size="0.75rem"
              style={{
                transform:
                  Number(item?.percentChange24h || 0) > 0
                    ? "rotateZ(0)"
                    : "rotateZ(180deg)",
              }}
              color={
                Number(item?.percentChange24h || 0) > 0
                  ? theme?.palette?.info?.[600]
                  : theme?.palette?.error?.[500]
              }
            />
          </Stack>
        </Stack>
        {isMdUp && (
          <Button
            textColor="primary.700"
            bgColor="primary.50"
            href={`/v2/dashboard/markets/${item?._id}`}
          >
            <Typography variant="BodyMedium">خرید و فروش</Typography>
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
