import { Wrapper } from "@components/layout/wrapper";
import { Button } from "@components/common/button";
import { SelectModal } from "@components/common/select-modal";
import { Typography } from "@components/common/typography";
import { TableListLayout } from "@components/layout/table-list-layout";
import SwiperList from "@components/shared/swiper-list";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { useFilter } from "@hooks/useFilter";
import { Divider, Stack } from "@mui/material";
import {
  useMarketInfiniteList,
  useMarketSlider,
} from "@services/queries/markets/queries";
import { IMarket } from "@services/queries/markets/types";
import { Fragment, useEffect, useMemo, useState } from "react";
import { BaseCoin, FilterMarkets } from "~types/index";
import { MarketsItemCard } from "./_components/markets-card-item";
import { MarketsSelectedCard } from "./_components/markets-selected-card";
import { InfiniteScroll } from "@components/common/infinite-scroll";

interface SwiperListType {
  title: string;
  data: IMarket;
}

const Markets = () => {
  const { searchParams } = useFilter();
  const isMdUp = useBreakpoint("md");

  const [activeTab, setActiveTab] = useState<BaseCoin>(
    searchParams?.get("base") === BaseCoin?.USDT
      ? BaseCoin?.USDT
      : searchParams?.get("base") === BaseCoin?.TOMAN
      ? BaseCoin?.TOMAN
      : BaseCoin?.TOMAN
  );

  // prettier-ignore
  const marketListQuery = useMarketInfiniteList({
    favorite: searchParams?.get("favorite") === "true" ? true : false,
    filterMarkets: searchParams?.get("filter") as FilterMarkets,
    search: searchParams?.get("search") || "",
    baseOn: activeTab,
  });

  useEffect(() => {
    marketListQuery?.refetch();
  }, [searchParams]);

  // prettier-ignore
  const objName =  activeTab === BaseCoin?.USDT ? "USDT" : "IRT" as 'USDT' | 'IRT';

  const marketSlider = useMarketSlider();

  const marketList = useMemo(
    () =>
      marketListQuery?.data?.pages?.flatMap(
        (e) => e?.result?.[objName]?.items
      ) || [],

    [marketListQuery?.isFetchingNextPage, marketListQuery?.isFetching]
  );

  return (
    <Wrapper sxContainer={{ pt: "2rem", pb: "2rem" }} title="لیست بازارها">
      {!!marketSlider?.data?.result?.length && (
        <SwiperList<SwiperListType>
          renderComponent={(item) => (
            <MarketsSelectedCard data={item?.data} title={item?.title} />
          )}
          swiperList={marketSlider?.data?.result}
          minWidth={320}
          spaceBetween={16}
          loading={marketSlider?.isLoading}
          count={(marketSlider?.data?.result || [])?.length}
          swiperStyle={{ marginBottom: "2.75rem" }}
        />
      )}

      <TableListLayout
        isLoading={!!marketListQuery?.isLoading}
        title="لیست ارزها"
        searchPlaceholder="جست‌وجو در بازار"
        withFavorite
        withSearch
        total={marketListQuery?.data?.pages?.[0]?.result?.[objName]?.total || 0}
        actions={
          <Stack
            direction="row"
            width="100%"
            sx={{ justifyContent: { xs: "space-between", md: "flex-end" } }}
            gap="0.5rem"
          >
            <Stack
              direction="row"
              borderRadius="100%"
              height="fit-content"
              sx={{
                bgcolor: { md: "colorfulGray.50" },
              }}
            >
              <Button
                bgColor={
                  activeTab === BaseCoin?.TOMAN
                    ? "white"
                    : isMdUp
                    ? "colorfulGray.50"
                    : "transparent"
                }
                textColor="colorfulGray.700"
                onClick={() => setActiveTab(BaseCoin?.TOMAN)}
              >
                <Typography variant="BodyBold" color="neutral.A700">
                  تومان
                </Typography>
              </Button>
              <Button
                bgColor={
                  activeTab === BaseCoin?.USDT
                    ? "white"
                    : isMdUp
                    ? "colorfulGray.50"
                    : "transparent"
                }
                textColor="colorfulGray.700"
                onClick={() => setActiveTab(BaseCoin?.USDT)}
              >
                <Typography variant="BodyBold" color="neutral.A700">
                  تتر
                </Typography>
              </Button>
            </Stack>

            <SelectModal
              defaultValue={{
                label: "بیشترین حجم معامله",
                value: FilterMarkets?.MOST_VOLUME,
              }}
              options={[
                {
                  label: "جدید‌ترین",
                  value: FilterMarkets?.NEWEST,
                },
                {
                  label: "بیشترین حجم معامله",
                  value: FilterMarkets?.MOST_VOLUME,
                },
                {
                  label: "بیشترین سود",
                  value: FilterMarkets?.MOST_PROFIT,
                },
                {
                  label: "بیشترین ضرر",
                  value: FilterMarkets?.MOST_LOSSES,
                },
              ]}
            />
          </Stack>
        }
      >
        <Stack sx={{ overflowY: "auto", p: { xs: "0.5rem", md: "1.5rem" } }}>
          <InfiniteScroll
            fetchMore={async () => {
              !marketListQuery?.isFetchingNextPage &&
              marketListQuery?.hasNextPage
                ? await marketListQuery.fetchNextPage()
                : await 0;
            }}
          >
            {marketList?.map((item, i) => (
              <Fragment key={`market-item-${i}-list-${item?._id}`}>
                <MarketsItemCard item={item} />
                {marketList?.length - 1 !== i && (
                  <Divider
                    sx={{
                      my: "1rem",
                      borderColor: "neutral.50",
                    }}
                  />
                )}
              </Fragment>
            ))}
          </InfiniteScroll>
        </Stack>
      </TableListLayout>
    </Wrapper>
  );
};

export default Markets;
