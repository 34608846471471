import { persianRegex } from "@constants/index";
import { z } from "zod";

export const BaseAuthFormSchema = z.object({
  nationalCode: z
    .string()
    .min(10, "فیلد کدملی شما باید 10 رقمی باشد.")
    .regex(/\d/, "لطفا مقادیر عددی وارد کنید.")
    .trim(),
  firstName: z
    .string()
    .min(1, "این فیلد اجباری است.")
    .regex(persianRegex, "لطفا فیلد خود را با زبان فارسی وارد کنید."),
  lastName: z
    .string()
    .min(1, "این فیلد اجباری است.")
    .regex(persianRegex, "لطفا فیلد خود را با زبان فارسی وارد کنید."),
  birthday: z.date(),
  gender: z.enum(["male", "female"]),
});

export const DocumentAuthFormSchema = z.object({
  city: z.string().min(1, "این فیلد اجباری است.").trim(),
  address: z.string().min(1, "این فیلد اجباری است.").trim(),
  document: z.string().min(1, "این فیلد اجباری است.").trim(),
});
