import { CircularProgress, CircularProgressProps, Stack } from "@mui/material";
import { Typography } from "../typography";
import { useEffect, useState } from "react";

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number; labelVal: number }
) {
  return (
    <Stack sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Stack
        sx={{
          top: 0,
          left: 0,
          bottom: -1,
          right: -0.5,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" color="neutral.50">
          {`${Math.round(props.labelVal)}`}
        </Typography>
      </Stack>
    </Stack>
  );
}

interface ProgressTimerWithLabelProps {
  minute: number;
  resetDeps: boolean;
}

export function ProgressTimerWithLabel({
  minute,
  resetDeps,
}: ProgressTimerWithLabelProps) {
  const [progress, setProgress] = useState(minute);

  useEffect(() => {
    setProgress(minute);
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress === 0 ? 0 : prevProgress - 1
      );
    }, 900);
    return () => {
      clearInterval(timer);
    };
  }, [resetDeps]);

  return (
    <CircularProgressWithLabel
      size="1.5rem"
      color="inherit"
      sx={{
        "svg circle": {
          transition:
            "stroke-dashoffset 300ms 0ms",
        },
      }}
      value={progress * 10}
      labelVal={progress}
    />
  );
}
