import { Button } from "@components/common/button";
import Container from "@components/common/container";
// import { Header } from "@components/layout/navigation";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { InputAdornment, Stack, useTheme } from "@mui/material";
import {
  AddCircle,
  ArrowRight,
  Card,
  Information,
  Key,
  LampCharge,
  Money4,
  SecurityUser,
  Warning2,
} from "iconsax-react";
import { IconTypography } from "@components/common/icon-typography";
import Hint from "@components/common/hint";
import { TomanIcon } from "@assets/icons/TomanIcon";
import { MaxWithdrawalItem } from "./_components/max-withdrawal";
import { numUtils } from "@utils/num-helpers";
import { DropDown } from "@components/common/dropdown";
import { BankCardItem } from "@components/shared/bank-card-item";
import { AddBankButton } from "../../../../../../../components/shared/add-bank-button";
import { Fragment, useMemo, useState } from "react";
import { ApproveWithdrawPortal } from "../../../../_components/approve-withdraw-portal";
import {
  useGetDailyMonthlyWithdraw,
  useGetMyBankAccount,
  useGetMyWallet,
  useGetSpecificWalletItem,
} from "@services/queries/wallet/queries";
import { bankInfo } from "@utils/common";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { TomanWithdrawSchema } from "@pages/dashborad/wallet/_validations";
import { zodResolver } from "@hookform/resolvers/zod";
import { useUserStore } from "@store/user";
import { AddBankModal } from "../../../../../../../components/shared/add-bank-modal";
import { useHandlePreviewTomanWithdraw } from "@services/queries/wallet/mutations";
import { LastTomanWithdrawTransaction } from "./_components/last-toman-withdraw-transaction";
import { Image } from "@components/common/image";
import { NeedAuthorize } from "@pages/dashborad/wallet/_components/need-authorize";
import { BaseCoin } from "~types/index";
import { toast } from "@utils/toast";
import {
  HandlePreviewTomanWithdrawRes,
  MyBankAccountItem,
} from "@services/queries/wallet/types";
import { useNavigate } from "react-router-dom";
import { numberToWords } from "@utils/num-to-word";

export default function TomanWithdraw() {
  const isMdUp = useBreakpoint("md");
  const theme = useTheme();
  const navigate = useNavigate();

  const user = useUserStore((state) => state?.user);

  const getBankAccountQuery = useGetMyBankAccount();
  const getDailyMonthlyWithdrawQuery = useGetDailyMonthlyWithdraw({});
  const handlePreviewWithdrawMutation = useHandlePreviewTomanWithdraw();
  const getSpecificWalletItemQuery = useGetSpecificWalletItem({
    coinId: "IRT",
  });

  // prettier-ignore
  const [approveWithdrawData, setApproveWithdrawData] = useState<HandlePreviewTomanWithdrawRes | null>(null);
  const [isAddBankAccountModal, setIsAddBankAccountModal] = useState(false);

  const {
    register,
    watch,
    setValue,
    getValues,
    formState: { isValid },
  } = useForm<z.output<typeof TomanWithdrawSchema>>({
    mode: "onTouched",
    resolver: zodResolver(TomanWithdrawSchema),
  });
  const handleSubmit = () => {
    const amount = Number(watch("amount"));
    const balance = getSpecificWalletItemQuery?.data?.result?.balanceAvailable;
    if (amount > (balance || 0)) {
      return toast.warning("موجودی کیف پول شما کافی نیست");
    }
    handlePreviewWithdrawMutation.mutate(
      {
        amount: amount,
        ibanId: getValues("iban_id"),
      },
      { onSuccess: (data) => setApproveWithdrawData(data?.result) }
    );
  };

  // prettier-ignore
  const isWithdrawButtonDisabled = useMemo(() => {
    const amount = Number(watch("amount"));
    
    const balance = getSpecificWalletItemQuery?.data?.result?.balanceAvailable;

    if (amount < Number(getSpecificWalletItemQuery?.data?.result?.wallets[0]?.minWithdrawalSize)) return true;
    if (amount > (balance || 0)) return true;

    const withdrawalLevel = getDailyMonthlyWithdrawQuery?.data?.result
    const isMoreThanDaily = ((Number(withdrawalLevel?.currency24Houre) + amount) * 100) / Number(withdrawalLevel?.currencyDaily) > 100
    const isMoreThanMonthly = ((Number(withdrawalLevel?.currency30Day) + amount) * 100) / Number(withdrawalLevel?.currencyMonthly) > 100

    if (isMoreThanDaily || isMoreThanMonthly) return true;

    return false;
  }, [getSpecificWalletItemQuery?.data?.result, watch("amount")]);

  const renderHelperText = () => {
    const amount = getValues("amount");
    // prettier-ignore
    if (Number(amount) !== 0) {
      const balance = getSpecificWalletItemQuery?.data?.result?.balanceAvailable;

      // Below of minimum withdrawal amount
      if (Number(amount) < Number(getSpecificWalletItemQuery?.data?.result?.wallets[0]?.minWithdrawalSize)) {
        return `حداقل مبلغ برداشت ${numUtils.commaformatter.toCurrency(Number(getSpecificWalletItemQuery?.data?.result?.wallets[0]?.minWithdrawalSize))} تومان است`
      }

      const withdrawalLevel = getDailyMonthlyWithdrawQuery?.data?.result
      const isMoreThanDaily = ((Number(withdrawalLevel?.currency24Houre) + Number(amount)) * 100) / Number(withdrawalLevel?.currencyDaily) > 100
      const isMoreThanMonthly = ((Number(withdrawalLevel?.currency30Day) + Number(amount)) * 100) / Number(withdrawalLevel?.currencyMonthly) > 100

      if (Number(amount) > (balance || 0)) return `مبلغ مورد نظر بیشتر از موجودی شما است.`;

      // Upper of daily or monthly withdrawal limit
      if (isMoreThanDaily || isMoreThanMonthly) {
        return `حداکثر مبلغ برداشت ${isMoreThanMonthly ? 'ماهانه' :'روزانه'} ${numUtils.commaformatter.toCurrency(isMoreThanMonthly ? Number(withdrawalLevel?.currencyMonthly) : Number(withdrawalLevel?.currencyDaily))} تومان است`
      }

      // Current Input Amount
      return (
        <Typography variant="Caption" color="colorfulGray.500">
          {numberToWords(Number(watch("amount")) || 0)?.toString()} تومان
        </Typography>
      );
    }

    return <></>;
  };

  return (
    <Wrapper
      hideBottomNavigation
      sxContainer={{
        my: { md: "2rem" },
      }}
      isNoPaddingModeContainer
      backMode
      loading={getBankAccountQuery?.isLoading}
      title="برداشت پول"
      onClickBackArrow={() => navigate("/v2/dashboard/wallet")}
    >
      {approveWithdrawData && (
        <ApproveWithdrawPortal
          bankSheba={getValues("bank")}
          ibanId={getValues("iban_id")}
          data={approveWithdrawData}
          onClose={() => setApproveWithdrawData(null)}
        />
      )}

      <Stack gap="1rem">
        <Stack
          py="2rem"
          width="100%"
          sx={{
            p: "1rem",
            flex: { xs: 1, md: 0 },
            bgcolor: "white",
            borderRadius: { md: "1.125rem" },
            pb: { xs: "4.5rem", md: "2rem" },
            gap: { xs: "0.75rem", md: "2rem" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Stack flex={1} justifyContent="space-between">
            {!!user?.mobileVerification && !!user?.IDverification ? (
              !user?.twoFactorAuthEnabled ? (
                <Stack
                  sx={{ py: { md: "2rem" }, maxWidth: "500px", mx: "auto" }}
                >
                  <IconTypography
                    bgColor="colorfulGray.A100"
                    icon={
                      <Key variant="Bold" color={theme.palette.primary[400]} />
                    }
                    title={
                      <Typography variant="Body" smVariant="BodyMedium">
                        برای امنیت بیشتر در برداشت وجه نیاز به شناسه دو عاملی
                        دارید، قبل از عملیات برداشت شناسه دو عاملی را فعال کنید.
                      </Typography>
                    }
                  />

                  <Button
                    fullWidth
                    href="/v2/dashboard/settings/google-verification"
                    sx={{ mt: "1.5rem" }}
                    bgColor="primary.700"
                    startIcon={<Money4 size="1.5rem" />}
                  >
                    <Typography variant="BodyMedium">فعالسازی</Typography>
                  </Button>
                </Stack>
              ) : (
                <>
                  <Stack gap="1.5rem">
                    {user?.isSuspended ? (
                      <Hint
                        severity="error"
                        IconComponent={Information}
                        content={
                          // prettier-ignore
                          <Typography variant="Caption" color="error.700">
                    {`شما مجاز به واریز یا برداشت نیستید، لطفا با پشتیبانی تماس بگیرید.`}
                  </Typography>
                        }
                      />
                    ) : (
                      <>
                        <DropDown
                          titleModal="انتخاب شماره شبا"
                          label="برداشت تومان به مقصد"
                          placeholder="انتخاب شماره شبا"
                          value={watch("bank")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {(watch("bank") || "")?.length < 6 ? (
                                  <Card
                                    variant="Bold"
                                    size="1.5rem"
                                    color={theme.palette.colorfulGray[400]}
                                  />
                                ) : (
                                  <Image
                                    width="1.25rem"
                                    height="1.25rem"
                                    alt="لوگو بانک"
                                    src={`/v2/images/banks-logo/${
                                      bankInfo(watch("bank"), "Sheba")?.logo
                                    }`}
                                  />
                                )}
                              </InputAdornment>
                            ),
                          }}
                          action={
                            <Button
                              onClick={() => setIsAddBankAccountModal(true)}
                              textColor="primary.500"
                              variant="text"
                              startIcon={
                                <AddCircle size="1.35rem" variant="Bold" />
                              }
                              size="small"
                            >
                              <Typography variant="BodyMedium">
                                شبا جدید
                              </Typography>
                            </Button>
                          }
                        >
                          <Stack
                            gap="0.75rem"
                            maxHeight="400px"
                            overflow="auto"
                          >
                            {/* @ts-ignore */}
                            {getBankAccountQuery?.data?.items
                              ?.filter(
                                (e: MyBankAccountItem) =>
                                  e?.accType === "account"
                              )
                              ?.map((item: MyBankAccountItem) => (
                                <Fragment key={`bank-card-item-${item?._id}`}>
                                  <BankCardItem
                                    // prettier-ignore
                                    onClick={() => {
                                  setValue("bank", item?.no, {shouldValidate:true});
                                  setValue("iban_id", item?._id, {shouldValidate:true})
                                }}
                                    title={item?.no}
                                    mode="account"
                                    imgSrc={`/v2/images/banks-logo/${
                                      bankInfo(item?.no, "Sheba")?.logo
                                    }`}
                                    alt="حساب بانکی"
                                  />
                                </Fragment>
                              ))}

                            <AddBankButton
                              titleBtn="شبا جدید"
                              onSuccess={() => {}}
                            />
                          </Stack>
                        </DropDown>
                        <Stack gap="0.25rem">
                          <TextField
                            {...register("amount")}
                            numberInput
                            thousandSeparator
                            label="مبلغ برداشت"
                            placeholder="مبلغ انتقال"
                            error={typeof renderHelperText() === "string"}
                            helperText={renderHelperText()}
                            value={watch("amount")}
                            action={
                              <Button
                                onClick={() =>
                                  setValue(
                                    "amount",
                                    (
                                      getSpecificWalletItemQuery?.data?.result
                                        ?.balanceAvailable || 0
                                    ).toString(),
                                    { shouldValidate: true }
                                  )
                                }
                                textColor="primary.500"
                                variant="text"
                                size="small"
                              >
                                <Typography variant="BodyMedium">
                                  حداکثر قابل برداشت
                                </Typography>
                              </Button>
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <TomanIcon
                                    sx={{
                                      fontSize: "1.25rem",
                                      fill: (theme) =>
                                        theme.palette.colorfulGray[400],
                                    }}
                                  />
                                </InputAdornment>
                              ),
                            }}
                          />

                          <Typography
                            variant="Caption"
                            color="colorfulGray.500"
                          >
                            کل موجودی شما:{" "}
                            {
                              getSpecificWalletItemQuery?.data?.result
                                ?.balanceAvailableFormatted
                            }{" "}
                            تومان
                          </Typography>
                        </Stack>
                        {/* <Stack gap="1rem">
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="Body" color="colorfulGray.300">
                          کارمزد حدودی برداشت
                        </Typography>

                        <Stack
                          direction="row"
                          gap="0.25rem"
                          alignItems="center"
                        >
                          <Typography
                            variant="BodyMedium"
                            color="colorfulGray.200"
                          >
                            {numUtils.commaformatter.toCurrency(
                              getDailyMonthlyWithdrawQuery?.data?.result?.fee
                            )}
                          </Typography>
                          <TomanIcon
                            sx={{
                              fontSize: { xs: "0.75rem", md: "1rem" },
                              fill: (theme) => theme.palette.colorfulGray[200],
                            }}
                          />
                        </Stack>
                      </Stack>

                      {getDailyMonthlyWithdrawQuery?.data?.result?.fee && (
                        <Stack direction="row" justifyContent="space-between">
                          <Typography variant="Body" color="colorfulGray.300">
                            مبلغی دریافتی بعد از کسر کارمزد
                          </Typography>

                          <Stack
                            direction="row"
                            gap="0.25rem"
                            alignItems="center"
                          >
                            <Typography
                              variant="BodyMedium"
                              color="colorfulGray.200"
                            >
                              {Number(watch("amount")) >
                              getDailyMonthlyWithdrawQuery?.data?.result?.fee
                                ? numUtils.commaformatter
                                    .toCurrency(
                                      Number(watch("amount")) -
                                        getDailyMonthlyWithdrawQuery?.data
                                          ?.result?.fee
                                    )
                                    .replaceAll("-", "")
                                : "---"}
                            </Typography>
                            <TomanIcon
                              sx={{
                                fontSize: { xs: "0.75rem", md: "1rem" },
                                fill: (theme) =>
                                  theme.palette.colorfulGray[200],
                              }}
                            />
                          </Stack>
                        </Stack>
                      )}
                      
                      </Stack> */}
                      </>
                    )}
                  </Stack>

                  {!!isMdUp && (
                    <Button
                      loading={handlePreviewWithdrawMutation.isPending}
                      disabled={isWithdrawButtonDisabled || !isValid}
                      onClick={handleSubmit}
                      sx={{ mt: "1.5rem" }}
                      variant="contained"
                      tailStartIcon={<ArrowRight size="1.5rem" />}
                    >
                      <Typography variant="BodyBold">درخواست برداشت</Typography>
                    </Button>
                  )}
                </>
              )
            ) : (
              <NeedAuthorize baseOn={BaseCoin.TOMAN} />
            )}
          </Stack>
          {!!user?.twoFactorAuthEnabled && (
            <Stack flex={1} gap="0.75rem">
              {!!getDailyMonthlyWithdrawQuery?.isSuccess && (
                <Stack
                  gap="1.5rem"
                  bgcolor="neutral.50"
                  borderRadius="0.625rem"
                  p="0.75rem"
                >
                  <MaxWithdrawalItem
                    title="حداکثر برداشت ۲۴ ساعته شما"
                    titlePrice={
                      getDailyMonthlyWithdrawQuery?.data?.result?.currencyDaily
                    }
                    subtitle="کل برداشتان در ۲۴ ساعت گذشته"
                    subtitlePrice={
                      getDailyMonthlyWithdrawQuery?.data?.result
                        ?.currency24Houre
                    }
                    amount={Number(watch("amount")) || 0}
                  />
                  <MaxWithdrawalItem
                    title="حداکثر برداشت ۳۰ روزه شما"
                    titlePrice={
                      getDailyMonthlyWithdrawQuery?.data?.result
                        ?.currencyMonthly
                    }
                    subtitle="کل برداشتان در ۳۰ روز گذشته"
                    subtitlePrice={
                      getDailyMonthlyWithdrawQuery?.data?.result?.currency30Day
                    }
                    amount={Number(watch("amount")) || 0}
                  />
                </Stack>
              )}

              {!!user?.IDverification &&
                !!user?.mobileVerification &&
                !user?.personalAttachmentsVerification && (
                  <Hint
                    severity="warning"
                    IconComponent={SecurityUser}
                    content={
                      <Typography variant="Caption" color="warning.900">
                        برای بالا بردن مبلغ روزانه و ماهیانه احراز هویتتان را
                        ارتقا بدهید
                      </Typography>
                    }
                    action={
                      <Button
                        href="/v2/dashboard/authentication"
                        bgColor="white"
                        textColor="primary.700"
                        sx={{ border: 1.5, borderColor: "primary.700" }}
                        startIcon={<SecurityUser size="1.25rem" />}
                      >
                        <Typography variant="BodyMedium">احراز هویت</Typography>
                      </Button>
                    }
                  />
                )}
              <Hint
                severity="info"
                IconComponent={LampCharge}
                content={
                  <Typography variant="Caption" color="info.700">
                    طبق دستور بانک مرکزی، سقف واریز در هر روز به هر حساب بانکی
                    100 میلیون تومان است. برای برداشت بیشتر می‌توانید از چند
                    حساب بانکی استفاده کنید.
                  </Typography>
                }
              />

              <Hint
                severity="warning"
                IconComponent={Warning2}
                content={
                  <Typography variant="Caption" color="warning.900">
                    واریز وجه به حساب افراد ناشناس که از طریق آگهی‌های درآمدزایی
                    و ... شما را پیدا کرده‌اند کلاه‌برداری است و مسئولیت جرم
                    صورت‌گرفته متوجه شما می‌شود.
                  </Typography>
                }
              />

              {!isMdUp && (
                <Stack
                  position="fixed"
                  p="0.75rem"
                  width="100%"
                  bottom={0}
                  left={0}
                  bgcolor="white"
                >
                  <Button
                    size="large"
                    loading={handlePreviewWithdrawMutation.isPending}
                    disabled={isWithdrawButtonDisabled || !isValid}
                    onClick={handleSubmit}
                    variant="contained"
                    tailStartIcon={<ArrowRight size="1.5rem" />}
                  >
                    <Typography variant="BodyBold">درخواست برداشت</Typography>
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>

        {isMdUp &&
          !!user?.mobileVerification &&
          !!user?.IDverification &&
          !!user?.twoFactorAuthEnabled && (
            <Stack
              sx={{
                gap: { xs: "0.75rem", md: "2rem" },
                p: "1.5rem",
                flex: { xs: 1, md: 0 },
                bgcolor: "white",
                borderRadius: { md: "1.125rem" },
              }}
            >
              <Typography variant="TitleBold">
                آخرین برداشت‌های تومان
              </Typography>

              <LastTomanWithdrawTransaction />
            </Stack>
          )}
      </Stack>

      <AddBankModal
        isOpen={isAddBankAccountModal}
        onClose={() => setIsAddBankAccountModal(false)}
        onSuccess={() => {
          setIsAddBankAccountModal(false);
        }}
      />
    </Wrapper>
  );
}
