import Tabs from '@components/common/tabs';
import { TabItem } from '@components/common/tabs/types';
import { Grid, Paper } from '@mui/material';
import TVChartContainer from './chart';

interface Props {
  symbol?: string;
  grid?: number | undefined;
}

const Chart = ({ symbol, grid }: Props) => {
  const tab: TabItem[] = [
    { label: 'نمودار جهانی', content: <TVChartContainer symbol={symbol} /> },
  ];
  return (
    <Grid item xs={grid ?? 'auto'}>
      <Paper
        sx={{
          height: 449,
        }}
        elevation={0}
      >
        <Tabs tabs={tab} variant="standard" />
      </Paper>
    </Grid>
  );
};

export default Chart;
