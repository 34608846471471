import { Button } from "@components/common/button";
import { IconTypography } from "@components/common/icon-typography";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack, useTheme } from "@mui/material";
import { useLogout } from "@services/queries/auth/mutations";
import { useUserStore } from "@store/user";
import { clearCookie } from "@utils/storage";
import { LogoutCurve } from "iconsax-react";
import { useNavigate } from "react-router-dom";

interface LogoutModalProps {
  onClose: () => void;
  open: boolean;
}

export const LogoutModal = ({ onClose, open }: LogoutModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate()
  const  setUser  = useUserStore((state) => state.setUser);
  const useLogoutMutation = useLogout();

  const handleLogout = () => {
    useLogoutMutation?.mutate(undefined, {
      onSuccess: () => {
        navigate("/v2/login")
        onClose();
        setUser(null);
        clearCookie("Token");
        clearCookie("WpId");
        clearCookie("RefreshToken");
        window.isLoggedIn = false
      },
    });
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Stack gap="1.5rem" width="100%">
        <IconTypography
          sx={{ gap: "1.5rem" }}
          title="برای خروج از حساب کاربری تایید کنید."
          icon={<LogoutCurve size="2.5rem" color={theme.palette.error[500]} />}
          bgColor="error.A100"
        />

        <Stack direction="row" width="100%" gap="0.75rem" mt="0.75rem">
          <Button fullWidth onClick={onClose} variant="text">
            <Typography variant="Body">بازگشت</Typography>
          </Button>
          <Button
            loading={useLogoutMutation?.isPending}
            fullWidth
            onClick={handleLogout}
            variant="contained"
            bgColor="error.500"
          >
            <Typography variant="Body">خروج</Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
