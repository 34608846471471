import { TomanIcon } from "@assets/icons/TomanIcon";
import { Button } from "@components/common/button";
import { Typography } from "@components/common/typography";
import { BallonPattern } from "@components/shared/ballon-pattern";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack } from "@mui/material";
import { numUtils } from "@utils/num-helpers";
import { ArrowDown2, Eye, EyeSlash, Tether } from "iconsax-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import SwitchUsdtToIRT from "../switch-usdt-irt";
import { BaseCoin, BaseOnProps } from "../types";

const UserAssetsCard = ({
  baseOn,
  setBaseOn,
  isHidePrice,
  setIsHidePrice,
  IRTBalance,
  USDTBalance,
}: BaseOnProps) => {
  const { commaformatter } = numUtils;

  const navigate = useNavigate();

  const isMdUp = useBreakpoint("md");
  const isIRT = baseOn?.value === BaseCoin.TOMAN ? "IRT" : "USDT";

  const price = useMemo(
    () => (isIRT === "IRT" ? IRTBalance : USDTBalance),
    [IRTBalance, USDTBalance, isIRT]
  );

  return (
    <Stack
      position="relative"
      overflow="hidden"
      sx={{
        p: "1.5rem",
        mb: "1.5rem",
        borderRadius: { md: "1.125rem" },
        height: { xs: "173px", md: "auto" },
        background: (theme) =>
          `linear-gradient(180deg,${theme.palette.primary.main} 93.07%, #594FCE 211.49%)`,
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          justifyContent: { xs: "center", md: "space-between" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap="0.2rem"
          onClick={() => setIsHidePrice(!isHidePrice)}
          width="100%"
          justifyContent={{ xs: "center", md: "flex-start" }}
        >
          <Stack
            alignItems="center"
            sx={{
              flexDirection: { xs: "column", md: "row" },
            }}
            gap="0.5rem"
          >
            <Typography
              variant="ExtendTitleBold"
              color="primary.A100"
              smVariant="BodyMedium"
            >
              داریی کل
            </Typography>
            <Typography
              variant="ExtendTitleBold"
              color="primary.A100"
              smVariant="HeadlineBold"
              gap="0.5rem"
            >
              {isHidePrice ? "********" : commaformatter.toCurrency(price)}
              {baseOn?.value === BaseCoin.TOMAN ? (
                <TomanIcon
                  sx={{
                    width: "1rem",
                    fill: "white",
                    marginLeft: "0.3rem",
                    marginBottom: "-0.2rem",
                  }}
                />
              ) : (
                <Tether
                  size="1.2rem"
                  color={"white"}
                  style={{ marginRight: "0.3rem", marginBottom: "-0.2rem" }}
                />
              )}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          gap="1rem"
          alignItems="center"
          sx={{
            display: { xs: "none", md: "flex" },
          }}
        >
          {isHidePrice ? (
            <EyeSlash
              onClick={() => setIsHidePrice(!isHidePrice)}
              style={{ cursor: "pointer" }}
              variant="Outline"
              size="1.5rem"
              color="white"
            />
          ) : (
            <Eye
              onClick={() => setIsHidePrice(!isHidePrice)}
              style={{ cursor: "pointer" }}
              variant="Outline"
              size="1.5rem"
              color="white"
            />
          )}
          {isMdUp && <SwitchUsdtToIRT baseOn={baseOn} setBaseOn={setBaseOn} />}
        </Stack>

        <Stack
          direction="row-reverse"
          sx={{
            display: { xs: "flex", md: "none" },
          }}
          width="100%"
          alignItems="center"
          justifyContent="flex-start"
          mt="0.5rem"
        >
          <Button
            variant="text"
            endIcon={
              <ArrowDown2
                size="1.2rem"
                color="white"
                style={{ rotate: "90deg" }}
              />
            }
            sx={{
              zIndex: 1,
            }}
            onClick={() => {
              navigate("/v2/dashboard/wallet");
            }}
          >
            <Typography color="primary.A100" smVariant="BodyMedium">
              جزئیات کیف پول
            </Typography>
          </Button>
        </Stack>
      </Stack>

      {!isMdUp && (
        <>
          <BallonPattern
            mode="dark"
            sx={{
              width: "12.5rem",
              height: "12.5rem",
              position: "absolute",
              top: "-1rem",
              left: "-1rem",
            }}
          />

          <BallonPattern
            mode="light"
            sx={{
              width: "12.5rem",
              height: "12.5rem",
              position: "absolute",
              bottom: "-3rem",
              right: "-3rem",
            }}
          />
        </>
      )}
    </Stack>
  );
};

export default UserAssetsCard;
