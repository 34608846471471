import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./styles/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@theme/index.tsx";
import { EmotionProvider } from "@theme/emotion-provider.tsx";
import { ReactQueryProvider } from "@services/react-query";
import { SnackBar } from "@components/common/snackbar";

import "@i18n/index.ts";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider>
      <EmotionProvider>
        <CssBaseline />
        {/* <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}> */}
        {/* <Suspense fallback={<Loader />}> */}
        <ReactQueryProvider>
          <Router>
            <App />
            <SnackBar />
          </Router>
        </ReactQueryProvider>
        {/* </Suspense> */}
        {/* </ErrorBoundary> */}
      </EmotionProvider>
    </ThemeProvider>
  </React.StrictMode>
);
