import { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Loader } from "@components/layout/loader";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "@components/layout/error-fallback";
import { logError } from "@utils/logger";

import LoginPage from "@pages/auth/login";
import OTPForm from "@pages/auth/otp-form";
import GoogleAuthenticator from "@pages/auth/ga-form";
import DashboardLayout from "@pages/dashborad/_layout";
import OtcPage from "@pages/dashborad/otc";
import Markets from "@pages/dashborad/markets/market-list";
import MarketDetails from "@pages/dashborad/markets/market-details";
import Authentication from "@pages/dashborad/authentication";
import BaseAuthentication from "@pages/dashborad/authentication/_pages/base-authentication";
import ProAuthentication from "@pages/dashborad/authentication/_pages/pro-authentication";
import WalletPage from "@pages/dashborad/wallet";
import TomanDeposit from "@pages/dashborad/wallet/_pages/toman/_pages/deposit";
import PaymentBankCardDepositToman from "@pages/dashborad/wallet/_pages/toman/_pages/deposit/_pages/payment-bank-card-deposit-toman";
import PaymentShebaDepositToman from "@pages/dashborad/wallet/_pages/toman/_pages/deposit/_pages/payment-sheba-deposit-toman";
import UIKit from "@pages/ui-kit";
import SingleWalletPage from "@pages/dashborad/wallet/_pages/single-wallet";
import TomanWithdraw from "@pages/dashborad/wallet/_pages/toman/_pages/withdraw";
import CoinDeposit from "@pages/dashborad/wallet/_pages/coin/_pages/deposit";
import CoinWithdraw from "@pages/dashborad/wallet/_pages/coin/_pages/withdraw";
import { ProfilePage } from "@pages/dashborad/profile";
import { Menu } from "@pages/dashborad/menu";
import DashboardPage from "@pages/dashborad";
import { FinancialOrders } from "@pages/dashborad/financial/_pages/orders";
import { FinancialTransactions } from "@pages/dashborad/financial/_pages/transactions";
import { FinancialOtc } from "@pages/dashborad/financial/_pages/otc";
import { LoginHistory } from "@pages/dashborad/settings/_pages/login-history";
import { SettingsPage } from "@pages/dashborad/settings";
import { GoogleVerification } from "@pages/dashborad/settings/_pages/google-verification";
import { BankPage } from "@pages/dashborad/profile/_pages/banks";
import { ReferralPage } from "@pages/dashborad/referral";
import { GiftRedeem } from "@pages/dashborad/gift/_pages/redeem";

// const LoginPage = lazy(() => import("@pages/auth/login"));
// const OTPForm = lazy(() => import("@pages/auth/otp-form"));
// const GoogleAuthenticator = lazy(() => import("@pages/auth/ga-form"));
// const DashboardLayout = lazy(() => import("@pages/dashborad"));
// const OtcPage = lazy(() => import("@pages/dashborad/otc"));
// const Authentication = lazy(() => import("@pages/dashborad/authentication"));
// const BaseAuthentication = lazy(() => import("@pages/dashborad/authentication/_pages/base-authentication"));
// const ProAuthentication = lazy(() => import("@pages/dashborad/authentication/_pages/pro-authentication"));
// const Markets = lazy(() => import("@pages/dashborad/markets/market-list"));
// const MarketDetails = lazy(() => import("@pages/dashborad/markets/market-details"));
// const WalletPage = lazy(() => import("@pages/dashborad/wallet"));
// const UiKit = lazy(() => import("@pages/ui-kit"));
// const TomanDeposit = lazy(() => import("@pages/dashborad/wallet/_pages/toman/_pages/deposit"));
// const PaymentBankCardDepositToman = lazy(() => import("@pages/dashborad/wallet/_pages/toman/_pages/deposit/_pages/payment-bank-card-deposit-toman"));
// const PaymentShebaDepositToman = lazy(() => import("@pages/dashborad/wallet/_pages/toman/_pages/deposit/_pages/payment-sheba-deposit-toman"));
// const TomanWithdraw = lazy(() => import("@pages/dashborad/wallet/_pages/toman/_pages/withdraw"));
// const CoinWithdraw = lazy(() => import("@pages/dashborad/wallet/_pages/coin/_pages/withdraw"));
// const CoinDeposit = lazy(() => import("@pages/dashborad/wallet/_pages/coin/_pages/deposit"));

export const AppRouter = () => {
  // const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={logError}
    >
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route 
            path="v2/redirect" 
            element={
              <div>
                <a href="balinex://v2/dashboard">Redirect Balinex</a>
                <a href="hebitex://v2/dashboard">Redirect Hebitex</a>
              </div>
            } 
          />
          <Route path="/" element={<Navigate to="v2/dashboard" />} />
          <Route path="v2/" element={<Navigate to="v2/dashboard" />} />
          <Route path="v2/login" element={<LoginPage />} />
          <Route path="v2/login" element={<LoginPage />} />
          <Route path="v2/otp" element={<OTPForm />} />
          <Route path="v2/ga" element={<GoogleAuthenticator />} />
          <Route path="v2/dashboard" element={<DashboardLayout />}>
            <Route path="" element={<DashboardPage />} />

            <Route path="menu" element={<Menu />} />

            <Route path="profile" element={<ProfilePage />} />
            <Route path="profile/banks" element={<BankPage />} />

            <Route path="otc" element={<OtcPage />} />

            <Route path="markets" element={<Markets />} />
            <Route path="markets/:currency" element={<MarketDetails />} />

            <Route path="authentication" element={<Authentication />}/>
            <Route path="authentication/base" element={<BaseAuthentication />}/>
            <Route path="authentication/pro" element={<ProAuthentication />}/>

            <Route path="wallet" element={<WalletPage />}/>
            <Route path="wallet/:coinId" element={<SingleWalletPage />}/>
            <Route path="wallet/toman/deposit" element={<TomanDeposit />}/>
            <Route path="wallet/toman/deposit/card" element={<PaymentBankCardDepositToman />}/>
            <Route path="wallet/toman/deposit/sheba" element={<PaymentShebaDepositToman />}/>
            <Route path="wallet/toman/withdraw" element={<TomanWithdraw />}/>
            <Route path="wallet/coin/:coinId/deposit" element={<CoinDeposit />}/>
            <Route path="wallet/coin/:coinId/withdraw" element={<CoinWithdraw />}/>

            <Route path="financial/orders" element={<FinancialOrders />}/>
            <Route path="financial/transactions" element={<FinancialTransactions />}/>
            <Route path="financial/otc" element={<FinancialOtc />}/>

            <Route path="settings" element={<SettingsPage />}/>
            <Route path="settings/google-verification" element={<GoogleVerification />}/>
            <Route path="settings/login-history" element={<LoginHistory />}/>

            <Route path="referral" element={<ReferralPage />}/>
            
            <Route path="gift/redeem" element={<GiftRedeem />}/>

            {import.meta.env.PROD && <Route path="*" element={<Navigate to="/v2/dashboard" />} />}
          </Route>
          {import.meta.env.PROD && <Route path="*" element={<Navigate to="/v2/dashboard" />} />}
          {import.meta.env.PROD && <Route path="" element={<Navigate to="/v2/login" />} />}
          {import.meta.env.DEV && <Route path="*" element={<UIKit/>} />}
        </Routes>
      </Suspense>
    </ErrorBoundary>
   
  );
};
