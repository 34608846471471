import request from "@services/axios";
import {
  OrderItemRes,
  OTCItemRes,
  SpecificItemRes,
  TransactionsArgs,
  TransactionsItemRes,
} from "./types";
import { DEFAULT_PER_PAGE } from "@constants/index";

export const getTransactions = async ({
  page,
  perPage,
  ...data
}: TransactionsArgs) => {
  return await request.post<{ items: TransactionsItemRes[]; total: number }>(
    `/api/WithdrawAuto/v2/allTransactions/${page ?? 0}/${
      perPage ?? DEFAULT_PER_PAGE
    }`,
    {
      sort: [
        {
          field: "createdAt",
          operator: "des",
        },
      ],
      populate: [],
      ...data,
    }
  );
};

export const getOrders = async ({
  page,
  perPage,
  ...data
}: TransactionsArgs): Promise<{ items: OrderItemRes[]; total: number }> => {
  // @ts-ignore
  return await request.post<{ items: OrderItemRes[]; total: number }>(
    `/api/ExCoreData/my/order/all/browse/${page ?? 0}/${
      perPage ?? DEFAULT_PER_PAGE
    }`,
    {
      sort: [
        {
          field: "createdAt",
          operator: "des",
        },
      ],
      populate: [],
      ...data,
    }
  );
};

export const getOTC = async ({
  page,
  perPage,
  ...data
}: TransactionsArgs): Promise<{ items: OTCItemRes[]; total: number }> => {
  // @ts-ignore
  return await request.post<{ items: OTCItemRes[]; total: number }>(
    `/api/OtcRequestV2/browse/my/${page ?? 0}/${perPage ?? DEFAULT_PER_PAGE}`,
    {
      sort: [
        {
          field: "createdAt",
          operator: "des",
        },
      ],
      populate: [],
      ...data,
    }
  );
};

export const getSpecificTransaction = async ({
  orderId,
}: {
  orderId: string;
}): Promise<{ items: SpecificItemRes[]; total: number }> => {
  // @ts-ignore
  return await request.post<{ items: SpecificItemRes[]; total: number }>(
    `/api/ExCoreData/my/trades/${orderId}/browse/0/100`
  );
};

export const cancelSpecificOrder = async ({ orderId }: { orderId: string }) => {
  return await request.delete<null>(`/api/ExCore/cancel/order/${orderId}`);
};
