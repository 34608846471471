import request from "@services/axios";
import {
  ConfirmOtpGoogleVerificationRes,
  LoginHistoryArgs,
  LoginHistoryRes,
} from "./types";
import { DEFAULT_PER_PAGE } from "@constants/index";

export const getMyLoginHistory = async (
  data: LoginHistoryArgs
): Promise<{ items: LoginHistoryRes[]; total: number }> => {
  // @ts-ignore
  return await request.post<{ items: LoginHistoryRes[]; total: number }>(
    `/api/ActivityHistory/browse/my/${data?.page ?? 0}/${
      data?.perPage ?? DEFAULT_PER_PAGE
    }`,
    {
      populate: ["wId", "uId"],
      query: [],
      sort: [{ field: "createdAt", operator: "des" }],
    }
  );
};

export const requestForGoogleVerification = async () =>
  await request.post<any>(`/api/auth/request/twofactor/activation`);

export const confirmOtpGoogleVerification = async (otp: string) =>
  await request.get<ConfirmOtpGoogleVerificationRes>(
    `/api/auth/secretcode/${otp}`
  );

export const enableGoogleCode = async (code: string) =>
  await request.post<any>(`/api/auth/active/twofactor`, {
    otp: code,
  });

export const disableGoogleCode = async (code: string) =>
  await request.post<any>(`/api/auth/deactive/twofactor`, {
    otp: code,
  });
