import { Box, MenuItem, Pagination as MuiPagination } from "@mui/material";
import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from "@mui/icons-material/";
import Select from "@mui/material/Select";
import { Typography } from "../typography";
import { Dispatch, SetStateAction } from "react";

export type PaginationProps = {
  totalData: number;
  onChange?: (page: number, perPage: number) => void;
  page?: number;
  perPage?: number;
  setPage: Dispatch<SetStateAction<number>>;
  setPerPage: Dispatch<SetStateAction<number>>;
};

const pageNumOptions = [5, 10];

export const Pagination = ({
  totalData,
  onChange = () => {},
  page = 0,
  perPage = 1,
  setPage,
  setPerPage,
}: PaginationProps) => {
  const calcPages = () =>
    totalData % perPage !== 0
      ? Math.floor(totalData / perPage) + 1
      : totalData / perPage;

  const handlePageChange = (page: number, perPage: number) => {
    onChange(page, perPage);
    setPage(page);
    setPerPage(perPage);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: { xs: "center", md: "center" },
        alignItems: "center",
        gap: { md: "64px" },
        position: "relative",
        height: "3rem",
        bgcolor: "white",
      }}
    >
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          alignItems: "center",
          gap: { md: "10px" },
          position: "absolute",
          left: 0,
        }}
      >
        <Typography variant="body2" sx={{ color: "neutral.900", m: 0 }}>
          تعداد نمایش در صفحه
        </Typography>
        <Select
          value={perPage.toString()}
          onChange={(e) => handlePageChange(1, +e.target.value)}
          size="small"
          sx={{ width: "68px" }}
        >
          {pageNumOptions.map((option, index) => {
            return (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </Select>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ArrowForwardIosIcon
          onClick={() => page !== 1 && handlePageChange(page - 1, perPage)}
          style={
            page !== 1
              ? { cursor: "pointer" }
              : { filter: "grayscale(1)", opacity: 0.5, cursor: "not-allowed" }
          }
          fontSize="small"
          sx={{
            color: "#427CB2",
          }}
        />
        <MuiPagination
          count={calcPages()}
          page={page}
          onChange={(e, page) => handlePageChange(page, perPage)}
          shape="rounded"
          hidePrevButton
          hideNextButton
          className="pagination"
        />
        <ArrowBackIosIcon
          onClick={() =>
            page !== calcPages() && handlePageChange(page + 1, perPage)
          }
          style={
            page !== calcPages()
              ? { cursor: "pointer" }
              : { filter: "grayscale(1)", opacity: 0.5, cursor: "not-allowed" }
          }
          fontSize="small"
          sx={{
            color: "#427CB2",
          }}
        />
      </Box>
    </Box>
  );
};
