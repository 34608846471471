import { Stack, useTheme } from "@mui/material";
import { Typography } from "../typography";
import { Warning2 } from "iconsax-react";

export const InputError = ({ error }: { error?: string }) => {
  const theme = useTheme();

  return (
    <Stack gap="0.15rem" direction="row" mt="0.25rem" alignItems="center">
      {!!error && (
        <>
          <Warning2
            variant="Bold"
            size="1.25rem"
            style={{ marginTop: "-2px" }}
            color={theme?.palette.error[500]}
          />
          <Typography color="error.500" variant="Caption" align="left">
            {error}
          </Typography>
        </>
      )}
    </Stack>
  );
};
