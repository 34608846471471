import { Typography } from "@components/common/typography";
import { Grid, Stack } from "@mui/material";
import { TomanIcon } from "@assets/icons/TomanIcon";
import { numUtils } from "@utils/num-helpers";
import { TransfersItem } from "@services/queries/wallet/types";
import { Fragment } from "react";

interface WithdrawCyclesProps {
  data: TransfersItem[];
}

export const WithdrawCycles = ({ data }: WithdrawCyclesProps) => {
  return (
    <Grid
      container
      rowGap="0.5rem"
      sx={{ maxHeight: "250px", overflowY: "auto" }}
    >
      <Grid xs={4} item>
        <Stack
          direction="row"
          gap="0.25rem"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="ExtendedBody" color="colorfulGray.700">
            مبلغ پایا
          </Typography>
          <TomanIcon
            sx={{
              fontSize: { xs: "0.75rem", md: "1rem" },
              fill: (theme) => theme.palette.colorfulGray[500],
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={4} item>
        <Stack
          direction="row"
          gap="0.25rem"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="ExtendedBody" color="colorfulGray.700">
            کارمزد
          </Typography>
          <TomanIcon
            sx={{
              fontSize: { xs: "0.75rem", md: "1rem" },
              fill: (theme) => theme.palette.colorfulGray[500],
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={4} item>
        <Stack
          direction="row"
          gap="0.25rem"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="ExtendedBody" color="colorfulGray.700">
            دریافتی
          </Typography>
          <TomanIcon
            sx={{
              fontSize: { xs: "0.75rem", md: "1rem" },
              fill: (theme) => theme.palette.colorfulGray[500],
            }}
          />
        </Stack>
      </Grid>
      {data?.map((item) => (
        <Fragment key={`cycles-${item?.transferID}`}>
          <Grid xs={4} item sx={{ textAlign: "center" }}>
            <Typography variant="ExtendedBody" color="colorfulGray.700">
              {numUtils.commaformatter.toCurrency(item?.amount + item?.fee)}
            </Typography>
          </Grid>
          <Grid xs={4} item sx={{ textAlign: "center" }}>
            <Typography variant="ExtendedBody" color="colorfulGray.700">
              {numUtils.commaformatter.toCurrency(item?.fee)}
            </Typography>
          </Grid>
          <Grid xs={4} item sx={{ textAlign: "center" }}>
            <Typography variant="ExtendedBody" color="colorfulGray.700">
              {numUtils.commaformatter.toCurrency(item?.amount)}
            </Typography>
          </Grid>
        </Fragment>
      ))}
    </Grid>
  );
};
