import React from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Typography } from "@components/common/typography";
import { Box, Divider, IconButton, Paper, Popover, Stack } from "@mui/material";
import { Button } from "@components/common/button";
import { useUserStore } from "@store/user";
import useCurrentCurrency from "@pages/dashborad/markets/market-details/_hooks/useCurrentCurrency";
import { useNavigate } from "react-router-dom";

const FeelLevelPopover = () => {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "trade-popover" : undefined;

  const currentCurrency = useCurrentCurrency();
  const isIRT = currentCurrency?.quoteCurrency === "IRT" || "";

  return (
    <>
      <Button
        sx={{ color: "neutral.600" }}
        disableRipple
        // onClick={handleClick}
        onClick={handleClick}
        variant="text"
      >
        سطح کارمزد
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Paper
          sx={{
            width: 445,
            // height: 296,
            p: 2,
          }}
        >
          <Typography>سطح کارمزد</Typography>
          <Stack direction={"column"}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography color={"neutral.500"} variant="h6">
                سطح فعلی شما
              </Typography>
              <Box display={"flex"} alignItems={"center"} my={1}>
                <Stack
                  component="span"
                  px="0.75rem"
                  py="0.3rem"
                  bgcolor="primary.A100"
                  borderRadius="6.25rem"
                  onClick={() => navigate("/v2/dashboard/profile")}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="CaptionMedium" color="primary.600">
                    {user?.levelInfo?.[isIRT ? 0 : 1]?.name}
                  </Typography>
                </Stack>
                <IconButton size="small">
                  <NavigateBeforeIcon />
                </IconButton>
              </Box>
            </Box>
            {/* <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              my={1}
            >
              <Typography color={"neutral.500"} variant="body1">
                حجم معاملات 30 روز گذشته
              </Typography>
              <Typography color={"neutral.500"} variant="body2">
                0 تومان
              </Typography>
            </Box> */}
            <Divider flexItem sx={{ my: 1 }} />
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              p={2}
            >
              <Box>
                <Typography color={"neutral.500"}>
                  سفارش گذار (Maker)
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{
                    mt: 1,
                  }}
                >
                  {Number(
                    (
                      (user?.levelInfo[isIRT ? 0 : 1]?.makerWage ?? 0) * 100
                    ).toFixed(10)
                  )}
                  %
                </Typography>
              </Box>
              <Box>
                <Typography color={"neutral.500"}>
                  سفارش بردار (Taker)
                </Typography>
                <Typography
                  textAlign={"center"}
                  sx={{
                    mt: 1,
                  }}
                >
                  {Number(
                    (
                      (user?.levelInfo?.[isIRT ? 0 : 1]?.takerWage ?? 0) * 100
                    ).toFixed(10)
                  )}
                  %
                </Typography>
              </Box>
            </Stack>
            <Divider flexItem sx={{ my: 1 }} />
            <Stack
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box>
                <Typography variant="body2">
                  با دعوت از دوستانتان،‌ از کارمزد آن‌ها هدیه بگیرید
                </Typography>
              </Box>
              <Box>
                <Button
                  variant="text"
                  onClick={() => navigate("/v2/dashboard/referral")}
                >
                  مطالعه بیشتر {">"}
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Paper>
      </Popover>
    </>
  );
};

export default FeelLevelPopover;
