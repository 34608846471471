import { Box, Skeleton, SxProps } from "@mui/material";
import { useState } from "react";

interface ImageProps {
  src: string;
  alt: string;
  width?: string;
  height?: string;
  sx?: SxProps;
  skeletonVariant?: "text" | "rectangular" | "rounded" | "circular";
  id?: string
}

export const Image = ({
  alt,
  height,
  src,
  width,
  sx,
  skeletonVariant,
  id,
}: ImageProps) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  return (
    <Box id={id} position="relative" width={width} height={height} sx={sx}>
      <Box
        id={id}
        component="img"
        visibility={isImageLoaded ? "visible" : "hidden"}
        onLoad={() => setIsImageLoaded(true)}
        src={src}
        alt={alt}
        width="100%"
        height="100%"
      />
      <Skeleton
        variant={skeletonVariant || "rounded"}
        width="90%"
        height="90%"
        sx={{
          top: 0,
          left: 0,
          position: "absolute",
          display: isImageLoaded ? "none" : "block",
        }}
      />
    </Box>
  );
};
