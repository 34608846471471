import { useMutation } from "@tanstack/react-query";
import { OTPLoginArgs, SendMobileArgs, VerifyGoogleAuthArgs } from "./types";
import { authenticatorLogin, logout, otpLogin, otpSendMobile } from "./apis";
import { toast } from "@utils/toast";
import { useTranslation } from "react-i18next";
import { detectEnv } from "@utils/common";

export function useSendMobile({
  isSignUpMode = false,
}: {
  isSignUpMode?: boolean;
}) {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: (data: SendMobileArgs) => otpSendMobile(data),
    onSettled: async (data, error) => {
      if (!error && data && !isSignUpMode) {
        if (data?.result?.newUser) {
          return toast.error(t("messages.notMobileExisted"));
        }
      }
    },
  });
}

export function useVerifyOtp() {
  return useMutation({
    mutationFn: (data: OTPLoginArgs) => otpLogin(data),
    onSettled: async (data, error) => {
      if (!error && data) {
        if (detectEnv() === "WebView") {
          if (
            !data?.result?.apiKey?.secretKey &&
            !data?.result?.apiKey?.accessKey
          )
            return console.error(
              "Secret Key & Access Key not found in login response"
            );

          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              action: "saveSecret",
              data: {
                secretKey: data?.result?.apiKey?.secretKey,
                accessKey: data?.result?.apiKey?.accessKey,
              },
            })
          );
        }
      }
    },
  });
}

export function useVerifyGoogleAuth() {
  return useMutation({
    mutationFn: (data: VerifyGoogleAuthArgs) => authenticatorLogin(data),
    onSettled: async (data, error) => {
      if (!error && data) {
        if (detectEnv() === "WebView") {
          if (
            !data?.result?.apiKey?.secretKey &&
            !data?.result?.apiKey?.accessKey
          )
            return console.error(
              "Secret Key & Access Key not found in 2FA login response"
            );

          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              action: "saveSecret",
              data: {
                secretKey: data?.result?.apiKey?.secretKey,
                accessKey: data?.result?.apiKey?.accessKey,
              },
            })
          );
        }
      }
    },
  });
}

export function useLogout() {
  return useMutation({
    mutationFn: logout,
    onSettled: async (data, error) => {
      if (!error && data) {
        if (detectEnv() === "WebView") {
          window?.ReactNativeWebView?.postMessage(
            JSON.stringify({
              action: "logout",
            })
          );
        }
      }
    },
  });
}
