import { Typography } from "@components/common/typography";
import {
  Stack,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  StepperProps,
} from "@mui/material";

interface AuthStepperProps extends StepperProps {
  steps: { id: string; title: string; icon: React.ReactNode }[];
  activeStep: number;
}

export default function AuthStepper({
  steps,
  activeStep,
  sx,
  ...props
}: AuthStepperProps) {
  return (
    <Stepper
      sx={{
        pt: "1rem",
        "& .MuiStepConnector-line": {
          borderStyle: "dashed",
          borderWidth: "1px",
          mx: "1.5rem",
          mt: "0.65rem",
        },
        ...sx,
      }}
      alternativeLabel
      activeStep={activeStep}
      {...props}
    >
      {steps?.map((step, i) => (
        <Step key={step?.id}>
          <StepButton
            disabled
            icon={
              <Stack
                justifyContent="center"
                alignItems="center"
                sx={{
                  filter: activeStep < i ? "grayscale(1)" : "grayscale(0)",
                  bgcolor: "colorfulGray.400",
                  width: { xs: "2.5rem", md: "3rem" },
                  aspectRatio: "1/1",
                  borderRadius: "50%",
                  position: "relative",
                  "&:after": {
                    content: '" "',
                    display: "block",
                    border: "1.5px solid",
                    borderColor: "colorfulGray.500",
                    width: { xs: "2.7rem", md: "3.5rem" },
                    height: { xs: "2.7rem", md: "3.5rem" },
                    borderRadius: "50%",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  },
                }}
              >
                {step.icon}
              </Stack>
            }
          >
            <StepLabel>
              <Typography
                color="colorfulGray.700"
                variant="body2"
                sx={{
                  filter: activeStep < i ? "grayscale(1)" : "grayscale(0)",
                  fontSize: { xs: "0.7rem", md: "0.85rem" },
                }}
              >
                {step?.title}
              </Typography>
            </StepLabel>
          </StepButton>
        </Step>
      ))}
    </Stepper>
  );
}
