import { Button } from "@components/common/button";
import Hint from "@components/common/hint";
import { Typography } from "@components/common/typography";
import { Stack } from "@mui/material";
import { MaxWithdrawalItem } from "@pages/dashborad/wallet/_pages/toman/_pages/withdraw/_components/max-withdrawal";
import { useGetDailyMonthlyWithdraw } from "@services/queries/wallet/queries";
import { useUserStore } from "@store/user";
import { ArrangeVertical, SecurityUser } from "iconsax-react";
import { useMemo } from "react";

export const LimitedWithdrawalSection = () => {
  const user = useUserStore((state) => state.user);

  const authenticationLevel: "BASE" | "NOT_VERIFIED" | "PRO" = useMemo(() => {
    if (
      !!user?.IDverification &&
      !!user?.mobileVerification &&
      !!user?.personalAttachmentsVerification
    )
      return "PRO";

    if (!!user?.IDverification && !!user?.mobileVerification) return "BASE";

    return "NOT_VERIFIED";
  }, [user]);

  const getDailyMonthlyWithdrawQuery = useGetDailyMonthlyWithdraw({
    enabled: authenticationLevel !== "NOT_VERIFIED",
  });

  return (
    <Stack
      bgcolor="white"
      width="100%"
      px="0.75rem"
      py="1.5rem"
      borderRadius="1.125rem"
      gap="1.5rem"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="0.5rem"
      >
        <Stack direction="row" gap="0.75rem" alignItems="center">
          <Stack
            component="span"
            p="0.5rem"
            borderRadius="50%"
            bgcolor="primary.500"
            sx={{ aspectRatio: "1/1" }}
          >
            <ArrangeVertical variant="Bold" color="white" />
          </Stack>
          <Typography
            smVariant="BodyMedium"
            variant="ExtendedBodyMedium"
            color="colorfulGray.700"
          >
            محدودیت‌های واریز و برداشت من
          </Typography>
        </Stack>
      </Stack>

      <Stack
        direction="row"
        gap="0.75rem"
        sx={{
          px: { md: "1.5rem" },
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Stack
          gap="0.5rem"
          borderRadius="0.625rem"
          p="0.75rem"
          border={1}
          borderColor="colorfulGray.A100"
          sx={{ maxWidth: { md: "50%" } }}
          width="100%"
        >
          <Typography variant="BodyMedium" color="colorfulGray.700">
            برداشت ارزی
          </Typography>
          {!!["NOT_VERIFIED", "PRO"].includes(authenticationLevel) &&
          !!getDailyMonthlyWithdrawQuery?.isSuccess ? (
            <>
              <MaxWithdrawalItem
                title="حداکثر برداشت ۲۴ ساعته شما"
                titlePrice={
                  getDailyMonthlyWithdrawQuery?.data?.result?.cryptoDaily || 0
                }
                subtitle="کل برداشتان در ۲۴ ساعت گذشته"
                subtitlePrice={
                  Number(
                    getDailyMonthlyWithdrawQuery?.data?.result?.crypto24Houre
                  ).toFixed(0) || 0
                }
              />
              <MaxWithdrawalItem
                title="حداکثر برداشت ۳۰ روزه شما"
                titlePrice={
                  getDailyMonthlyWithdrawQuery?.data?.result?.cryptoMonthly || 0
                }
                subtitle="کل برداشتان در ۳۰ روز گذشته"
                subtitlePrice={
                  Number(
                    getDailyMonthlyWithdrawQuery?.data?.result?.crypto30Day
                  ).toFixed(0) || 0
                }
              />
            </>
          ) : (
            <Hint
              severity="info"
              IconComponent={SecurityUser}
              content={
                <Typography variant="Caption" color="warning.900">
                  برای بالا بردن مبلغ روزانه و ماهیانه احراز هویتتان را ارتقا
                  بدهید
                </Typography>
              }
              action={
                <Button
                  href="/v2/dashboard/authentication"
                  bgColor="white"
                  textColor="primary.700"
                  sx={{ border: 1.5, borderColor: "primary.700" }}
                  startIcon={<SecurityUser size="1.25rem" />}
                >
                  <Typography variant="BodyMedium">احراز هویت</Typography>
                </Button>
              }
            />
          )}
        </Stack>
        <Stack
          gap="0.5rem"
          borderRadius="0.625rem"
          p="0.75rem"
          border={1}
          borderColor="colorfulGray.A100"
          sx={{ maxWidth: { md: "50%" } }}
          width="100%"
        >
          <Typography variant="BodyMedium" color="colorfulGray.700">
            برداشت تومانی
          </Typography>
          {!!["BASE", "PRO"].includes(authenticationLevel) &&
          !!getDailyMonthlyWithdrawQuery?.isSuccess ? (
            <>
              <MaxWithdrawalItem
                title="حداکثر برداشت ۲۴ ساعته شما"
                titlePrice={
                  getDailyMonthlyWithdrawQuery?.data?.result?.currencyDaily
                }
                subtitle="کل برداشتان در ۲۴ ساعت گذشته"
                subtitlePrice={
                  getDailyMonthlyWithdrawQuery?.data?.result?.currency24Houre
                }
              />
              <MaxWithdrawalItem
                title="حداکثر برداشت ۳۰ روزه شما"
                titlePrice={
                  getDailyMonthlyWithdrawQuery?.data?.result?.currencyMonthly
                }
                subtitle="کل برداشتان در ۳۰ روز گذشته"
                subtitlePrice={
                  getDailyMonthlyWithdrawQuery?.data?.result?.currency30Day
                }
              />
            </>
          ) : (
            <Hint
              severity="info"
              IconComponent={SecurityUser}
              content={
                <Typography variant="Caption" color="warning.900">
                  برای بالا بردن مبلغ روزانه و ماهیانه احراز هویتتان را ارتقا
                  بدهید
                </Typography>
              }
              action={
                <Button
                  href="/v2/dashboard/authentication"
                  bgColor="white"
                  textColor="primary.700"
                  sx={{ border: 1.5, borderColor: "primary.700" }}
                  startIcon={<SecurityUser size="1.25rem" />}
                >
                  <Typography variant="BodyMedium">احراز هویت</Typography>
                </Button>
              }
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
