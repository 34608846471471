import DepositCurrencyIcon from "@assets/icons/DepositCurrencyIcon";
import DepositRialIcon from "@assets/icons/DepositRialIcon";
import { Button } from "@components/common/button";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface DepositModalProps {
  title: string;
  onClose: () => void;
  open: boolean;
}

const DepositModal = ({ open, onClose, title }: DepositModalProps) => {
  const navigate = useNavigate();

  const handleClick = () => {
    onClose();
    navigate("/v2/dashboard/wallet");
  };

  return (
    <Modal open={open} title={title} onClose={onClose}>
      <Stack width="100%" sx={{ borderRadius: { xs: "2rem 0 2rem 0" } }}>
        <Stack gap={4} my={2}>
          <Stack direction={"row"} alignItems={"center"} gap="0.75rem">
            <Stack direction="row" flex={1} alignItems="center">
              <DepositRialIcon
                sx={{
                  fontSize: "3.5rem",
                }}
              />
              <Stack justifyContent={"space-between"} width={"100%"} ml={1}>
                <Typography variant="subtitle2">واریز ریال</Typography>
                <Typography variant="caption">
                  با واریز ریال کیف پول خود را شارژ کنید
                </Typography>
              </Stack>
            </Stack>
            <Button
              color="primary"
              sx={{
                minWidth: 104,
                height: 40,
              }}
              onClick={handleClick}
            >
              واریز تومان
            </Button>
          </Stack>
          <Stack direction={"row"} alignItems={"center"} gap="0.75rem">
            <Stack direction="row" flex={1} alignItems="center">
              <DepositCurrencyIcon
                sx={{
                  fontSize: "3.5rem",
                }}
              />
              <Stack width={"100%"} ml={1}>
                <Typography variant="subtitle2">واریز ارز</Typography>
                <Typography variant="caption">
                  با واریز ارز دیجیتال کیف پول ارزی خود را شارژ کنید
                </Typography>
              </Stack>
            </Stack>
            <Button
              color="primary"
              sx={{
                minWidth: 104,
                height: 40,
              }}
              onClick={handleClick}
            >
              واریز ارز
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Modal>
  );
};

export default DepositModal;
