import {
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  CircularProgress,
  Stack,
  useTheme,
  SxProps,
} from "@mui/material";
import { Typography } from "../typography";
import { CloseSquare } from "iconsax-react";

interface ModalProps {
  loading?: boolean;
  title?: string;
  containerSx?: SxProps;
  maxWidth?: string;
}

export const Modal = ({
  children,
  open,
  loading,
  title,
  sx,
  containerSx,
  onClose,
  maxWidth,
  ...props
}: ModalProps & MuiModalProps) => {
  const theme = useTheme();

  return (
    <MuiModal
      sx={{
        backdropFilter: "blur(3px)",
        display: "flex",
        alignItems: { xs: "flex-end", md: "center" },
        justifyContent: "center",
        p: "1rem",
        outline: "none",
        "& div": {
          outline: "none",
        },
        ...sx,
      }}
      open={open}
      onClose={onClose}
      {...props}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        position="relative"
        bgcolor="white"
        borderRadius="1.125rem"
        overflow="hidden"
        sx={{
          maxWidth: maxWidth || "unset",
          minWidth: { md: "400px" },
          width: { xs: "100%", md: "auto" },
          py: { xs: "1.5rem", md: "2rem" },
          px: { xs: "1rem", md: "1.5rem" },
          ...containerSx,
        }}
      >
        {!!loading && (
          <Stack
            position="absolute"
            width="100%"
            height="100%"
            justifyContent={"center"}
            alignItems={"center"}
            zIndex={3}
            sx={{ backdropFilter: "blur(1px)" }}
          >
            <CircularProgress color="inherit" />
          </Stack>
        )}

        {/* Header Start */}
        {title && (
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb="1rem"
          >
            <Typography
              color="colorfulGray.700"
              smVariant="BodyBold"
              variant="ExtendedBodyBold"
            >
              {title || ""}
            </Typography>
            <CloseSquare
              onClick={() => onClose && onClose({}, "escapeKeyDown")}
              size="1.5rem"
              color={theme.palette.colorfulGray[500]}
            />
          </Stack>
        )}
        {/* Header End */}

        {/* Content Start */}

        {children}
      </Stack>
    </MuiModal>
  );
};
