import { Divider, InputAdornment, Stack, SvgIcon } from "@mui/material";
import { FilterItem, IValueFilter } from "../../_constants";
import { Typography } from "@components/common/typography";
import { Checkbox } from "@components/common/checkbox";
import { Button } from "@components/common/button";
import { Fragment } from "react/jsx-runtime";
import { TextField } from "@components/common/textfield";
import { DatePicker, DatePickerProvider } from "@components/common/date-picker";
import { useState } from "react";
import { CloseCircle, Trash } from "iconsax-react";
import { useBreakpoint } from "@hooks/useBreakpoint";

interface FilterModalProps {
  filterList: FilterItem[];
  filters: IValueFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IValueFilter[]>>;
  onClose: () => void;
}

export const FilterModal = ({
  filterList,
  filters,
  setFilters,
  onClose,
}: FilterModalProps) => {
  const isMdUp = useBreakpoint("md");

  const [fromDate, setFromDate] = useState(
    filters?.find((e) => e?.id === "DATE")?.filter[0]?.value?.from
  );
  const [toDate, setToDate] = useState(
    filters?.find((e) => e?.id === "DATE")?.filter[0]?.value?.to
  );

  return (
    <Stack gap="1rem" width="100%" sx={{ minWidth: { md: "450px" } }}>
      <Stack gap="1rem" maxHeight="70vh" overflow="auto">
        {filterList
          ?.filter((e) => e?.type === "CHECKBOX")
          ?.map((item) => {
            return (
              <Stack
                px="0.75rem"
                py="1rem"
                border={1}
                borderColor="colorfulGray.A100"
                borderRadius="0.625rem"
                gap="0.5rem"
                key={item?.prefix}
                sx={{ ".MuiFormControl-root": { m: "0px !important" } }}
              >
                <Typography variant="BodyMedium" color="colorfulGray.700">
                  {item?.title}
                </Typography>

                {item?.type === "CHECKBOX" &&
                  item?.children?.map((child) =>
                    // prettier-ignore
                    <Fragment key={`${item.prefix}${child.value?.id}`}>
                <Checkbox
                  formControlProps={{
                    label: (
                      <Typography variant="BodyMedium" color="colorfulGray.700">
                        {child?.title}
                      </Typography>
                    ),
                    onChange: (_, checked) => {
                      setFilters((prev) => {
                        if (checked) {
                          return [...prev, {id:`${item.prefix}${child.value?.id}`, filter: child?.value?.filter}];
                        } else {
                          return prev.filter(
                            (filter) => filter?.id !== `${item.prefix}${child.value?.id}`
                          );
                        }
                      });
                    },
                    checked: filters?.map((e)=> e?.id).includes(`${item.prefix}${child.value?.id}`),
                  }}
                />
              </Fragment>
                  )}
              </Stack>
            );
          })}

        <Stack
          px="0.75rem"
          py="1rem"
          border={1}
          borderColor="colorfulGray.A100"
          borderRadius="0.625rem"
          gap="0.5rem"
          sx={{ ".MuiFormControl-root": { m: "0px !important" } }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="BodyMedium" color="colorfulGray.700">
              بازه زمانی
            </Typography>

            <Button
              onClick={() => {
                setFromDate(null);
                setToDate(null);
              }}
              size="small"
              variant="text"
              startIcon={
                <SvgIcon
                  sx={{
                    fontSize: "1rem",
                    color: (theme) => theme.palette.primary[400],
                  }}
                >
                  <Trash />
                </SvgIcon>
              }
            >
              <Typography variant="Caption" color="primary.500">
                حذف تاریخ
              </Typography>
            </Button>
          </Stack>

          <Stack
            border={1}
            px="0.5rem"
            borderRadius="0.75rem"
            borderColor="colorfulGray.A100"
            direction="row"
          >
            <TextField
              renderComponent={
                <DatePickerProvider>
                  <DatePicker
                    slotProps={{
                      field: {
                        clearable: true,
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                variant="Body"
                                color="colorfulGray.300"
                                mb="-1rem"
                              >
                                از
                              </Typography>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    className="persian-num"
                    views={["year", "month", "day"]}
                    openTo="year"
                    value={fromDate}
                    onChange={(date) => date && setFromDate(date)}
                    sx={{
                      outline: "none",
                      width: "100%",
                      bgcolor: "transparent",
                    }}
                    disableFuture
                  />
                </DatePickerProvider>
              }
            />

            <Divider orientation="vertical" flexItem sx={{ my: "0.5rem" }} />

            <TextField
              renderComponent={
                <DatePickerProvider>
                  <DatePicker
                    slotProps={{
                      field: {
                        clearable: true,
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <Typography
                                variant="Body"
                                color="colorfulGray.300"
                                mb="-1rem"
                              >
                                تا
                              </Typography>
                            </InputAdornment>
                          ),
                        },
                      },
                    }}
                    className="persian-num"
                    views={["year", "month", "day"]}
                    openTo="year"
                    value={toDate}
                    onChange={(date) => date && setToDate(date)}
                    sx={{
                      outline: "none",
                      width: "100%",
                      bgcolor: "transparent",
                    }}
                    disableFuture
                  />
                </DatePickerProvider>
              }
            />
          </Stack>
        </Stack>

        <Stack direction="row" gap="0.5rem">
          <Button
            onClick={() => {
              setFilters([
                ...filters.filter((filter) => filter?.id !== "DATE"),
                ...((fromDate instanceof Date && !isNaN(fromDate.getTime())) ||
                (toDate instanceof Date && !isNaN(toDate.getTime()))
                  ? [
                      {
                        id: "DATE",
                        filter: [
                          {
                            field: "createdAt",
                            operator: "bt",
                            value: {
                              from: fromDate ? fromDate : "",
                              to: toDate ? toDate : "",
                            },
                          },
                        ],
                      },
                    ]
                  : []),
              ]);
              onClose();
            }}
            fullWidth
            textColor="white"
          >
            <Typography variant="BodyBold">اعمال فیلتر</Typography>
          </Button>
          <Button
            onClick={() => {
              setFilters([]);
              onClose();
            }}
            fullWidth
            bgColor="primary.A100"
            textColor="primary.700"
          >
            <Typography variant="BodyBold">حذف فیلترها</Typography>
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
