import request from "@services/axios";
import type {
  CreateOtcReqArgs,
  CreateOtcReqInvoiceArgs,
  CreateOtcReqInvoiceRes,
  GetPairDetailsArgs,
  GetPairDetailsRes,
} from "./types";
import type { Coin } from "~types/index";

export const getPairDetails = async ({ from, to }: GetPairDetailsArgs) => {
  return await request.get<GetPairDetailsRes>(
    `/api/OtcRequestV2/pair/details/${from}/${to}`
  );
};

export const getPairSides = async () => {
  return await request.post<Coin[]>(`/api/OtcRequestV2/pair/side/0/1000`);
};

export const createOtcRequest = async (data: CreateOtcReqArgs) => {
  return await request.post(`/api/OtcRequestV2`, data);
};

export const createOtcRequestInvoice = async (
  data: CreateOtcReqInvoiceArgs
) => {
  return await request.post<CreateOtcReqInvoiceRes>(
    `/api/OtcRequestV2/invoice`,
    data
  );
};

export const getOTCQuickView = async () => {
  return await request.post(`/api/OtcView/view`);
};
