import { Box, Stack } from "@mui/material";
import { Typography } from "@components/common/typography";
import { Button } from "@components/common/button";
import {
  PlayCircleOutline,
  RadioButtonCheckedOutlined,
  Refresh,
  StopCircleOutlined,
  VideocamOutlined,
} from "@mui/icons-material";
import { useEffect, useMemo, useRef, useState } from "react";
import { toast } from "@utils/toast";
import { useUploadFile } from "@services/queries/upload/mutations";
import { useVerifyBaseAuth } from "@services/queries/authentication/mutations";
import { useUserStore } from "@store/user";
import { SuccessVideoUploadedModal } from "../success-video-uploaded-modal";

export const VideoRecorder = () => {
  const cameraRef = useRef<HTMLVideoElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState<MediaRecorder | null>(null);
  const [chunks, setChunks] = useState<BlobPart[]>([]);
  const [camPermission, setCamPermission] = useState<boolean | null>(null);
  const [recordedVideoUrl, setRecordedVideoUrl] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);

  const user = useUserStore((state) => state.user);

  const currentState = useMemo(() => {
    if (!camPermission) {
      return { state: "PERMISSION_NEEDED", title: "دسترسی به دوربین" };
    } else if (recorder?.state === "recording") {
      return { state: "RECORDING", title: "در حال ضبط ویدیو" };
    } else if (!!recordedVideoUrl) {
      return { state: "RECORDED", title: "ویدیو ضبط شده" };
    } else if (isPlaying) {
      return { state: "PLAYING", title: "در حال پخش ویدیو" };
    } else {
      return { state: "READY_TO_RECORD", title: "برای شروع ضبط کلیک کنید" };
    }
  }, [camPermission, recorder?.state, recordedVideoUrl, isPlaying]);

  const useUploadMutation = useUploadFile();
  const verifyBaseAuthMutation = useVerifyBaseAuth();

  const handlePlayNewVideo = (recorder: MediaRecorder) => {
    if (recorder.state === "recording") {
      setRecording(false);
      recorder.stop();
      recorder.ondataavailable = (e) => {
        setChunks((prev) => [...prev, e.data]);
        const videoUrl = URL.createObjectURL(e.data);
        if (videoRef.current) {
          videoRef.current.src = videoUrl;
        }
        setRecordedVideoUrl(videoUrl);
      };
    } else {
      setRecording(false);
      console.warn("Recorder is not recording. Cannot stop.");
    }
  };

  const handleRetryAsFirstRecording = async () => {
    setRecordedVideoUrl(null);
    setChunks([]);
    setRecording(false);
    setRecorder(null);
    if (videoRef.current) {
      videoRef.current.src = "";
      videoRef.current.srcObject = null;
    }
  };

  const handleStopRecording = () => {
    if (recorder) {
      if (recorder.state === "recording") {
        handlePlayNewVideo(recorder);
      } else {
        console.warn("Recorder is not in a recording state.");
      }
    } else {
      console.warn("No recorder instance available.");
    }
  };

  const checkSupportVideoType = () => {
    try {
      if (MediaRecorder.isTypeSupported("video/mp4")) {
        return { mimeType: "video/mp4", ext: "mp4" };
      } else if (MediaRecorder.isTypeSupported("video/webm")) {
        return { mimeType: "video/webm", ext: "webm" };
      } else {
        console.log("هیچ ویدیو فرمتی پشتیبانی نمی شود.");
        return;
      }
    } catch (error) {
      console.error("Error checking supported video type:", error);
      return;
    }
  };

  const handleOpenCameraRecord = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();

      if (window?.isWebView) {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ action: "requestMediaAccess" })
        );
      }

      const videoInputDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );

      if (videoInputDevices.length === 0) {
        setCamPermission(false);
        return toast.error("دستگاه شما از دوربین پشتیبانی نمی کند.");
      }

      if (!recording) {
        await handleRetryAsFirstRecording();

        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: {
              facingMode: "user",
              width: { ideal: 600 },
              height: { ideal: 800 },
              frameRate: { ideal: 16 },
            },
            audio: true,
          });

          if (cameraRef.current) {
            cameraRef.current.srcObject = stream;
          }

          const recorderInstance = new MediaRecorder(
            stream,
            checkSupportVideoType()
          );

          recorderInstance.ondataavailable = (e) => {
            setChunks((prev) => [...prev, e.data]);
          };

          setCamPermission(true);
          setRecorder(recorderInstance);
          recorderInstance.start();
          setRecording(true);

          setTimeout(() => {
            if (recorderInstance.state === "recording") {
              console.log("Recording stopped by timeout.");
              handlePlayNewVideo(recorderInstance);
            }
          }, import.meta.env.VITE_AUTH_RECORD_VIDEO_INTERVAL_IN_SEC * 1000);
        } catch (err) {
          console.error("Error accessing camera:", err);
          toast.error("خطا در دسترسی به دوربین.");
        }
      }
    } catch (error) {
      console.error("Error opening camera:", error);
      toast.error("خطا در باز کردن دوربین.");
    }
  };

  const handleSubmit = async () => {
    const blob = new Blob(chunks, { type: checkSupportVideoType()?.mimeType });
    const formData = new FormData();
    formData.append(
      "video",
      new File([blob], `selfi.${checkSupportVideoType()?.ext}`, {
        type: checkSupportVideoType()?.mimeType,
      })
    );

    useUploadMutation?.mutate(formData, {
      onSuccess: (data) => {
        if (!user)
          return toast.error("کاربر یافت نشد.، لطفا دوباره وارد شوید.");

        verifyBaseAuthMutation.mutate(
          {
            birthday: user?.birthday?.toString() ?? "",
            firstName: user?.firstName ?? "",
            lastName: user?.lastName ?? "",
            nationalCode: user?.nationalCode ?? "",
            selfieVideoAddress: data?.result?.fullUrl ?? "",
          },
          {
            onSuccess: () => {
              toast.success("ویدیو با موفقیت آپلود شد.");
              setIsSuccessModal(true);
            },
          }
        );
      },
    });
  };

  return (
    <>
      <Stack
        mx="auto"
        justifyContent="center"
        alignItems="center"
        width="16rem"
        height="16rem"
        borderRadius="50%"
        position="relative"
        p="1.2rem"
        sx={{
          transform: "scaleX(-1)",
        }}
      >
        {recording && (
          <svg
            width="100%"
            height="100%"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              zIndex: 1,
              transform: "rotate(-90deg)",
            }}
          >
            <Box
              component="circle"
              cx="50"
              cy="50"
              r="45"
              fill="none"
              strokeWidth="1"
              strokeDasharray="282.743"
              strokeDashoffset="282.743"
              sx={{
                stroke: (theme) => theme?.palette?.primary?.[500],
                animation: `progress ${
                  import.meta.env.VITE_AUTH_RECORD_VIDEO_INTERVAL_IN_SEC
                }s linear forwards`,
              }}
            />
          </svg>
        )}

        <Stack
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
          borderRadius="50%"
          overflow="hidden"
          position="relative"
          border="1px solid"
          zIndex={3}
          borderColor={camPermission ? "success.500" : "neutral.400"}
        >
          {/* If recorded video exist now */}
          <Box
            component="video"
            ref={videoRef}
            width="150%"
            height="150%"
            autoPlay
            playsInline
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
            sx={{
              zIndex: 2,
              visibility: !recordedVideoUrl ? "hidden" : "visible",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              WebkitAppearance: "none",
              appearance: "none",
              "&::-webkit-media-controls": {
                display: "none",
              },
            }}
          />

          {/* If recorded video doesn't exist now */}
          <Box
            component="video"
            ref={cameraRef}
            width="150%"
            height="150%"
            autoPlay
            playsInline
            muted
            sx={{
              visibility:
                currentState.state === "RECORDING" ||
                (currentState.state === "READY_TO_RECORD" && camPermission)
                  ? "visible"
                  : "hidden",
              zIndex: 2,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              WebkitAppearance: "none",
              appearance: "none",
              "&::-webkit-media-controls": {
                display: "none",
              },
            }}
          />

          {currentState.state !== "RECORDING" && (
            <VideocamOutlined sx={{ fontSize: "5rem", color: "neutral.400" }} />
          )}
        </Stack>
      </Stack>

      <Stack
        mx="auto"
        gap="0.5rem"
        alignItems="center"
        sx={{ flexDirection: { xs: "column", md: "row" } }}
      >
        {/* Permission and Record Button */}
        <Button
          bgColor={camPermission ? "primary.700" : "secondary.600"}
          disabled={currentState.state === "RECORDING"}
          endIcon={
            currentState.state === "RECORDING" ? (
              <RadioButtonCheckedOutlined sx={{ color: "neutral.50" }} />
            ) : currentState.state === "RECORDED" ? (
              <Refresh sx={{ color: "primary.700" }} />
            ) : (
              <RadioButtonCheckedOutlined sx={{ color: "neutral.50" }} />
            )
          }
          onClick={handleOpenCameraRecord}
        >
          <Typography variant="BodyMedium">
            {/* Permission Allow */}
            {camPermission &&
              (currentState.state === "RECORDING"
                ? "در حال ضبط..."
                : currentState.state === "RECORDED"
                ? "ضبط دوباره"
                : "ضبط ویدیو")}

            {/* Permission Denied */}
            {!camPermission && "بازکردن دوربین"}
          </Typography>
        </Button>

        {/* Stop And Play Video Button */}
        {(recorder?.state === "recording" || !!recordedVideoUrl) && (
          <Button
            bgColor="secondary.600"
            endIcon={
              currentState.state === "RECORDING" ? (
                <StopCircleOutlined sx={{ color: "neutral.900" }} />
              ) : (
                <PlayCircleOutline sx={{ color: "neutral.900" }} />
              )
            }
            onClick={() => {
              if (currentState.state === "RECORDING") {
                handleStopRecording();
              } else if (
                currentState.state === "RECORDED" ||
                currentState.state === "PLAYING"
              ) {
                if (videoRef?.current) {
                  if (videoRef?.current.paused) {
                    videoRef?.current.play();
                    setIsPlaying(true);
                  } else {
                    videoRef?.current.pause();
                    videoRef?.current?.setAttribute("currentTime", "0");
                    setIsPlaying(false);
                  }
                }
              }
            }}
          >
            <Typography variant="BodyMedium">
              {currentState.state === "RECORDING"
                ? "اتمام ضبط ویدیو"
                : isPlaying
                ? "توقف پخش"
                : "پخش ویدیو"}
            </Typography>
          </Button>
        )}
      </Stack>

      {/* Submit Button */}
      <Button
        disabled={!recordedVideoUrl}
        loading={
          useUploadMutation?.isPending || verifyBaseAuthMutation?.isPending
        }
        onClick={handleSubmit}
        sx={{ mt: { xs: "1.5rem", md: "2.5rem" } }}
        size="large"
      >
        <Typography variant="body1" color="neutral.50">
          تایید و ادامه
        </Typography>
      </Button>

      {/* Success Modal */}
      {isSuccessModal && (
        <SuccessVideoUploadedModal onClose={() => setIsSuccessModal(false)} />
      )}
    </>
  );
};
