import request from "@services/axios";
import type { IUserModel } from "~types/user";
import type {
  OTPLoginArgs,
  OTPLoginRes,
  SendMobileArgs,
  SendMobileRes,
  VerifyGoogleAuthArgs,
} from "./types";

export const whoAmI = async () => {
  return await request.get<{ item: IUserModel }>("/api/user/whoAmI");
};

export const otpSendMobile = async (data: SendMobileArgs) => {
  return await request.post<SendMobileRes>("/public/auth/otp", data);
};

export const otpLogin = async (data: OTPLoginArgs) => {
  return await request.post<OTPLoginRes>("/public/auth/otp/login", data);
};

export const authenticatorLogin = async (data: VerifyGoogleAuthArgs) => {
  return await request.post<OTPLoginRes>("/public/auth/2fa/login", data);
};

export const refreshAuthToken = async () => {
  return await request.post(`/public/auth/refresh`);
};

export const retryLoginWithSecret = async ({
  accessKey,
  secretKey,
}: {
  accessKey: string;
  secretKey: string;
}) => {
  return await request.post(`/public/auth/api/login`, { accessKey, secretKey });
};

export const logout = async () => {
  return await request.post("/api/auth/logout");
};

export const logoutAllDevices = async () => {
  return await request.post("/public/auth/otp/login/addDevices");
};

export const requestQRCode = async () => {
  return await request.post("/api/auth/request/twofactor/activation");
};

export const getQRCode = async (otp: string) => {
  return await request.get(`/api/auth/secretcode/${otp}`);
};

export const activateTwoFactor = async (otp: string) => {
  return await request.post(`/api/auth/active/twofactor`, { otp });
};

export const deactivateTwoFactor = async (otp: string) => {
  return await request.post(`/api/auth/deactive/twofactor`, { otp });
};

export const getAllRoles = async () => {
  return await request.post(`/api/auth/roles/browse/0/20`);
};
