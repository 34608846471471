import { Button } from "@components/common/button";
import { Checkbox } from "@components/common/checkbox";
import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, SvgIcon } from "@mui/material";
import { Key, Mobile, ScanBarcode } from "iconsax-react";
import { useState } from "react";
import { CodeVerificationModal } from "../code-verification-modal";
import {
  useConfirmOtpGoogleVerification,
  useRequestForGoogleVerification,
} from "@services/queries/settings/mutations";
import { ConfirmOtpGoogleVerificationRes } from "@services/queries/settings/types";

const listSteps = [
  {
    title: "نصب اپلیکیشن گوگل",
    subtitle:
      "اپلیکیشن Google Authenticator را از Google play و یا App Store و یا کافه بازار را نصب کنید.",
    icon: (
      <Image
        src="/v2/images/google-two-step-icon.png"
        width="2.5rem"
        height="2.5rem"
        alt="google-verification"
        sx={{ aspectRatio: "1/1" }}
      />
    ),
  },
  {
    title: "اسکن بارکد",
    subtitle: "بارکد را در اپلیکیشن Google Authenticator اسکن کنید.",
    icon: (
      <SvgIcon
        sx={{
          mx: "0.25rem",
          fontSize: "2rem",
          color: (theme) => theme.palette.secondary[600],
        }}
      >
        <ScanBarcode variant="Bold" />
      </SvgIcon>
    ),
  },
  {
    title: "رمز Authenticator",
    subtitle:
      "رمزی که google Authenticator ساخته است و پیامک احراز هویت را وارد کنید.",
    icon: (
      <SvgIcon
        sx={{
          mx: "0.25rem",
          fontSize: "2rem",
          color: (theme) => theme.palette.success[700],
        }}
      >
        <Key variant="Bold" />
      </SvgIcon>
    ),
  },
  {
    title: "ثبت کد",
    subtitle: "کدی که پیامک شده را وارد کنید.",
    icon: (
      <SvgIcon
        sx={{
          mx: "0.25rem",
          fontSize: "2rem",
          color: "#4D35D7",
        }}
      >
        <Mobile variant="Bold" />
      </SvgIcon>
    ),
  },
];

interface G2VStep1Props {
  onSuccess: (data: ConfirmOtpGoogleVerificationRes) => void;
}

export const G2VStep1 = ({ onSuccess }: G2VStep1Props) => {
  const isMdUp = useBreakpoint("md");

  const [isConfirmRoles, setIsConfirmRoles] = useState(false);
  const [isOpenRolesModal, setIsOpenRolesModal] = useState(false);
  const [isCodeVerificationModal, setIsCodeVerificationModal] = useState(false);

  const handleRequestForGoogleVerification = useRequestForGoogleVerification();
  const handleConfirmOtpGoogleVerification = useConfirmOtpGoogleVerification();

  return (
    <>
      {isCodeVerificationModal && (
        <CodeVerificationModal
          onSubmit={(otp) => {
            handleConfirmOtpGoogleVerification.mutate(otp, {
              onSuccess: (data) => {
                setIsCodeVerificationModal(false);
                onSuccess(data?.result);
              },
            });
          }}
          loading={handleConfirmOtpGoogleVerification?.isPending}
          onClose={() => setIsCodeVerificationModal(false)}
        />
      )}

      <Modal
        open={isOpenRolesModal}
        title="شرایط و قوانین کد دوعاملی"
        onClose={() => setIsOpenRolesModal(false)}
      >
        <Typography maxWidth="700px" variant="Body" color="colorfulGray.700">
          لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با
          استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در
          ستون و سطرآنچنان که لازم است، و برای شرایط فعلی تکنولوژی مورد نیاز، و
          کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد، کتابهای زیادی
          در شصت و سه درصد گذشته حال و آینده، شناخت فراوان جامعه و متخصصان را می
          طلبد، تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی
          الخصوص طراحان خلاقی، و فرهنگ پیشرو در زبان فارسی ایجاد کرد، در این
          صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها، و
          شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای
          اصلی، و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده
          قرار گیرد.
        </Typography>
      </Modal>

      <Stack
        bgcolor="white"
        sx={{ p: { xs: "1.5rem", md: "3rem" } }}
        borderRadius="0.625rem"
        gap="1.5rem"
        height="100%"
      >
        {isMdUp && (
          <Typography color="colorfulGray.800" variant="TitleBold">
            کد ورود دوعاملی
          </Typography>
        )}

        <Stack
          sx={{ px: { md: "1.5rem" } }}
          gap="2rem"
          flex={1}
          justifyContent="space-between"
        >
          <Stack component="ul">
            {listSteps?.map((item) => (
              <Stack
                component="li"
                alignItems="flex-start"
                sx={{
                  "&:not(:last-child)::after": {
                    content: '" "',
                    height: "1.5rem",
                    borderRight: 2,
                    borderColor: "colorfulGray.A100",
                    pl: "1.2rem",
                    my: { xs: "0.75rem", md: "1rem" },
                  },
                }}
              >
                <Stack direction="row" gap="0.75rem" alignItems="center">
                  {item?.icon}
                  <Stack>
                    <Typography
                      variant="BodyMedium"
                      smVariant="CaptionMedium"
                      color="colorfulGray.700"
                    >
                      {item?.title}
                    </Typography>
                    <Typography
                      variant="Body"
                      smVariant="Caption"
                      color="colorfulGray.500"
                    >
                      {item?.subtitle}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            ))}
          </Stack>

          <Stack gap="1.5rem">
            <Checkbox
              formControlProps={{
                label: (
                  <Typography variant="BodyBold" color="colorfulGray.700">
                    <Typography
                      onClick={() => setIsOpenRolesModal(true)}
                      component="span"
                      px="0.25rem"
                      display="inline-flex"
                      color="info.600"
                    >
                      شرایط و قوانین
                    </Typography>
                    کد دو عاملی را می دانم.
                  </Typography>
                ),
                onChange: (_, checked) => setIsConfirmRoles(checked),
              }}
              isOutline
            />

            <Button
              loading={handleRequestForGoogleVerification.isPending}
              onClick={() =>
                handleRequestForGoogleVerification.mutate(undefined, {
                  onSuccess: () => setIsCodeVerificationModal(true),
                })
              }
              disabled={!isConfirmRoles}
            >
              <Typography variant="BodyBold" color="white">
                فعالسازی کد دو‌عاملی
              </Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
