import { useBreakpoint } from "@hooks/useBreakpoint";
import { useUserStore } from "@store/user";
import { detectEnv } from "@utils/common";
import { useEffect } from "react";

declare global {
  interface Window {
    Goftino: any;
  }
}

export const useGoftino = () => {
  const isMdUp = useBreakpoint("md");
  const user = useUserStore((state) => state.user);
  useEffect(() => {
    try {
      const goftinoReadyHandler = () => {
        window.Goftino?.setUser({
          email: user?.email || "",
          name:
            user?.firstName && user?.lastName
              ? `${user?.firstName} ${user?.lastName}`
              : "",
          phone: user?.mobileNumber,
          forceUpdate: true,
        });
        if (!isMdUp || detectEnv() === "WebView") {
          window?.Goftino?.setWidget({
            iconUrl: "",
            hasIcon: false,
            counter: "#SUPPORT_ICON_GOFTINO",
          });
          // window?.Goftino?.close();
        }
      };

      if (user) {
        if (!isMdUp) {
          window?.Goftino?.setWidget({
            iconUrl: "",
            hasIcon: false,
            counter: "#SUPPORT_ICON_GOFTINO",
          });
          // window?.Goftino?.close();
        }
      }

      window.addEventListener("goftino_ready", goftinoReadyHandler);

      return () => {
        window.removeEventListener("goftino_ready", goftinoReadyHandler);
      };
    } catch (error) {
      console.log("GoftinoService:", error);
    }
  }, [user, isMdUp]);
};
