import { Backdrop, Stack } from "@mui/material";
import { createPortal } from "react-dom";
import type { FallbackProps } from "react-error-boundary";

export const ErrorFallback = ({ error }: FallbackProps) => {
  return createPortal(
    <Backdrop sx={{ zIndex: 9999, color: "#fff" }} open>
      <Stack>
        <p className="fw-[bold] pb-[0.5rem]">یک خطایی رخ داده.</p>
        <pre>{error.message}</pre>
        <pre>{error.stack}</pre>
      </Stack>
    </Backdrop>,
    document.body
  );
};
