import { Slider, ThemeProvider, useTheme } from "@mui/material";
import { OtcMethod } from "../../_types";
import { useFormContext } from "react-hook-form";
import { useEffect, useState } from "react";
import { toast } from "@utils/toast";
import { numUtils } from "@utils/num-helpers";

interface OtcSliderProps {
  method: OtcMethod;
  onChange: (percent: number) => void;
  disabled?: boolean;
  balance: number;
}

const marks = [
  {
    value: 0,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
];

export const OtcSlider = ({
  method,
  onChange,
  disabled,
  balance,
}: OtcSliderProps) => {
  const [val, setVal] = useState(0);

  const theme = useTheme();
  const { watch, setValue } = useFormContext();

  useEffect(() => {
    const payment = +numUtils.commaformatter.toRaw(watch("payment") || "");
    if (!!payment && balance !== 0) {
      const percent = (+payment * 100) / balance;

      setVal(+percent.toFixed(0));
    }
  }, [watch("payment")]);

  useEffect(() => {
    setVal(0);
  }, [method, balance]);

  return (
    <ThemeProvider theme={{ ...theme, direction: "ltr" }}>
      <Slider
        disabled={disabled}
        color={method === OtcMethod.Buy ? "success" : "error"}
        valueLabelDisplay="auto"
        valueLabelFormat={(val) => `${val}%`}
        getAriaLabel={(val) => `${val}%`}
        step={-1}
        marks={marks}
        onChangeCommitted={(e, val) => {
          if (balance === 0) {
            toast.error("لطفا موجودی پرداختی خود را شارژ کنید.");
            return setVal(0);
          }
        }}
        onChange={(e, val) => {
          setVal(val as number);

          if (balance === 0) {
            setValue("payment", "");
            setValue("receive", "");
            return;
          }

          typeof val === "number" && onChange(val);
        }}
        value={val}
        min={0}
        max={100}
        sx={{
          py: "0.75rem",
          my: "0.75rem",
          filter: disabled ? "grayscale(1)" : "grayscale(0)",
          ".MuiSlider-thumb": {
            border: "2px solid",
            mr: "-20px",
            borderColor: method === OtcMethod.Buy ? "success.700" : "error.500",
            backgroundColor: "neutral.50",
            height: "1.5rem",
            width: "1.5rem",
            "&::before": {
              width: "70%",
              height: "70%",
              backgroundColor:
                method === OtcMethod.Buy ? "success.700" : "error.500",
            },
          },
          ".MuiSlider-mark": {
            backgroundColor: "neutral.200",
            height: "0.5rem",
            width: "0.5rem",
            borderRadius: "50%",
            opacity: 1,
            "&.MuiSlider-markActive": {
              backgroundColor:
                method === OtcMethod.Buy ? "success.700" : "error.500",
            },
          },
          ".MuiSlider-track": {
            height: "0.15rem",
          },
          ".MuiSlider-rail": {
            backgroundColor: "neutral.200",
          },

          ".MuiSlider-valueLabel": {
            py: 0,
            pt: "2px",
            px: "0.5rem",
            backgroundColor:
              method === OtcMethod.Buy ? "success.700" : "error.500",
            color: "neutral.50",
            fontSize: "0.75rem",
            borderRadius: "0.25rem",
          },
        }}
      />
    </ThemeProvider>
  );
};
