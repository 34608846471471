import { Button } from "@components/common/button";
import Hint from "@components/common/hint";
import { Typography } from "@components/common/typography";
import { SecurityUser } from "iconsax-react";
import { BaseCoin } from "~types/index";

interface NeedAuthorizeProps {
  baseOn: BaseCoin;
}

export const NeedAuthorize = ({ baseOn }: NeedAuthorizeProps) => {
  return (
    <Hint
      severity="warning"
      IconComponent={SecurityUser}
      content={
        <Typography variant="Body" color="warning.900">
          برای واریز یا برداشت {baseOn === BaseCoin.TOMAN ? "تومان" : "ارز"} ، نیاز به تکمیل احراز هویت خود دارید.
        </Typography>
      }
      action={
        <Button
          href="/v2/dashboard/authentication"
          bgColor="white"
          textColor="primary.700"
          sx={{ border: 1.5, borderColor: "primary.700" }}
          startIcon={<SecurityUser size="1.25rem" />}
        >
          <Typography variant="BodyMedium">احراز هویت</Typography>
        </Button>
      }
    />
  );
};
