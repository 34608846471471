import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack } from "@mui/material";
import { LoginHistoryCard } from "./_components/login-history-card";
import { Typography } from "@components/common/typography";
import { useGetMyLoginHistory } from "@services/queries/settings/queries";
import { useMemo } from "react";
import { InfiniteScroll } from "@components/common/infinite-scroll";
import { IconTypography } from "@components/common/icon-typography";
import { Image } from "@components/common/image";

export const LoginHistory = () => {
  const isMdUp = useBreakpoint("md");

  const getLoginHistory = useGetMyLoginHistory({});

  const loginHistoryList = useMemo(
    () => getLoginHistory?.data?.pages?.flatMap((e) => e?.items) || [],
    [getLoginHistory?.isFetchingNextPage, getLoginHistory?.isFetching]
  );

  return (
    <Wrapper
      sxContainer={{
        pt: { md: "2rem" },
        mb: { md: "2rem" },
        flex: 1,
        bgcolor: { xs: "white", md: "transparent" },
      }}
      isFullWidth={!isMdUp}
      isScreenHeight
      title="تاریخچه ورود"
      backMode
      loading={getLoginHistory?.isLoading}
    >
      <Stack bgcolor="white" borderRadius="0.625rem">
        {isMdUp && (
          <Typography
            pt="1rem"
            px="1.5rem"
            color="colorfulGray.800"
            variant="TitleBold"
          >
            تاریخچه ورود
          </Typography>
        )}
        <Stack component="ul">
          {!!loginHistoryList?.length ? (
            <InfiniteScroll
              fetchMore={async () => {
                !getLoginHistory?.isFetchingNextPage &&
                getLoginHistory?.hasNextPage
                  ? await getLoginHistory.fetchNextPage()
                  : await 0;
              }}
            >
              <Stack component="ul" sx={{ mt: { md: "1rem" } }}>
                {loginHistoryList?.map((item) => (
                  <LoginHistoryCard data={item} />
                ))}
              </Stack>
            </InfiniteScroll>
          ) : (
            <Stack py="5rem">
              <IconTypography
                icon={
                  <Image
                    alt="empty-img"
                    src="/v2/images/empty.png"
                    height="72px"
                    width="72px"
                  />
                }
                title="هیچ ورودی یافت نشد."
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Wrapper>
  );
};
