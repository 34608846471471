import { QuestionCircleIcon } from "@assets/icons/QuestionCircle";
import Container from "@components/common/container";
import { Typography } from "@components/common/typography";
import { Button } from "@components/common/button";
import { Divider, Stack } from "@mui/material";
import { TomanIcon } from "@assets/icons/TomanIcon";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { TickSquare, LampCharge } from "iconsax-react";
import Hint from "@components/common/hint";
import { createPortal } from "react-dom";
import { Image } from "@components/common/image";
import { numUtils } from "@utils/num-helpers";
import { useState } from "react";
import { HandlePreviewTomanWithdrawRes } from "@services/queries/wallet/types";
import { bankInfo } from "@utils/common";
import { WithdrawCycles } from "./_components/withdraw-cycles";
import { useHandleApproveWithdrawToman } from "@services/queries/wallet/mutations";
import { useUserStore } from "@store/user";
import { SuccessWithdrawModal } from "../../_pages/toman/_pages/withdraw/_components/success-withdraw-modal";
import { GoogleTwoStepVerificationModal } from "@components/shared/google-two-step-verification-modal";

interface ApproveWithdrawPortalProps {
  onClose: () => void;
  data: HandlePreviewTomanWithdrawRes;
  bankSheba: string;
  ibanId: string;
}

export const ApproveWithdrawPortal = ({
  onClose,
  data,
  bankSheba,
  ibanId,
}: ApproveWithdrawPortalProps) => {
  const isMdUp = useBreakpoint("md");

  const user = useUserStore((state) => state?.user);

  const handleApproveWithdrawTomanMutation = useHandleApproveWithdrawToman();
  // const handleApproveWithdrawMutation = useHandleApproveWithdraw();

  const [isGoogleTwoStep, setIsGoogleTwoStep] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const handleVerifyWithdraw = (authenticatorCode: string) => {
    handleApproveWithdrawTomanMutation.mutate(
      {
        id: data?.reqId,
        authenticatorCode,
      },
      {
        onSuccess: () => setIsSuccessModalOpen(true),
      }
    );
    //  handleApproveWithdrawMutation.mutate(
    //     {
    //       amount: data?.fee + data?.totalAmount,
    //       authenticatorCode,
    //       currency: "IRT",
    //       network: "BANK",
    //       walletAddress: ibanId,
    //     },
    //     {
    //       onSuccess: (data) => setIsSuccessModalOpen(true),
    //     }
    //   );
  };

  return createPortal(
    <Stack
      bgcolor="colorfulGray.A100"
      position="fixed"
      height="100dvh"
      width="100vw"
      sx={{ inset: 0 }}
      overflow="auto"
      zIndex={10}
    >
      <Wrapper
        hideBottomNavigation
        title="برداشت پول"
        isNoPaddingModeContainer
        backMode
        onClickBackArrow={()=> onClose()}
      >
        <Stack
          sx={{
            p: "1.25rem",
            my: { md: "2rem" },
            flex: { xs: 1, md: 0 },
            bgcolor: "white",
            borderRadius: { md: "1.125rem" },
            display: "flex",
          }}
        >
          <Stack
            width="100%"
            sx={{
              pb: { xs: "1.5rem", md: 0 },
              gap: { xs: "0.75rem", md: "2rem" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Stack flex={1} gap="0.5rem">
              {/* Item */}
              <Stack direction="row" justifyContent="space-between" py="0.5rem">
                <Typography variant="ExtendedBody" color="colorfulGray.600">
                  شبا
                </Typography>
                <Stack direction="row" gap="0.5rem" alignItems="center">
                  <Typography
                    variant="ExtendedBodyMedium"
                    color="colorfulGray.700"
                  >
                    {bankSheba}
                  </Typography>
                  {bankInfo(bankSheba, "Sheba")?.logo && (
                    <Image
                      width="1.25rem"
                      height="1.25rem"
                      alt="لوگو بانک"
                      src={`/v2/images/banks-logo/${
                        bankInfo(bankSheba, "Sheba")?.logo
                      }`}
                    />
                  )}
                </Stack>
              </Stack>

              {/* Item */}
              <Stack direction="row" justifyContent="space-between" py="0.5rem">
                <Typography variant="ExtendedBody" color="colorfulGray.600">
                  صاحب حساب
                </Typography>
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.700"
                >
                  {user?.firstName} {user?.lastName}
                </Typography>
              </Stack>

              {/* Item */}
              <Stack direction="row" justifyContent="space-between" py="0.5rem">
                <Typography variant="ExtendedBody" color="colorfulGray.600">
                  مبلغ برداشت
                </Typography>
                <Stack direction="row" gap="0.5rem" alignItems="center">
                  <Typography
                    variant="ExtendedBodyMedium"
                    color="colorfulGray.700"
                  >
                    {numUtils.commaformatter.toCurrency(data?.totalAmount)}
                  </Typography>
                  <TomanIcon
                    sx={{
                      fontSize: { xs: "0.75rem", md: "1rem" },
                      fill: (theme) => theme.palette.colorfulGray[500],
                    }}
                  />
                </Stack>
              </Stack>

              {/* Item */}
              <Stack py="0.5rem">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="ExtendedBody" color="colorfulGray.600">
                    کارمزد عملیات بانکی
                  </Typography>
                  <Stack direction="row" gap="0.5rem" alignItems="center">
                    <Typography
                      variant="ExtendedBodyMedium"
                      color="colorfulGray.700"
                    >
                      {numUtils.commaformatter.toCurrency(data?.fee)}
                    </Typography>
                    <TomanIcon
                      sx={{
                        fontSize: { xs: "0.75rem", md: "1rem" },
                        fill: (theme) => theme.palette.colorfulGray[500],
                      }}
                    />
                  </Stack>
                </Stack>
                <Typography variant="Body" color="colorfulGray.500">
                  کارمزد بابت عملیات بانکی است و درآمدی برای بالینکس ندارد
                </Typography>
              </Stack>

              {/* Item */}
              <Stack direction="row" justifyContent="space-between" py="0.5rem">
                <Typography variant="ExtendedBody" color="colorfulGray.600">
                  خالص دریافتی
                </Typography>
                <Stack direction="row" gap="0.5rem" alignItems="center">
                  <Typography
                    variant="ExtendedBodyMedium"
                    color="colorfulGray.700"
                  >
                    {numUtils.commaformatter.toCurrency(data?.receivedAmount)}
                  </Typography>
                  <TomanIcon
                    sx={{
                      fontSize: { xs: "0.75rem", md: "1rem" },
                      fill: (theme) => theme.palette.colorfulGray[500],
                    }}
                  />
                </Stack>
              </Stack>

              <Divider sx={{ borderStyle: "dashed", my: "1.5rem" }} />

              <Typography variant="ExtendedBodyBold" color="colorfulGray.700">
                فاکتور واریز
              </Typography>
              <Typography variant="ExtendedBody" color="info.600">
                مبلغ ۳ سیکل پایای دیگر به حساب شما واریز می‌شود
              </Typography>

              <WithdrawCycles data={data?.transfers} />

              {!!isMdUp && (
                <Button
                  onClick={() => setIsGoogleTwoStep(true)}
                  sx={{ mt: "1.5rem" }}
                  variant="contained"
                  startIcon={<TickSquare size="1.25rem" />}
                >
                  <Typography variant="BodyBold">تایید و برداشت</Typography>
                </Button>
              )}
            </Stack>
            <Stack flex={1} gap="0.75rem">
              <Hint
                severity="info"
                IconComponent={LampCharge}
                content={
                  <Stack gap="0.5rem">
                    <Typography variant="Body" color="info.700">
                      به علت محدودیت در مبلغ واریز مبلغ درخواستی‌تان را در چند
                      فاکتور 50 میلیون تومانی برایتان واریز می‌کنیم
                    </Typography>
                    <Stack>
                      <Typography variant="CaptionMedium" color="info.700">
                        سیکل‌های پایا (روزهای غیر تعطیل)
                      </Typography>
                      <Typography variant="Body" color="info.700">
                        ثبت قبل از ۱۳ ظهر-----۱۳:۴۵ همان روز
                      </Typography>
                      <Typography variant="Body" color="info.700">
                        ثبت‌ قبل از ۱۸:۰۰ عصر----- ۱۸:۴۵ همان روز
                      </Typography>
                      <Typography variant="Body" color="info.700">
                        ثبت بعد از ۱۸:۰۰ عصر--- ۱۳:۴۵ روز کاری بعد
                      </Typography>
                    </Stack>

                    <Stack mt="1.5rem">
                      <Typography variant="CaptionMedium" color="info.700">
                        سیکل‌های پایا (روزهای تعطیل)
                      </Typography>
                      <Typography variant="Body" color="info.700">
                        ساعت ۱۳:۴۵ روز کاری بعد
                      </Typography>
                    </Stack>
                  </Stack>
                }
              />

              {!isMdUp && (
                <Stack
                  position="fixed"
                  p="0.75rem"
                  width="100%"
                  bottom={0}
                  left={0}
                  bgcolor="white"
                >
                  <Button
                    size="large"
                    onClick={() => setIsGoogleTwoStep(true)}
                    variant="contained"
                    startIcon={<TickSquare size="1.25rem" />}
                  >
                    <Typography variant="BodyBold">تایید و برداشت</Typography>
                  </Button>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Wrapper>

      {isGoogleTwoStep && (
        <GoogleTwoStepVerificationModal
          onClose={() => setIsGoogleTwoStep(false)}
          onSubmit={(authenticatorCode) =>
            handleVerifyWithdraw(authenticatorCode)
          }
          loading={handleApproveWithdrawTomanMutation?.isPending}
        />
      )}

      <SuccessWithdrawModal isOpen={isSuccessModalOpen} onClose={onClose} />
    </Stack>,
    document.body
  );
};
