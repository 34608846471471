import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { Box, Divider, Grid, Hidden, Paper, Stack } from "@mui/material";
import { getCoinIconURL } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { useState } from "react";
import useCurrencyUpdate from "../../_hooks/useCurrencyUpdate";
import useCurrentCurrency from "../../_hooks/useCurrentCurrency";
import Wealth from "../wealth";

const Header = () => {
  const { commaformatter } = numUtils;

  const currentCurrency = useCurrentCurrency();

  const [isHover, setIsHover] = useState(false);

  const isMdUp = useBreakpoint("lg");

  const headerData = useCurrencyUpdate();

  // console.log(headerData, "header");

  const isSellSide = headerData?.side === "sell";

  return (
    <>
      <Paper
        sx={{
          height: { xs: 122, sm: 122, md: 72 },
          mt: { sx: 0, md: 2 },
          mb: 2,
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
        elevation={0}
      >
        <Hidden mdDown>
          <Stack
            height={"100%"}
            // width={"20%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={1}
            sx={{
              gap: { lg: 4, md: 2 },
            }}
            onMouseEnter={() => {
              !isMdUp && setIsHover(true);
            }}
            onMouseLeave={() => {
              !isMdUp && setIsHover(false);
            }}
          >
            <Box display={"flex"} justifyContent={"center"}>
              <Box
                component={"img"}
                alt="currency_logo"
                width={32}
                height={32}
                src={getCoinIconURL(currentCurrency?.baseCurrency)}
                ml={1.5}
              />
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Typography noWrap>{currentCurrency?.localizedTitle}</Typography>
              <Typography noWrap>{currentCurrency?.title}</Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"center"}
              gap={1}
              sx={{
                cursor: isHover && !isMdUp ? "pointer" : "default",
              }}
            >
              <Typography variant="caption" color={"primary.500"} noWrap>
                قیمت {currentCurrency?.localizedTitle}
              </Typography>
              <KeyboardArrowLeftIcon
                sx={{
                  color: "primary.500",
                }}
              />
              {isHover && (
                <Grid
                  sx={{
                    position: "absolute",
                    top: 150,
                    left: 25,
                    width: "33%",
                    zIndex: 5,
                  }}
                >
                  <Wealth elevation={2} />
                </Grid>
              )}
            </Box>
          </Stack>
          <Divider orientation="vertical" />
          <Stack
            height={"100%"}
            width={"70%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={8}
            px={1.5}
            sx={{
              overflowX: "auto",
            }}
            py={0.5}
          >
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
            >
              <Typography variant="caption" noWrap>
                قیمت {currentCurrency?.localizedTitle}
              </Typography>
              <Box display={"flex"} gap={1}>
                <Typography
                  variant="body1"
                  noWrap
                  color={isSellSide ? "error.500" : "success.700"}
                >
                  {currentCurrency?.quoteCurrency}
                </Typography>
                <Typography
                  variant="body1"
                  noWrap
                  fontWeight={"bold"}
                  color={isSellSide ? "error.500" : "success.700"}
                >
                  {commaformatter.toCurrency(Number(headerData?.price))}
                </Typography>
              </Box>
            </Box>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
            >
              <Typography variant="caption" noWrap>
                تغییرات 24 ساعته
              </Typography>
              <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  color={isSellSide ? "error.500" : "success.700"}
                  noWrap
                >
                  {commaformatter.toCurrency(headerData?.open24h)}%
                </Typography>
                {isSellSide ? (
                  <ArrowDropDown color="error" />
                ) : (
                  <ArrowDropUpIcon color="success" />
                )}
              </Box>
            </Box>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
            >
              <Typography variant="caption" noWrap>
                بیشترین قیمت روزانه
              </Typography>
              <Typography variant="body1" noWrap>
                {commaformatter.toCurrency(Number(headerData?.open24h))}
              </Typography>
            </Box>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
            >
              <Typography variant="caption" noWrap>
                کم‌ترین قیمت روزانه
              </Typography>
              <Typography variant="body1" noWrap>
                {commaformatter.toCurrency(Number(headerData?.low24h))}
              </Typography>
            </Box>
            <Box
              width={"100%"}
              height={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-around"}
            >
              <Typography variant="caption" noWrap>
                حجم معاملات روزانه
              </Typography>
              <Typography variant="body1" noWrap>
                {commaformatter.toCurrency(Number(headerData?.volume24h))}
              </Typography>
            </Box>
          </Stack>
        </Hidden>

        <Hidden mdUp>
          <Stack
            width={"100%"}
            height={"100%"}
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            p={1}
          >
            <Box display={"flex"} flexDirection={"column"} gap={1}>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography variant="body2" color="neutral.500">
                  حجم معامله‌ها
                </Typography>
                <Typography variant="body2" color="neutral.600">
                  {commaformatter.toCurrency(
                    Number(currentCurrency?.yesterdayVolume)
                  )}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography variant="body2" color="neutral.500">
                  پایین‌ترین قیمت
                </Typography>
                <Typography variant="body2" color="neutral.600">
                  {commaformatter.toCurrency(
                    Number(currentCurrency?.yesterdayLowPrice)
                  )}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography variant="body2" color="neutral.500">
                  بالاترین قیمت
                </Typography>
                <Typography variant="body2" color="neutral.600">
                  {commaformatter.toCurrency(
                    Number(currentCurrency?.yesterdayHighPrice)
                  )}
                </Typography>
              </Box>
            </Box>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={1}
              alignItems={"flex-end"}
            >
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography
                  variant="body2"
                  color={isSellSide ? "error.500" : "success.700"}
                >
                  {commaformatter.toCurrency(Number(headerData?.price))}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography variant="body2" color="neutral.500">
                  {currentCurrency?.baseCurrency}
                </Typography>
                <Typography variant="body2" color="neutral.500">
                  {commaformatter.toCurrency(
                    Number(currentCurrency?.todayPrice)
                  )}
                </Typography>
              </Box>
              <Box display={"flex"} flexDirection={"row"} gap={1}>
                <Typography
                  variant="caption"
                  color={isSellSide ? "error.600" : "success.700"}
                  bgcolor={isSellSide ? "error.100" : "success.100"}
                  p={"2px"}
                  borderRadius={1}
                  px={2}
                >
                  {commaformatter.toCurrency(headerData?.open24h)}%
                </Typography>
              </Box>
            </Box>
          </Stack>
        </Hidden>
      </Paper>
    </>
  );
};

export default Header;
