import { TabItem } from "@components/common/tabs/types";
import { Grid, Paper } from "@mui/material";
import RecentTrades from "../trade/_components/recent_trade";
import MediumOrderBook from "./md-order-book";
import Tabs from "@components/common/tabs";

const ShowTabs = () => {
  const tabs: TabItem[] = [
    {
      label: "دفتر سفارشات",
      content: <MediumOrderBook height={"394px"} />,
    },
    { label: "معاملات اخیر", content: <RecentTrades height={"344px"} /> },
  ];
  return (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        // sx={{ mt: 2 }}
      >
        <Tabs tabs={tabs} variant="standard" />
      </Paper>
    </Grid>
  );
};

export default ShowTabs;
