import { z } from "zod";

export const TomanDepositSchema = z.object({
  amount: z.string().min(1, "این فیلد اجباری است."),
});

export const AddBankAccount = z.object({
  account_num: z.string().min(1, "این فیلد اجباری است."),
});

export const TomanWithdrawSchema = z.object({
  amount: z.string().min(1, "این فیلد اجباری است."),
  bank: z.string().min(1, "این فیلد اجباری است."),
  iban_id: z.string().min(1, "این فیلد اجباری است."),
});

export const CoinWithdrawSchema = z.object({
  amount: z.string().min(1, "این فیلد اجباری است."),
  address: z.string().min(1, "این فیلد اجباری است."),
  memoCode: z.string().optional(),
});
