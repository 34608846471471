import { useBreakpoint } from "@hooks/useBreakpoint";
import {
  Typography as MuiTypography,
  TypographyProps as MuiTypographyProps,
} from "@mui/material";

interface TypographyProps extends MuiTypographyProps {
  smVariant?: MuiTypographyProps["variant"];
}

export const Typography = ({
  children,
  sx,
  color = "primary.800",
  variant,
  smVariant,
  ...otherProps
}: TypographyProps) => {
  const isMdUp = useBreakpoint("md");

  return (
    <MuiTypography
      variant={isMdUp ? variant : smVariant || variant}
      {...otherProps}
      color={color}
      sx={{ m: 0, ...sx }}
    >
      {children}
    </MuiTypography>
  );
};
