import { Button } from "@components/common/button";
import { Checkbox } from "@components/common/checkbox";
import { DropDown } from "@components/common/dropdown";
import Hint from "@components/common/hint";
import { IconTypography } from "@components/common/icon-typography";
import { Image } from "@components/common/image";
import { SearchBox } from "@components/common/search-box";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { transferTunnelInfo } from "@constants/index";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  useTheme,
} from "@mui/material";
import { CodeVerificationModal } from "@pages/dashborad/wallet/_components/code-verification-modal";
import { NeedAuthorize } from "@pages/dashborad/wallet/_components/need-authorize";
import { CoinWithdrawSchema } from "@pages/dashborad/wallet/_validations";
import {
  useHandleApproveWithdraw,
  useHandlePreviewWithdraw,
  useHandleVerifyWithdraw,
} from "@services/queries/wallet/mutations";
import {
  useGetDailyMonthlyWithdraw,
  useGetMyWallet,
  useGetSpecificWalletItem,
} from "@services/queries/wallet/queries";
import {
  HandleVerifyWithdrawRes,
  WalletNetworkItem,
} from "@services/queries/wallet/types";
import { useUserStore } from "@store/user";
import { getCoinIconURL } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { toast } from "@utils/toast";
import {
  ArrowRight,
  Coin,
  Information,
  Key,
  LampCharge,
  Money4,
  Warning2,
} from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { BaseCoin } from "~types/index";
import { MaxWithdrawalItem } from "../../../toman/_pages/withdraw/_components/max-withdrawal";
import { ResultWithdrawCoinModal } from "./_components/result-withdraw-coin-modal";
import { LastCoinWithdrawTransaction } from "./_components/result-withdraw-coin-modal/last-coin-withdraw-transaction";
import { GoogleTwoStepVerificationModal } from "@components/shared/google-two-step-verification-modal";

export default function CoinWithdraw() {
  const isMdUp = useBreakpoint("md");
  const navigate = useNavigate();
  const theme = useTheme();
  const { coinId } = useParams();

  const user = useUserStore((state) => state?.user);

  // prettier-ignore
  const getSpecificWalletItemQuery = useGetSpecificWalletItem({coinId: coinId || ""});
  const getAllWalletItemQuery = useGetMyWallet({ perPage: 200 });
  const getDailyMonthlyWithdrawQuery = useGetDailyMonthlyWithdraw({
    currencyId: coinId,
    enabled: !!coinId,
  });
  const handlePreviewWithdrawMutation = useHandlePreviewWithdraw();
  const handleApproveWithdraw = useHandleApproveWithdraw();
  const handleVerifyWithdraw = useHandleVerifyWithdraw();

  // prettier-ignore
  const [isGoogleTwoStepVerification, setIsGoogleTwoStepVerification] = useState(false);
  const [isCodeVerification, setIsCodeVerification] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] =
    useState<HandleVerifyWithdrawRes | null>(null);
  const [search, setSearch] = useState("");
  const [tunnel, setTunnel] = useState<WalletNetworkItem | null>(null);
  const [isIgnoreMemoCode, setIsIgnoreMemoCode] = useState(false);

  const {
    register,
    watch,
    setValue,
    getValues,
    reset,
    formState: { isValid, errors },
  } = useForm<z.output<typeof CoinWithdrawSchema>>({
    mode: "onTouched",
    resolver: zodResolver(CoinWithdrawSchema),
  });

  const currentCoin = useMemo(
    () => getSpecificWalletItemQuery?.data?.result,
    [getSpecificWalletItemQuery?.isFetching]
  );

  const isNeededAuthorize = useMemo(() => {
    return !(
      (!!user?.mobileVerification && !user?.IDverification) ||
      (!!user?.IDverification &&
        !!user?.mobileVerification &&
        !!user?.personalAttachmentsVerification)
    );
  }, [user]);

  const isWithdrawEnabled = useMemo(
    () => !(tunnel && tunnel?.withrawEnable !== true),
    [tunnel]
  );

  // If currency is IRT, redirect to IRT withdraw page
  useEffect(() => {
    setIsGoogleTwoStepVerification(false);
    setIsResultModalOpen(null);
    setIsCodeVerification(false);
    if (currentCoin?.currency === "IRT")
      return navigate("/v2/dashboard/wallet/toman/withdraw", { replace: true });

    setTunnel(null);
  }, [getSpecificWalletItemQuery?.isFetching]);

  const isWithdrawButtonDisabled = useMemo(() => {
    // prettier-ignore
    if(currentCoin){
      const amount = Number(watch("amount"));
      const perCoinEstimateInToman = (currentCoin?.balanceEstimate.IRT.total / currentCoin?.balanceTotal) * ((amount) || 0)

      const minAmount = Number(tunnel?.minWithdrawalSize || 0);
      const maxAmount = Number(tunnel?.maxWithdrawalSize || 0);
      
      if (amount < minAmount) return true;
      if (amount > maxAmount) return true;
      if (amount > (currentCoin?.balanceAvailable || 0)) return true;
      
      const withdrawalLevel = getDailyMonthlyWithdrawQuery?.data?.result
      const isMoreThanDaily = ((Number(withdrawalLevel?.crypto24Houre) + Number(perCoinEstimateInToman)) * 100) / Number(withdrawalLevel?.cryptoDaily) > 100
      const isMoreThanMonthly = ((Number(withdrawalLevel?.crypto30Day) + Number(perCoinEstimateInToman)) * 100) / Number(withdrawalLevel?.cryptoMonthly) > 100
      
      if (isMoreThanDaily || isMoreThanMonthly) return true;
    }
    return false;
  }, [tunnel, watch("amount")]);

  const handleSubmit = () => {
    const amount = getValues("amount");

    if (!currentCoin) return toast.warning("ارز خود را انتخاب کنید.");

    const balance = currentCoin?.balanceAvailable;

    if (Number(amount) > (balance || 0)) {
      return toast.warning("موجودی شما کافی نیست");
    }

    if (!tunnel) return toast.warning("شبکه انتقال خود را وارد کنید.");

    if (
      tunnel?.memoSupport &&
      !getValues("memoCode")?.length &&
      !isIgnoreMemoCode
    )
      return toast.error(
        "این شبکه نیازمند ممو کد است. لطفا ممو کد را وارد کنید."
      );

    if (!!isIgnoreMemoCode) {
      setValue("memoCode", "");
    }

    handlePreviewWithdrawMutation.mutate(
      {
        amount: amount,
        currency: currentCoin?.currency,
        network: tunnel?.networkId,
        walletAddress: getValues("address"),
        memoCode: !!isIgnoreMemoCode ? "" : getValues("memoCode") || undefined,
      },
      { onSuccess: () => setIsGoogleTwoStepVerification(true) }
    );
  };

  const renderHelperText = () => {
    const amount = getValues("amount");
    // prettier-ignore
    if (Number(amount) !== 0 && tunnel && currentCoin) {
      const perCoinEstimateInToman = (currentCoin?.balanceEstimate.IRT.total / currentCoin?.balanceTotal) * (Number(amount) || 0)
      
      // Below of minimum withdrawal amount
      if (Number(amount) < Number(tunnel?.minWithdrawalSize)) {
        return `حداقل میزان برداشت، ${numUtils.commaformatter.toCurrency(tunnel?.minWithdrawalSize)} ${currentCoin?.prettyNameLocalized} است`
      }

      // Upper of maximum withdrawal amount
      if (Number(amount) > Number(tunnel?.maxWithdrawalSize)) {
        return `حداکثر میزان برداشت، ${numUtils.commaformatter.toCurrency(tunnel?.maxWithdrawalSize)} ${currentCoin?.prettyNameLocalized} است`
      }

      // Upper of balance
      if (Number(amount) > (currentCoin?.balanceAvailable || 0)) {
        return `موجودی شما کافی نیست`
      }

      const withdrawalLevel = getDailyMonthlyWithdrawQuery?.data?.result
      const isMoreThanDaily = ((Number(withdrawalLevel?.crypto24Houre) + Number(perCoinEstimateInToman)) * 100) / Number(withdrawalLevel?.cryptoDaily) > 100
      const isMoreThanMonthly = ((Number(withdrawalLevel?.crypto30Day) + Number(perCoinEstimateInToman)) * 100) / Number(withdrawalLevel?.cryptoMonthly) > 100

      // Upper of daily or monthly withdrawal limit
      if (isMoreThanDaily || isMoreThanMonthly) {
        return `شما از حد مجاز برداشت ${isMoreThanMonthly ? 'ماهانه' :'روزانه'} خود عبور کرده‌اید.`
      }
    }

    return <></>;
  };

  return (
    <Wrapper
      loading={
        getSpecificWalletItemQuery?.isFetching ||
        getAllWalletItemQuery?.isLoading
      }
      title="برداشت رمز ارز"
      onClickBackArrow={() => navigate("/v2/dashboard/wallet")}
      hideBottomNavigation
      isNoPaddingModeContainer
      sxContainer={{ pt: { md: "2rem" } }}
      backMode
    >
      {isGoogleTwoStepVerification && (
        <GoogleTwoStepVerificationModal
          onSubmit={(code) => {
            if (tunnel?.networkId && currentCoin?.currency)
              handleApproveWithdraw?.mutate(
                {
                  amount: +getValues("amount"),
                  authenticatorCode: code,
                  currency: currentCoin?.currency,
                  network: tunnel?.networkId,
                  walletAddress: getValues("address"),
                  memoCode: getValues("memoCode") || undefined,
                },
                { onSuccess: () => setIsCodeVerification(true) }
              );
          }}
          loading={handleApproveWithdraw?.isPending}
          onClose={() => setIsGoogleTwoStepVerification(false)}
        />
      )}

      {isResultModalOpen && currentCoin && tunnel && (
        <ResultWithdrawCoinModal
          fee={tunnel?.fee}
          data={{
            result: isResultModalOpen,
            coin: currentCoin,
          }}
          onClose={() => {
            setIsGoogleTwoStepVerification(false);
            setIsResultModalOpen(null);
            setIsCodeVerification(false);
            getSpecificWalletItemQuery?.refetch();
          }}
        />
      )}

      {isCodeVerification && (
        <CodeVerificationModal
          withdrawId={handleApproveWithdraw?.data?.result?.id || ""}
          onSubmit={(otp) => {
            handleVerifyWithdraw.mutate(
              {
                id: handleApproveWithdraw?.data?.result?.id || "",
                otp,
              },
              {
                onSuccess: (data) => {
                  setIsResultModalOpen(data?.result);
                  setIsGoogleTwoStepVerification(false);
                  setIsCodeVerification(false);
                },
              }
            );
          }}
          loading={handleVerifyWithdraw?.isPending}
          onClose={() => setIsCodeVerification(false)}
        />
      )}

      <Stack
        sx={{
          p: "1.5rem",
          pb: { xs: "3rem", md: "1.5rem" },
          flex: 1,
          minHeight: { xs: "calc(100dvh - 90px)", md: "auto" },
          bgcolor: "white",
          borderRadius: { md: "1.125rem" },
          display: "flex",
        }}
      >
        <Stack
          width="100%"
          sx={{
            gap: { xs: "0.75rem", md: "2rem" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Stack sx={{ flex: { md: 1 } }} justifyContent="space-between">
            {!isNeededAuthorize ? (
              !user?.twoFactorAuthEnabled ? (
                <Stack
                  sx={{ py: { md: "2rem" }, maxWidth: "500px", mx: "auto" }}
                >
                  <IconTypography
                    bgColor="colorfulGray.A100"
                    icon={
                      <Key variant="Bold" color={theme.palette.primary[400]} />
                    }
                    title={
                      <Typography variant="Body" smVariant="BodyMedium">
                        برای امنیت بیشتر در برداشت وجه نیاز به شناسه دو عاملی
                        دارید، قبل از عملیات برداشت شناسه دو عاملی را فعال کنید.
                      </Typography>
                    }
                  />

                  <Button
                    fullWidth
                    href="/v2/dashboard/settings/google-verification"
                    sx={{ mt: "1.5rem" }}
                    bgColor="primary.700"
                    startIcon={<Money4 size="1.5rem" />}
                  >
                    <Typography variant="BodyMedium">فعالسازی</Typography>
                  </Button>
                </Stack>
              ) : (
                <>
                  <Stack gap="1.5rem" pb="2rem">
                    <DropDown
                      titleModal="دارایی‌های شما"
                      placeholder="انتخاب رمز ارز"
                      label="از دارایی‌هایتان رمز ارزی را انتخاب کنید"
                      value={
                        currentCoin
                          ? `${currentCoin?.prettyNameLocalized}/(${currentCoin?.currency})`
                          : ""
                      }
                      InputProps={{
                        startAdornment: currentCoin && (
                          <InputAdornment position="start">
                            <Image
                              src={getCoinIconURL(currentCoin?.currency || "")}
                              width="1.5rem"
                              height="1.5rem"
                              alt={currentCoin?.currency || ""}
                            />
                          </InputAdornment>
                        ),
                      }}
                    >
                      <Stack gap="1.5rem">
                        <SearchBox
                          withDebounce
                          placeholder="جست‌وجو ..."
                          onDebounceTrigger={(search) =>
                            setSearch(search.toUpperCase())
                          }
                          onResetValueOnClick={() => setSearch("")}
                        />
                        {!!getAllWalletItemQuery?.data?.result?.items
                          ?.filter((e) => !!e?.pin || e?.balanceAvailable > 0)
                          ?.filter(
                            (e) =>
                              e?.currency?.search(search) !== -1 ||
                              e?.prettyNameLocalized?.search(search) !== -1 ||
                              e?.prettyName?.search(search) !== -1
                          )?.length ? (
                          <List
                            disablePadding
                            sx={{ maxHeight: "350px", overflow: "auto" }}
                          >
                            {getAllWalletItemQuery?.data?.result?.items
                              ?.filter(
                                (e) => !!e?.pin || e?.balanceAvailable > 0
                              )
                              ?.filter(
                                (e) =>
                                  e?.currency?.search(search) !== -1 ||
                                  e?.prettyNameLocalized?.search(search) !==
                                    -1 ||
                                  e?.prettyName?.search(search) !== -1
                              )
                              ?.map((item) => (
                                <ListItem
                                  key={`coin-${item?._id}`}
                                  disablePadding
                                  sx={{ mb: "0.5rem", gap: "1rem" }}
                                >
                                  <ListItemButton
                                    onClick={() => {
                                      navigate(
                                        `/v2/dashboard/wallet/coin/${item?.currency}/withdraw`
                                      );
                                      setTunnel(null);
                                      reset({
                                        address: "",
                                        amount: "",
                                        memoCode: "",
                                      });
                                    }}
                                    sx={{
                                      borderRadius: "0.5rem",
                                      "&:hover": { bgcolor: "primary.A100" },
                                    }}
                                  >
                                    <Image
                                      src={getCoinIconURL(item?.currency)}
                                      width="1.5rem"
                                      height="1.5rem"
                                      alt={item?.currency}
                                      sx={{ mr: "1rem" }}
                                    />
                                    <ListItemText
                                      primary={`${item?.prettyNameLocalized}/(${item?.currency})`}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              ))}
                          </List>
                        ) : (
                          <IconTypography
                            sx={{ py: "1.5rem" }}
                            bgColor="primary.A100"
                            title={
                              <Typography
                                variant="ExtendedBody"
                                color="colorfulGray.700"
                              >
                                در حال حاضر در بالینکس دارایی ندارید.
                              </Typography>
                            }
                            icon={
                              <Coin
                                size="3rem"
                                color={theme.palette.primary[50]}
                              />
                            }
                          />
                        )}
                      </Stack>
                    </DropDown>
                    {!!currentCoin &&
                      isWithdrawEnabled &&
                      !user?.isSuspended && (
                        <>
                          <DropDown
                            titleModal="انتخاب شبکه"
                            placeholder="برای مثال ERC20"
                            label="شبکه‌ی انتقال‌تان را انتخاب کنید"
                            helperText={
                              tunnel
                                ? `کارمزد ${tunnel?.fee} ${currentCoin?.prettyNameLocalized}`
                                : ""
                            }
                            value={tunnel?.networkId || ""}
                          >
                            <Stack gap="1.5rem">
                              <Typography
                                sx={{ display: { xs: "none", md: "block" } }}
                                variant="BodyBold"
                                color="colorfulGray.700"
                              >
                                انتخاب شبکه
                              </Typography>
                              <Stack gap="0.75rem">
                                {currentCoin?.wallets?.map((item) => (
                                  <Stack
                                    onClick={() => {
                                      reset({
                                        address: "",
                                        amount: "",
                                        memoCode: "",
                                      });
                                      setTunnel(item);
                                    }}
                                    sx={{
                                      cursor: "pointer",
                                      p: "0.75rem",
                                      borderRadius: "0.5rem",
                                      "&:hover": {
                                        bgcolor: theme.palette.primary.A100,
                                      },
                                    }}
                                    key={`tunnel-${item?.networkId}`}
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="space-between"
                                  >
                                    <Typography
                                      variant="TitleBold"
                                      color="colorfulGray.700"
                                    >
                                      {item?.networkId}
                                    </Typography>
                                    <Stack>
                                      <Typography
                                        variant="CaptionMedium"
                                        color="colorfulGray.600"
                                      >
                                        زمانی تقریبی واریز:{" "}
                                        {transferTunnelInfo?.find(
                                          (e) => e.label === item?.networkId
                                        )?.estimate_time || "نامشخص"}{" "}
                                        دقیقه
                                      </Typography>
                                      <Typography
                                        variant="Caption"
                                        color="colorfulGray.400"
                                      >
                                        نیاز به تایید{" "}
                                        {transferTunnelInfo?.find(
                                          (e) => e.label === item?.networkId
                                        )?.blocks || "نامشخص"}{" "}
                                        بلاک شبکه
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                ))}
                              </Stack>
                            </Stack>
                          </DropDown>
                          {tunnel && (
                            <Stack gap="0.75rem">
                              <TextField
                                {...register("address")}
                                label="آدرس کیف پول مقصد را وارد کنید"
                                placeholder="آدرس مقصد"
                                error={!!errors?.address?.message}
                                helperText={errors?.address?.message?.toString()}
                              />
                              {!!tunnel?.memoSupport && (
                                <Stack>
                                  <TextField
                                    disabled={isIgnoreMemoCode}
                                    {...register("memoCode")}
                                    label=" کد ممو/ کامنت/ تگ مقصد"
                                    placeholder={
                                      isIgnoreMemoCode
                                        ? "شما این فیلد رو غیرفعال کردید."
                                        : "جای‌گذاری  کد ممو/ کامنت/ تگ مقصد"
                                    }
                                    error={!!errors?.memoCode?.message}
                                    helperText={
                                      errors?.memoCode?.message?.toString() ||
                                      "این شناسه واریز شماست اگر مقصد ممو کد یا کامنت دارد حتما وارد کنید"
                                    }
                                  />
                                  <Checkbox
                                    formControlProps={{
                                      sx: { mt: "-5px" },
                                      label: (
                                        <Typography
                                          variant="CaptionBold"
                                          color="colorfulGray.600"
                                        >
                                          مقصد ممو کد / تگ / کامنت ندارد
                                        </Typography>
                                      ),
                                      checked: isIgnoreMemoCode,
                                      onChange: (e, checked) => {
                                        setIsIgnoreMemoCode(checked);
                                        setValue("memoCode", "");
                                      },
                                    }}
                                    isOutline={false}
                                  />
                                </Stack>
                              )}
                              <Stack>
                                <TextField
                                  numberInput
                                  thousandSeparator
                                  {...register("amount")}
                                  label={`تعداد ${currentCoin?.prettyNameLocalized}`}
                                  placeholder="مقدار برداشتی شما"
                                  error={typeof renderHelperText() === "string"}
                                  helperText={renderHelperText()}
                                  value={watch("amount")}
                                  action={
                                    <Button
                                      onClick={() =>
                                        setValue(
                                          "amount",
                                          (
                                            currentCoin?.balanceAvailable || ""
                                          ).toString(),
                                          { shouldValidate: true }
                                        )
                                      }
                                      textColor="primary.500"
                                      variant="text"
                                      size="small"
                                    >
                                      <Typography variant="BodyMedium">
                                        حداکثر قابل برداشت
                                      </Typography>
                                    </Button>
                                  }
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Image
                                          src={getCoinIconURL(
                                            currentCoin?.currency || ""
                                          )}
                                          width="1.5rem"
                                          height="1.5rem"
                                          alt={currentCoin?.currency || ""}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                                <Typography
                                  variant="Caption"
                                  color="colorfulGray.500"
                                >
                                  {`کل موجودی شما: ${currentCoin?.balanceAvailableFormatted} ${currentCoin?.prettyNameLocalized}`}
                                </Typography>
                              </Stack>
                            </Stack>
                          )}
                        </>
                      )}
                    {user?.isSuspended && (
                      <Hint
                        severity="error"
                        IconComponent={Information}
                        content={
                          // prettier-ignore
                          <Typography variant="Caption" color="error.700">
                    {`شما مجاز به واریز یا برداشت نیستید، لطفا با پشتیبانی تماس بگیرید.`}
                  </Typography>
                        }
                      />
                    )}
                  </Stack>

                  {!!currentCoin && tunnel && (
                    <Stack gap="1rem" py="0.75rem">
                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="Body" color="colorfulGray.900">
                          کارمزد برداشت
                        </Typography>

                        <Stack
                          direction="row"
                          gap="0.25rem"
                          alignItems="center"
                        >
                          <Typography
                            variant="BodyMedium"
                            color="colorfulGray.700"
                          >
                            {tunnel?.fee || 0}
                          </Typography>
                          <Image
                            src={getCoinIconURL(currentCoin?.currency || "")}
                            width="1.5rem"
                            height="1.5rem"
                            alt={currentCoin?.currency || ""}
                          />
                        </Stack>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Typography variant="Body" color="colorfulGray.900">
                          مبلغی دریافتی بعد از کارمزد
                        </Typography>

                        <Stack
                          direction="row"
                          gap="0.25rem"
                          alignItems="center"
                        >
                          <Typography
                            variant="BodyMedium"
                            color="colorfulGray.700"
                          >
                            {Number(watch("amount")) > (tunnel?.fee || 0)
                              ? numUtils.commaformatter.toCurrency(
                                  Number(getValues("amount")) -
                                    (tunnel?.fee || 0)
                                )
                              : "---"}
                          </Typography>
                          <Image
                            src={getCoinIconURL(currentCoin?.currency || "")}
                            width="1.5rem"
                            height="1.5rem"
                            alt={currentCoin?.currency || ""}
                          />
                        </Stack>
                      </Stack>
                    </Stack>
                  )}

                  {!!getDailyMonthlyWithdrawQuery?.isSuccess &&
                    currentCoin &&
                    tunnel && (
                      <Stack
                        gap="1.5rem"
                        bgcolor="neutral.50"
                        borderRadius="0.625rem"
                        p="0.75rem"
                        my="2rem"
                      >
                        <MaxWithdrawalItem
                          title="حداکثر برداشت ۲۴ ساعته شما"
                          titlePrice={
                            getDailyMonthlyWithdrawQuery?.data?.result
                              ?.cryptoDaily || 0
                          }
                          subtitle="کل برداشتان در ۲۴ ساعت گذشته"
                          subtitlePrice={
                            Number(
                              getDailyMonthlyWithdrawQuery?.data?.result
                                ?.crypto24Houre
                            ).toFixed(0) || 0
                          }
                          amount={
                            getDailyMonthlyWithdrawQuery?.data?.result?.usdt *
                            (Number(watch("amount")) || 0)
                          }
                        />
                        <MaxWithdrawalItem
                          title="حداکثر برداشت ۳۰ روزه شما"
                          titlePrice={
                            getDailyMonthlyWithdrawQuery?.data?.result
                              ?.cryptoMonthly || 0
                          }
                          subtitle="کل برداشتان در ۳۰ روز گذشته"
                          subtitlePrice={
                            Number(
                              getDailyMonthlyWithdrawQuery?.data?.result
                                ?.crypto30Day
                            ).toFixed(0) || 0
                          }
                          amount={
                            getDailyMonthlyWithdrawQuery?.data?.result?.usdt *
                            (Number(watch("amount")) || 0)
                          }
                        />
                      </Stack>
                    )}

                  {isWithdrawEnabled && (
                    <Stack
                      position={isMdUp ? "static" : "fixed"}
                      p="0.75rem"
                      width="100%"
                      bottom={0}
                      left={0}
                      bgcolor="white"
                    >
                      <Button
                        size="large"
                        loading={handlePreviewWithdrawMutation.isPending}
                        disabled={
                          !currentCoin ||
                          !tunnel ||
                          !isValid ||
                          isWithdrawButtonDisabled
                        }
                        onClick={handleSubmit}
                        variant="contained"
                        tailStartIcon={<ArrowRight size="1.5rem" />}
                      >
                        <Typography variant="BodyBold">
                          درخواست برداشت
                        </Typography>
                      </Button>
                    </Stack>
                  )}
                </>
              )
            ) : (
              <NeedAuthorize baseOn={BaseCoin.USDT} />
            )}
          </Stack>
          {!!user?.twoFactorAuthEnabled && (
            <Stack flex={1} gap="0.75rem">
              {!isWithdrawEnabled && (
                <Hint
                  severity="error"
                  IconComponent={Information}
                  content={
                    // prettier-ignore
                    <Typography variant="Caption" color="error.700">
                    {`واریز ارز ${currentCoin?.prettyNameLocalized}/(${currentCoin?.currency}) بر روی شبکه ${tunnel?.networkId} در حال حاضر غیر فعال می‌باشد. لطفا در صورت بروز مشکل با پشتیبانی تماس بگیرید.`}
                  </Typography>
                  }
                />
              )}

              <Hint
                severity="info"
                IconComponent={LampCharge}
                content={
                  <Stack gap="0.75rem">
                    <Typography variant="Caption" color="info.700">
                      با رعایت این نکات، تراکنش‌های خود را با امنیت و کارایی
                      بیشتری انجام دهید:
                    </Typography>

                    <Typography variant="Caption" color="info.700">
                      در انتخاب شبکه برداشت دقت کنید و مطمئن شوید که کیف پول
                      مقصد از شبکه انتخابی شما پشتیبانی می‌کند.
                    </Typography>

                    <Typography variant="Caption" color="info.700">
                      در صورت استفاده از فیلترشکن، آن را خاموش کنید. به دستور
                      پلیس فتا، برای برداشت رمزارز IP شما باید کشور ایران باشد.
                    </Typography>

                    <Typography variant="Caption" color="info.700">
                      از برداشت مستقیم رمزارز به صرافی‌های جهانی که کاربران
                      ایرانی را تحریم کرده‌اند (مانند بایننس، کوین بیس و...)
                      خودداری کنید. در صورت نیاز به چنین انتقالی، حتماً از کیف
                      پول شخصی و آدرس‌های یکبار مصرف و انتقال چند لایه بین
                      آدرس‌های خود استفاده کنید.
                    </Typography>

                    <Typography variant="Caption" color="info.700">
                      اگر آدرس مقصد متعلق به کاربر دیگری در بالینکس باشد، انتقال
                      به صورت سریع و بدون کارمزد انجام می‌شود.
                    </Typography>
                  </Stack>
                }
              />

              <Hint
                severity="warning"
                IconComponent={Warning2}
                content={
                  <Stack gap="0.75rem">
                    <Typography variant="Caption" color="warning.900">
                      واریز وجه به حساب افراد ناشناس که از طریق آگهی‌های
                      درآمدزایی و ... شما را پیدا کرده‌اند کلاه‌برداری است و
                      مسئولیت جرم صورت‌گرفته متوجه شما می‌شود.
                    </Typography>

                    <Typography variant="Caption" color="warning.900">
                      به دلیل دستور مقام قضایی و پلیس فتا، ممکن است تا 72 ساعت
                      بین واریز ریال و برداشت رمزارز فاصله باشد.
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
          )}
        </Stack>
      </Stack>
      {isMdUp &&
        !!user?.mobileVerification &&
        !!user?.IDverification &&
        !!user?.twoFactorAuthEnabled && (
          <Stack
            sx={{
              gap: { xs: "0.75rem", md: "2rem" },
              p: "1.5rem",
              flex: { xs: 1, md: 0 },
              bgcolor: "white",
              borderRadius: { md: "1.125rem" },
              my: "1.75rem",
            }}
          >
            <Typography variant="TitleBold">
              آخرین برداشت‌های رمزارزی
            </Typography>

            <LastCoinWithdrawTransaction
              currency={currentCoin?.currency ?? ""}
              localName={currentCoin?.prettyNameLocalized ?? ""}
            />
          </Stack>
        )}
    </Wrapper>
  );
}
