import { OrderDetails } from "@services/queries/markets/types";

export function sortOrders(
  orders: OrderDetails[],
  sortBy: "asc" | "desc",
  sliceNumber?: number
) {
  // return orders;
  if (sortBy === "asc") {
    return orders
      ?.slice()
      ?.sort(
        (a: OrderDetails, b: OrderDetails) => Number(a.price) - Number(b.price)
      )
      ?.slice(0, sliceNumber);
  } else {
    return orders
      ?.slice()
      ?.sort(
        (a: OrderDetails, b: OrderDetails) => Number(b.price) - Number(a.price)
      )
      ?.slice(0, sliceNumber);
  }
}
