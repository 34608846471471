import { z } from "zod";
import { numUtils } from "@utils/num-helpers";

interface LimitOrderArgs {
  amountMin: number;
  amountMax: number;
  priceCoin: string;
}

export const LimitOrderSellSchema = ({
  amountMin,
  amountMax,
  priceCoin,
}: LimitOrderArgs) => {
  return z.object({
    price: z.string().refine((val) => Number(val) > 0, {
      message: `لطفا مبلغ را وارد نمایید`,
    }),
    // .refine((val) => Number(val) > 0.001, { message: `مقدار ${priceCoin} نمی تواند کمتر از ${numUtils.commaformatter.toCurrency(priceMin)} باشد.` }),
    amount: z
      .string()
      .refine((val) => Number(val) >= amountMin, {
        message: `مقدار ${priceCoin} نمی تواند کمتر از ${numUtils.commaformatter.toCurrency(
          amountMin
        )} باشد.`,
      })
      .refine((val) => Number(val) <= amountMax, {
        message: `مقدار ${priceCoin} نمی تواند بیشتر از ${numUtils.commaformatter.toCurrency(
          amountMax
        )} باشد.`,
      }),
    total: z.string().refine((val) => Number(val) > 0, {
      message: `لطفا قیمت و مقدار را وارد نمایید`,
    }),
  });
};

export const LimitOrderBuySchema = ({
  amountMin,
  amountMax,
  priceCoin,
}: LimitOrderArgs) => {
  return z.object({
    price: z.string().refine((val) => Number(val) > 0, {
      message: `لطفا مبلغ را وارد نمایید`,
    }),
    amount: z.string().refine((val) => Number(val) > 0, {
      message: `لطفا قیمت و مقدار را وارد نمایید`,
    }),
    total: z
      .string()
      .refine((val) => Number(val) >= amountMin, {
        message: `مبلغ کل سفارش ${priceCoin} نمی تواند کمتر از ${numUtils.commaformatter.toCurrency(
          amountMin
        )} باشد.`,
      })
      .refine((val) => Number(val) <= amountMax, {
        message: `مبلغ کل سفارش ${priceCoin} نمی تواند بیشتر از ${numUtils.commaformatter.toCurrency(
          amountMax
        )} باشد.`,
      }),
  });
};
