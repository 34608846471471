import { SvgIcon, SvgIconProps } from "@mui/material";

const DepositCurrencyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="62"
        height="61"
        viewBox="0 0 62 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="5.41693"
          y="46.31"
          width="39.1836"
          height="9.77286"
          rx="3.79714"
          stroke="#62676E"
          stroke-width="1.12"
        ></rect>
        <path
          d="M9.21436 38.125H44.0715C44.6731 38.125 45.1608 38.6127 45.1608 39.2143V44.6607C45.1608 45.2623 44.6731 45.75 44.0715 45.75H9.21436V38.125Z"
          fill="#FCD535"
        ></path>
        <rect
          x="5.41693"
          y="27.7924"
          width="39.1836"
          height="9.77286"
          rx="3.79714"
          stroke="#62676E"
          stroke-width="1.12"
        ></rect>
        <circle
          cx="45.4331"
          cy="41.8691"
          r="14.69"
          fill="#FABA16"
          stroke="#62676E"
          stroke-width="1.12"
        ></circle>
        <line
          x1="11.9377"
          y1="31.0446"
          x2="11.9377"
          y2="34.3125"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="15.2053"
          y1="40.8484"
          x2="15.2053"
          y2="44.1162"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="19.5627"
          y1="40.8484"
          x2="19.5627"
          y2="44.1162"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="25.009"
          y1="40.8484"
          x2="25.009"
          y2="44.1162"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="11.9377"
          y1="49.5622"
          x2="11.9377"
          y2="52.8301"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="17.384"
          y1="31.0446"
          x2="17.384"
          y2="34.3125"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="17.384"
          y1="49.5622"
          x2="17.384"
          y2="52.8301"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="22.8303"
          y1="31.0446"
          x2="22.8303"
          y2="34.3125"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="22.8303"
          y1="49.5622"
          x2="22.8303"
          y2="52.8301"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="28.2766"
          y1="49.5622"
          x2="28.2766"
          y2="52.8301"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <line
          x1="28.2766"
          y1="31.0446"
          x2="28.2766"
          y2="34.3125"
          stroke="#62676E"
          stroke-width="1.08929"
          stroke-linecap="round"
        ></line>
        <path
          d="M49.7867 39.2149C49.6283 37.6684 48.2065 37.1502 46.4099 37.0024V34.8564H45.0196V36.9451C44.6546 36.9451 44.2807 36.9516 43.9098 36.959V34.8564H42.5195L42.5185 37.0006C42.2174 37.0061 41.9213 37.0116 41.633 37.0116V37.0052L39.7153 37.0042V38.3992C39.7153 38.3992 40.7425 38.3807 40.7248 38.3982C41.2886 38.3982 41.4716 38.7049 41.5248 38.9701V41.4144C41.5641 41.4144 41.6143 41.4162 41.6714 41.4236H41.5248L41.5238 44.8481C41.4992 45.0144 41.3949 45.2795 41.0013 45.2804C41.019 45.2952 39.9908 45.2804 39.9908 45.2804L39.7144 46.8398H41.5248C41.8613 46.8398 42.1928 46.8453 42.5175 46.8472L42.5185 49.0172H43.9078V46.8703C44.2886 46.8777 44.6575 46.8804 45.0186 46.8804L45.0176 49.0172H46.4079V46.8518C48.7457 46.7262 50.3839 46.1728 50.5866 44.1109C50.7509 42.4509 49.9195 41.7091 48.5922 41.4098C49.4 41.0255 49.9047 40.3465 49.7867 39.2149ZM47.8405 43.8541C47.8405 45.4754 44.8838 45.2915 43.9403 45.2915V42.4158C44.8838 42.4176 47.8405 42.1636 47.8405 43.8541ZM47.1931 39.7978C47.1931 41.2731 44.7254 41.1003 43.9403 41.1012V38.4943C44.7264 38.4943 47.1941 38.2597 47.1931 39.7978Z"
          fill="white"
        ></path>
        <path
          d="M41.5278 41.3574H41.7345V41.5006H41.5278V41.3574Z"
          fill="white"
        ></path>
        <path
          d="M17.2332 15.3773L13.2636 19.5172C13.129 19.6575 12.9272 19.7277 12.7926 19.7277C12.5908 19.7277 12.4562 19.6575 12.3216 19.5172L8.35207 15.3773C8.15023 15.1668 8.08295 14.8861 8.15023 14.6054C8.28479 14.3248 8.48664 14.1844 8.75576 14.1844H10.0341V7.23781C10.0341 6.88697 10.3705 6.53613 10.7069 6.53613H14.811C15.1474 6.53613 15.4839 6.88697 15.4839 7.23781V14.1844H16.7622C17.0313 14.1844 17.3004 14.3248 17.3677 14.6054C17.435 14.8861 17.435 15.1668 17.2332 15.3773Z"
          fill="#FCD535"
        ></path>
        <path
          d="M28.9587 15.3773L24.9892 19.5172C24.8546 19.6575 24.6528 19.7277 24.5182 19.7277C24.3164 19.7277 24.1818 19.6575 24.0472 19.5172L20.0777 15.3773C19.8758 15.1668 19.8085 14.8861 19.8758 14.6054C20.0104 14.3248 20.2122 14.1844 20.4813 14.1844H21.7597V7.23781C21.7597 6.88697 22.0961 6.53613 22.4325 6.53613H26.5366C26.873 6.53613 27.2094 6.88697 27.2094 7.23781V14.1844H28.4878C28.7569 14.1844 29.026 14.3248 29.0933 14.6054C29.1606 14.8861 29.1606 15.1668 28.9587 15.3773Z"
          fill="#FCD535"
        ></path>
      </svg>
    </SvgIcon>
  );
};

export default DepositCurrencyIcon;
