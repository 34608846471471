import { Button } from "@components/common/button";
import { IconTypography } from "@components/common/icon-typography";
import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack, useTheme } from "@mui/material";
import { bankInfo, formatCardBank } from "@utils/common";
import { Trash } from "iconsax-react";

interface ApproveDeleteBankModalProps {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  data: { iban: string; card: string };
  isSheba?: boolean;
}

export const ApproveDeleteBankModal = ({
  isOpen,
  onClose,
  onDelete,
  data,
  isSheba,
}: ApproveDeleteBankModalProps) => {
  const theme = useTheme();

  return (
    <Modal open={isOpen} title="حذف حساب بانکی" onClose={onClose}>
      <Stack width="100%" gap="1.5rem">
        <IconTypography
          bgColor="success.A100"
          icon={
            <Trash
              color={theme?.palette?.neutral?.[500]}
              variant="Bold"
              size="2rem"
            />
          }
          title={""}
        />

        {!isSheba ? (
          <Stack direction="row" justifyContent="space-between">
            <Typography
              color="colorfulGray.600"
              whiteSpace="nowrap"
              variant="BodyMedium"
            >
              شماره کارت
            </Typography>
            <Stack direction="row" gap="0.5rem">
              <Typography
                variant="ExtendedBodyBold"
                color="colorfulGray.700"
                dir="ltr"
              >
                {formatCardBank(data?.card)}
              </Typography>
              {bankInfo(data?.card, "Card")?.logo && (
                <Image
                  src={`/v2/images/banks-logo/${
                    bankInfo(data?.card, "Card")?.logo
                  }`}
                  width="1.5rem"
                  height="1.5rem"
                  alt="عکس حساب بانکی"
                />
              )}
            </Stack>
          </Stack>
        ) : (
          <Stack direction="row" justifyContent="space-between">
            <Typography
              color="colorfulGray.600"
              whiteSpace="nowrap"
              variant="BodyMedium"
            >
              شماره شبا
            </Typography>
            <Stack direction="row" gap="0.5rem">
              <Typography
                variant="ExtendedBodyBold"
                color="colorfulGray.700"
                dir="ltr"
              >
                {formatCardBank(data?.iban)}
              </Typography>
              {bankInfo(data?.iban, "Sheba")?.logo && (
                <Image
                  src={`/v2/images/banks-logo/${
                    bankInfo(data?.iban, "Sheba")?.logo
                  }`}
                  width="1.5rem"
                  height="1.5rem"
                  alt="عکس حساب بانکی"
                />
              )}
            </Stack>
          </Stack>
        )}

        <Stack direction="row" width="100%" gap="0.75rem">
          <Button
            fullWidth
            onClick={onDelete}
            startIcon={<Trash color="white" variant="Bold" size="1.25rem" />}
            bgColor="error.500"
          >
            <Typography variant="BodyMedium" color="white">
              حذف
            </Typography>
          </Button>
          <Button fullWidth onClick={onClose}>
            <Typography variant="BodyMedium" color="white">
              انصراف
            </Typography>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
