import { SwitchTab } from "@components/common/switch-tab";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack } from "@mui/material";
import { useMemo, useState } from "react";
import { SwiperFilter } from "../../_components/swiper-filter";
import { IValueFilter, listTabs, transactionsFilterList } from "../../_constants";
import { Modal } from "@components/common/modal";
import { FilterModal } from "../../_components/filter-modal";
import { useNavigate } from "react-router-dom";
import { IconTypography } from "@components/common/icon-typography";
import { Image } from "@components/common/image";
import { useTransactionsInfiniteList } from "@services/queries/financial/queries";
import { InfiniteScroll } from "@components/common/infinite-scroll";
import { TransactionsCard } from "../../_components/transactions-card";

export const FinancialTransactions = () => {
  const isMdUp = useBreakpoint("md");

  const navigate = useNavigate();

  const [filters, setFilters] = useState<IValueFilter[]>([]);
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const handleOpenFilterModal = () => setIsFilterModalOpen(true);

  const transformedFilters = (): IValueFilter[] => {
    return filters?.map((filter) => {
      if (filter?.id === "DATE") {
        const fromDate = filter?.filter[0]?.value?.from;
        const toDate = filter?.filter[0]?.value?.to;
        return {
          id: "DATE",
          filter: [
            {
              field: "createdAt",
              operator: "bt",
              value: [
                fromDate instanceof Date && !isNaN(fromDate.getTime())
                  ? fromDate.toISOString().split("T")[0]
                  : "",
                toDate instanceof Date && !isNaN(toDate.getTime())
                  ? toDate.toISOString().split("T")[0]
                  : "",
              ],
            },
          ],
        };
      }
      return filter;
    });
  };

  const transactionsListQuery = useTransactionsInfiniteList({
    query: [
      ...transformedFilters()
        ?.map((e) => e?.filter)
        .flat(),
    ],
  });

  const transactionsList = useMemo(
    () =>
      transactionsListQuery?.data?.pages?.flatMap((e) => e?.result?.items) ||
      [],
    [
      transactionsListQuery?.isFetchingNextPage,
      transactionsListQuery?.isFetching,
    ]
  );


  return (
    <Wrapper
      title="تاریخچه تراکنش‌ها"
      backMode={!isMdUp}
      hideBottomNavigation
      withSearch
      isFullWidth={!isMdUp}
      sxContainer={{
        py: { md: "2rem" },
      }}
      loading={transactionsListQuery?.isLoading}
    >
      <Modal
        title="فیلترها"
        open={isFilterModalOpen}
        onClose={() => setIsFilterModalOpen(false)}
      >
        <FilterModal
          filters={filters}
          setFilters={setFilters}
          filterList={transactionsFilterList}
          onClose={() => setIsFilterModalOpen(false)}
        />
      </Modal>

      <Stack bgcolor="white" p="1.5rem" borderRadius="0.625rem">
        <Stack gap="1.5rem">
          <SwitchTab
            sx={{ width: "100%", display: { xs: "flex", md: "none" } }}
            onChange={(data) =>
              navigate(
                `/v2/dashboard/financial/${data?.value
                  ?.toString()
                  ?.toLowerCase()}`
              )
            }
            defaultTab={{
              label: "تراکنش‌ها",
              value: "TRANSACTIONS",
            }}
            list={listTabs}
          />

          <SwiperFilter
            filters={filters}
            setFilters={setFilters}
            filterList={transactionsFilterList}
            handleOpenFilterModal={handleOpenFilterModal}
          />
        </Stack>

        {!!transactionsList?.length ? (
          <InfiniteScroll
            fetchMore={async () => {
              !transactionsListQuery?.isFetchingNextPage &&
              transactionsListQuery?.hasNextPage
                ? await transactionsListQuery.fetchNextPage()
                : await 0;
            }}
          >
            <Stack component="ul" sx={{ mt: { md: "1rem" } }}>
              {transactionsList.map((item) => (
                <TransactionsCard data={item} />
              ))}
            </Stack>
          </InfiniteScroll>
        ) : (
          <Stack py="5rem">
            <IconTypography
              icon={
                <Image
                  alt="empty-img"
                  src="/v2/images/empty.png"
                  height="72px"
                  width="72px"
                />
              }
              title="هیچ سفارشی یافت نشد."
            />
          </Stack>
        )}
      </Stack>
    </Wrapper>
  );
};
