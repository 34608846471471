import { useSocket } from "@context/scoket";
import { useUserStore } from "@store/user";
import { useEffect, useState } from "react";

interface UserFundsChanges {
  available: string;
  currencyCode: string;
  hold: string;
  sequence: number;
  type: string;
  userId: string;
}

interface Props {
  currencies?: string[];
}

const useWalletUser = ({ currencies = [] }: Props) => {
  const { socket } = useSocket();
  const [data, setData] = useState<UserFundsChanges[]>([]);
  const user = useUserStore((state) => state.user);

  useEffect(() => {
    if (!socket) return;

    const handleFundsChange = (fundsChange: UserFundsChanges) => {
      if (
        currencies.length > 0 &&
        !currencies.includes(fundsChange.currencyCode)
      ) {
        return;
      }

      setData((prevData) => {
        const updatedData = prevData.filter(
          (item) => item.currencyCode !== fundsChange.currencyCode
        );
        return [...updatedData, fundsChange];
      });
    };

    socket.on(`funds:${user?.personalWorkSpace}`, handleFundsChange);

    return () => {
      socket.off(`funds:${user?.personalWorkSpace}`, handleFundsChange);
    };
  }, [socket, user?.personalWorkSpace, currencies]);

  return data;
};

export default useWalletUser;
