import { formatTimeMinutesSeconds } from "@utils/common";
import { useEffect, useState } from "react";

let interval: any | null = null;

export const useIntervalTimer = () => {
  const [targetTime, setTargetTime] = useState<number>();
  const [remainedTime, setRemainedTime] = useState<false | string>(false);

  const generateTimer = (time: number) => setTargetTime(time);

  useEffect(() => {
    if (targetTime) {
      interval = setInterval(() => {
        const now = new Date().getTime();
        const difference =
          +Math.floor(targetTime / 1000) - Math.floor(now / 1000);
        if (difference >= 0) {
          setRemainedTime(formatTimeMinutesSeconds(difference));
        } else {
          setRemainedTime(false);
        }
      }, 1000);
      return () => clearInterval(interval as any);
    }
  }, [targetTime]);

  useEffect(() => {
    if (remainedTime === false) clearInterval(interval as any);
  }, [remainedTime]);

  return { remainedTime, generateTimer };
};
