import Table from "@components/common/table";
import { HeadCell } from "@components/common/table/types";
import { useTheme } from "@mui/material";
import { useGetMyHistoryTrade } from "@services/queries/markets/queries";
import { HistoryTrade } from "@services/queries/markets/types";
import useCurrentCurrency from "../../../_hooks/useCurrentCurrency";
import { HistoryTradeData, createTradeHistoryTableData } from "../types";
import { useState } from "react";

const createHeadCells = (): readonly HeadCell<Partial<HistoryTradeData>>[] => {
  return [
    {
      id: "_id",
      numeric: false,
      disablePadding: true,
      label: "شماره سفارش",
      type: "persianNumber",
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: "زمان سفارش",
      type: "date",
      sortable: true,
    },
    {
      id: "prodId",
      numeric: false,
      disablePadding: true,
      label: "بازار",
    },
    {
      id: "side",
      numeric: false,
      disablePadding: false,
      label: "سمت",
      type: "custom",
      sortable: true,
    },
    {
      id: "size",
      numeric: true,
      disablePadding: false,
      label: "مقدار سفارش",
      sortable: true,
      type: "persianNumber",
    },
    {
      id: "price",
      numeric: true,
      disablePadding: false,
      label: "قیمت واحد",
      sortable: true,
      type: "currency",
    },
    {
      id: "fee",
      numeric: true,
      disablePadding: false,
      label: "کارمزد",
      sortable: true,
      type: "currency",
    },
    {
      id: "totalPrice",
      numeric: true,
      disablePadding: false,
      label: "مبلغ کل",
      type: "currency",
    },
    {
      id: "pureAmount",
      numeric: true,
      disablePadding: false,
      label: "خالص دریافتی / پرداختی",
      sortable: true,
      type: "currency",
    },
  ];
};

const TradeHistoryTab = ({ dense }: { dense?: boolean }) => {
  const theme = useTheme();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const currentCurrency = useCurrentCurrency();
  const isIRT = currentCurrency?.quoteCurrency === "IRT";

  const headCells = createHeadCells();

  const { data, isFetching } = useGetMyHistoryTrade(
    page,
    rowsPerPage,
    currentCurrency?.id
  );

  // @ts-expect-error
  const handleRows = (data) => {
    if (!data) return [];

    return data?.items?.map((item: HistoryTrade) => {
      const totalPrice = Number(item?.size) * Number(item?.price);
      const isBuy = item?.side === "buy";
      const side = isBuy ? (
        <span style={{ color: theme.palette.success[500] }}>خرید</span>
      ) : (
        <span style={{ color: theme.palette.error[600] }}>فروش</span>
      );

      const pureAmount = totalPrice - Number(item?.fee);
      return createTradeHistoryTableData(
        item?._id,
        item?.createdAt,
        item?.prodId,
        side,
        Number(item?.size)?.toFixed(
          currentCurrency?.scale?.[currentCurrency?.baseCurrency]?.toRoundScale
        ),
        Number(item?.price)?.toFixed(2),
        Number(item?.fee)?.toFixed(
          isBuy
            ? currentCurrency?.scale?.[currentCurrency?.baseCurrency]
                ?.feeRoundScale
            : currentCurrency?.scale?.[currentCurrency?.baseCurrency]
                ?.feeRoundScale
        ),
        totalPrice.toString(),
        pureAmount.toString()
      );
    });
  };

  return (
    <Table
      headCells={headCells}
      rows={handleRows(data)}
      isLoading={isFetching}
      dense={dense}
      page={page}
      setPage={setPage}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      // @ts-expect-error
      totalData={data?.total}
    />
  );
};

export default TradeHistoryTab;
