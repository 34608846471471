import request from "@services/axios";
import type {
  HistoryTrade,
  IMarket,
  IMarketList,
  IOrderPreview,
  IOrderPreviewArgs,
  IUserBalance,
  MarketListArgs,
  MarketListResponse,
  OrderBook,
  OrderHistory,
  Trades,
} from "./types";
import { DEFAULT_PER_PAGE } from "@constants/index";

export const getCurrencyInfo = async (productId?: string) => {
  return await request.post<IMarketList>("api/ExCore/v2/browse/markets/0/15", {
    productId,
  });
};

export const getMarketTrades = async (marketId: string) => {
  return await request.get<Trades[]>(`/api/ExCore/trades/${marketId}`);
};

export const getMarketOrderBook = async (marketId: string) => {
  return await request.get<OrderBook>(`api/ExCore/orderbook/${marketId}`);
};

// prettier-ignore
export const getMyOrders = async (marketId: string,limit: number,status: string) => {
  return await request.get<OrderHistory>(
    `api/ExCore/myorders/${marketId}?limit=${limit}&${status}&=`
  );
};

export const closeOrder = async (orderId: string) => {
  return await request.delete(`/api/ExCore/cancel/order/${orderId}`);
};

export const closeAllOrders = async () => {
  return await request.delete(`/api/ExCore/cancel/orders/all`);
};

export const getUserBalance = async (marketId: string) => {
  return await request.get<IUserBalance>(
    `/api/ExCore/account/balances/${marketId}`
  );
};

export const getLimitOrderPreview = async (data: IOrderPreviewArgs) => {
  return await request.post<IOrderPreview>(
    `/api/ExCore/place/order/preview`,
    data
  );
};

export const sendLimitOrder = async (data: IOrderPreviewArgs) => {
  return await request.post<IOrderPreview>(`/api/ExCore/place/order`, data);
};

export const getMyHistoryTrade = async (
  skip: number,
  limit: number,
  marketId: string
) => {
  return await request.get<HistoryTrade>(
    `/api/ExCoreData/my/trade/history/browse/${skip}/${limit}/${marketId}`
  );
};

// prettier-ignore
export const getMarketList = async ({page, perPage, ...data}: MarketListArgs) => {
  return await request.post<MarketListResponse>(`/api/ExCoreData/markets/landing/${page ?? 0}/${perPage ?? DEFAULT_PER_PAGE}`, data);
};

export const updateFavorite = async (id: string) => {
  return await request.post<boolean>(`/api/ExCoreData/faveCurrency/${id}`);
};

// prettier-ignore
export const getMarketSlider = async () => {
  return await request.get<{title: string, data: IMarket}[]>(`/api/ExCoreData/topSlider`);
};
