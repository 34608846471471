import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cancelSpecificOrder } from "./apis";

export function useCancelSpecificOrderMutation() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (orderId: string) => cancelSpecificOrder({ orderId }),
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["orders-financial"] });
    },
  });
}
