import { Typography } from "@components/common/typography";
import { Stack, useTheme } from "@mui/material";
import { use } from "i18next";
import { Card } from "iconsax-react";
import { NavigateOptions, useNavigate } from "react-router-dom";

interface ChoosePaymentMethodProps {
  title: string;
  description?: string;
  href?: string;
  disabled?: boolean;
  navigateOptions?: NavigateOptions;
}

export const ChoosePaymentMethod = ({
  title,
  description,
  href,
  disabled,
  navigateOptions,
}: ChoosePaymentMethodProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Stack
      onClick={() => {
        if (disabled || !href) return;

        return navigate(href, navigateOptions);
      }}
      p="0.75rem"
      border={1}
      borderColor="primary.400"
      borderRadius="1.125rem"
      sx={{
        opacity: disabled ? "0.5" : "1",
        transition: "0.3s",
        cursor: disabled ? "not-allowed" : "pointer",
        "&:hover": disabled ? {} : { bgcolor: "primary.A100" },
      }}
    >
      <Stack direction="row" gap="0.25rem" alignItems="center">
        <Card
          variant="Bold"
          color={theme?.palette?.colorfulGray[400]}
          size="1.25rem"
        />
        <Typography variant="BodyMedium" color="colorfulGray.700">
          {title}
        </Typography>
      </Stack>
      <Typography variant="Caption" color="colorfulGray.700">
        {description}
      </Typography>
    </Stack>
  );
};
