import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";
import NotFoundItem from "@components/common/not-found";
import { TextField } from "@components/common/textfield";
import { Search } from "@mui/icons-material";
import {
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from "@mui/material";
import { IMarket } from "@services/queries/markets/types";
import { getCoinIconURL } from "@utils/common";
import { ChangeEventHandler, useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface SelectCoinModalProps {
  title: string;
  onClose: () => void;
  open: boolean;
  searchable?: boolean;
  list: IMarket[];
  onSelectCoin: (item: IMarket) => void;
}

export const SelectMarketModal = ({
  title,
  onClose,
  open,
  list,
  searchable,
  onSelectCoin,
}: SelectCoinModalProps) => {
  const [value, setValue] = useState("");
  const [listCoins, setListCoins] = useState<IMarket[]>([]);

  useEffect(() => {
    setListCoins(list);
  }, [list]);

  // prettier-ignore
  const handleOnChangeSearch:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const val = e.target.value
    setValue(val)

    if(!val?.length) return setListCoins(list)

    const regex = new RegExp(val, "ig");
    const tempList = list?.filter(e=>
      e?._id?.search(regex) !== -1 ||e?.localizedTitle?.search(regex) !== -1 || e?.baseCurrency?.search(regex) !== -1
    )
    setListCoins(tempList)
  };

  return (
    <Modal open={open} title={title} onClose={onClose}>
      <Stack
        width="100%"
        sx={{ borderRadius: { xs: "2rem 0 2rem 0" } }}
        height={"80%"}
      >
        <Stack>
          {searchable && (
            <TextField
              autoFocus
              color="warning"
              InputProps={{
                sx: { height: "40px" + "!important" },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search
                      sx={{
                        color: "neutral.400",
                      }}
                    />
                  </InputAdornment>
                ),
              }}
              placeholder="جستجو"
              value={value}
              onChange={handleOnChangeSearch}
            />
          )}

          <List
            disablePadding
            sx={{
              maxHeight: "400px",
              overflow: "auto",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {!!value?.length && !listCoins?.length ? (
              <NotFoundItem />
            ) : (
              listCoins?.map((item) => (
                <Link replace to={`/v2/dashboard/markets/${item?._id}`}>
                  <ListItem
                    key={`coin-${item?._id}`}
                    disablePadding
                    sx={{ mb: "0.5rem", gap: "1rem" }}
                  >
                    <ListItemButton
                      onClick={() => onSelectCoin(item)}
                      sx={{
                        borderRadius: "0.5rem",
                        "&:hover": { bgcolor: "primary.A100" },
                      }}
                    >
                      <Image
                        src={getCoinIconURL(item.baseCurrency)}
                        width="1.5rem"
                        height="1.5rem"
                        alt={item._id}
                        sx={{ mr: "1rem" }}
                      />
                      <ListItemText
                        primary={`${item.localizedTitle}  (${item.baseCurrency})`}
                      />
                    </ListItemButton>
                  </ListItem>
                </Link>
              ))
            )}
          </List>
        </Stack>
      </Stack>
    </Modal>
  );
};
