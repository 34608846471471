import { Collapse, Stack, SvgIcon } from "@mui/material";
import { ISideNavItem } from "../..";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserStore } from "@store/user";
import { Typography } from "@components/common/typography";
import { ArrowDown2 } from "iconsax-react";
import { useEffect, useState } from "react";

interface SideNavItemProps {
  item: ISideNavItem;
}

export const SideNavItem = ({ item }: SideNavItemProps) => {
  const navigate = useNavigate();
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    item?.children?.forEach((child) => {
      if (child?.href === location?.pathname) {
        !!open && setIsOpen(true);
      }
    });
  }, []);

  return (
    <Stack key={`side-nav-item-${item?.title}`} component="li">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ cursor: "pointer", "&:hover": { bgcolor: "primary.A100" } }}
        bgcolor={
          item?.href === location?.pathname ? "primary.A100" : "transparent"
        }
        p="0.75rem"
        borderRadius="0.5rem"
        onClick={() => {
          if (item?.children?.length) {
            setIsOpen(!isOpen);
            return;
          }

          if (item?.href) {
            if (item.href === "SUPPORT") return window.Goftino.toggle();

            if (item?.isLinkOutSource) {
              return (window.location.href = item?.href?.replaceAll(
                "WORKSPACE",
                user?.currentWorkSpaceId || ""
              ));
            }

            return navigate(item?.href);
          }
        }}
      >
        <Stack direction="row">
          <SvgIcon
            sx={{
              mr: "0.5rem",
              color: (theme) =>
                item?.href === location.pathname
                  ? theme?.palette?.primary?.[600]
                  : theme?.palette.colorfulGray?.[500],
            }}
          >
            {item?.icon(item?.href === location.pathname)}
          </SvgIcon>
          <Typography
            color={
              item?.href === location.pathname
                ? "primary.700"
                : "colorfulGray.500"
            }
            variant={
              item?.href === location.pathname
                ? "ExtendedBodyBold"
                : "ExtendedBodyMedium"
            }
          >
            {item?.title}
          </Typography>
        </Stack>
        {item?.children?.length && (
          <SvgIcon
            sx={{
              transition: "transform 0.3s",
              transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
              color: (theme) => theme.palette.colorfulGray[500],
            }}
          >
            <ArrowDown2 size="1.5rem" />
          </SvgIcon>
        )}
      </Stack>
      {item?.children?.length && (
        <Collapse in={isOpen}>
          <Stack component="ul">
            {item?.children?.map((child) => (
              <Stack
                component={"li"}
                py="0.5rem"
                px="2rem"
                key={`side-nav-child-item-${child?.title}`}
                sx={{
                  cursor: "pointer",
                  "&:hover": { bgcolor: "primary.A100" },
                }}
                bgcolor={
                  child?.href === location?.pathname
                    ? "primary.A100"
                    : "transparent"
                }
                borderRadius="0.5rem"
                onClick={() => {
                  if (child?.href) {
                    if (child?.isLinkOutSource) {
                      return (window.location.href = child?.href?.replaceAll(
                        "WORKSPACE",
                        user?.currentWorkSpaceId || ""
                      ));
                    }

                    return navigate(child?.href);
                  }
                }}
              >
                <Typography
                  color={
                    child?.href === location.pathname
                      ? "primary.700"
                      : "colorfulGray.500"
                  }
                  variant={
                    child?.href === location.pathname
                      ? "ExtendedBodyBold"
                      : "ExtendedBodyMedium"
                  }
                >
                  {child?.title}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Collapse>
      )}
    </Stack>
  );
};
