import { Button } from "@components/common/button";
import { Slider } from "@components/common/slider";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { useBreakpoint } from "@hooks/useBreakpoint";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Box, IconButton, InputAdornment, Stack } from "@mui/material";
import useGetOrderBooks from "@pages/dashborad/markets/market-details/_hooks/useGetOrderBooks";
import { useSetLimitOrderPreview } from "@services/queries/markets/mutations";
import {
  IMarket,
  IOrderPreview,
  IUserBalance,
} from "@services/queries/markets/types";
import { useUserStore } from "@store/user";
import { numUtils } from "@utils/num-helpers";
import { toast } from "@utils/toast";
import {
  Dispatch,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { Response } from "~types/index";
import { sortOrders } from "../../../order-book/helperOrderBook";
import VerifyModalBazzar from "../verify-modal/VerifyModalBazzar";
import { MarketOrderSchema } from "./_validation";
import useWalletUser from "@pages/dashborad/markets/market-details/_hooks/useWalletUser";

interface SellProps {
  currentCurrency: IMarket;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  userBalanceData: IUserBalance | undefined;
}

const Sell = ({
  currentCurrency,
  setOpenModal,
  userBalanceData,
}: SellProps) => {
  const isMdUp = useBreakpoint("md");
  const user = useUserStore((state) => state.user);
  const [openVerify, setOpenVerify] = useState<{
    open: boolean;
    data: IOrderPreview | undefined;
  }>({
    open: false,
    data: undefined,
  });

  const [sliderValue, setSliderValue] = useState(0);
  const { data: orderBookData } = useGetOrderBooks();

  const zObject = useMemo(
    () =>
      MarketOrderSchema({
        priceCoin: currentCurrency?.baseCurrency,
        amountMin: Number(userBalanceData?.sell?.min),
        amountMax: Number(userBalanceData?.sell?.max),
      }),
    []
  );

  const location = useLocation();
  useEffect(() => {
    formMethods.reset();
  }, [location]);

  const formMethods = useForm<z.output<typeof zObject>>({
    mode: "all",
    resolver: zodResolver(zObject),
  });

  // prettier-ignore
  const balance = useMemo(()=> Number(userBalanceData?.baseBalance) ?? 0,[userBalanceData])
  const amount = formMethods.watch("amount");

  const price = useMemo(
    () =>
      Number(
        sortOrders(orderBookData?.bids || [], "asc")?.filter(
          (item) => Number(item.total) > 0
        )[0]?.price ?? 0
      ) ?? 0,
    [orderBookData?.bids]
  );

  const percent = useMemo(
    () => (balance * sliderValue) / 100,
    [balance, sliderValue]
  );
  // const finalAmount = useMemo(() => percent * price, [percent, price]);
  const finalAmount = useMemo(
    () => (price > 0 ? percent * price : 0),
    [percent, price]
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    setSliderValue(newValue as number);

    if (balance <= 0) {
      formMethods.setValue("amount", "");
      return;
    }

    const amount = (balance * (newValue as number)) / 100;

    formMethods.setValue(
      "amount",
      amount
        // .toFixed(currentCurrency?.fromRoundScale || 0)
        .toString()
    );
  };

  useEffect(() => {
    if (Object.keys(formMethods.formState.errors).length > 0) {
      Object.values(formMethods.formState.errors)?.map((error) =>
        toast.error(error?.message ?? "")
      );
    }
  }, [formMethods.formState.errors]);

  const setPreviewMutate = useSetLimitOrderPreview();
  const onSubmit: SubmitHandler<z.output<typeof zObject>> = () => {
    const amountValue = document.getElementById(
      "market-sell-amount"
    ) as HTMLInputElement;

    setPreviewMutate.mutate(
      {
        productId: currentCurrency?.id,
        size: 0,
        price: Number(price),
        side: "sell",
        type: "market",
        timeInForce: new Date().toISOString(),
        source: currentCurrency?.baseCurrency,
        funds: Number(amountValue?.value?.replaceAll(",", "") ?? 0),
      },
      {
        onSuccess: (data: Response<IOrderPreview>) => {
          setOpenVerify({
            open: true,
            data: { ...data.result, target: currentCurrency?.quoteCurrency },
          });
        },
      }
    );
  };

  const CalFee = useMemo(() => {
    const scale =
      currentCurrency?.scale?.[currentCurrency?.quoteCurrency]?.feeRoundScale;
    const takerWage = Number(user?.levelInfo[0]?.takerWage ?? 0);
    const fee = takerWage * (finalAmount ?? 0);
    const feeRounded = fee.toFixed(scale);

    return numUtils.commaformatter.toCurrency(feeRounded);
  }, [
    currentCurrency?.quoteCurrency,
    currentCurrency?.scale,
    finalAmount,
    user?.levelInfo,
  ]);

  // useEffect(() => {
  //   if (price > 0 && balance > 0) {
  //     const initialFinalAmount = percent / price;
  //     console.log("Initial Final Amount:", initialFinalAmount);
  //     formMethods.setValue(
  //       "amount",
  //       initialFinalAmount.toFixed(
  //         currentCurrency?.scale?.[currentCurrency?.baseCurrency]
  //           ?.toRoundScale || 6
  //       )
  //     );
  //   }
  // }, [price, balance, percent, formMethods, currentCurrency]);

  const userBalanceSocket = useWalletUser({
    currencies: [currentCurrency?.baseCurrency],
  });

  const userBalance = userBalanceSocket?.find(
    (item) => item.currencyCode === currentCurrency?.baseCurrency
  )?.available;

  return (
    <>
      <Box
        component={"form"}
        onSubmit={formMethods.handleSubmit(onSubmit)}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        minHeight={"305px"}
      >
        <Stack>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={-1}
          >
            <Typography variant="caption" color="neutral.500">
              قابل فروش
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              <IconButton onClick={() => setOpenModal(true)}>
                <AddCircleIcon
                  sx={{
                    color: "secondary.700",
                  }}
                  fontSize="small"
                />
              </IconButton>
              <Stack gap={1} direction={"row"}>
                <Typography fontSize={14} color={"primary.700"}>
                  {currentCurrency?.baseCurrency}
                </Typography>
                <Typography
                  fontSize={14}
                  fontWeight={"bold"}
                  color={"primary.700"}
                >
                  {userBalance
                    ? numUtils?.commaformatter.toCurrency(
                        Number(userBalance)?.toFixed(
                          currentCurrency?.scale?.[
                            currentCurrency?.baseCurrency
                          ]?.toRoundScale
                        )
                      )
                    : userBalanceData?.baseBalanceFormatted}
                </Typography>
              </Stack>
            </Box>
          </Box>

          <Stack gap="0.675rem" mt="0.5rem">
            <TextField
              placeholder="بهترین قیمت بازار"
              sx={{ height: 40, mb: -1 }}
              disabled
              inputProps={{ style: { textAlign: "center" } }}
            />
            <TextField
              {...formMethods.register("amount")}
              numberInput
              thousandSeparator
              decimalScale={
                currentCurrency?.scale?.[currentCurrency?.baseCurrency]
                  ?.toRoundScale || 0
              }
              sx={{ height: 40, mb: -1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Typography
                      variant="body1"
                      textAlign={"right"}
                      color={"neutral.500"}
                    >
                      {currentCurrency?.baseCurrency}
                    </Typography>
                  </InputAdornment>
                ),
                startAdornment: isMdUp && (
                  <InputAdornment position="start">
                    <Typography sx={{ color: "neutral.500", fontSize: 13 }}>
                      مقدار
                    </Typography>
                  </InputAdornment>
                ),
              }}
              placeholder={!isMdUp ? "مقدار" : ""}
              value={formMethods.watch("amount")}
              inputProps={{ style: { direction: "ltr", marginLeft: 10 } }}
              id="market-sell-amount"
            />
          </Stack>
          <Stack pl="0.75rem" height={35} marginX={1}>
            <Slider
              side="Sell"
              valueLabelDisplay="auto"
              valueLabelFormat={(val) => `${val}%`}
              getAriaLabel={(val) => `${val}%`}
              onChange={handleSliderChange}
              onChangeCommitted={(e, val) => {
                if (balance === 0) {
                  toast.error("لطفا موجودی پرداختی خود را شارژ کنید.");
                  return setSliderValue(0);
                }
              }}
              value={sliderValue}
            />
          </Stack>

          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mt={1}
          >
            <Typography variant="caption" color="neutral.500">
              کارمزد تقریبی
            </Typography>
            <Stack gap={1} direction={"row"}>
              <Typography variant="caption">
                {currentCurrency?.quoteCurrency}
              </Typography>
              <Typography variant="caption" fontWeight={"bold"}>
                {/* {isNaN(parseFloat(CalFee)) ? 0 : CalFee} */}
                {CalFee}
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Stack>
          <Button
            variant="contained"
            bgColor="error.main"
            fullWidth
            sx={{
              height: 40,
              my: 1,
            }}
            type="submit"
            disabled={setPreviewMutate?.isPending}
          >
            <Typography color={"white"} fontSize={14}>
              فروش
            </Typography>
          </Button>
        </Stack>
      </Box>
      {openVerify && (
        <VerifyModalBazzar
          open={openVerify.open}
          data={openVerify.data}
          onClose={() => setOpenVerify({ open: false, data: undefined })}
          title="تایید خرید"
          resetForm={formMethods.reset as () => void}
        />
      )}
    </>
  );
};

const MemoizedSell = memo(Sell);
export default MemoizedSell;
