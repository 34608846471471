import RefreshIcon from "@components/common/refresh-icon";
import Tabs from "@components/common/tabs";
import { TabItem } from "@components/common/tabs/types";
import { Grid, IconButton, Paper, Stack } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import OpenOrders from "./open-orders";
import OrderHistory from "./order-history";
import TradeHistoryTab from "./trade-history";

const Orders = () => {
  const queryClient = useQueryClient();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    if (isRefreshing) return;

    setIsRefreshing(true);
    queryClient.invalidateQueries({ queryKey: ["my_order"] });

    setTimeout(() => setIsRefreshing(false), 5000);
  };

  const handleRefreshTrade = () => {
    if (isRefreshing) return;

    setIsRefreshing(true);
    queryClient.invalidateQueries({ queryKey: ["my_history_trade"] });

    setTimeout(() => setIsRefreshing(false), 5000);
  };

  const tabs: TabItem[] = [
    {
      label: "سفارشات باز",
      content: <OpenOrders />,
    },
    { label: "تاریخچه سفارشات", content: <OrderHistory /> },
    { label: "تاریخچه معاملات", content: <TradeHistoryTab /> },
  ];

  return (
    <Grid xs={12} mb={2} item>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Tabs
          setCurrentTab={setCurrentTab}
          tabs={tabs}
          isButtonType
          variant="standard"
          leftAction={
            <Stack display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <IconButton
                size="small"
                sx={{ mr: 0.5 }}
                onClick={currentTab === 2 ? handleRefreshTrade : handleRefresh}
              >
                <RefreshIcon />
              </IconButton>
            </Stack>
          }
        />
      </Paper>
    </Grid>
  );
};

export default Orders;
