import { SvgIcon, SvgIconProps } from "@mui/material";

const MarketFilterAllIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="13.375"
          y="4.375"
          width="6.25"
          height="0.75"
          rx="0.375"
          fill="#D9D9D9"
          stroke="#9E9E9E"
          stroke-width="0.75"
        ></rect>
        <rect
          x="13.375"
          y="8.875"
          width="6.25"
          height="0.75"
          rx="0.375"
          fill="#D9D9D9"
          stroke="#9E9E9E"
          stroke-width="0.75"
        ></rect>
        <rect
          x="13.375"
          y="13.375"
          width="6.25"
          height="0.75"
          rx="0.375"
          fill="#D9D9D9"
          stroke="#9E9E9E"
          stroke-width="0.75"
        ></rect>
        <rect
          x="13.375"
          y="17.875"
          width="6.25"
          height="0.75"
          rx="0.375"
          fill="#D9D9D9"
          stroke="#9E9E9E"
          stroke-width="0.75"
        ></rect>
        <mask id="path-5-inside-1_230_928" fill="white">
          <rect x="4" y="13" width="7" height="7" rx="1"></rect>
        </mask>
        <rect
          x="4"
          y="13"
          width="7"
          height="7"
          rx="1"
          stroke="#3CCAA1"
          // stroke-width="3"
          mask="url(#path-5-inside-1_230_928)"
        ></rect>
        <mask id="path-6-inside-2_230_928" fill="white">
          <rect x="4" y="4" width="7" height="7" rx="1"></rect>
        </mask>
        <rect
          x="4"
          y="4"
          width="7"
          height="7"
          rx="1"
          stroke="#E66766"
          // stroke-width="3"
          mask="url(#path-6-inside-2_230_928)"
        ></rect>
      </svg>
    </SvgIcon>
  );
};

export default MarketFilterAllIcon;
