import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  confirmOtpGoogleVerification,
  disableGoogleCode,
  enableGoogleCode,
  requestForGoogleVerification,
} from "./apis";

export function useRequestForGoogleVerification() {
  return useMutation({
    mutationFn: requestForGoogleVerification,
  });
}

export function useConfirmOtpGoogleVerification() {
  return useMutation({
    mutationFn: confirmOtpGoogleVerification,
  });
}

export function useEnableGoogleCode() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: enableGoogleCode,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Profile"] });
    },
  });
}

export function useDisabledGoogleCode() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: disableGoogleCode,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["Profile"] });
    },
  });
}
