import { Button } from "@components/common/button";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFilter } from "@hooks/useFilter";
import { Box, InputAdornment, Stack, SvgIcon } from "@mui/material";
import { useSendMobile } from "@services/queries/auth/mutations";
import { ArrowRight, Mobile } from "iconsax-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import { SendOTPSchema } from "../../../_validation";
import TermsModal from "./TermsModal";
import { Response } from "~types/index";
import { SendMobileRes } from "@services/queries/auth/types";

export const SignUpForm = () => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<z.output<typeof SendOTPSchema>>({
    mode: "onTouched",
    resolver: zodResolver(SendOTPSchema),
  });

  const { searchParams } = useFilter();

  const [showModal, setShowModal] = useState(false);

  const sendMobileMutation = useSendMobile({ isSignUpMode: true });

  const navigate = useNavigate();

  const referer = searchParams.get("referer") || "";

  const navigateToOTP = (data: Response<SendMobileRes>) => {
    const resData = data?.result;
    navigate("/v2/otp", {
      state: { mobile: getValues("mobile"), referer, resData },
    });
  };

  useEffect(() => {
    if (window?.isWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: "requestSMS" })
      );
    }
  }, []);

  const onSubmit = () => {
    const mobile = getValues("mobile");
    sendMobileMutation.mutate(
      { mobile, referer },
      {
        onSuccess(data) {
          navigateToOTP(data);
        },
      }
    );
  };

  useEffect(() => {
    const mobile = searchParams.get("mobile");
    if (mobile?.length === 11) {
      setValue("mobile", mobile, { shouldValidate: true });
      sendMobileMutation.mutate(
        { mobile, referer },
        {
          onSuccess(data) {
            navigateToOTP(data);
          },
        }
      );
    }
  }, []);

  return (
    <>
      <Typography
        variant="TitleBold"
        smVariant="ExtendedBodyBold"
        pb="2.75rem"
        sx={{
          textAlign: "left",
          color: (theme) => theme?.palette?.colorfulGray?.[700],
          width: "100%",
        }}
      >
        ورود/ ثبت‌نام
      </Typography>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        // gap="1.5rem"
        width="100%"
      >
        <TextField
          className="persian-num"
          numberInput
          allowLeadingZeros
          {...register("mobile")}
          inputProps={{ maxLength: 11, inputMode: "numeric" }}
          autoComplete="on"
          label="شماره موبایلتان را وارد کنید:"
          error={!!errors?.mobile?.message}
          helperText={errors?.mobile?.message}
          placeholder="مانند *******0912"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon
                  sx={{
                    color: (theme) => theme?.palette?.colorfulGray?.[300],
                  }}
                >
                  <Mobile variant="Bold" />
                </SvgIcon>
              </InputAdornment>
            ),
          }}
        />

        <Stack
          direction={"row"}
          gap={1}
          alignItems={"center"}
          mt="2.75rem"
          mb="1.5rem"
        >
          <Typography variant="BodyMedium" color={"neutral.900"}>
            با ثبت نام در بالینکس
          </Typography>
          <Typography
            onClick={() => setShowModal(true)}
            variant="BodyMedium"
            color={"info.600"}
            sx={{
              borderBottom: (theme) => `1px dashed ${theme.palette.info[600]}`,
              ":hover": {
                cursor: "pointer",
              },
            }}
          >
            قوانین و مقررات
          </Typography>
          <Typography variant="BodyMedium" color={"neutral.900"}>
            را می‌پذیرم.
          </Typography>
        </Stack>
        <Button
          id="enter-mobile-number"
          type="submit"
          disabled={!isValid}
          loading={sendMobileMutation?.isPending}
          size="large"
          fullWidth
          tailStartIcon={<ArrowRight variant="Bold" />}
        >
          ادامه
        </Button>
      </Box>

      <TermsModal open={showModal} onClose={() => setShowModal(false)} />
    </>
  );
};
