import { Button } from "@components/common/button";
import { IconTypography } from "@components/common/icon-typography";
import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack, useTheme } from "@mui/material";
import { bankInfo, formatCardBank } from "@utils/common";
import { Verify } from "iconsax-react";

interface ApproveAddBankModalProps {
  isOpen: boolean;
  onClose: () => void;
  data: any;
}

export const ApproveAddBankModal = ({
  isOpen,
  onClose,
  data,
}: ApproveAddBankModalProps) => {
  const theme = useTheme();

  return (
    <Modal open={isOpen} title="افزودن حساب بانکی" onClose={onClose}>
      <Stack width="100%" gap="1.5rem">
        <IconTypography
          bgColor="success.A100"
          icon={
            <Verify
              color={theme?.palette?.success?.[700]}
              variant="Bold"
              fontSize="1.97rem"
            />
          }
          title={
            <Typography color="success.900" variant="Body">
              شماره شبا و کارت تایید و اضافه شد
            </Typography>
          }
        />

        <Stack direction="row" justifyContent="space-between">
          <Typography color="colorfulGray.600" variant="BodyMedium">
            شماره کارت
          </Typography>
          <Stack direction="row" gap="0.5rem">
            <Typography
              variant="ExtendedBodyBold"
              color="colorfulGray.700"
              dir="ltr"
            >
              {formatCardBank(data?.no || data?.card)}
            </Typography>
            {bankInfo(data?.no || data?.card, "Card")?.logo && (
              <Image
                src={`/v2/images/banks-logo/${
                  bankInfo(data?.no || data?.card, "Card")?.logo
                }`}
                width="1.5rem"
                height="1.5rem"
                alt="عکس حساب بانکی"
              />
            )}
          </Stack>
        </Stack>
        {data?.iban && (
          <Stack direction="row" justifyContent="space-between">
            <Typography color="colorfulGray.600" variant="BodyMedium">
              شماره شبا
            </Typography>
            <Typography variant="ExtendedBodyBold" color="colorfulGray.700">
              {data?.iban || ""}
            </Typography>
          </Stack>
        )}
        <Button onClick={onClose} variant="outlined" size="medium">
          متوجه شدم
        </Button>
      </Stack>
    </Modal>
  );
};
