import { Typography } from "@components/common/typography";
import { CopyIcon } from "@components/shared/copy-icon";
import { IconButton, Stack } from "@mui/material";
import { useUserStore } from "@store/user";
import { toClipboard } from "@utils/common";
import { useState } from "react";

function ReferralCard() {
  const user = useUserStore((state) => state.user);
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [isReferralCodeCopied, setIsReferralCodeCopied] = useState(false);

  const referralLink =
    window?.location?.origin + "/v2/login?referer=" + user?.referralId;

  return (
    <Stack
      borderRadius="1.125rem"
      bgcolor="white"
      boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.16)"
      p={"1.5rem"}
    >
      <Typography
        variant="ExtendedBodyBold"
        smVariant="BodyBold"
        color="colorfulGray.700"
      >
        درآمد کسب کن
      </Typography>
      <Typography
        variant="ExtendedBody"
        smVariant="Body"
        color="colorfulGray.700"
      >
        دوستانت رو معرفی کن و و تا ۳۰ درصد از کارمزد هدیه بگیر
      </Typography>
      <Stack
        direction="row"
        mt="1.81rem"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="ExtendedBodyMedium"
          smVariant="Body"
          color="colorfulGray.700"
        >
          کد دعوت شما
        </Typography>
        <Stack direction="row" gap="0.2rem" alignItems="center">
          <IconButton
            onClick={async () =>
              await toClipboard({
                text: user?.referralId ?? "",
                cb: (isCopied) => setIsReferralCodeCopied(isCopied),
              })
            }
          >
            <CopyIcon
              isCopied={isReferralCodeCopied}
              onChange={(isCopied) => setIsReferralCodeCopied(isCopied)}
              text={user?.referralId ?? ""}
            />
          </IconButton>
          <Typography
            variant="TitleMedium"
            smVariant="Body"
            color="colorfulGray.700"
          >
            {user?.referralId}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        mt="0.5rem"
        width="100%"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography
          variant="ExtendedBodyMedium"
          smVariant="Body"
          color="colorfulGray.700"
        >
          لینک دعوت شما
        </Typography>
        <Stack direction="row" gap="0.2rem" alignItems="center">
          <IconButton
            onClick={async () =>
              await toClipboard({
                text: referralLink ?? "",
                cb: (isCopied) => setIsLinkCopied(isCopied),
              })
            }
          >
            <CopyIcon
              isCopied={isLinkCopied}
              onChange={(isCopied) => setIsLinkCopied(isCopied)}
              text={referralLink?? ""}
            />
          </IconButton>
          <Typography
            variant="TitleMedium"
            smVariant="Body"
            color="colorfulGray.700"
            dir="ltr"
          >
            {referralLink?.slice(0, 20) + "..."}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default ReferralCard;
