import {
  IconButton,
  InputAdornment,
  SvgIcon,
  TextFieldProps,
} from "@mui/material";
import { CloseCircle, SearchNormal1 } from "iconsax-react";
import { TextField } from "../textfield";
import { forwardRef, useEffect, useState } from "react";
import { useDebounce } from "@hooks/useDebounce";
import { useFilter } from "@hooks/useFilter";

interface SearchFieldProps {
  onResetValueOnClick?: () => void;
  withDebounce?: boolean;
  onDebounceTrigger?: (search: string) => void;
  isCloseCircleShow?: boolean;
}

export const SearchBox = forwardRef(
  (
    {
      placeholder = "جست‌وجو",
      InputProps,
      withDebounce,
      onDebounceTrigger = () => {},
      sx = {},
      onResetValueOnClick = () => {},
      isCloseCircleShow,
      ...props
    }: SearchFieldProps & TextFieldProps,
    ref: any
  ) => {
    const { searchParams } = useFilter();

    const [search, setSearch] = useState<string>(
      searchParams?.get("search") || ""
    );

    const debouncedSearch = useDebounce(search || "", 500);

    useEffect(() => {
      if (withDebounce) {
        onDebounceTrigger(search);
      }
    }, [debouncedSearch]);

    return (
      <TextField
        sx={{
          "&.Mui-focused": {
            ".MuiInputAdornment-positionEnd": {
              ".MuiSvgIcon-root": {
                color: (theme) => theme?.palette?.colorfulGray?.[500],
              },
            },
          },
          ...sx,
        }}
        ref={ref}
        onChange={(e) => setSearch(e.target.value)}
        // @ts-ignore
        value={search}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SvgIcon
                sx={{
                  color: (theme) => theme?.palette?.colorfulGray?.[300],
                }}
              >
                <SearchNormal1 size={"1.5rem"} />
              </SvgIcon>
            </InputAdornment>
          ),
          endAdornment: (!!search?.length || !!isCloseCircleShow) && (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search"
                onClick={() => {
                  setSearch("");
                  onResetValueOnClick();
                }}
              >
                <SvgIcon
                  sx={{
                    color: (theme) => theme?.palette?.colorfulGray?.[400],
                  }}
                >
                  <CloseCircle variant="Bold" size={"1.5rem"} />
                </SvgIcon>
              </IconButton>
            </InputAdornment>
          ),
          ...InputProps,
        }}
        placeholder={placeholder}
        {...props}
      />
    );
  }
);
