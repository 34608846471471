import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { Response } from "~types/index";

const baseURL =
  import.meta.env.VITE_APP_UPLOAD_URL || "https://files.hebitex.ir";

const baseApi = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const request = {
  get: <T>(endpoint: string, headers?: AxiosRequestConfig<any>) =>
    baseApi.get<Response<T>>(endpoint, headers).then(responseBody),
  post: <T>(endpoint: string, body?: {}, headers?: AxiosRequestConfig<any>) =>
    baseApi.post<Response<T>>(endpoint, body, headers).then(responseBody),
  put: <T>(endpoint: string, body?: {}, headers?: AxiosRequestConfig<any>) =>
    baseApi.put<Response<T>>(endpoint, body, headers).then(responseBody),
  delete: <T>(endpoint: string, headers?: AxiosRequestConfig<any>) =>
    baseApi.delete<Response<T>>(endpoint, headers).then(responseBody),
};

export interface UploadRes {
  fullUrl: string;
  absoluteUrl: string;
  htmlUrl: string;
  fileName: string;
  downloadUrl: string;
}

export const uploadApi = async (formData: FormData) => {
  return request.post<UploadRes>(`/uploads`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
