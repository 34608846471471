import { Button } from "@components/common/button";
import { Modal } from "@components/common/modal";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { InputAdornment, Stack, useTheme } from "@mui/material";
import { Card, CardAdd } from "iconsax-react";
import { useEffect, useState } from "react";
import { ApproveAddBankModal } from "../approve-add-bank-modal";
import { Controller, useForm } from "react-hook-form";
import { AddBankAccount } from "@pages/dashborad/wallet/_validations";
import { z } from "zod";
import { bankInfo } from "@utils/common";
import { Image } from "@components/common/image";
import { useHandleAddBankAccount } from "@services/queries/wallet/mutations";
import { toast } from "@utils/toast";
import { useQueryClient } from "@tanstack/react-query";

interface AddBankModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const AddBankModal = ({
  isOpen,
  onClose,
  onSuccess,
}: AddBankModalProps) => {
  const theme = useTheme();
  const queryClient = useQueryClient();

  const handleAddBankAccountMutation = useHandleAddBankAccount();

  const { control, watch, getValues, setValue } =
    useForm<z.output<typeof AddBankAccount>>();

  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [bankLogo, setBankLogo] = useState<{
    logo: string;
    name: string;
  } | null>(null);

  const handleSearchBank = (val: string) => {
    if (val?.length >= 6) {
      setBankLogo(bankInfo(val, "Card") || null);
    } else {
      setBankLogo(null);
    }
  };

  const handleSubmit = () => {
    handleAddBankAccountMutation.mutate(
      {
        accType: "card",
        name: bankLogo?.name || "حساب بانکی",
        no: getValues("account_num"),
      },
      {
        onSuccess: (data) => {
          if (!!data?.success) {
            queryClient.invalidateQueries({ queryKey: ["my_bank_account"] });
            // @ts-ignore
            setIsApproveModalOpen(true);
          }
        },
      }
    );
  };

  const handleClickApproveClose = () => {
    setIsApproveModalOpen(false);
    onSuccess();
  };

  useEffect(() => {
    setValue("account_num", "");
    setBankLogo(null);
  }, [isOpen]);

  return (
    <>
      <Modal open={isOpen} title="افزودن حساب بانکی" onClose={onClose}>
        <Stack width="100%" gap="1rem">
          <Controller
            control={control}
            name="account_num"
            render={({ field: { value, onChange, ...otherFields } }) =>
              // prettier-ignore
              <TextField
                autoFocus
                numberInput
                inputMode="numeric"
                value={(value)}
                onChange={(e) => {
                  const regex = /^\d*\.?\d*$/;
                  const val = ((e.target.value)?.replaceAll("$1-", ""));
                  if (val === "" || regex.test(val)) {
                    onChange(val);
                    handleSearchBank(val)
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {bankLogo ? <Image src={`/v2/images/banks-logo/${bankLogo.logo}`} width="1.5rem" height="1.5rem" alt={bankLogo.name} /> : <Card
                        variant="Bold"
                        size="1.5rem"
                        color={theme.palette.colorfulGray[400]}
                      />}
                    </InputAdornment>
                  ),
                }}
                label="شماره کارت"
                placeholder="۱۲۳۴ ۴۵۶۷ ۱۲۳۴ ۳۴۵۶"
                helperText={
                  <Typography
                    color="colorfulGray.500"
                    variant="Caption"
                    align="left"
                  >
                    شماره کارت به‌نام خودتان باشد
                  </Typography>
                }
                
                inputProps={{
                  maxlength: 16
                }}
                {...otherFields}
              />
            }
          />

          <Button
            disabled={watch("account_num")?.length !== 16}
            onClick={handleSubmit}
            loading={handleAddBankAccountMutation?.isPending}
            variant="outlined"
            fullWidth
            startIcon={<CardAdd size="1.5rem" variant="Bold" />}
          >
            <Typography variant="BodyMedium" color="primary.700">
              افزودن
            </Typography>
          </Button>
        </Stack>
      </Modal>

      {handleAddBankAccountMutation?.data?.result && (
        <ApproveAddBankModal
          data={handleAddBankAccountMutation?.data?.result}
          isOpen={isApproveModalOpen}
          onClose={handleClickApproveClose}
        />
      )}
    </>
  );
};
