import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import { ButtonItem, FilterBtnCurrency, HeaderProps } from "../types";
import { Star1 } from "iconsax-react";

const buttons: ButtonItem[] = [
  {
    label: "بر پایه تومان",
    type: "IRT",
  },
  {
    label: "بر پایه تتر",
    type: "USDT",
  },
];

const CryptoHeader = ({ setBtnFilter, btnFilter }: HeaderProps) => {
  const handleClick = (type: FilterBtnCurrency) => {
    setBtnFilter(type);
  };
  const theme = useTheme();
  return (
    <Stack display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <IconButton onClick={() => setBtnFilter("FAV")}>
        <Star1
          variant={btnFilter === "FAV" ? "Bold" : "Outline"}
          color={theme?.palette?.primary?.[700]}
        />
      </IconButton>
      <Divider orientation="vertical" flexItem />

      <Box
        sx={{
          ":first-of-type": {
            ml: 2.2,
          },
          display: "flex",

          width: "100%",
          gap: 1,
        }}
      >
        {buttons.map((button, index) => (
          <Button
            key={index}
            variant={button.type === btnFilter ? "contained" : "outlined"}
            // color="primary"
            size="small"
            sx={{
              height: "32px",
              color: button.type === btnFilter ? "neutral.50" : "primary",
              fontSize: "12px",
              boxShadow: "none",
              "&.MuiButton-contained": {
                color: "white",
                fontWeight: "bold",
              },
              "&.MuiButton-outlined": {
                backgroundColor: "primary.50",
                color: "colorfulGray.700",
                border: "none",
                fontWeight: "bold",
              },
            }}
            onClick={() => handleClick(button?.type as FilterBtnCurrency)}
          >
            {button.label}
          </Button>
        ))}
      </Box>
    </Stack>
  );
};

export default CryptoHeader;
