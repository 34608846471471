type SetCookieType = {
  name: string;
  value: string;
  expireInTimeStamp?: number;
  expireInSeconds?: number;
  path?: string;
};

export function setCookie({
  name,
  value,
  expireInTimeStamp,
  expireInSeconds,
  path = "/",
}: SetCookieType): void {
  let expires = "";

  if (expireInTimeStamp) {
    const date = new Date(expireInTimeStamp);
    expires = "; expires=" + date.toUTCString();
  } else if (expireInSeconds) {
    const date = new Date();
    date.setTime(date.getTime() + expireInSeconds * 1000);
    expires = "; expires=" + date.toUTCString();
  } else {
    // Default expiration: 30 days
    const date = new Date();
    date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toUTCString();
  }

  document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(
    value
  )}${expires}; path=${path}`;
}

export function getCookie(name: string) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export function clearCookie(name: string) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

export const getLocalStorageItem = (key: string): Promise<string | null> => {
  return new Promise((resolve) => {
    const value = localStorage.getItem(key);
    resolve(value);
  });
};

export const setLocalStorageItem = (
  key: string,
  value: string
): Promise<void> => {
  return new Promise((resolve) => {
    localStorage.setItem(key, value);
    resolve();
  });
};

export const removeLocalStorageItem = (key: string): Promise<void> => {
  return new Promise((resolve) => {
    localStorage.removeItem(key);
    resolve();
  });
};
