import { Button } from '@components/common/button';
import { Wrapper } from '@components/layout/wrapper';
import { useSocket } from '@context/scoket';
import { Grid, Hidden, Typography } from '@mui/material';
import { useGetMarketCurrency } from '@services/queries/markets/queries';
import { IMarket } from '@services/queries/markets/types';
import { ArrowDown2 } from 'iconsax-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Chart from './_components/chart';
import Header from './_components/header';
import OrderBook from './_components/order-book';
import ShowTabs from './_components/order-book/showTabs';
import Orders from './_components/orders';
import TabOrderBook from './_components/orders/tab-order-book';
import { SelectMarketModal } from './_components/select-market-modal';
import Spot from './_components/spot';
import Trade from './_components/trade';
import Wealth from './_components/wealth';
import useCurrentCurrency from './_hooks/useCurrentCurrency';

export default function MarketDetails() {
  const [openModal, setOpenModal] = useState(false);
  const [symbol, setSymbol] = useState<string>();

  const navigate = useNavigate();

  const { handleJoinRoom, handleLeaveRoom } = useSocket();

  const getMarketCurrencyQuery = useGetMarketCurrency();

  const currencies: IMarket[] =
    getMarketCurrencyQuery?.data?.result?.items || [];

  const currentCurrency = useCurrentCurrency();

  useEffect(() => {
    if (!currentCurrency) return;
    handleJoinRoom(currentCurrency?.id?.toString());
    setSymbol(currentCurrency?.symbol);

    return () => handleLeaveRoom(currentCurrency?.id?.toString());
  }, [currentCurrency?._id]);

  // if (!currentCurrency) {
  //   navigate(`/v2/dashboard/markets`);
  //   return <></>;
  // }

  const handleSelectCoin = (coin: IMarket) => {
    navigate(`/v2/markets/${coin?._id}`);
    setOpenModal(false);
  };

  return (
    <>
      <Wrapper
        backMode
        hideBottomNavigation
        hideSideNavigation
        loading={getMarketCurrencyQuery?.isLoading}
        isNoPaddingModeContainer
        maxWidth="xl"
        title={
          <Button
            sx={{ width: 'fit-content' }}
            variant="text"
            textColor="primary.500"
            onClick={() => setOpenModal(true)}
            endIcon={<ArrowDown2 variant="Bold" size="1rem" />}
          >
            <Typography variant="BodyMedium" color="primary.500">
              {currentCurrency?.localizedTitle}
            </Typography>
          </Button>
        }
        // otherActions={<StarBorderIcon />}
      >
        <Header />
        <Grid container spacing={2}>
          <Hidden lgDown>
            <Grid
              item
              display={'flex'}
              flexDirection={'column'}
              height={'100%'}
              xs={3}
            >
              <Wealth
                /* @ts-ignore */
                elevation={0}
              />
              <Trade />
            </Grid>
          </Hidden>
          <Hidden mdDown>
            <Grid item xs={6} md={8} lg={6}>
              <Chart symbol={symbol} />
              <Spot marginTop={2} />
            </Grid>
            <OrderBook />
          </Hidden>
          <Hidden mdUp>{/* <TabOrderBook /> */}</Hidden>
          <Hidden mdUp>
            <Chart symbol={symbol} grid={12} />
            <ShowTabs />
            <TabOrderBook />
          </Hidden>
          <Hidden mdDown>
            <Orders />
          </Hidden>
        </Grid>
      </Wrapper>
      <SelectMarketModal
        title="انتخاب بازار"
        searchable={true}
        open={openModal}
        list={currencies}
        onSelectCoin={(coin) => handleSelectCoin(coin)}
        onClose={() => setOpenModal(false)}
      />
    </>
  );
}
