import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { CircularProgress, Divider, Stack } from "@mui/material";
import { getCoinIconURL, getPersianEquivalent } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { CancelOrderModal } from "../cancel-order-modal";
import { Modal } from "@components/common/modal";
import { OrderItemRes } from "@services/queries/financial/types";
import {
  format,
  formatDistanceToNow,
  isBefore,
  subDays,
} from "date-fns-jalali";
import { OrdersStatus } from "~types/index";
import { faIR } from "date-fns-jalali/locale";
import { useSpecificTransaction } from "@services/queries/financial/queries";
import { IconTypography } from "@components/common/icon-typography";
import { Button } from "@components/common/button";

const formatDate = (dateString: string): string => {
  if (!dateString) return "نامشخص";

  const date = new Date(dateString);
  const oneDayAgo = subDays(new Date(), 1);

  if (isBefore(date, oneDayAgo)) {
    return format(date, "yyyy/MM/dd - HH:mm", { locale: faIR });
  } else {
    return `${formatDistanceToNow(date, { addSuffix: true, locale: faIR })}`;
  }
};

export const OrderDetails = ({
  data,
  onClose,
}: {
  data: OrderItemRes;
  onClose: () => void;
}) => {
  const [isOutOfView, setIsOutOfView] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

  const specificTransactionQuery = useSpecificTransaction({
    orderId: data?._id,
  });

  return (
    <Stack
      width="100%"
      gap="1.5rem"
      position="relative"
      py="1rem"
      px="0.5rem"
      sx={{
        maxHeight: "80vh",
        minWidth: { md: "450px" },
        overflow: "auto",
      }}
    >
      {/* Cancel Modal */}
      <Modal
        title="لغو سفارش"
        open={isCancelModalOpen}
        onClose={() => setIsCancelModalOpen(false)}
      >
        <CancelOrderModal
          onClose={() => setIsCancelModalOpen(false)}
          data={data}
          onSuccess={() => {
            setIsCancelModalOpen(false);
            onClose();
          }}
        />
      </Modal>

      {/* Animation Card Start  */}
      <AnimatePresence>
        <Stack
          component={motion.div}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          boxShadow="0px 0px 10px 1px rgba(0, 0, 0, 0.1)"
          borderRadius="1.125rem"
          bgcolor="white"
          zIndex={10}
          p="1.5rem"
          position="sticky"
          top={0}
          marginTop="-140px"
          animate={{
            transform: isOutOfView ? "translateY(0px)" : "translateY(-200px)",
          }}
          transition={{
            duration: isOutOfView ? 0.3 : 0,
            type: "keyframes",
            ease: "easeInOut",
          }}
        >
          <Stack alignItems="flex-start" gap="0.5rem">
            <Typography
              py="0.25rem"
              px="0.25rem"
              bgcolor={data?.side === "buy" ? "info.A100" : "error.A100"}
              color={data?.side === "buy" ? "info.700" : "error.500"}
              variant="CaptionBold"
              smVariant="SmallCaptionBold"
              borderRadius="0.25rem"
            >
              {data?.side === "buy" ? "خرید" : "فروش"}
            </Typography>
            <Typography
              variant="CaptionMedium"
              smVariant="SmallCaptionMedium"
              color="colorfulGray.400"
            >
              {formatDate(data?.createdAt)}
            </Typography>
          </Stack>
          <Stack alignItems="center" gap="0.3rem">
            <Stack direction="row">
              <Image
                src={getCoinIconURL(data?.baseCurrency)}
                alt={data?.baseCurrency}
                width="2.25rem"
                height="2.25rem"
                sx={{ mr: "-0.5rem", zIndex: 2 }}
              />
              <Image
                src={getCoinIconURL(data?.quoteCurrency)}
                alt={data?.quoteCurrency}
                width="2.25rem"
                height="2.25rem"
                sx={{ ml: "-0.5rem" }}
              />
            </Stack>
            <Typography variant="BodyMedium" color="colorfulGray.500">
              <Typography
                component="span"
                px="0.25rem"
                variant="BodyMedium"
                color="colorfulGray.800"
              >
                {data?.quoteCurrency} /
              </Typography>
              {data?.baseCurrency}
            </Typography>
          </Stack>
        </Stack>
      </AnimatePresence>
      {/* Animation Card End  */}

      <Stack
        component={motion.div}
        onViewportLeave={() => setIsOutOfView(true)}
        onViewportEnter={() => setIsOutOfView(false)}
        gap="1rem"
        boxShadow="0px 0px 10px 1px rgba(0, 0, 0, 0.1)"
        borderRadius="1.125rem"
        p="1.5rem"
      >
        <Stack gap="1rem">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
          >
            <Typography
              py="0.25rem"
              px="0.25rem"
              bgcolor={data?.side === "buy" ? "info.A100" : "error.A100"}
              color={data?.side === "buy" ? "info.700" : "error.500"}
              variant="CaptionBold"
              smVariant="SmallCaptionBold"
              borderRadius="0.25rem"
            >
              {data?.side === "buy" ? "خرید" : "فروش"}
            </Typography>

            <Stack alignItems="center" gap="0.3rem">
              <Stack direction="row">
                <Image
                  src={getCoinIconURL(data?.baseCurrency)}
                  alt={data?.baseCurrency}
                  width="2.25rem"
                  height="2.25rem"
                  sx={{ mr: "-0.5rem", zIndex: 2 }}
                />
                <Image
                  src={getCoinIconURL(data?.quoteCurrency)}
                  alt={data?.quoteCurrency}
                  width="2.25rem"
                  height="2.25rem"
                  sx={{ ml: "-0.5rem" }}
                />
              </Stack>
              <Typography variant="BodyMedium" color="colorfulGray.500">
                <Typography
                  component="span"
                  px="0.25rem"
                  variant="BodyMedium"
                  color="colorfulGray.800"
                >
                  {data?.quoteCurrency} /
                </Typography>
                {data?.baseCurrency}
              </Typography>
            </Stack>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body" color="colorfulGray.600">
              نوع
            </Typography>
            <Typography variant="BodyMedium" color="colorfulGray.700">
              قیمت {data?.type === "limit" ? "محدود" : "بازار"}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              smVariant="Caption"
              variant="Body"
              color="colorfulGray.600"
            >
              وضعیت
            </Typography>
            <Typography
              py="0.25rem"
              px="0.5rem"
              bgcolor="neutral.50"
              color="colorfulGray.600"
              variant="CaptionBold"
              smVariant="SmallCaptionBold"
              borderRadius="0.25rem"
            >
              {getPersianEquivalent(data?.status as OrdersStatus)}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body" color="colorfulGray.600">
              زمان
            </Typography>
            <Typography variant="BodyMedium" color="colorfulGray.700">
              {formatDate(data?.createdAt)}
            </Typography>
          </Stack>

          <Divider sx={{ borderStyle: "dashed" }} />

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body" color="colorfulGray.600">
              قیمت ثبت شده
            </Typography>
            <Typography
              smVariant="BodyBold"
              variant="ExtendedBodyBold"
              color="colorfulGray.700"
            >
              {numUtils.commaformatter.toCurrency(Number(data?.price))}
              <Typography
                component="span"
                variant="Caption"
                smVariant="SmallCaption"
                px="0.25rem"
                color="colorfulGray.500"
              >
                {data?.quoteCurrency}
              </Typography>
            </Typography>
          </Stack>

          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="Body" color="colorfulGray.600">
                تعداد
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.700"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.size))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.5rem"
                  color="colorfulGray.500"
                >
                  {data?.baseCurrency}
                </Typography>
              </Typography>
            </Stack>

            <Divider sx={{ borderStyle: "dashed" }} />

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="Body" color="colorfulGray.600">
                کارمزد
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.700"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.fillFees))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.25rem"
                  color="colorfulGray.500"
                >
                  {data?.feeUnit}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="Body" color="colorfulGray.600">
                مقدار تمام شده
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.700"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.filledSize))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.25rem"
                  color="colorfulGray.500"
                >
                  {data?.baseCurrency}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="Body" color="colorfulGray.600">
                میانگین واحد تمام شده
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.700"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.avgPrice))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.25rem"
                  color="colorfulGray.500"
                >
                  {data?.quoteCurrency}
                </Typography>
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="Body" color="colorfulGray.600">
                قیمت کل تمام شده
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.700"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.funds))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.25rem"
                  color="colorfulGray.500"
                >
                  {data?.quoteCurrency}
                </Typography>
              </Typography>
            </Stack>
          </>
        </Stack>
        {data?.status === "open" && (
          <Button
            onClick={() => setIsCancelModalOpen(true)}
            textColor="error.500"
            variant="outlined"
          >
            <Typography variant="BodyMedium">لغو سفارش</Typography>
          </Button>
        )}
      </Stack>

      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="ExtendedBodyBold" color="colorfulGray.800">
            لیست معاملات انجام شده
          </Typography>

          {specificTransactionQuery?.isLoading && (
            <CircularProgress size="1rem" />
          )}
        </Stack>
        {specificTransactionQuery?.data?.items?.length ? (
          <Stack component="ul">
            {specificTransactionQuery?.data?.items.map((item) => (
              <Stack
                component="li"
                py="1rem"
                borderBottom={1}
                gap="0.25rem"
                borderColor="primary.50"
                sx={{ "&:last-child": { borderBottom: "none" } }}
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="Body" color="colorfulGray.600">
                    قیمت
                  </Typography>
                  <Typography
                    smVariant="BodyBold"
                    variant="ExtendedBodyBold"
                    color="colorfulGray.700"
                  >
                    {numUtils.commaformatter.toCurrency(Number(item?.price))}
                    <Typography
                      component="span"
                      variant="Caption"
                      smVariant="SmallCaption"
                      px="0.25rem"
                      color="colorfulGray.500"
                    >
                      {data?.quoteCurrency}
                    </Typography>
                  </Typography>
                </Stack>

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="Body" color="colorfulGray.600">
                    مقدار
                  </Typography>
                  <Typography
                    smVariant="BodyBold"
                    variant="ExtendedBodyBold"
                    color="colorfulGray.700"
                  >
                    {numUtils.commaformatter.toCurrency(Number(item?.size))}
                    <Typography
                      component="span"
                      variant="Caption"
                      smVariant="SmallCaption"
                      px="0.25rem"
                      color="colorfulGray.500"
                    >
                      {data?.baseCurrency}
                    </Typography>
                  </Typography>
                </Stack>

                {/* <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="Body" color="colorfulGray.600">
                      کارمزد
                    </Typography>
                    <Typography
                      smVariant="BodyBold"
                      variant="ExtendedBodyBold"
                      color="colorfulGray.700"
                    >
                      {numUtils.commaformatter.toCurrency(0.02)}
                      <Typography
                        component="span"
                        variant="Caption"
                        smVariant="SmallCaption"
                        px="0.25rem"
                        color="colorfulGray.500"
                      >
                        {data?.baseCurrency}
                      </Typography>
                    </Typography>
                  </Stack> */}

                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="Body" color="colorfulGray.600">
                    جمع مبلغ
                  </Typography>
                  <Typography
                    smVariant="BodyBold"
                    variant="ExtendedBodyBold"
                    color="colorfulGray.700"
                  >
                    {numUtils.commaformatter.toCurrency(
                      Number(item?.size) * Number(item?.price)
                    )}
                    <Typography
                      component="span"
                      variant="Caption"
                      smVariant="SmallCaption"
                      px="0.25rem"
                      color="colorfulGray.500"
                    >
                      {data?.quoteCurrency}
                    </Typography>
                  </Typography>
                </Stack>

                <Typography
                  textAlign="right"
                  color="colorfulGray.400"
                  smVariant="SmallCaptionMedium"
                  variant="CaptionMedium"
                >
                  {formatDate(item?.time)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        ) : (
          <Stack py="5rem">
            <IconTypography
              icon={
                <Image
                  alt="empty-img"
                  src="/v2/images/empty.png"
                  height="72px"
                  width="72px"
                />
              }
              title="هیچ معامله‌ای یافت نشد."
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};
