import { Button } from "@components/common/button";
import { Image } from "@components/common/image";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { ArrowDropDown } from "@mui/icons-material";
import { InputAdornment, Stack } from "@mui/material";
import { GetPairDetailsRes } from "@services/queries/otc/types";
import { Controller, useFormContext } from "react-hook-form";
import { OtcMethod } from "../../_types";
import { numUtils } from "@utils/num-helpers";
import { useEffect } from "react";

interface ReceiveFormProps {
  onSelectCoin: () => void;
  amount: string | number;
  unit_en: string;
  approximatePriceFaName: string;
  unit_icon: string;
  dataDetails?: GetPairDetailsRes;
  method: OtcMethod;
  base_fa_name: string;
}

export const ReceiveForm = ({
  amount,
  onSelectCoin,
  unit_en,
  approximatePriceFaName,
  base_fa_name,
  unit_icon,
  method,
  dataDetails,
}: ReceiveFormProps) => {
  const { control, setValue, getValues } = useFormContext();

  const handlePaymentInput = (val: number) => {
    const price = dataDetails?.price;
    if (price) {
      const payment = method === OtcMethod.Buy ? val * price : val / price;

      setValue(
        "payment",
        (payment || 0)?.toFixed(dataDetails?.toRoundScale).toString()
      );
    }
  };

  // When price change have to change receive amount
  useEffect(() => {
    const price = dataDetails?.price;
    if (!!price && !!getValues("payment")) {
      const val = Number(numUtils.commaformatter.toRaw(getValues("payment")));
      const receive = method === OtcMethod.Buy ? val / price : val * price;
      setValue(
        "receive",
        (receive || 0)?.toFixed(dataDetails?.toRoundScale).toString()
      );
    }
  }, [dataDetails?.price]);

  return (
    <>
      <Typography variant="body1" color="primary.800">
        دریافت می‌کنم (تقریبی):
      </Typography>

      <Controller
        control={control}
        name="receive"
        render={({ field: { value, onChange, ...otherFields } }) => (
          <TextField
            className="persian-num"
            inputMode="numeric"
            inputProps={{ maxLength: 15 }}
            value={numUtils.commaformatter.toCurrency(value || "")}
            onChange={(e) => {
              const regex = /^\d*\.?\d*$/;
              const val = numUtils.commaformatter.toRaw(e.target.value);
              if (val === "" || regex.test(val)) {
                onChange(val);
                handlePaymentInput(+val);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Stack borderRight="1px solid" borderColor="neutral.400">
                    <Button onClick={onSelectCoin} variant="text" size="small">
                      <Stack direction="row" alignItems="center">
                        <Typography variant="body2" color="neutral.600">
                          {unit_en}
                        </Typography>
                        <Image
                          src={unit_icon}
                          width="1.5rem"
                          height="1.5rem"
                          alt={unit_en}
                          sx={{ ml: "0.5rem" }}
                        />
                        <ArrowDropDown
                          sx={{
                            fontSize: "1.5rem",
                            color: (theme) => theme?.palette?.primary?.[800],
                          }}
                        />
                      </Stack>
                    </Button>
                  </Stack>
                </InputAdornment>
              ),
            }}
            {...otherFields}
          />
        )}
      />

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="caption" color="primary.800">
          قیمت تقریبی {approximatePriceFaName}:
        </Typography>
        <Typography
          className="persian-num"
          variant="caption"
          color="primary.800"
        >
          {amount} {base_fa_name}
        </Typography>
      </Stack>
    </>
  );
};
