export interface IValueFilter {
  id: string;
  filter: { field: string; operator: string; value: any }[];
}

export type FilterItem = {
  title: string;
  prefix: string;
  type: "CHECKBOX" | "DATE";
  children?: {
    title: string;
    value: IValueFilter;
  }[];
};

export const orderFilterList: FilterItem[] = [
  {
    title: "عملیات",
    prefix: "OPERATION_",
    type: "CHECKBOX",
    children: [
      {
        title: "فروش",
        value: {
          id: "SELL",
          filter: [{ field: "side", operator: "eq", value: "sell" }],
        },
      },
      {
        title: "خرید",
        value: {
          id: "BUY",
          filter: [{ field: "side", operator: "eq", value: "buy" }],
        },
      },
    ],
  },
  {
    title: "وضعیت سفارش",
    prefix: "ORDER_STATUS_",
    type: "CHECKBOX",
    children: [
      {
        title: "سفارش‌ باز",
        value: {
          id: "OPEN",
          filter: [{ field: "status", operator: "eq", value: "open" }],
        },
      },
      {
        title: "سفارش‌ بسته ",
        value: {
          id: "CLOSED",
          filter: [{ field: "status", operator: "eq", value: "filled" }],
        },
      },
      {
        title: "سفارش‌ لغو شده",
        value: {
          id: "CANCELED",
          filter: [{ field: "status", operator: "eq", value: "cancelled" }],
        },
      },
    ],
  },
  {
    title: "نوع سفارش",
    prefix: "ORDER_MODE_",
    type: "CHECKBOX",
    children: [
      {
        title: "سفارش‌با قیمت ثابت",
        value: {
          id: "FIXED_PRICE",
          filter: [{ field: "type", operator: "eq", value: "limit" }],
        },
      },
      {
        title: "سفارش‌ با قیمت بازار",
        value: {
          id: "MARKET_PRICE",
          filter: [{ field: "type", operator: "eq", value: "market" }],
        },
      },
      // {
      //   title: "سفارش‌ سریع",
      //   value: "FAST",
      // },
    ],
  },
  {
    title: "بازه زمانی",
    prefix: "DATE",
    type: "DATE",
  },
];

export const otcFilterList: FilterItem[] = [
  {
    title: "عملیات",
    prefix: "OPERATION_",
    type: "CHECKBOX",
    children: [
      {
        title: "فروش",
        value: {
          id: "SELL",
          filter: [
            { field: "to", operator: "eq", value: ["IRT", "USDT"] },
            { field: "from", operator: "!eq", value: "IRT" },
          ],
        },
      },
      {
        title: "خرید",
        value: {
          id: "BUY",
          filter: [
            { field: "from", operator: "eq", value: ["IRT", "USDT"] },
            { field: "to", operator: "!eq", value: "IRT" },
          ],
        },
      },
    ],
  },
  {
    title: "بازه زمانی",
    prefix: "DATE",
    type: "DATE",
  },
];

export const transactionsFilterList: FilterItem[] = [
  {
    title: "عملیات",
    prefix: "OPERATION_",
    type: "CHECKBOX",
    children: [
      {
        title: "تراکنش‌های ریالی",
        value: {
          id: "TOMAN_TRANSACTIONS",
          filter: [{ field: "currency", operator: "eq", value: "IRT" }],
        },
      },
      {
        title: "تراکنش‌های رمزارزی",
        value: {
          id: "CRYPTO_TRANSACTIONS",
          filter: [{ field: "currency", operator: "!eq", value: "IRT" }],
        },
      },
      {
        title: "واریز",
        value: {
          id: "DEPOSIT",
          filter: [{ field: "actionType", operator: "eq", value: "deposit" }],
        },
      },
      {
        title: "برداشت",
        value: {
          id: "WITHDRAW",
          filter: [{ field: "actionType", operator: "eq", value: "withdraw" }],
        },
      },
    ],
  },
  {
    title: "بازه زمانی",
    prefix: "DATE",
    type: "DATE",
  },
];

export const listTabs = [
  {
    label: "تراکنش‌ها",
    value: "TRANSACTIONS",
  },
  {
    label: "سفارشات",
    value: "ORDERS",
  },
  {
    label: "معاملات سریع",
    value: "OTC",
  },
];
