import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { BaseCoin, type Response } from "~types/index";

import {
  getCurrencyInfo,
  getLimitOrderPreview,
  getMarketList,
  getMarketOrderBook,
  getMarketSlider,
  getMarketTrades,
  getMyHistoryTrade,
  getMyOrders,
  getUserBalance,
} from "./apis";

import type {
  HistoryTrade,
  IMarket,
  IMarketList,
  IOrderPreview,
  IOrderPreviewArgs,
  IUserBalance,
  MarketListArgs,
  MarketListResponse,
  OrderBook,
  OrderHistory,
  Trades,
} from "./types";
import { DEFAULT_PER_PAGE } from "@constants/index";

export const useGetMarketCurrency = (productId?: string) =>
  useQuery<Response<IMarketList>, void>({
    queryKey: ["currency_market", productId],
    queryFn: () => getCurrencyInfo(productId),
  });

export const useGetTrades = (marketId: string) =>
  useQuery<Response<Trades[]>, void>({
    queryKey: ["market_trades", marketId],
    queryFn: () => getMarketTrades(marketId),
    enabled: !!marketId,
  });

export const useGetOrderBook = (marketId: string) =>
  useQuery<Response<OrderBook>, void>({
    queryKey: ["market_order_book", marketId],
    queryFn: () => getMarketOrderBook(marketId),
    enabled: !!marketId,
  });

// prettier-ignore
export const useGetMyOrders = (marketId: string, limit: number, status: string) =>
  useQuery<Response<OrderHistory>, void>({
    queryKey: ["my_order", marketId,status,limit],
    queryFn: () => getMyOrders(marketId, limit, status),
    enabled: !!marketId,
  });

export const useGetUserBalance = (marketId: string) =>
  useQuery<Response<IUserBalance>, void>({
    queryKey: ["user_balance", marketId],
    queryFn: () => getUserBalance(marketId),
    enabled: !!marketId,
  });

export const useGetLimitOrderPreview = (data: IOrderPreviewArgs) =>
  useQuery<Response<IOrderPreview>, void>({
    queryKey: ["limit_order_preview", data?.productId],
    queryFn: () => getLimitOrderPreview(data),
  });

export const useGetMyHistoryTrade = (
  skip: number,
  limit: number,
  marketId: string
) =>
  useQuery<Response<HistoryTrade>, void>({
    queryKey: ["my_history_trade", skip, limit, marketId],
    queryFn: () => getMyHistoryTrade(skip, limit, marketId),
  });

export const useMarketList = (data: MarketListArgs) =>
  useQuery<Response<MarketListResponse>, void>({
    queryKey: ["market_list", data],
    queryFn: () => getMarketList(data),
  });

export const useMarketInfiniteList = (data: MarketListArgs) =>
  useInfiniteQuery<Response<MarketListResponse>, void>({
    queryKey: ["market_list", data],
    queryFn: ({ pageParam }) =>
      getMarketList({
        ...data,
        page: Number(pageParam ?? 0),
        search: data?.search,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      // prettier-ignore
      const list:{ items: IMarket[]; total: number } = lastPage?.result?.[data?.baseOn === BaseCoin?.USDT ? "USDT" : "IRT"] || { items: [], total: 0 }

      if (list?.items?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
  });

export const useMarketSlider = () =>
  useQuery<Response<{ title: string; data: IMarket }[]>, void>({
    queryKey: ["market_slider"],
    queryFn: getMarketSlider,
  });
