import { Button } from "@components/common/button";
import { Stack } from "@mui/material";
import { useState } from "react";
import SpotMobileModal from "./SpotMobileModal";

interface ModalState {
  open: boolean;
  type: "buy" | "sell" | "";
}

const SpotMobile = () => {
  const [isModalOpen, setIsModalOpen] = useState<ModalState>({
    open: false,
    type: "",
  });

  return (
    <Stack
      sx={{
        position: "fixed",
        left: "0",
        right: "0",
        bottom: "0",
        height: "64px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "1rem",
        backgroundColor: "white",
        borderTop: "1px solid #E0E0E0",
        padding: "1rem",
        zIndex: 10,
      }}
    >
      <Stack direction="row" gap={1} width="100%">
        <Button
          fullWidth
          bgColor="success.700"
          onClick={() => setIsModalOpen({ open: true, type: "buy" })}
        >
          خرید
        </Button>
        <Button
          fullWidth
          bgColor="error.700"
          sx={{}}
          onClick={() => setIsModalOpen({ open: true, type: "sell" })}
        >
          فروش
        </Button>
      </Stack>
      {isModalOpen && (
        <SpotMobileModal
          open={isModalOpen.open}
          onClose={() => setIsModalOpen({ open: false, type: "" })}
          type={isModalOpen.type}
        />
      )}
    </Stack>
  );
};

export default SpotMobile;
