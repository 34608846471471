import { LoadingIcon } from "@assets/icons/LoadingIcon";
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  Stack,
  SvgIcon,
} from "@mui/material";
import React, { CSSProperties, useRef } from "react";
import { Link } from "react-router-dom";

interface ButtonProps extends MuiButtonProps {
  loading?: boolean;
  bgColor?: string;
  hoverBgColor?: string;
  textColor?: string;
  tailEndIcon?: React.ReactNode;
  tailStartIcon?: React.ReactNode;
  customPy?: string;
  mode?: "default" | "icon";
  href?: string;
  linkStyle?: CSSProperties;
  underline?: boolean;
}

export const Button = ({
  disabled,
  id,
  children,
  loading,
  textColor,
  bgColor = "primary.700",
  hoverBgColor,
  tailEndIcon,
  endIcon,
  startIcon,
  tailStartIcon,
  sx,
  href,
  customPy,
  linkStyle,
  underline,
  variant = "contained",
  onClick = () => {},
  ...props
}: ButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);
  // const [borderRadius, setBorderRadius] = useState(0);

  // useEffect(() => {
  //   if (ref.current) {
  //     setBorderRadius(ref.current.clientHeight * 0.35);
  //   }
  // }, []);

  // prettier-ignore
  const ExtendIdToChildren = ({ id, children }: { id: string, children: React.ReactNode }) => {
    if(!id) return children;
    
    const extendId = (child: React.ReactNode) => {
      if (!React.isValidElement(child)) {
        return child;
      }

      let newChildren = child.props.children;

      if (child.props.children) {
        newChildren = React.Children.map(child.props.children, extendId);
      }

      return React.cloneElement(child as React.ReactElement<any>, { id, children: newChildren });
    };

    return React.Children.map(children, extendId);
  };

  const renderBtn = () => (
    <MuiButton
      ref={ref}
      variant={variant}
      id={id}
      disabled={loading || disabled}
      onClick={loading ? () => {} : onClick}
      sx={{
        px: "0.75rem",
        boxShadow: "none !important",
        height: "fit-content",
        // borderRadius: `${borderRadius}px`,
        borderRadius: `0.7rem`,
        gap: "0.25rem",
        position: "relative",
        overflow: "hidden",
        "& *": {
          color: !!textColor?.split(".")[1]
            ? (theme) =>
                // @ts-ignore
                theme.palette?.[textColor?.split(".")[0]]?.[
                  textColor?.split(".")[1]
                ] + "!important"
            : textColor + "!important",
        },
        justifyContent: "space-between",
        "&.MuiButton-contained": {
          bgcolor: bgColor,
          "*": { color: textColor || "white" },
        },
        "&.MuiButton-outlined": {
          borderColor: textColor,
        },
        "&.MuiButton-text": underline
          ? {
              borderBottom: 1,
              borderRadius: 0,
              borderStyle: "dashed",
              borderColor: textColor,
            }
          : {},
        "&.MuiButton-sizeSmall": {
          py: customPy || "0.31rem",
        },
        "&.MuiButton-sizeMedium": {
          py: customPy || "0.5rem",
        },
        "&.MuiButton-sizeLarge": {
          py: customPy || "0.62rem",
        },
        "&.Mui-disabled": {
          "& span": {
            color: (theme)=> theme.palette.neutral[400] + " !important",
          },
          "&:not(.MuiButton-text)": {
            bgcolor: "colorfulGray.A100",
          },
          "*": { color: "colorfulGray.100" },
          borderColor: "transparent",
        },
        "&:hover": { bgcolor: hoverBgColor },
        ...sx,
      }}
      {...props}
    >
      <ExtendIdToChildren id={id || ""}>
        {loading && !disabled && (
          <Stack
            position="absolute"
            justifyContent="center"
            alignItems="center"
            bgcolor={variant !== "contained" ? "white" : bgColor}
            sx={{ inset: 0 }}
          >
            <SvgIcon
              className="rotate"
              sx={{ animationDirection: "0.3s" }}
              fontSize={props.size === "small" ? props.size : "medium"}
            >
              <LoadingIcon />
            </SvgIcon>
          </Stack>
        )}

        {tailStartIcon}
        <Stack
          flex={1}
          direction="row"
          gap="0.35rem"
          alignItems="center"
          justifyContent="center"
        >
          {startIcon}
          {children}
          {endIcon}
        </Stack>
        {tailEndIcon}
      </ExtendIdToChildren>
    </MuiButton>
  );

  if (href)
    return (
      <Link style={linkStyle || {}} to={href}>
        {renderBtn()}
      </Link>
    );

  return renderBtn();
};
