const units = [
  "",
  "یک",
  "دو",
  "سه",
  "چهار",
  "پنج",
  "شش",
  "هفت",
  "هشت",
  "نه",
  "ده",
  "یازده",
  "دوازده",
  "سیزده",
  "چهارده",
  "پانزده",
  "شانزده",
  "هفده",
  "هجده",
  "نوزده",
];

const tens = [
  "",
  "",
  "بیست",
  "سی",
  "چهل",
  "پنجاه",
  "شصت",
  "هفتاد",
  "هشتاد",
  "نود",
];

const hundreds = [
  "",
  "صد",
  "دویست",
  "سیصد",
  "چهارصد",
  "پانصد",
  "ششصد",
  "هفتصد",
  "هشتصد",
  "نهصد",
];

const scales = ["", "هزار", "میلیون", "میلیارد", "تریلیون"];

export const numberToWords = (arg: number | string): string => {
  let num: number;

  if (typeof arg === "string") {
    num = Number(arg);
  } else {
    num = arg;
  }

  if (num === 0) return "صفر";

  const getBelowThousand = (n: number): string => {
    if (n < 20) return units[n];
    if (n < 100) return `${tens[Math.floor(n / 10)]} ${units[n % 10]}`.trim();
    return `${hundreds[Math.floor(n / 100)]} ${getBelowThousand(
      n % 100
    )}`.trim();
  };

  const parts: string[] = [];
  let scaleIndex = 0;

  while (num > 0) {
    const part = num % 1000;
    if (part > 0) {
      const partInWords = getBelowThousand(part);
      parts.unshift(`${partInWords} ${scales[scaleIndex]}`.trim());
    }
    num = Math.floor(num / 1000);
    scaleIndex++;
  }

  return parts.join(" و ");
};
