import { ShareIcon } from "@assets/icons/ShareIcon";
import { Button } from "@components/common/button";
import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { CopyIcon } from "@components/shared/copy-icon";
import { useBreakpoint } from "@hooks/useBreakpoint";
import {
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepLabel,
  Stepper,
  styled,
} from "@mui/material";
import { toClipboard } from "@utils/common";
import { useState } from "react";
import { PercentPattern } from "./_components/percent-pattern";
import { TomanIcon } from "@assets/icons/TomanIcon";
import { ArrowLeft2, Cup } from "iconsax-react";
import { InvitedFriendsModal } from "./_components/invited-friends-modal";
import { LeaderBoardModal } from "./_components/leader-board-modal";
import { useUserStore } from "@store/user";
import { steps } from "./_data";
import { generateDynamicText } from "./_utils";
import {
  useGetReferralLevels,
  useGetTotalProfits,
} from "@services/queries/referral/queries";
import { numUtils } from "@utils/num-helpers";

const Connector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    height: "85px",
    border: 0,
    borderLeft: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.primary[50],
  },
}));
const HConnector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    border: 0,
    borderTop: 1,
    borderStyle: "solid",
    borderColor: theme.palette.primary[50],
  },
}));

export const ReferralPage = () => {
  const isMdUp = useBreakpoint("md");

  const user = useUserStore((state) => state?.user);

  const referralLink =
    window?.location?.origin + "/v2/login?referer=" + user?.referralId;

  const [isInvitedModalOpen, setIsInvitedModalOpen] = useState(false);
  const [isLeaderBoardModalOpen, setIsLeaderBoardModalOpen] = useState(false);
  const [isReferralCodeCopied, setIsReferralCodeCopied] = useState(false);

  const getReferralLevels = useGetReferralLevels();
  const getTotalProfit = useGetTotalProfits();

  return (
    <Wrapper
      sxContainer={{
        py: { md: "2rem" },
        flex: 1,
      }}
      isFullWidth={!isMdUp}
      isScreenHeight
      title="دعوت از دوستان"
      backMode
    >
      {isInvitedModalOpen && (
        <InvitedFriendsModal
          onClose={() => setIsInvitedModalOpen(false)}
          open={isInvitedModalOpen}
        />
      )}

      {isLeaderBoardModalOpen && (
        <LeaderBoardModal
          onClose={() => setIsLeaderBoardModalOpen(false)}
          open={isLeaderBoardModalOpen}
        />
      )}

      <Stack
        sx={{
          gap: { xs: 0, md: "1.5rem" },
          p: { xs: 0, md: "1.5rem" },
          bgcolor: "white",
          borderRadius: { md: "1.125rem" },
        }}
      >
        {/* Banner Section */}
        <Stack
          alignItems="center"
          justifyContent="center"
          position="relative"
          sx={{
            borderRadius: { md: "1.125rem" },
            background: (theme) => ({
              xs: `radial-gradient(50% 50% at 50% 50%, ${theme.palette.primary[500]} 0%, ${theme.palette.primary[700]} 100%)`,
              md: `radial-gradient(30% 50% at 50% 50%, ${theme.palette.primary[500]} 0%, ${theme.palette.primary[700]} 100%)`,
            }),
          }}
        >
          <Typography
            variant="ExtendTitleBold"
            smVariant="TitleBold"
            color="white"
            pt="1.5rem"
          >
            دوستات رو دعوت کن و هدیه بگیر
          </Typography>
          <Image
            src="/v2/images/referral-cash.png"
            width="11.3rem"
            height="13.5rem"
            alt="cash-referral"
            sx={{
              width: "auto",
              height: "300px",
            }}
          />
        </Stack>

        <Stack px="1.5rem">
          {/* Step Section */}
          <Stack py="2rem" gap="2rem">
            <Typography
              mt="1.5rem"
              variant="ExtendedBodyBold"
              smVariant="BodyBold"
            >
              چطور هدیه دریافت کنم؟
            </Typography>

            <Stepper
              orientation="vertical"
              activeStep={0}
              connector={<Connector />}
              sx={{
                ".MuiStepConnector-root": {
                  mt: "-2rem",
                },
              }}
            >
              {steps?.map((item, index) => (
                <Step
                  sx={{
                    "span.MuiStepLabel-root": {
                      p: 0,
                      display: "flex",
                      alignItems: "flex-start",
                    },
                  }}
                  key={item?.title}
                >
                  <StepLabel
                    StepIconComponent={() => (
                      <Stack
                        width="2.75rem"
                        height="2.75rem"
                        borderRadius="1.375rem"
                        bgcolor="primary.100"
                        alignItems="center"
                        justifyContent="center"
                        ml="-0.5rem"
                        zIndex={5}
                      >
                        <Typography
                          variant="ExtendTitleBold"
                          color="primary. 700"
                        >
                          {index + 1}
                        </Typography>
                      </Stack>
                    )}
                  >
                    <Stack>
                      <Typography
                        variant="ExtendedBodyBold"
                        smVariant="BodyBold"
                        color="colorfulGray.700"
                      >
                        {item?.title}
                      </Typography>
                      <Typography
                        variant="Body"
                        smVariant="Caption"
                        color="colorfulGray.700"
                      >
                        {item?.subtitle}
                      </Typography>
                    </Stack>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Stack>
        </Stack>

        {/* Invite Section */}
        <Stack
          alignItems="center"
          justifyContent="center"
          position="relative"
          gap="1.5rem"
          sx={{
            borderRadius: { md: "1.125rem" },
            bgcolor: "primary.700",
            py: "2rem",
            px: { xs: "7.5%", md: "20%" },
            mx: { md: "1.5rem" },
          }}
        >
          <Stack
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Typography
              variant="ExtendedBodyMedium"
              smVariant="BodyMedium"
              color="white"
            >
              کد دعوت شما
            </Typography>
            <Stack
              direction="row"
              gap="0.5rem"
              justifyContent="flex-end"
              p="0.7rem"
              borderRadius="0.625rem"
            >
              <Typography
                variant="ExtendedBodyBold"
                color="white"
                sx={{ cursor: "pointer" }}
                maxWidth="400px"
                overflow="hidden"
                onClick={async () =>
                  await toClipboard({
                    text: user?.referralId || "",
                    cb: (isCopied) => setIsReferralCodeCopied(isCopied),
                  })
                }
              >
                {user?.referralId}
              </Typography>
              <CopyIcon
                iconColor="white"
                successIconColor="white"
                isCopied={isReferralCodeCopied}
                onChange={(isCopied) => setIsReferralCodeCopied(isCopied)}
                text={user?.referralId || ""}
              />
            </Stack>
          </Stack>

          <Stack width="100%" gap="0.25rem">
            <Typography
              variant="ExtendedBodyMedium"
              smVariant="BodyMedium"
              color="white"
            >
              لینک دعوت
            </Typography>
            <Stack
              direction="row"
              bgcolor="white"
              py="0.13rem"
              px="0.1rem"
              borderRadius="0.5625rem"
              alignItems="center"
              justifyContent="space-between"
              gap="0.5rem"
            >
              <Button
                onClick={async () =>
                  await toClipboard({
                    text: referralLink || "",
                    withToast: true,
                  })
                }
                bgColor="secondary.600"
                startIcon={
                  <ShareIcon sx={{ fontSize: "1.25rem", fill: "white" }} />
                }
              >
                <Typography
                  sx={{ display: { xs: "none", md: "block" } }}
                  variant="BodyMedium"
                  smVariant="CaptionMedium"
                >
                  اشتراک گذاری
                </Typography>
              </Button>
              <Typography
                width="270px"
                pr="0.5rem"
                whiteSpace="nowrap"
                overflow="hidden"
                dir="ltr"
                variant="TitleBold"
                smVariant="ExtendedBodyMedium"
                color="colorfulGray.300"
              >
                {referralLink || ""}
              </Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack px="1.5rem">
          {/* PercentPattern */}
          <Stack gap="1rem">
            <Stack
              position="relative"
              mt="3rem"
              justifyContent="center"
              alignItems="center"
            >
              <PercentPattern
                sx={{ width: "100%", height: { xs: "7rem", md: "10rem" } }}
              />
              <Typography
                position="absolute"
                color="primary.800"
                variant="SuperExtendHeadlineBold"
                smVariant="ExtendHeadlineBold"
                sx={{ top: { xs: "1.75rem", md: "3rem" } }}
              >
                {Number(user?.referralUserCount) > 0
                  ? user?.referralLevelInfo?.feePercentage
                  : 0}
                %
              </Typography>
            </Stack>

            <Typography
              variant="ExtendedBody"
              smVariant="Body"
              color="colorfulGray.600"
              textAlign="center"
            >
              هدیه از کارمزد {user?.referralUserCount} نفر از
              <Typography
                variant="ExtendedBodyBold"
                smVariant="BodyBold"
                px="0.25rem"
                sx={
                  Number(user?.referralUserCount) !== 0
                    ? { textDecoration: "underline", cursor: "pointer" }
                    : {}
                }
                onClick={() =>
                  Number(user?.referralUserCount) !== 0 &&
                  setIsInvitedModalOpen(true)
                }
              >
                دوستانتان
              </Typography>
            </Typography>

            <Stepper
              sx={{
                my: "1rem",
                flexDirection: "row-reverse",
                ".MuiStepConnector-root": {
                  mt: "0.75rem",
                  left: "calc(50% + 20px)",
                  right: "calc(-50% + 20px)",
                },
              }}
              activeStep={
                Number(user?.referralUserCount) === 0
                  ? 0
                  : Number(user?.referralLevelInfo?.priority ?? 1)
              }
              connector={<HConnector />}
              alternativeLabel
            >
              {getReferralLevels?.data?.items
                ?.sort((a, b) =>
                  Number(a?.priority) > Number(b?.priority) ? 1 : -1
                )
                ?.map((item) => (
                  <Step key={item?.name}>
                    <StepLabel
                      className="referralStep"
                      sx={{
                        ".MuiStepLabel-label": {
                          mt: "0.25rem",
                        },
                        ".Mui-completed > div": {
                          bgcolor: "primary.400",
                          filter: "contrast(2.5)",
                          "& > *": {
                            color: "white",
                          },
                        },
                        ".Mui-active > div": {
                          bgcolor: "primary.700",
                          border: 4.5,
                          borderColor: "#C8C1F3",
                          "& > *": {
                            color: "white",
                          },
                        },
                      }}
                      StepIconComponent={() => (
                        <Stack
                          borderRadius="5rem"
                          alignItems="center"
                          justifyContent="center"
                          bgcolor="primary.50"
                          zIndex={5}
                          sx={{
                            width: { xs: "3rem", md: "3.75rem" },
                            height: { xs: "3rem", md: "3.75rem" },
                          }}
                        >
                          <Typography
                            variant="ExtendedBodyBold"
                            smVariant="CaptionBold"
                            color="colorfulGray.300"
                          >
                            {item?.feePercentage}%
                          </Typography>
                        </Stack>
                      )}
                    >
                      <Typography
                        variant="CaptionBold"
                        smVariant="SmallCaptionBold"
                        color="colorfulGray.500"
                        whiteSpace="nowrap"
                      >
                        بالای {item?.minimumInvites} نفر
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
            </Stepper>

            <Typography
              variant="ExtendedBody"
              smVariant="Body"
              color="colorfulGray.600"
              textAlign="center"
              px="5%"
            >
              {generateDynamicText(
                getReferralLevels?.data?.items || [],
                user?.referralUserCount || 0
              )}
            </Typography>
          </Stack>
        </Stack>

        {/* Total Profile */}
        <Stack
          my="1rem"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="secondary.A100"
          borderRadius="1.125rem"
          p="1.5rem"
        >
          <Stack gap="0.5rem">
            <Stack direction="row" alignItems="center" gap="0.2rem">
              <Typography variant="ExtendTitleBold" color="colorfulGray.800">
                {numUtils?.commaformatter.toCurrency(
                  Number(
                    getTotalProfit?.data?.result?.totalProfit || 0
                  ).toFixed(0)
                )}
              </Typography>
              <TomanIcon
                sx={{
                  width: "1rem",
                  fill: (theme) => theme?.palette?.colorfulGray?.[500],
                }}
              />
            </Stack>
            <Typography
              variant="ExtendedBody"
              smVariant="Body"
              color="colorfulGray.700"
            >
              مجموع هدیه‌های دریافت شده
            </Typography>

            <Button
              onClick={() => setIsLeaderBoardModalOpen(true)}
              bgColor="white"
              textColor="primary.700"
              sx={{ border: 1.5, borderColor: "primary.700" }}
              startIcon={<Cup size="1.25rem" variant="Bold" />}
              endIcon={<ArrowLeft2 size="1.25rem" />}
            >
              <Typography variant="BodyMedium">نفرات برتر</Typography>
            </Button>
          </Stack>

          <Image src="/v2/images/gift-referral.svg" alt="referral-gift" />
        </Stack>

        <Stack px="2rem" maxWidth="600px" width="100%" mx="auto" mb="4rem">
          <Stack
            alignItems="center"
            justifyContent="center"
            position="relative"
            gap="1.5rem"
            sx={{
              border: "1px solid var(--border-divider-border-light, #EFEEF4)",
              borderRadius: "0.75rem",
              background:
                "linear-gradient(90deg, #FAFAFF 0.12%, #FFF 49%, #FAFAFF 99.86%)",
              boxShadow: "0px 0px 22px -12px rgba(0, 0, 0, 0.16)",
              p: { xs: "1rem", md: "2rem" },
              mx: { md: "1.5rem" },
              overflow: "hidden",
            }}
          >
            <Image
              src="/v2/images/pattern.svg"
              alt="pattern"
              width="150%"
              height="150%"
              sx={{ position: "absolute" }}
            />
            <Stack
              width="100%"
              justifyContent="space-between"
              alignItems="center"
              direction="row"
              zIndex={1}
            >
              <Typography
                variant="ExtendedBodyMedium"
                smVariant="BodyMedium"
                color="colorfulGray.500"
              >
                کد دعوت شما
              </Typography>
              <Stack
                direction="row"
                gap="0.5rem"
                justifyContent="flex-end"
                p="0.7rem"
                borderRadius="0.625rem"
              >
                <Typography
                  variant="ExtendTitleBold"
                  color="primary.700"
                  sx={{ cursor: "pointer", mt: "-0.5rem" }}
                  maxWidth="400px"
                  overflow="hidden"
                  onClick={async () =>
                    await toClipboard({
                      text: user?.referralId || "",
                      cb: (isCopied) => setIsReferralCodeCopied(isCopied),
                    })
                  }
                >
                  {user?.referralId}
                </Typography>
                <CopyIcon
                  iconColor="#565485"
                  successIconColor="#565485"
                  isCopied={isReferralCodeCopied}
                  onChange={(isCopied) => setIsReferralCodeCopied(isCopied)}
                  text={user?.referralId || ""}
                />
              </Stack>
            </Stack>

            <Stack width="100%" gap="0.25rem" zIndex={1}>
              <Typography
                variant="ExtendedBodyMedium"
                smVariant="BodyMedium"
                color="colorfulGray.500"
              >
                لینک دعوت
              </Typography>
              <Stack
                direction="row"
                bgcolor="colorfulGray.A100"
                py="0.13rem"
                px="0.1rem"
                borderRadius="0.5625rem"
                alignItems="center"
                justifyContent="space-between"
                gap="0.5rem"
              >
                <Button
                  onClick={async () =>
                    await toClipboard({
                      text: referralLink || "",
                      withToast: true,
                    })
                  }
                  bgColor="secondary.600"
                  startIcon={
                    <ShareIcon sx={{ fontSize: "1.25rem", fill: "white" }} />
                  }
                >
                  <Typography
                    sx={{ display: { xs: "none", md: "block" } }}
                    variant="BodyMedium"
                    smVariant="CaptionMedium"
                  >
                    اشتراک گذاری
                  </Typography>
                </Button>
                <Typography
                  width="270px"
                  pr="0.5rem"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  dir="ltr"
                  variant="TitleBold"
                  smVariant="ExtendedBodyMedium"
                  color="colorfulGray.300"
                >
                  {referralLink || ""}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  );
};
