import { create } from "zustand";
import { devtools } from "zustand/middleware";

interface OrderStore {
  value: string | null;
  setValue: (value: string) => void;
}

export const useOrdersStore = create<OrderStore>()(
  devtools((set) => ({
    value: null,
    setValue: (value) => set(() => ({ value })),
  }))
);
