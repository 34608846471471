import { create } from "zustand";
import { IUserModel } from "~types/user";
import { devtools } from "zustand/middleware";

interface UserStore {
  user: IUserModel | null;
  setUser: (user: IUserModel | null) => void;
}

export const useUserStore = create<UserStore>()(
  devtools((set) => ({
    user: null,
    setUser: (user) => set(() => ({ user })),
  }))
);
