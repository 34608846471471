import { SvgIcon, SvgIconProps } from "@mui/material";

export const LoadingIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M16.8396 21.9227C17.072 22.3992 16.8753 22.9778 16.3817 23.1714C14.7785 23.8002 13.055 24.078 11.3281 23.9812C9.28099 23.8664 7.29743 23.229 5.56664 22.1298C3.83586 21.0305 2.41559 19.5062 1.44131 17.7021C0.467042 15.898 -0.0287321 13.8744 0.00128616 11.8243C0.0313045 9.77419 0.586114 7.76596 1.61279 5.99119C2.63946 4.21641 4.10376 2.7343 5.86598 1.68623C7.62821 0.638165 9.62958 0.0591041 11.6792 0.0042892C13.4082 -0.0419508 15.1228 0.286165 16.7069 0.96165C17.1946 1.16961 17.3743 1.75374 17.1281 2.22326C16.8818 2.69279 16.3029 2.86912 15.812 2.66861C14.5211 2.14126 13.1314 1.88614 11.7305 1.9236C10.0088 1.96965 8.32769 2.45606 6.84742 3.33644C5.36715 4.21681 4.13715 5.46179 3.27474 6.9526C2.41234 8.44341 1.9463 10.1303 1.92108 11.8524C1.89587 13.5745 2.31232 15.2744 3.1307 16.7898C3.94909 18.3052 5.14212 19.5857 6.59598 20.509C8.04984 21.4323 9.71603 21.9678 11.4356 22.0642C12.8348 22.1427 14.2314 21.9283 15.5372 21.439C16.0337 21.2529 16.6072 21.4462 16.8396 21.9227Z" />
        <path d="M22.766 14.4445C23.283 14.5619 23.8014 14.2381 23.8771 13.7133C24.1508 11.8157 23.9665 9.87501 23.3332 8.05546C22.6999 6.2359 21.6394 4.60016 20.2466 3.28256C19.8614 2.9182 19.2541 2.98624 18.9217 3.39929C18.5892 3.81233 18.6584 4.41357 19.0379 4.78375C20.1534 5.87168 21.0048 7.20677 21.5199 8.68658C22.0349 10.1664 22.1965 11.7416 21.9975 13.287C21.9298 13.8129 22.2489 14.3271 22.766 14.4445Z" />
        <path d="M18.0966 21.204C18.3894 21.646 18.9878 21.7699 19.405 21.4428C20.7441 20.3927 21.8447 19.0696 22.6333 17.5617C22.8791 17.0919 22.6482 16.526 22.1603 16.3186C21.6723 16.1112 21.1126 16.3414 20.8598 16.8074C20.2274 17.9728 19.3712 19.0022 18.3404 19.8362C17.9282 20.1697 17.8038 20.762 18.0966 21.204Z" />
      </svg>
    </SvgIcon>
  );
};
