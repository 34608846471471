import { DEFAULT_PER_PAGE } from "@constants/index";
import {
  ILeaderBoardArgs,
  ILeaderBoardRes,
  ProfitMembersArgs,
  ProfitMembersRes,
  ReferralLevelsRes,
} from "./types";
import request from "@services/axios";
import { Response } from "~types/index";

export const getLeaderBoardTotal = async (
  data: ILeaderBoardArgs
): Promise<{ items: ILeaderBoardRes[]; total: number }> => {
  // @ts-ignore
  return await request.post<{ items: ILeaderBoardRes[]; total: number }>(
    `/api/ExCoreData/profitsRanking/browse/${data?.page ?? 0}/${
      data?.perPage ?? DEFAULT_PER_PAGE
    }`
  );
};

export const getLeaderBoardLastMonth = async (
  data: ILeaderBoardArgs
): Promise<{ items: ILeaderBoardRes[]; total: number }> => {
  // @ts-ignore
  return await request.post<{ items: ILeaderBoardRes[]; total: number }>(
    `/api/ExCoreData/lastMonthProfitsRanking/browse/${data?.page ?? 0}/${
      data?.perPage ?? DEFAULT_PER_PAGE
    }`
  );
};

export const getTotalProfits = async () => {
  return await request.get<{ totalProfit: string }>(
    `/api/ExCoreData/my/total/profit`
  );
};

export const getProfitMembers = async (data: ProfitMembersArgs) => {
  return await request.post<ProfitMembersRes[]>(
    `/api/user/my/referrals/browse/${data?.page ?? 0}/${
      data?.perPage ?? DEFAULT_PER_PAGE
    }`
  );
};

export const getReferralLevels = async (): Promise<{
  items: ReferralLevelsRes[];
  total: number;
}> => {
  // @ts-ignore
  return await request.post<{ items: ReferralLevelsRes[]; total: number }>(
    "/api/ReferralLevel/browse/0/15"
  );
};
