import { Button } from "@components/common/button";
import RefreshIcon from "@components/common/refresh-icon";
import Tabs from "@components/common/tabs";
import { TabItem } from "@components/common/tabs/types";
import { Typography } from "@components/common/typography";
import DownloadIcon from "@mui/icons-material/Download";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Bazzar from "./_components/bazzar";
import FeelLevelPopover from "./_components/feel-level-popover";
import Limit from "./_components/limit";

interface Props {
  marginTop?: number;
  isMobile?: boolean;
  type?: "sell" | "buy" | "";
}

const Spot = ({ marginTop, isMobile, type }: Props) => {
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [currentBtnTab, setCurrentBtnTab] = useState<number>(
    type === "sell" ? 1 : 0
  );

  const tabs: TabItem[] = [
    {
      label: "محدود",
      content: (
        <Limit
          currentBtnTab={currentBtnTab}
          setCurrentBtnTab={setCurrentBtnTab}
        />
      ),
    },
    {
      label: "بازار",
      content: (
        <Bazzar
          currentBtnTab={currentBtnTab}
          setCurrentBtnTab={setCurrentBtnTab}
        />
      ),
      // tabDisabled: true,
    },
  ];

  const queryClient = useQueryClient();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    if (isRefreshing) return;

    setIsRefreshing(true);

    queryClient.invalidateQueries({ queryKey: ["user_balance"] });

    setTimeout(() => setIsRefreshing(false), 5000);
  };

  const handleClick = () => {
    navigate("/v2/dashboard/wallet");
  };

  return (
    <Grid item xs mt={marginTop}>
      <Paper
        sx={{
          height: isMobile ? "unset" : "408px",
        }}
        elevation={0}
      >
        <Stack
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {!isMobile && (
            <>
              <Button
                variant="text"
                sx={{
                  borderBottom: "2px solid currentColor",
                  paddingBottom: 1.2,
                  borderRadius: 0,
                  width: 90,
                }}
              >
                اسپات
              </Button>

              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gap={0.5}
                py={1}
              >
                <FeelLevelPopover />

                <Divider orientation="vertical" flexItem />
                <IconButton
                  size="small"
                  sx={{ mr: 0.5 }}
                  onClick={handleRefresh}
                >
                  <RefreshIcon />
                </IconButton>
              </Box>
            </>
          )}
        </Stack>
        {!isMobile && <Divider flexItem sx={{ marginTop: -0.8 }} />}
        <Stack mt={1}>
          <Tabs
            setCurrentTab={setCurrentTab}
            tabs={tabs}
            isButtonType
            variant="standard"
            tabPanelStyle={{
              "&.MuiTab-root": {
                minWidth: 30,
              },
            }}
            wrapperStyle={{
              height: 40,
              marginBottom: isMobile ? 0 : -1,
            }}
            rightAction={
              <Stack
                display={"flex"}
                flexDirection={"row"}
                alignItems={"center"}
                justifyContent={"center"}
                px={1}
              >
                <Tooltip
                  title={
                    <Box p={0.5}>
                      {currentTab === 0 ? (
                        <>
                          <Typography variant="body2" color={"white"}>
                            در سفارش محدود شما می‌توانید قیمت خرید یا فروش را
                            تعیین کنید. و هنگامی که رمزارز به قیمت تعیین شده
                            توسط شما برسد، معامله انجام خواهد شد.
                          </Typography>
                          <Button variant="text" color="warning">
                            مطالعه بیشتر
                          </Button>
                        </>
                      ) : (
                        <>
                          <Typography variant="body2" color={"white"}>
                            در سفارش بازار، تراکنش خرید یا فروش شما با قیمت
                            بازار در لحظه پر و اجرا می‌شود.
                          </Typography>
                          <Button variant="text" color="warning">
                            مطالعه بیشتر
                          </Button>
                        </>
                      )}
                    </Box>
                  }
                  arrow
                  placement="top"
                >
                  <InfoOutlinedIcon sx={{ color: "neutral.500" }} />
                </Tooltip>
              </Stack>
            }
            leftAction={
              !isMobile && (
                <Stack
                  display={"flex"}
                  flexDirection={"row"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  px={1}
                >
                  <Button
                    variant="text"
                    startIcon={<DownloadIcon />}
                    onClick={handleClick}
                  >
                    واریز تومان
                  </Button>
                </Stack>
              )
            }
          />
        </Stack>
      </Paper>
    </Grid>
  );
};

export default Spot;
