import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material/";
import { List, ListItem, ListItemText, Stack } from "@mui/material";
import { RowTitleProps } from "../types";
import { SortState } from "../../header/types";

const RowTitle = ({
  name,
  listStyle,
  listItemStyle,
  listItemTextStyle,
  setSortState,
  sortState,
  children,
}: RowTitleProps) => {
  const handleSort = () => {
    if (sortState.type === "none") {
      setSortState({ title: name as SortState["title"], type: "asc" });
    } else if (sortState.type === "asc") {
      setSortState({ title: name as SortState["title"], type: "desc" });
    } else {
      setSortState({ title: name as SortState["title"], type: "none" });
    }
  };
  return (
    <List
      sx={{
        "&:hover": {
          cursor: "pointer",
        },
        ...listStyle,
      }}
      aria-label="currency row title"
    >
      <ListItem
        disablePadding
        onClick={handleSort}
        sx={{
          ...listItemStyle,
        }}
      >
        <ListItemText
          primary={name}
          sx={{
            textWrap: "nowrap",
            ...listItemTextStyle,
          }}
          primaryTypographyProps={{
            color: "neutral.600",
            fontSize: "0.75rem",
          }}
        />

        <Stack display={"flex"} flexDirection={"column"} alignItems={"center"}>
          <ArrowDropUpIcon
            sx={{
              fontSize: "0.75rem",
              transform: "translateY(25%)",
              color: sortState.type === "asc" ? "primary.500" : "secondary.600",
            }}
          />
          <ArrowDropDownIcon
            sx={{
              fontSize: "0.75rem",
              transform: "translateY(-25%)",
              color:
                sortState.type === "desc" ? "primary.500" : "secondary.600",
            }}
          />
        </Stack>
        {children}
      </ListItem>
    </List>
  );
};

export default RowTitle;
