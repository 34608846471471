import { StarPattern } from "@components/shared/star-pattern";
import { Stack, SxProps, Typography } from "@mui/material";
import { useUserStore } from "@store/user";
import { SecurityUser } from "iconsax-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

interface AuthLevelCardProps {
  sxContainer?: SxProps;
}

export const AuthLevelCard = ({ sxContainer }: AuthLevelCardProps) => {
  const user = useUserStore((state) => state?.user);
  const navigate = useNavigate();

  const authenticationLevel: "BASE" | "NOT_VERIFIED" | "PRO" = useMemo(() => {
    if (
      !!user?.IDverification &&
      !!user?.mobileVerification &&
      !!user?.personalAttachmentsVerification
    )
      return "PRO";

    if (!!user?.IDverification && !!user?.mobileVerification) return "BASE";

    return "NOT_VERIFIED";
  }, [user]);
  return (
    <>
      {authenticationLevel !== "PRO" && (
        <Stack
          onClick={() => navigate("/v2/dashboard/authentication")}
          borderRadius="1.125rem"
          bgcolor="primary.600"
          p="0.62rem"
          position="relative"
          sx={{ cursor: "pointer", ...sxContainer }}
        >
          <Stack direction="row" gap="0.5rem" alignItems="center">
            <SecurityUser variant="Bold" size="1.5rem" color="white" />
            <Typography variant="BodyBold" color="primary.A100">
              {authenticationLevel === "NOT_VERIFIED" && "احراز هویت پایه"}
              {authenticationLevel === "BASE" && "احراز هویت ویژه"}
            </Typography>
          </Stack>
          <Typography variant="BodyBold" color="primary.100">
            {authenticationLevel === "NOT_VERIFIED" &&
              "برای واریز پول احراز هویتتان را تکمیل کنید."}
            {authenticationLevel === "BASE" &&
              "برای واریز و برداشت پول احراز هویتتان را تکمیل کنید."}
          </Typography>

          <StarPattern
            sx={{
              position: "absolute",
              right: 15,
              bottom: -5,
              height: "5rem",
              width: "6rem",
              fill: "white",
            }}
          />
        </Stack>
      )}
    </>
  );
};
