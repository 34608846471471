import { Wrapper } from "@components/layout/wrapper";
import { SocketProvider } from "@context/scoket";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { useProfile } from "@services/queries/auth/queries";
import { useUserStore } from "@store/user";
import { toast } from "@utils/toast";
import { useEffect, useState } from "react";
import { Navigate, Outlet } from "react-router-dom";

export default function DashboardLayout() {
  const [loading, setLoading] = useState(true);
  const isMdUp = useBreakpoint("md");
  const { setUser, user } = useUserStore((state) => state);

  const profileQuery = useProfile();

  useEffect(() => {
    if (profileQuery?.isSuccess) {
      setLoading(false);
      setUser(profileQuery?.data?.result?.item);
      if (!isMdUp) {
        window?.Goftino?.setWidget({
          iconUrl: "",
          hasIcon: false,
          counter: "#SUPPORT_ICON_GOFTINO",
        });
        // window?.Goftino?.close();
      }
    } else if (profileQuery?.isError) {
      toast?.error(
        "مشکلی پیش آمده!  از قسمت چت میتونید با پشتیبانی ارتباط برقرار کنید."
      );
    }
  }, [profileQuery.isFetching]);

  if (loading) return <Wrapper loading />;

  if (user)
    return (
      <SocketProvider>
        <Outlet />
      </SocketProvider>
    );

  return <Navigate to={"/v2/login"} />;
}
