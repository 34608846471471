export interface TableData {
  _id: string;
  createdAt: string;
  productId: string;
  side: string | JSX.Element;
  type: string;
  size: string;
  avgPrice: string;
  price: string;
  totalPrice: string;
  filledSize: string;
}

export function createTableData(
  _id: string,
  createdAt: string,
  productId: string,
  side: string | JSX.Element,
  type: string,
  size: string,
  price: string,
  avgPrice: string,
  totalPrice: string,
  filledSize: string
): TableData {
  return {
    _id,
    createdAt,
    productId,
    side,
    type,
    size,
    price,
    avgPrice,
    totalPrice,
    filledSize,
  };
}

export interface HistoryTableData {
  _id: string;
  createdAt: string;
  productId: string;
  side: string | JSX.Element;
  type: string;
  size: string;
  price: string;
  avgPrice: string;
  totalPrice: string;
  filledSize: string;
  status: string | JSX.Element;
}

export function createHistoryTableData(
  _id: string,
  createdAt: string,
  productId: string,
  side: string | JSX.Element,
  type: string,
  size: string,
  price: string,
  avgPrice: string,
  totalPrice: string,
  filledSize: string,
  status: string | JSX.Element
): HistoryTableData {
  return {
    _id,
    createdAt,
    productId,
    side,
    type,
    size,
    price,
    avgPrice,
    totalPrice,
    filledSize,
    status,
  };
}

export interface HistoryTradeData {
  _id: string;
  tradeId: string;
  orderId: string;
  prodId: string;
  prodTitle: string;
  prodLocalizedTitle: string;
  baseCurrency: string;
  quoteCurrency: string;
  workSpaceId: string;
  orderType: "limit" | "market";
  side: "buy" | "sell" | JSX.Element;
  size: string;
  price: string;

  fee: string;
  feeUnit: string;
  createdAt: string;
  totalPrice: string;
  pureAmount: string;
}

export function createTradeHistoryTableData(
  _id: string,
  createdAt: string,
  prodId: string,
  side: "sell" | "buy" | JSX.Element,
  size: string,
  price: string,
  fee: string,
  totalPrice: string,
  pureAmount: string
): Partial<HistoryTradeData> {
  return {
    _id,
    createdAt,
    prodId,
    side,
    size,
    price,
    fee,
    totalPrice,
    pureAmount,
  };
}
