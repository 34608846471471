import { Button } from "@components/common/button";
import { Typography } from "@components/common/typography";
import { MoneyAdd, WalletAdd } from "iconsax-react";
import { BaseCoin } from "~types/index";

interface ActionToChargeBalanceProps {
  baseOn: BaseCoin;
}

export const ActionToChargeBalance = ({
  baseOn,
}: ActionToChargeBalanceProps) => {
  return (
    <>
      <Button
        href={
          baseOn === BaseCoin.TOMAN
            ? "/v2/dashboard/wallet/toman/deposit"
            : "/v2/dashboard/wallet/coin/USDT/deposit"
        }
        size="large"
        variant="outlined"
        fullWidth
        linkStyle={{ width: "100%" }}
        startIcon={<MoneyAdd variant="Bold" size="1.25rem" />}
      >
        <Typography variant="BodyMedium">
          واریز {baseOn === BaseCoin.TOMAN ? "تومان" : "تتر"}
        </Typography>
      </Button>
      <Button
        href={
          baseOn === BaseCoin.TOMAN
            ? "/v2/dashboard/wallet/toman/withdraw"
            : "/v2/dashboard/wallet/coin/USDT/withdraw"
        }
        size="large"
        variant="outlined"
        fullWidth
        linkStyle={{ width: "100%" }}
        startIcon={<WalletAdd variant="Bold" size="1.25rem" />}
      >
        <Typography variant="BodyMedium">
          برداشت {baseOn === BaseCoin.TOMAN ? "تومان" : "تتر"}
        </Typography>
      </Button>
    </>
  );
};
