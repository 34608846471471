import { Fragment, useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

interface SwiperListProps<T> {
  minWidth: number;
  swiperStyle?: React.CSSProperties;
  spaceBetween: number;
  count: number;
  loading: boolean;
  swiperList: T[];
  renderComponent: (item: T) => React.ReactNode;
}

export default function SwiperList<T>({
  minWidth,
  swiperStyle,
  spaceBetween,
  count,
  loading,
  swiperList,
  renderComponent,
}: SwiperListProps<T>) {
  const [isWidthOk, setIsWidthOk] = useState(true);
  const swiperRef = useRef(null);

  const checkWidth = () => {
    // prettier-ignore
    if (swiperRef.current) {
          // @ts-ignore
          const swiperWidth = swiperRef?.current?.offsetWidth;
          const ratio = swiperWidth / ((minWidth + spaceBetween) * (count));
          setIsWidthOk(ratio >= 1);
        }
  };

  useEffect(() => {
    checkWidth();
    window.addEventListener("resize", checkWidth);

    return () => window.removeEventListener("resize", checkWidth);
  }, [loading]);

  return (
    <Swiper
      style={{ height: "auto", ...swiperStyle }}
      ref={swiperRef}
      spaceBetween={spaceBetween}
      slidesPerView={isWidthOk ? count : "auto"}
    >
      {(swiperList || [])?.map((item, i) => (
        <Fragment key={`markets-selected-${i}-card`}>
          <SwiperSlide style={{ width: isWidthOk ? "100%" : `${minWidth}px` }}>
            {renderComponent(item)}
          </SwiperSlide>
        </Fragment>
      ))}
    </Swiper>
  );
}
