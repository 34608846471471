import { Tabs as MTabs } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import React, { useState } from "react";
import TabPanel from "./panel";
import { ButtonTabsProps } from "./types";

function a11yProps(index: number) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

const ButtonTabs = ({
  tabs,
  initialTabIndex = 0,
  variant,
  wrapperStyle,
  leftAction,
  tabPanelStyle,
  rightAction,
  setCurrentBtnTab,
}: ButtonTabsProps) => {
  const [value, setValue] = useState<number>(initialTabIndex);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (setCurrentBtnTab) {
      setCurrentBtnTab(newValue || 0);
    }
  };

  return (
    <>
      <Box
        {...(leftAction && {
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        })}
        sx={{
          ...wrapperStyle,
        }}
      >
        <MTabs
          value={value}
          onChange={handleChange}
          aria-label="bali tabs"
          variant={variant || "scrollable"}
          scrollButtons
          allowScrollButtonsMobile
          {...{
            TabIndicatorProps: {
              style: { display: "none" },
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              {...a11yProps(index)}
              sx={{
                ...tabPanelStyle,
                textTransform: "none",
                minHeight: "34px",
                height: "40px",
                color: "black",
                minWidth: "10px",
                backgroundColor: value ? "error.main" : "success.main",
                fontSize: 14,
                boxShadow: "none",
                borderRadius: value ? "0 4px 4px 0" : "4px 0 0 4px",

                "&.MuiTabs-indicator": {
                  display: "none",
                },
                "&.Mui-selected": {
                  color: "white",
                  fontWeight: "bold",
                },
                "&.Mui-disabled": {
                  color: "white",
                  backgroundColor: "grey",
                },
                "&[aria-selected='false']": {
                  backgroundColor: "white",
                  color: "neutral.500",
                  border: "1px solid #e7e7e7",
                },
              }}
            />
          ))}
          {rightAction}
        </MTabs>
        {leftAction}
      </Box>
      {tabs.map((tab, index) => (
        <TabPanel key={index} value={value} index={index}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  );
};

export default ButtonTabs;
