import { Wrapper } from "@components/layout/wrapper";
import { ProfileDropdownNavigation } from "@components/shared/profile-dropdown-navigation";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Menu = () => {
  const isMdUp = useBreakpoint("md");
  const navigate = useNavigate();

  useEffect(() => {
    if (isMdUp) {
      if (window.history?.state?.idx === 0)
        return navigate("/v2/dashboard/markets", { replace: true });

      navigate(-1);
    }
  }, [isMdUp]);

  return (
    <Wrapper
      isNoPaddingModeContainer
      hideSideNavigation
      isFullWidth
      title="فهرست"
      sxContainer={{ bgcolor: "#neutral.A100" }}
    >
      <Stack
        maxWidth="400px"
        mx="auto"
        overflow="auto"
        maxHeight="calc(100dvh - 130px)"
      >
        <ProfileDropdownNavigation listOfDisplayId="ALL" />
      </Stack>
    </Wrapper>
  );
};
