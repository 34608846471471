import { useQuery } from "@tanstack/react-query";
import { createOtcRequestInvoice, getPairDetails, getPairSides } from "./apis";
import type {
  CreateOtcReqInvoiceArgs,
  CreateOtcReqInvoiceRes,
  GetPairDetailsArgs,
  GetPairDetailsRes,
} from "./types";
import type { Coin, Response } from "~types/index";

export function usePairDetails(data: GetPairDetailsArgs, isEnable?: boolean) {
  return useQuery<Response<GetPairDetailsRes>, GetPairDetailsArgs>({
    queryKey: ["pairDetails", `${data?.from}-${data?.to}`],
    queryFn: () => getPairDetails(data),
    enabled: !!data?.from && !!data?.to && isEnable,
    staleTime: 0,
  });
}

export function usePairSides() {
  return useQuery<Response<Coin[]>>({
    queryKey: ["pairSides"],
    queryFn: getPairSides,
    staleTime: 0,
  });
}

export function usePairSidesInvoice(
  data: CreateOtcReqInvoiceArgs,
  isEnable?: boolean
) {
  return useQuery<Response<CreateOtcReqInvoiceRes>, CreateOtcReqInvoiceArgs>({
    queryKey: ["pairSidesInvoice"],
    queryFn: () => createOtcRequestInvoice(data),
    enabled: isEnable,
    staleTime: 0,
  });
}
