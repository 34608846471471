import Container from "@components/common/container";
// import { Header } from "@components/layout/navigation";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { BankCardItem } from "@components/shared/bank-card-item";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, useTheme } from "@mui/material";
import { useHandleJibitDepositMutation } from "@services/queries/wallet/mutations";
import { useGetMyBankAccount } from "@services/queries/wallet/queries";
import { bankInfo } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { Card, CardAdd, LampCharge } from "iconsax-react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AddBankButton } from "../../../../../../../../../components/shared/add-bank-button";
import { useUserStore } from "@store/user";
import { MyBankAccountItem } from "@services/queries/wallet/types";
import Hint from "@components/common/hint";

export default function PaymentBankCardDepositToman() {
  const isMdUp = useBreakpoint("md");
  const theme = useTheme();

  const { state } = useLocation();
  const navigate = useNavigate();

  const getBankAccountQuery = useGetMyBankAccount();

  const handleJibitDepositMutation = useHandleJibitDepositMutation();

  const user = useUserStore((state) => state?.user);

  useEffect(() => {
    if (!state?.amount) navigate("/v2/dashboard/wallet/toman/deposit");
    if (!(!!user?.IDverification && !!user?.mobileVerification)) {
      navigate("/v2/dashboard/wallet/toman/deposit");
    }
  }, [state, user]);

  return (
    <Wrapper
      loading={
        getBankAccountQuery?.isLoading || handleJibitDepositMutation?.isPending
      }
      title="انتخاب کارت بانکی"
      icon={
        <CardAdd variant="Bold" color={theme?.palette?.colorfulGray[500]} />
      }
      backMode
      hideBottomNavigation
      isNoPaddingModeContainer
      sxContainer={{ pt: { md: "2rem" } }}
    >
      <Stack
        sx={{
          p: "1.5rem",
          flex: 1,
          bgcolor: "white",
          borderRadius: { md: "1.125rem" },
        }}
      >
        <Stack
          width="100%"
          sx={{
            minHeight: { xs: "calc(100dvh - 90px)", md: "auto" },
            gap: "2rem",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Stack
            sx={{ flex: { md: 1 } }}
            maxWidth="600px"
            width="100%"
            mx="auto"
          >
            {/* @ts-ignore */}
            {!!getBankAccountQuery?.data?.items?.filter(
              (e: MyBankAccountItem) => e?.accType === "card"
            )?.length && (
              <Typography
                pb="0.5rem"
                variant="BodyMedium"
                color="colorfulGray.700"
                alignItems="center"
              >
                انتقال{" "}
                <Typography
                  px="0.1rem"
                  component="span"
                  variant="ExtendedBodyBold"
                >
                  {numUtils.commaformatter.toCurrency(state?.amount)}
                </Typography>{" "}
                از مبداء
              </Typography>
            )}
            {/* @ts-ignore */}
            {!!getBankAccountQuery?.data?.items?.filter(
              (e: MyBankAccountItem) => e?.accType === "card"
            )?.length ? (
              <Stack gap="0.75rem">
                {/* @ts-ignore */}
                {getBankAccountQuery?.data?.items
                  ?.filter((e: MyBankAccountItem) => e?.accType === "card")
                  ?.map((item: MyBankAccountItem) => (
                    <BankCardItem
                      onClick={() =>
                        handleJibitDepositMutation.mutate(
                          {
                            amount: Number(state?.amount),
                            cardId: item?._id,
                            gatewayId: "jibit",
                          },
                          {
                            onSuccess: (data) =>
                              (window.location.href =
                                data?.result?.bankRedirectURL),
                          }
                        )
                      }
                      key={`card-${item?._id}`}
                      title={item.no}
                      imgSrc={`/v2/images/banks-logo/${
                        bankInfo(item?.no, "Card")?.logo || "melli.svg"
                      }`}
                      alt={bankInfo(item?.no, "Card")?.name || "حساب بانکی"}
                    />
                  ))}
              </Stack>
            ) : (
              <Stack gap="1.5rem" alignItems="center">
                <Stack
                  borderRadius="50%"
                  sx={{
                    width: { xs: "5rem", md: "6.75rem" },
                    height: { xs: "5rem", md: "6.75rem" },
                  }}
                  alignItems="center"
                  justifyContent="center"
                  bgcolor="neutral.50"
                >
                  <Card
                    variant="Bold"
                    color={theme?.palette?.primary[50]}
                    size={isMdUp ? "4.4rem" : "3.5rem"}
                  />
                </Stack>
                <Typography
                  variant="ExtendedBodyMedium"
                  smVariant="BodyMedium"
                  color="colorfulGray.700"
                >
                  هنوز کارت بانکی ثبت نکرده‌اید
                </Typography>
              </Stack>
            )}

            <AddBankButton titleBtn="افزودن کارت جدید" />
          </Stack>
          {/* @ts-ignore */}
          {!!getBankAccountQuery?.data?.items?.filter(
            (e: MyBankAccountItem) => e?.accType === "card"
          )?.length && (
            <Stack sx={{ flex: { md: 1 } }}>
              <Hint
                severity="info"
                IconComponent={LampCharge}
                content={
                  <Stack gap="0.62rem">
                    <Typography variant="Caption" color="info.700">
                      {`حداقل مبلغ ${numUtils?.commaformatter?.toCurrency(
                        import.meta.env.VITE_MIN_DEPOSIT_TOMAN
                      )}  تومان و حداکثر ${numUtils?.commaformatter?.toCurrency(
                        state?.totalAvailable || 0
                      )} تومان می باشد.`}
                    </Typography>
                    <Typography variant="Caption" color="info.700">
                      کارمزد واریز ۰.۰۲ درصد با حداقل ۱۲۰ تومان و حداکثر ۴،۰۰۰
                      تومان است که توسط شبکه شاپرک از مبلغ واریزی کسر و مابقی
                      مبلغ به کیف پول شما واریز می شود.
                    </Typography>
                    <Typography variant="Caption" color="info.700">
                      در صفحه درگاه دقت کنید که حتما مبلغ نمایش داده شده درست
                      باشد.
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Wrapper>
  );
}
