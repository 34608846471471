import { Stack, SvgIcon, useTheme } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useBreakpoint } from "@hooks/useBreakpoint";
import {
  ArrangeVertical,
  Category2,
  Chart2,
  UserSquare,
  Wallet2,
} from "iconsax-react";
import { Typography } from "@components/common/typography";

const listBottomNavItems = [
  {
    title: "داشبورد",
    href: "/v2/dashboard",
    icon: (isActive: boolean) => (
      <Category2 size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
  },
  {
    title: "بازار",
    href: "/v2/dashboard/markets",
    icon: (isActive: boolean) => (
      <Chart2 size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
  },
  {
    title: "معامله سریع",
    href: "/v2/dashboard/otc",
    icon: (isActive: boolean) => (
      <ArrangeVertical size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
  },
  {
    title: "کیف‌پول",
    href: "/v2/dashboard/wallet",
    icon: (isActive: boolean) => (
      <Wallet2 size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
  },
  {
    title: "فهرست",
    href: "/v2/dashboard/menu",
    icon: (isActive: boolean) => (
      <UserSquare size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
  },
];

export interface BottomNavigationProps {}

export const BottomNavigation = ({}: BottomNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Stack
      direction="row"
      component="ul"
      bgcolor="white"
      position="fixed"
      width="100%"
      bottom={0}
      zIndex={20}
      justifyContent="space-between"
      py="1rem"
      px="1.5rem"
      height="70px"
    >
      {listBottomNavItems?.map((item) => (
        <Stack
          onClick={() => navigate(item?.href)}
          direction="row"
          gap="0.25rem"
          component="li"
          px={item?.href === location?.pathname ? "0.5rem" : 0}
          py="0.5rem"
          bgcolor={
            item?.href === location?.pathname ? "primary.A100" : "transparent"
          }
          alignItems="center"
          sx={{ transition: "0.3s" }}
          borderRadius="0.75rem"
        >
          <SvgIcon
            sx={{
              color: (theme) =>
                item?.href === location?.pathname
                  ? theme?.palette?.primary?.[600]
                  : theme?.palette.colorfulGray?.[500],
            }}
          >
            {item?.icon(item?.href === location?.pathname)}
          </SvgIcon>
          <Typography
            variant="CaptionBold"
            color="primary.700"
            width={item?.href === location?.pathname ? "fit-content" : 0}
            overflow="hidden"
            sx={{ transition: "0.3s" }}
          >
            {item?.title}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );
};
