import NotFoundItem from "@components/common/not-found";
import { SearchBox } from "@components/common/search-box";
import Tabs from "@components/common/tabs";
import { TabItem } from "@components/common/tabs/types";
import { Grid, Paper } from "@mui/material";
import { useMarketList } from "@services/queries/markets/queries";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { Currencies, SortState } from "../header/types";
import All from "./_components/all";
import { FilterBtnCurrency } from "./types";

const Wealth = ({ elevation }: Currencies) => {
  const queryClient = useQueryClient();

  const [sortState, setSortState] = useState<SortState>({
    title: "نام ارز",
    type: "none",
  });

  const [btnFilter, setBtnFilter] = useState<FilterBtnCurrency>("IRT");

  const { data } = useMarketList({
    perPage: 200,
    favorite: btnFilter === "FAV",
  });

  const sortCurrency = data?.result?.[btnFilter]?.items?.sort((a, b) => {
    if (sortState.title === "نام ارز") {
      if (sortState.type === "asc") {
        return a.localizedTitle.localeCompare(b.localizedTitle);
      } else if (sortState.type === "desc") {
        return b.localizedTitle.localeCompare(a.localizedTitle);
      } else {
        return 0;
      }
    } else if (sortState.title === "آخرین قیمت") {
      if (sortState.type === "asc") {
        return Number(a.todayPrice) - Number(b.todayPrice);
      } else if (sortState.type === "desc") {
        return Number(b.todayPrice) - Number(a.todayPrice);
      } else {
        return 0;
      }
    } else if (sortState.title === "تغییر 24h") {
      if (sortState.type === "asc") {
        return Number(a.percentChange24h) - Number(b.percentChange24h);
      } else if (sortState.type === "desc") {
        return Number(b.percentChange24h) - Number(a.percentChange24h);
      } else {
        return 0;
      }
    } else if (sortState.title === "حجم") {
      if (sortState.type === "asc") {
        return Number(a.yesterdayVolume) - Number(b.yesterdayVolume);
      } else if (sortState.type === "desc") {
        return Number(b.yesterdayVolume) - Number(a.yesterdayVolume);
      } else {
        return 0;
      }
    }
    return 0;
  });

  const [filterCurrency, setFilterCurrency] = useState(sortCurrency);

  const initFaveList = () => {
    const faveList = [
      ...(data?.result?.IRT?.items?.filter((item) => item.isFave) ?? []),
      ...(data?.result?.USDT?.items?.filter((item) => item.isFave) ?? []),
    ];
    return {
      result: {
        ...data?.result,
        FAV: {
          total: faveList.length,
          items: faveList,
        },
      },
    };
  };

  const callInitFaveList = () => {
    const tmp = initFaveList();
    setFilterCurrency(tmp?.result?.[btnFilter]?.items);
  };

  const updateInitFaveList = () => {
    queryClient.invalidateQueries({ queryKey: ["market_list"] });
  };

  useEffect(() => {
    callInitFaveList();
  }, [btnFilter, data]);

  const handleSearch = (value: string) => {
    if (value === "") {
      setFilterCurrency(data?.result?.[btnFilter]?.items);
      callInitFaveList();
    } else {
      setFilterCurrency(
        filterCurrency?.filter((item) => {
          if (btnFilter === "IRT") {
            return (
              item.localizedTitle.toLowerCase().includes(value.toLowerCase()) ||
              item.baseCurrency.toLowerCase().includes(value.toLowerCase())
            );
          } else if (btnFilter === "USDT") {
            return (
              item.localizedTitle.toLowerCase().includes(value.toLowerCase()) ||
              item.baseCurrency.toLowerCase().includes(value.toLowerCase())
            );
          } else if (btnFilter === "FAV") {
            return (
              item.localizedTitle.toLowerCase().includes(value.toLowerCase()) ||
              item.baseCurrency.toLowerCase().includes(value.toLowerCase())
            );
          }
        })
      );
    }
  };

  const tabs: TabItem[] = [
    {
      label: "همه",
      content: (
        <All
          filterCurrency={filterCurrency || []}
          setBtnFilter={setBtnFilter}
          btnFilter={btnFilter}
          setOnFavorite={updateInitFaveList}
          sortState={sortState}
          setSortState={setSortState}
        />
      ),
    },
    { label: "داریی‌ها", content: <NotFoundItem /> },
  ];

  return (
    <Grid item xs>
      <Paper
        sx={{
          px: 0.5,
          height: 449,
          overflowX: "none",
        }}
        elevation={elevation}
      >
        <SearchBox
          sx={{ mt: "0.25rem" }}
          withDebounce
          onResetValueOnClick={() => handleSearch("")}
          onDebounceTrigger={(value) => handleSearch(value)}
        />

        <Tabs
          tabs={tabs}
          variant="fullWidth"
          tabPanelStyle={{ fontSize: 12 }}
        />
      </Paper>
    </Grid>
  );
};

export default Wealth;
