import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { lightTheme } from "./light";
import { useMediaQuery } from "@mui/material";

import { THEME_STORAGE_NAME } from "@constants/index";
import { getLocalStorageItem, setLocalStorageItem } from "@utils/storage";

interface ThemeContextType {
  toggleColorMode: () => void;
  mode?: "light" | "dark";
}

const initialThemeContext = {
  toggleColorMode: () => {},
};

export const ThemeContext =
  createContext<ThemeContextType>(initialThemeContext);

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const [mode, setMode] = useState<"light" | "dark">(
    prefersDarkMode ? "dark" : "light"
  );

  useEffect(() => {
    const defaultTheme = async () => {
      const lastStoreTheme = await getLocalStorageItem(THEME_STORAGE_NAME);
      if (lastStoreTheme === "dark" || lastStoreTheme === "light") {
        setMode(lastStoreTheme);
      }
    };
    defaultTheme();
  }, []);

  const toggleColorMode = () => {
    const setTheme = async (currentThemeMode: string) => {
      await setLocalStorageItem(THEME_STORAGE_NAME, currentThemeMode);
    };
    setMode((prevMode) => {
      const currentThemeMode = prevMode === "light" ? "dark" : "light";
      setTheme(currentThemeMode);
      return currentThemeMode;
    });
  };

  return (
    <ThemeContext.Provider value={{ mode, toggleColorMode }}>
      <MuiThemeProvider theme={mode === "dark" ? lightTheme : lightTheme}>
        {children}
      </MuiThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);
