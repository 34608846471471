import { Button } from "@components/common/button";
import Hint from "@components/common/hint";
import { IconTypography } from "@components/common/icon-typography";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { CopyIcon } from "@components/shared/copy-icon";
import { Stack, useTheme } from "@mui/material";
import { useHandleCreateDepositAuto } from "@services/queries/wallet/mutations";
import {
  useGetDepositAuto,
  useGetMyBankAccount,
} from "@services/queries/wallet/queries";
import {
  DepositAutoRes,
  MyBankAccountItem,
} from "@services/queries/wallet/types";
import { useUserStore } from "@store/user";
import { toClipboard } from "@utils/common";
import { InfoCircle, LampCharge, Money4, Warning2 } from "iconsax-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddBankButton } from "../../../../../../../../../components/shared/add-bank-button";

export default function PaymentShebaDepositToman() {
  const theme = useTheme();
  const navigate = useNavigate();

  const getBankAccountQuery = useGetMyBankAccount();
  const createDepositAutoMutation = useHandleCreateDepositAuto();
  const getDepositAuthQuery = useGetDepositAuto();

  const user = useUserStore((state) => state?.user);

  useEffect(() => {
    if (!(!!user?.IDverification && !!user?.mobileVerification)) {
      navigate("/v2/dashboard/wallet/toman/deposit");
    }
  }, [user]);

  const noShebaFoundedComponent = () => (
    <Stack>
      <IconTypography
        bgColor="error.A100"
        icon={<Warning2 variant="Bold" color={theme.palette.error[400]} />}
        title={
          <Typography variant="Body" smVariant="BodyMedium">
            واریز با شناسه، با ثبت شماره شبا در بالینکس امکان پذیر است، شما
            شماره شبایی در بالینکس ثبت نکرده‌اید.
          </Typography>
        }
      />

      <AddBankButton titleBtn="افزودن شبا" />
    </Stack>
  );

  return (
    <Wrapper
      isNoPaddingModeContainer
      hideBottomNavigation
      loading={
        getBankAccountQuery?.isLoading || getDepositAuthQuery?.isFetching
      }
      title="دریافت شناسه واریز"
      backMode
      sxContainer={{ pt: { md: "2rem" } }}
    >
      <Stack
        width="100%"
        sx={{
          p: "1.5rem",
          flex: 1,
          bgcolor: "white",
          borderRadius: { md: "1.125rem" },
          display: "flex",
        }}
      >
        <Stack
          direction="row"
          width="100%"
          sx={{
            minHeight: { xs: "calc(100dvh - 90px)", md: "auto" },
            gap: { xs: "2rem", md: "2.81rem" },
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Stack justifyContent="space-between" sx={{ flex: { md: 1 } }}>
            {!getDepositAuthQuery?.data?.result?.isExist ? (
              // @ts-ignore
              !getBankAccountQuery?.data?.items?.filter(
                (e: MyBankAccountItem) => e?.accType === "account"
              )?.length ? (
                noShebaFoundedComponent()
              ) : (
                <Stack>
                  <IconTypography
                    bgColor="colorfulGray.A100"
                    icon={
                      <Money4
                        variant="Bold"
                        color={theme.palette.primary[400]}
                      />
                    }
                    title={
                      <Typography variant="Body" smVariant="BodyMedium">
                        با توجه به دلیل محدودیت‌های بانکی، واریز بیشتراز ۲۵
                        میلیون تومان روزانه از طریق شناسه پرداخت انجام می‌شود
                      </Typography>
                    }
                  />

                  <Button
                    onClick={() => createDepositAutoMutation.mutate()}
                    loading={createDepositAutoMutation.isPending}
                    sx={{ mt: "1.5rem" }}
                    bgColor="primary.700"
                    startIcon={<Money4 size="1.5rem" />}
                  >
                    <Typography variant="BodyMedium">
                      دریافت شناسه واریز
                    </Typography>
                  </Button>
                </Stack>
              )
            ) : (
              <ResultShebaId {...getDepositAuthQuery?.data?.result} />
            )}
          </Stack>
          <Stack sx={{ flex: { md: 1 } }} gap="1rem">
            <Hint
              severity="warning"
              IconComponent={InfoCircle}
              content={
                <Stack gap="0.62rem">
                  <Typography variant="Caption" color="info.700">
                    هنگام واریز وجه از روش های پایا، ساتنا و حساب به حساب حتما
                    در بخش شناسه‌ی واریز، شناسه‌ی دریافتی خود را وارد کنید.
                  </Typography>
                </Stack>
              }
            />
            <Hint
              severity="info"
              IconComponent={LampCharge}
              content={
                <Stack gap="0.62rem">
                  <Typography variant="Caption" color="info.700">
                    مبلغ به صورت خودکار طی سیکل پایا یا ساتنا به حساب شما افزوده
                    می‌شود و به محض اضافه شدن به حساب بالینکس به شما اطلاع
                    خواهیم داد. تا قبل از آن بالینکس هیچ دسترسی برای پیگیری
                    انتقال وجه بانکی ندارد.
                  </Typography>
                  <Typography variant="Caption" color="info.700">
                    مبلغ کارمزد با شناسه پرداخت، به ازای هر یک میلیون تومان،{" "}
                    {getDepositAuthQuery?.data?.result?.fee} تومان میباشد.
                  </Typography>
                </Stack>
              }
            />
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  );
}

const ResultShebaId = ({ data }: DepositAutoRes) => {
  const [isShebaCopied, setIsShebaCopied] = useState(false);
  const [isIdCopied, setIsIdCopied] = useState(false);

  return (
    <Stack gap="0.75rem">
      <Typography textAlign="center" variant="BodyMedium" color="neutral.900">
        انتقال از طریق شماره شبا (پایا، ساتنا و ...)
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        borderRadius="0.625rem"
        p="0.62rem"
        bgcolor="neutral.50"
        gap="0.97rem"
      >
        <Stack>
          <Typography textAlign="center" variant="Body" color="primary.600">
            شماره شبا:
            <Typography component="span" color="primary.600" px="0.25rem">
              به نام {data?.destinationOwnerName}
            </Typography>
          </Typography>
          <Typography
            textAlign="center"
            variant="ExtendedBodyBold"
            color="primary.700"
            onClick={async () =>
              await toClipboard({
                text: data?.destinationIban || "",
                cb: (isCopied) => setIsShebaCopied(isCopied),
              })
            }
          >
            {data?.destinationIban}
          </Typography>
        </Stack>
        <CopyIcon
          isCopied={isShebaCopied}
          onChange={(isCopied) => setIsShebaCopied(isCopied)}
          text={data?.destinationIban || ""}
        />
      </Stack>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        borderRadius="0.625rem"
        p="0.62rem"
        bgcolor="neutral.50"
        gap="0.97rem"
      >
        <Stack>
          <Typography textAlign="center" variant="Body" color="primary.600">
            شناسه واریز
          </Typography>
          <Typography
            textAlign="center"
            variant="ExtendedBodyBold"
            color="primary.700"
            onClick={async () =>
              await toClipboard({
                text: data?.payId || "",
                cb: (isCopied) => setIsIdCopied(isCopied),
              })
            }
          >
            {data?.payId}
          </Typography>
        </Stack>
        <CopyIcon
          isCopied={isIdCopied}
          onChange={(isCopied) => setIsIdCopied(isCopied)}
          text={data?.payId || ""}
        />
      </Stack>

      <Typography variant="Body" color="colorfulGray.600">
        ثبت شناسه در هنگام واریز الزامی است، در صورت ثبت نکردن مبلغ به حساب شما
        اضافه نخواهد شد و پیگیری آن برای بالینکس امکان پذیر نیست
      </Typography>
    </Stack>
  );
};
