import { useState, useRef, KeyboardEvent, ClipboardEventHandler } from "react";
import { TextField } from "@components/common/textfield";
import { ChangeEvent } from "react";
import { Stack } from "@mui/material";

interface CodeInputsProps {
  count: number;
  onChange: (val: string) => void;
}

export const CodeInputs = ({ count, onChange }: CodeInputsProps) => {
  const [values, setValues] = useState<string[]>(Array(count).fill(""));
  const refs = useRef<HTMLInputElement[]>([]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    const newChar = e.target.value.slice(-1);
    const newValues = [...values];
    newValues[index] = newChar;

    setValues(newValues);

    if (index < values.length - 1 && e.target.value.length >= 1) {
      refs.current[index + 1].focus();
    }

    onChange(newValues.join(""));
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>, index: number) => {
    if (e.key === "Backspace" || e.key === "Delete") {
      const newValues = [...values];
      newValues[index] = "";
      setValues(newValues);

      e.preventDefault();
      if (index > 0) {
        refs.current[index - 1].focus();
      }

      onChange(newValues.join(""));
    }
  };

  const handlePaste: ClipboardEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();

    const paste = await e.clipboardData.getData('text')
    const pasteNumbers = paste.split("").filter((char) => !isNaN(Number(char)));

    if (pasteNumbers.length > 0) {
      const newValues = [...values];

      let insertIndex = refs.current.findIndex(
        (ref) => ref === document.activeElement
      );
      insertIndex = insertIndex >= 0 ? insertIndex : 0;

      pasteNumbers.forEach((num, index) => {
        if (insertIndex + index < newValues.length) {
          newValues[insertIndex + index] = num;
        } else {
          newValues.push(num);
        }
      });

      setValues(newValues);

      const lastPasteIndex =
        Math.min(insertIndex + pasteNumbers.length, newValues.length) - 1;
      if (lastPasteIndex < refs.current.length) {
        refs.current[lastPasteIndex].focus();
        onChange(newValues.join(""));
      }
    }
  };

  return (
    <Stack direction="row-reverse" gap="0.5rem">
      {Array.from({ length: count }).map((_, index) => (
        <TextField
          key={`code-${index}`}
          inputProps={{
            "aria-autocomplete": "none",
            inputMode: "numeric",
            maxLength: 1,
          }}
          numberInput
          autoFocus={index === 0}
          name={`baliCode-${index + 1}`}
          value={values[index]}
          onChange={(e) => handleChange(e, index)}
          onKeyDown={(e) => handleKeyDown(e, index)}
          onPaste={handlePaste}
          inputRef={(el) => (refs.current[index] = el)}
          sx={{
            p: 0,
            width: "fit-content",
            ".MuiInputBase-input": {
              p: 0,
              pt: "3px",
              textAlign: "center",
              fontFamily: "YekanBakhBold",
              fontSize: "2.25rem",
              width: "3rem",
              height: "3rem",
            },
          }}
        />
      ))}
    </Stack>
  );
};
