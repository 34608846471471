import { Button } from "@components/common/button";
import { CodeInputs } from "@components/common/code-inputs";
import { Modal } from "@components/common/modal";
import { motion } from "framer-motion";

import { Typography } from "@components/common/typography";
import { Stack, SvgIcon } from "@mui/material";
import { TickCircle, Timer1 } from "iconsax-react";
import { useEffect, useState } from "react";
import { useIntervalTimer } from "@hooks/useIntervalTimer";
import { useHandleResendWithdrawOtp } from "@services/queries/wallet/mutations";

interface CodeVerificationModalProps {
  onSubmit: (code: string) => void;
  onClose: () => void;
  loading?: boolean;
  withdrawId: string;
}

export const CodeVerificationModal = ({
  onSubmit,
  onClose,
  loading,
  withdrawId,
}: CodeVerificationModalProps) => {
  const { generateTimer, remainedTime } = useIntervalTimer();

  const handleResendWithdrawOtp = useHandleResendWithdrawOtp();

  const [code, setCode] = useState("");

  useEffect(() => {
    // console.log(Date.now() + 60000);
    generateTimer(Date.now() + 60000);
  }, []);

  const handleResendCode = () => {
    handleResendWithdrawOtp.mutate(withdrawId, {
      onSuccess: () => {
        generateTimer(Date.now() + 60000);
      },
    });
  };

  return (
    <Modal onClose={onClose} title="ورود کد" open={true}>
      <Stack gap="0.75rem">
        <Stack direction="row" gap={0.5} alignItems="center" mx="auto">
          {remainedTime ? (
            <motion.div
              initial={{ opacity: 0, transform: "translateX(-100px)" }}
              animate={{ opacity: 1, transform: "translateX(0)" }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.25rem",
              }}
            >
              <Typography
                pt="4px"
                variant="ExtendedBodyMedium"
                color="colorfulGray.500"
              >
                {remainedTime || "00:00"}
              </Typography>
              <SvgIcon
                sx={{
                  color: (theme) => theme.palette.colorfulGray[500],
                }}
              >
                <Timer1 variant="Bold" size="1.5rem" />
              </SvgIcon>
            </motion.div>
          ) : (
            <Button
              variant="text"
              textColor="primary.500"
              onClick={handleResendCode}
            >
              <Typography variant="ExtendedBodyBold" smVariant="BodyBold">ارسال کد مجدد</Typography>
            </Button>
          )}
        </Stack>
        <Typography variant="ExtendedBodyBold" smVariant="BodyBold" color="colorfulGray.700">
          کد ارسالی به شماره موبایلتان را وارد کنید
        </Typography>

        <CodeInputs count={6} onChange={(val) => setCode(val)} />

        <Button
          loading={loading}
          disabled={code?.length < 6}
          fullWidth
          variant="outlined"
          size="medium"
          startIcon={<TickCircle size="1.5rem" />}
          onClick={() => onSubmit(code)}
        >
          <Typography variant="BodyBold">تایید نهایی</Typography>
        </Button>
      </Stack>
    </Modal>
  );
};
