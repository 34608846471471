import { Button } from "@components/common/button";
import { CodeInputs } from "@components/common/code-inputs";
import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";

import { Typography } from "@components/common/typography";
import { Stack } from "@mui/material";
import { TickCircle } from "iconsax-react";
import { useState } from "react";

interface GoogleTwoStepVerificationModalProps {
  onSubmit: (code: string) => void;
  onClose: () => void;
  loading?: boolean;
}

export const GoogleTwoStepVerificationModal = ({
  onSubmit,
  onClose,
  loading
}: GoogleTwoStepVerificationModalProps) => {
  const [code, setCode] = useState("")

  return (
    <Modal onClose={onClose} title="ورود کد عاملی" open={true}>
      <Stack gap="0.75rem">
        <Image
          sx={{mx:"auto"}}
          src="/v2/images/google-two-step-icon.png"
          alt="google-two-step-icon.png"
          width="5rem"
          height="5rem"
        />
        <Typography variant="ExtendedBodyBold" color="colorfulGray.700">
          کد اپلیکیشن Google Authenticator را وارد کنید
        </Typography>

        <CodeInputs count={6} onChange={(val) => setCode(val)} />

        <Button
          loading={loading}
          disabled={code?.length < 6}
          fullWidth
          variant="outlined"
          startIcon={<TickCircle variant="Bold" size="1.25rem" />}
          onClick={()=> onSubmit(code)}
        >
          <Typography variant="BodyBold">تایید نهایی</Typography>
        </Button>
      </Stack>
    </Modal>
  );
};
