import { Typography } from "@components/common/typography";
import { CircularProgress, Grid, Stack, useTheme } from "@mui/material";
import { DocumentFilter } from "iconsax-react";
import { Fragment, useEffect, useMemo } from "react";
import { WalletItemCard } from "../wallet-card-item";
import {
  useGetMyEstimateWallet,
  useInfiniteGetMyWallet,
} from "@services/queries/wallet/queries";
import { BaseCoin } from "~types/index";
import { IconTypography } from "@components/common/icon-typography";
import { SearchBox } from "@components/common/search-box";
import { useFilter } from "@hooks/useFilter";
import { InfiniteScroll } from "@components/common/infinite-scroll";
import { WalletItem } from "@services/queries/wallet/types";

interface ListCurrencyAndWalletProps {
  isHidePrice?: boolean;
  baseOn: BaseCoin;
}

export const ListCurrencyAndWallet = ({
  isHidePrice,
  baseOn,
}: ListCurrencyAndWalletProps) => {
  const theme = useTheme();

  const { searchParams, setParams } = useFilter();

  const useGetMyWalletQuery = useInfiniteGetMyWallet({
    search: searchParams?.get("search") || "",
  });

  const useGetMyEstimateWalletQuery = useGetMyEstimateWallet();

  const walletList = useMemo(
    () => useGetMyWalletQuery?.data?.pages?.flatMap((e) => e?.result?.items),
    [useGetMyWalletQuery?.isFetchingNextPage, useGetMyWalletQuery?.isFetching]
  );

  useEffect(() => {
    useGetMyWalletQuery?.refetch();
  }, [searchParams?.get("search")]);

  const prioritizeByCurrency = (
    walletList: WalletItem[],
    priorityCurrencies: string[]
  ): WalletItem[] => {
    return walletList?.sort((a, b) => {
      const aPriority = priorityCurrencies.indexOf(a.currency);
      const bPriority = priorityCurrencies.indexOf(b.currency);

      if (aPriority === -1 && bPriority === -1) {
        return 0; // Both items are not in the priority list, keep their order
      }
      if (aPriority === -1) {
        return 1; // a is not in the priority list, b should come first
      }
      if (bPriority === -1) {
        return -1; // b is not in the priority list, a should come first
      }
      return aPriority - bPriority; // Both items are in the priority list, sort by their priority
    });
  };

  const mergedWalletList = useMemo(() => {
    const estimateWallets = useGetMyEstimateWalletQuery?.data?.result || [];
    const mergedList = walletList?.map((wallet) => {
      const estimate = estimateWallets?.find(
        (e) => e.currency === wallet.currency
      );
      return estimate
        ? { ...wallet, balanceEstimate: estimate.balanceEstimate }
        : wallet;
    });
    return mergedList || [];
  }, [walletList, useGetMyEstimateWalletQuery?.data]);

  const priorityCurrencies = ["TON", "HMSTR"];

  const sortedWalletList = useMemo(
    () => prioritizeByCurrency(mergedWalletList, priorityCurrencies),
    [mergedWalletList, priorityCurrencies]
  );

  return (
    <Stack position="relative">
      {useGetMyWalletQuery?.isFetching && (
        <Stack
          position="absolute"
          justifyContent="center"
          alignItems="center"
          bgcolor="rgba(255, 255, 255, 0.8)"
          sx={{ backdropFilter: "blur(4px)" }}
        >
          <CircularProgress color="primary" size={24} />
        </Stack>
      )}

      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        zIndex={0}
      >
        <Typography flex={1} variant="TitleBold" color="primary.800">
          {!!sortedWalletList?.filter(
            (e) => !!e?.pin || e?.balanceAvailable > 0
          )?.length
            ? "دارایی‌های شما"
            : "ارز های دیگر"}
        </Typography>
        <Stack sx={{ display: { xs: "none", md: "block" } }} flex={1}>
          <SearchBox
            withDebounce
            onResetValueOnClick={() => setParams({ name: "search", value: "" })}
            onDebounceTrigger={(value) => setParams({ name: "search", value })}
          />
        </Stack>
      </Stack>

      <InfiniteScroll
        fetchMore={async () => {
          !useGetMyWalletQuery?.isFetchingNextPage &&
          useGetMyWalletQuery?.hasNextPage
            ? await useGetMyWalletQuery.fetchNextPage()
            : await 0;
        }}
      >
        <Grid columns={14} container pt="1.5rem" rowSpacing="1rem">
          {/* Header Columns */}
          {/* prettier-ignore */}
          <>
          <Grid sx={{display: {xs: "none", md: "flex"}}} xs={3} item><Typography variant="TitleBold" color="colorfulGray.500">ارز‌</Typography></Grid>
          <Grid sx={{display: {xs: "none", md: "flex"}, justifyContent:"flex-end"}} xs={3} item><Typography variant="TitleBold" color="colorfulGray.500">در دسترس</Typography></Grid>
          <Grid sx={{display: {xs: "none", md: "flex"}, justifyContent:"flex-end"}} xs={3} item><Typography variant="TitleBold" color="colorfulGray.500">در معامله</Typography></Grid>
          <Grid sx={{display: {xs: "none", md: "flex"}, justifyContent:"flex-end"}} xs={3} item><Typography variant="TitleBold" color="colorfulGray.500">دارایی کل</Typography></Grid>
          <Grid sx={{display: {xs: "none", md: "flex"}}} xs={2} item></Grid>
        </>

          {!!sortedWalletList?.length ? (
            sortedWalletList
              ?.filter((e) => !!e?.pin || e?.balanceTotal > 0)
              ?.filter(
                (e) =>
                  e.currency !== (baseOn === BaseCoin.TOMAN ? "IRT" : "USDT")
              )
              ?.map((item) => (
                <Fragment key={`market-item-${item?.id}-list-${item?._id}`}>
                  <WalletItemCard
                    isEstimateLoading={useGetMyEstimateWalletQuery?.isLoading}
                    baseOn={baseOn}
                    isHidePrice={isHidePrice}
                    item={item}
                  />
                </Fragment>
              ))
          ) : (
            <Grid item xs={13}>
              <IconTypography
                sx={{ py: "1.5rem" }}
                bgColor="primary.A100"
                title={
                  <Typography variant="ExtendedBody" color="colorfulGray.700">
                    موردی برای نمایش یافت نشد.
                  </Typography>
                }
                icon={
                  <DocumentFilter
                    size="3rem"
                    color={theme.palette.primary[50]}
                  />
                }
              />
            </Grid>
          )}

          {!searchParams?.get("search")?.length && (
            <Grid item xs={13}>
              <Typography flex={1} variant="TitleBold" color="primary.800">
                ارز‌‌ های دیگر
              </Typography>
            </Grid>
          )}

          {!!sortedWalletList?.filter((e) => !e.pin && e?.balanceTotal <= 0)
            ?.length &&
            sortedWalletList
              ?.filter((e) => !e.pin && e?.balanceTotal <= 0)
              ?.map((item) => (
                <Fragment key={`market-item-${item?.id}-list-${item?._id}`}>
                  <WalletItemCard
                    isEstimateLoading={useGetMyEstimateWalletQuery?.isLoading}
                    isOtherCoin
                    baseOn={baseOn}
                    isHidePrice={isHidePrice}
                    item={item}
                  />
                </Fragment>
              ))}
        </Grid>
      </InfiniteScroll>
    </Stack>
  );
};
