import { useEffect, useState } from "react";
import { HeaderData } from "../_components/header/types";
import useCurrentCurrency from "./useCurrentCurrency";
import useGetLastTrade from "./useGetLastTrade";

const useCurrencyUpdate = () => {
  const currentCurrency = useCurrentCurrency();

  const { lastPriceCoin } = useGetLastTrade();

  const [headerData, setHeaderData] = useState<HeaderData>();

  useEffect(() => {
    if (currentCurrency) {
      setHeaderData({
        price: currentCurrency?.todayPrice,
        open24h: currentCurrency?.yesterdayHighPrice,
        low24h: currentCurrency?.yesterdayLowPrice,
        volume24h: currentCurrency?.yesterdayPrice,
      });
    }
  }, [currentCurrency]);

  useEffect(() => {
    if (lastPriceCoin) {
      setHeaderData({
        price: lastPriceCoin?.price,
        open24h: lastPriceCoin?.open24h,
        low24h: lastPriceCoin?.low24h,
        volume24h: lastPriceCoin?.volume24h,
        side: lastPriceCoin?.side,
      });
    }
  }, [lastPriceCoin]);

  return headerData;
};

export default useCurrencyUpdate;
