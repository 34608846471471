import { CodeInputs } from "@components/common/code-inputs";
import { Typography } from "@components/common/typography";
import { IconButton, Stack, SvgIcon } from "@mui/material";
import { useVerifyGoogleAuth } from "@services/queries/auth/mutations";
import { ArrowLeft } from "iconsax-react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthLayout from "../_components/auth-layout";

declare global {
  interface Window {
    isLoggedIn: boolean;
    isNewUser: boolean;
  }
}

export default function GoogleAuthenticator() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [otpCode, setOTPCode] = useState("");

  useEffect(() => {
    if (!state?.mobile || !state.isTwoAuth) {
      navigate("/v2/login");
    }
  }, [location, state?.mobile]);

  const twoGoogleAuth = useVerifyGoogleAuth();

  useEffect(() => {
    if (otpCode.length === 6) {
      twoGoogleAuth.mutate(
        { loginToken: state.token, code: otpCode },
        {
          onSuccess() {
            window.isLoggedIn = true;
            window.isNewUser = false;
            navigate("/v2/dashboard");
          },
        }
      );
    }
  }, [otpCode]);

  return (
    <AuthLayout>
      <Stack
        mx="1.5rem"
        width="100%"
        height="100%"
        justifyContent="space-between"
      >
        <Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt="2.75rem"
          >
            <Typography variant="ExtendedBodyMedium">کد دو عاملی</Typography>
            <IconButton onClick={() => navigate("/v2/login")}>
              <SvgIcon
                sx={{
                  color: (theme) => theme.palette.colorfulGray[500],
                }}
              >
                <ArrowLeft />
              </SvgIcon>
            </IconButton>
          </Stack>

          <Stack
            sx={{
              marginTop: { xs: "2.75rem", md: "2rem" },
            }}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Stack
              component={"img"}
              src="images/google-two-step-icon.png"
              width="5rem"
              height="5rem"
              mb="1.25rem"
            />
          </Stack>
          <Typography variant="BodyBold" mt="0.75rem">
            کد احراز هویت گوگلی‌تان را وارد کنید
          </Typography>
          <Stack mt="0.5rem">
            <CodeInputs count={6} onChange={(val) => setOTPCode(val)} />
          </Stack>
        </Stack>
      </Stack>
    </AuthLayout>
  );
}
