import { Button } from "@components/common/button";
import { IconTypography } from "@components/common/icon-typography";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack, useTheme } from "@mui/material";
import { TickCircle, Verify } from "iconsax-react";
import { useNavigate } from "react-router-dom";

interface SuccessWithdrawModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SuccessWithdrawModal = ({
  isOpen,
  onClose,
}: SuccessWithdrawModalProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Modal open={isOpen} title="درخواست برداشت" onClose={onClose}>
      <Stack width="100%" gap="1.5rem" maxWidth="350px">
        <IconTypography
          bgColor="success.A100"
          icon={
            <Verify
              color={theme?.palette?.success?.[700]}
              variant="Bold"
              fontSize="1.97rem"
            />
          }
          title={
            <Typography
              color="success.900"
              variant="ExtendedBody"
              textAlign="center"
            >
              درخواست برداشتان ثبت شد و در صف سیکل پایا قرار گرفته‌است.
            </Typography>
          }
        />

        <Typography
          variant="ExtendedBody"
          textAlign="center"
          color="colorfulGray.700"
          mt="0.75rem"
        >
          برای پیگیری وضعیت برداشتتان به صفحه تاریخچه برداشت‌ها مراجعه کنید.
        </Typography>

        <Button
          fullWidth
          variant="outlined"
          startIcon={<TickCircle variant="Bold" size="1.25rem" />}
          onClick={() => navigate("/v2/dashboard/financial/transactions")}
        >
          <Typography variant="BodyBold">تاریخچه برداشت</Typography>
        </Button>
      </Stack>
    </Modal>
  );
};
