import { useBreakpoint } from "@hooks/useBreakpoint";
import { InputAdornment, Modal, Popover, Stack, SvgIcon } from "@mui/material";
import { ArrowDown2 } from "iconsax-react";
import { useEffect, useState } from "react";
import { TextField, TextFieldProps } from "../textfield";
import { Typography } from "../typography";

interface DropDownProps {
  children: React.ReactNode;
  placeholder?: string;
  label?: string;
  titleModal: string;
}

export const DropDown = ({
  children,
  label,
  placeholder,
  titleModal,
  InputProps,
  disabled,
  ...props
}: DropDownProps & TextFieldProps) => {
  const isMdUp = useBreakpoint("md");

  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    setAnchorEl(null);
  }, [props.value]);

  return (
    <>
      <Stack position="relative">
        <TextField
          label={label}
          placeholder={placeholder}
          sx={{
            ".MuiInputBase-input": {
              "-webkit-text-fill-color": "unset",
              color: "primary.800",
            },
          }}
          InputProps={{
            disabled: true,
            endAdornment: (
              <InputAdornment position="end">
                <SvgIcon
                  sx={{
                    transform: anchorEl ? "rotate(-180deg)" : "rotate(0)",
                    transition: "0.3s",
                    color: (theme) => theme.palette.colorfulGray[800],
                    fontSize: "1.25rem",
                  }}
                >
                  <ArrowDown2 />
                </SvgIcon>
              </InputAdornment>
            ),
            ...InputProps,
          }}
          {...props}
        />
        <Stack
          position="absolute"
          sx={{
            bottom: 0,
            left: 0,
            width: "100%",
            height: "calc(100% - 25px)",
          }}
          onClick={(e) => !disabled && setAnchorEl(e.currentTarget)}
        />
      </Stack>
      <Modal
        hideBackdrop={isMdUp}
        sx={{
          display: "flex",
          backdropFilter: { xs: "blur(3px)", md: "unset" },
          outline: "none",
          "& div": {
            outline: "none",
          },
        }}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      >
        {isMdUp ? (
          <Popover
            sx={{
              mt: "0.65rem",
              boxShadow: "none",
              ".MuiPaper-root": {
                width: `${anchorEl?.clientWidth}px`,
                borderRadius: "1.125rem",
                boxShadow: "0px 0px 22px -12px rgba(0, 0, 0, 0.16)",
              },
            }}
            id="select-modal"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Stack bgcolor="white" p="1rem" borderRadius="0.625rem">
              {children}
            </Stack>
          </Popover>
        ) : (
          <Stack width="100%" p="1rem" mt="auto" justifyContent="flex-end">
            <Stack
              bgcolor="white"
              py="1.5rem"
              px="1rem"
              borderRadius="1.125rem"
              gap="1.5rem"
            >
              <Typography color="colorfulGray.700" smVariant="BodyBold">
                {titleModal}
              </Typography>
              {children}
            </Stack>
          </Stack>
        )}
      </Modal>
    </>
  );
};
