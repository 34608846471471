import { Button } from "@components/common/button";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { useFilter } from "@hooks/useFilter";
import { CircularProgress, Stack, useTheme } from "@mui/material";
import { FilterSquare, Star1 } from "iconsax-react";
import { useEffect, useRef, useState } from "react";
import { SearchBox } from "@components/common/search-box";

interface TableListLayoutProps {
  title: string;
  searchPlaceholder?: string;
  actions?: React.ReactNode;
  withFavorite?: boolean;
  children: React.ReactNode;
  withSearch?: boolean;
  isLoading?: boolean;
  total: number;
}

export const TableListLayout = ({
  title,
  actions,
  withFavorite,
  children,
  withSearch,
  isLoading,
  total,
}: TableListLayoutProps) => {
  const theme = useTheme();

  const stickyEle = useRef<HTMLDivElement>(null);

  const { searchParams, setParams } = useFilter();

  const [isFavorite, setIsFavorite] = useState(
    searchParams?.get("favorite") === "true" || false
  );

  const handleFavorite = () => {
    setIsFavorite(!isFavorite);
    setParams({
      name: "favorite",
      value: !isFavorite ? "true" : "",
      reset: true,
    });
  };

  const isMdUp = useBreakpoint("md");

  useEffect(() => {
    const checkStickiness = () => {
      if (stickyEle.current) {
        const rect = stickyEle.current.getBoundingClientRect();
        const topNavigation = document
          .querySelector("#TOP_NAVIGATION")
          ?.getBoundingClientRect();
        stickyEle.current.classList.toggle(
          "is-pinned",
          rect.top <= (!!topNavigation ? topNavigation?.height : 100)
        );
      }
    };
    window.onscroll = checkStickiness;
    return () => {
      window.onscroll = null;
    };
  }, []);

  return (
    <>
      <Typography variant="TitleBold" smVariant="Body" color="primary.800">
        {title}
      </Typography>

      <Stack>
        {/* <motion.div initial={{ opacity: 0 }} whileInView={{ opacity: 1 }}> */}
        <Stack
          ref={stickyEle}
          position="sticky"
          sx={{
            "&.is-pinned": {
              px: { xs: "0.5rem", md: "1rem" },
            },
            transition: "0.3s",
            top: "57px",
            bgcolor: "rgba(239,238,244,0.75)",
            backdropFilter: "blur(5px)",
            gap: "0.5rem",
            py: "0.5rem",
            flexDirection: { xs: "column", md: "row" },
          }}
          pb="0.2rem"
          zIndex={99}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack width="100%">
            {withSearch && (
              <SearchBox
                sx={{ bgcolor: "colorfulGray.50" }}
                withDebounce
                onResetValueOnClick={() =>
                  setParams({ name: "search", value: "" })
                }
                onDebounceTrigger={(value) =>
                  setParams({ name: "search", value })
                }
              />
            )}
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            width="100%"
            sx={{ gap: { md: "0.5rem" } }}
          >
            {actions}

            {!!withFavorite && (
              <Button
                onClick={handleFavorite}
                bgColor={isMdUp ? "white" : "transparent"}
                startIcon={
                  <Star1
                    size={24}
                    color={theme?.palette?.primary?.[700]}
                    variant={isFavorite ? "Bold" : "Outline"}
                  />
                }
              />
            )}
          </Stack>
        </Stack>
        {/* </motion.div> */}

        <Stack
          bgcolor="white"
          borderRadius="1.125rem"
          boxShadow="0px 0px 22px -12px rgba(0, 0, 0, 0.16)"
          p="0.5rem"
          minHeight="40px"
        >
          {isLoading && (
            <Stack
              position="absolute"
              justifyContent="center"
              alignItems="center"
              bgcolor="rgba(255, 255, 255, 0.8)"
              sx={{ backdropFilter: "blur(4px)" }}
            >
              <CircularProgress color="primary" size={24} />
            </Stack>
          )}

          {!isLoading && total <= 0 && (
            <Stack
              py="1rem"
              gap="1rem"
              justifyContent="center"
              alignItems="center"
            >
              {isFavorite ? (
                <Star1
                  size={64}
                  variant="Bold"
                  color={theme?.palette?.primary?.[100]}
                />
              ) : (
                <FilterSquare
                  size={64}
                  variant="Bold"
                  color={theme?.palette?.primary?.[100]}
                />
              )}
              <Typography
                maxWidth="300px"
                variant="Body"
                color="neutral.800"
                textAlign="center"
              >
                {isFavorite
                  ? "هنوز لیست منتخبی نداری بازار‌های منخبت رو انتخاب کن تا سریع‌تر بررسیشان کنی"
                  : "موردی برای نمایش وجود ندارد"}
              </Typography>
            </Stack>
          )}

          {children}
        </Stack>
      </Stack>
    </>
  );
};
