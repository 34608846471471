import { Stack } from "@mui/material";
import {
  Category2,
  Chart2,
  ArrangeVertical,
  Headphone,
  People,
  ReceiptText,
  TextalignCenter,
  Wallet2,
  MessageQuestion,
  Setting2,
  Gift,
} from "iconsax-react";
import { SideNavItem } from "./_components/side-nav-item";
import { Fragment } from "react";

export interface ISideNavItem {
  title: string;
  href?: string;
  icon: (isActive: boolean) => JSX.Element;
  isLinkOutSource: boolean;
  children?: Omit<ISideNavItem, "icon" | "children">[];
}

const sideNavList: ISideNavItem[] = [
  {
    title: "داشبورد",
    href: "/v2/dashboard",
    icon: (isActive: boolean) => (
      <Category2 size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
  },
  {
    title: "بازار‌ها",
    href: "/v2/dashboard/markets",
    icon: (isActive: boolean) => (
      <Chart2 size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
  },
  {
    title: "کیف پول",
    href: "/v2/dashboard/wallet",
    icon: (isActive: boolean) => (
      <Wallet2 size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
  },
  {
    title: "معامله سریع",
    href: "/v2/dashboard/otc",
    icon: (isActive: boolean) => (
      <ArrangeVertical size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
  },
  {
    title: "تاریخچه مالی",
    icon: (isActive: boolean) => (
      <ReceiptText size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
    children: [
      {
        title: "سفارشات",
        href: `/v2/dashboard/financial/orders`,
        isLinkOutSource: false,
      },
      {
        title: "معاملات سریع",
        href: `/v2/dashboard/financial/otc`,
        isLinkOutSource: false,
      },
      {
        title: "تراکنش‌ها",
        href: `/v2/dashboard/financial/transactions`,
        isLinkOutSource: false,
      },
    ],
  },
  {
    title: "کارت هدیه",
    href: `/v2/dashboard/gift/redeem`,
    icon: (isActive: boolean) => (
      <Gift size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
  },
  {
    title: "تنظیمات",
    href: "/v2/dashboard/settings",
    icon: (isActive: boolean) => (
      <Setting2 size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
  },
  {
    title: "پشتیبانی",
    href: "SUPPORT",
    icon: (isActive: boolean) => (
      <Headphone size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
  },
  {
    title: "دعوت از دوستان",
    href: `/v2/dashboard/referral`,
    icon: (isActive: boolean) => (
      <People size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: false,
  },
  {
    title: "سوالات متداول",
    href: `https://www.balinex.com/mag/help/`,
    icon: (isActive: boolean) => (
      <MessageQuestion size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: true,
  },
  {
    title: "بلاگ",
    href: "https://balinex.com/mag",
    icon: (isActive: boolean) => (
      <TextalignCenter size="1.5rem" variant={isActive ? "Bold" : "Linear"} />
    ),
    isLinkOutSource: true,
  },
];

export const SideNavigation = () => {
  return (
    <Stack
      borderRadius="1.125rem"
      bgcolor="white"
      py="1.25rem"
      px="1rem"
      component="aside"
      width="100%"
      height="fit-content"
      position="sticky"
      top="5.5rem"
    >
      <Stack component="ul" gap="0.5rem">
        {sideNavList?.map((item) => (
          <Fragment key={`side-nav-item-${item.title}`}>
            <SideNavItem item={item} />
          </Fragment>
        ))}
      </Stack>
    </Stack>
  );
};
