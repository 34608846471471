import { Typography } from "@components/common/typography";
import { Stack, SvgIcon } from "@mui/material";
import { ArrowLeft2, ReceiptDiscount } from "iconsax-react";
import { TransactionCommissionModal } from "../transaction-commission-modal";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { useState } from "react";

export const CommissionSection = () => {
  const isMdUp = useBreakpoint("md");

  const [isTransactionCommissionModal, setIsTransactionCommissionModal] =
    useState(false);

  return (
    <Stack
      bgcolor="white"
      width="100%"
      px="0.75rem"
      py="1.5rem"
      borderRadius="1.125rem"
      gap="1.5rem"
    >
      <Stack
        onClick={() => setIsTransactionCommissionModal(true)}
        sx={{ cursor: "pointer" }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap="0.5rem"
      >
        <Stack direction="row" gap="0.75rem" alignItems="center">
          <Stack
            component="span"
            p="0.5rem"
            borderRadius="50%"
            bgcolor="primary.500"
            sx={{ aspectRatio: "1/1" }}
          >
            <ReceiptDiscount variant="Bold" color="white" />
          </Stack>
          <Stack>
            <Typography
              smVariant="BodyMedium"
              variant="ExtendedBodyMedium"
              color="colorfulGray.700"
            >
              کارمزد معاملات من
            </Typography>
            <Typography
              smVariant="Caption"
              variant="Body"
              color="colorfulGray.500"
            >
              {isMdUp
                ? "کارمزد در بالینکس براساس حجم معاملات ۳۰ روز گذشته شما به صورت پله‌ای محاسبه می‌شود"
                : "براساس حجم معاملات و پله‌ای محاسبه می‌شود "}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap="0.25rem">
          {/* <Stack
            component="span"
            px="0.75rem"
            py="0.3rem"
            bgcolor="primary.A100"
            borderRadius="6.25rem"
          >
            <Typography
              smVariant="CaptionMedium"
              variant="BodyMedium"
              color="primary.600"
            >
              پایه
            </Typography>
          </Stack> */}
          {!isMdUp && (
            <SvgIcon sx={{ color: (theme) => theme.palette.colorfulGray[500] }}>
              <ArrowLeft2 size="1.25rem" />
            </SvgIcon>
          )}
        </Stack>
      </Stack>

      {/* Volume Toman */}
      {(isTransactionCommissionModal || isMdUp) && (
        <Stack width="100%" mx="auto">
          <TransactionCommissionModal
            modalMode={!isMdUp}
            onClose={() => setIsTransactionCommissionModal(false)}
          />
        </Stack>
      )}
    </Stack>
  );
};
