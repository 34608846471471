import { Wrapper } from "@components/layout/wrapper";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import { Typography } from "@components/common/typography";
import { TextField } from "@components/common/textfield";
import { Button } from "@components/common/button";
import { DatePickerProvider, DatePicker } from "@components/common/date-picker";
import { BaseAuthFormSchema } from "../../_validation";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useVerifyBaseAuth } from "@services/queries/authentication/mutations";
import { useEffect } from "react";
import { useUserStore } from "@store/user";
import { useNavigate } from "react-router-dom";
import { toast } from "@utils/toast";
import { numUtils } from "@utils/num-helpers";

export default function BaseAuthentication() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!user?.IDverification && !!user?.mobileVerification) {
      // Redirect to auth page
      navigate("/v2/dashboard/authentication");
    }
  }, []);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<z.output<typeof BaseAuthFormSchema>>({
    mode: "onTouched",
    resolver: zodResolver(BaseAuthFormSchema),
  });

  const { convertToPersianDate, toEnglish } = numUtils;

  const verifyBaseAuthMutation = useVerifyBaseAuth();

  const onSubmit = (data: z.output<typeof BaseAuthFormSchema>) => {
    const newData = new Date(data?.birthday.setHours(0, 0, 0, 0));

    verifyBaseAuthMutation.mutate({
      ...data,
      birthday: toEnglish(
        convertToPersianDate(newData.toISOString(), "short-date-time")
      ),
    });
  };

  useEffect(() => {
    if (verifyBaseAuthMutation?.isSuccess) {
      navigate("/v2/dashboard/authentication");
    } else if (verifyBaseAuthMutation?.isError) {
      // @ts-ignore
      toast.error(verifyBaseAuthMutation?.error?.response?.data?.message.fa);
    }
  }, [verifyBaseAuthMutation?.isPending]);

  return (
    <Wrapper backMode title="احراز هویت پایه">
      <Stack
        width="100%"
        maxWidth="900px"
        mx="auto"
        sx={{ py: { xs: "1rem", md: "3rem" }, gap: { xs: "1rem", md: "3rem" } }}
      >
        {/* <Stack overflow="hidden">
          <AuthStepper
            activeStep={2}
            steps={[
              {
                id: "MOBILE",
                title: "شماره همراه",
                icon: <PhoneEnabledOutlined sx={{ color: "neutral.50" }} />,
              },
              {
                id: "VERIFY_MOBILE",
                title: "تایید شماره همراه",
                icon: (
                  <DomainVerificationOutlined sx={{ color: "neutral.50" }} />
                ),
              },
              {
                id: "PERSONAL_INFORMATION",
                title: "مشخصات کاربری",
                icon: <PermIdentityOutlined sx={{ color: "neutral.50" }} />,
              },
            ]}
          />
        </Stack> */}
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Stack
            width="90%"
            maxWidth="700px"
            mx="auto"
            borderRadius="1.125rem"
            gap="1rem"
            bgcolor="white"
            py="2rem"
            sx={{
              px: { xs: "1rem", md: "1.5rem" },
            }}
          >
            <Typography variant="body1" pb="1rem" color="primary.800">
              لطفا اطلاعات زیر را مطابق با کارت شناسایی وارد کنید
            </Typography>

            <FormControl>
              <FormLabel id="gender">جنسیت</FormLabel>
              <RadioGroup
                row
                aria-labelledby="gender"
                onChange={(e) =>
                  setValue("gender", e.target.value as "female" | "male", {
                    shouldValidate: true,
                  })
                }
              >
                <FormControlLabel
                  {...register("gender")}
                  value="female"
                  control={<Radio />}
                  label="خانم"
                />
                <FormControlLabel
                  {...register("gender")}
                  value="male"
                  control={<Radio />}
                  label="آقا"
                />
              </RadioGroup>
            </FormControl>

            <TextField
              {...register("firstName")}
              placeholder="مرتضی"
              label="نام"
              handleHalfSpace
              error={!!errors?.firstName?.message}
              helperText={errors?.firstName?.message}
            />

            <TextField
              {...register("lastName")}
              placeholder="حکیم"
              label="نام خانوادگی"
              handleHalfSpace
              error={!!errors?.lastName?.message}
              helperText={errors?.lastName?.message}
            />

            <TextField
              {...register("nationalCode", {
                onChange: (e) => {
                  const value = e.target.value;
                  const regex = /^[0-9]*$/;
                  if (!regex.test(value)) {
                    return (e.target.value = "");
                  }
                },
              })}
              placeholder="0214526358"
              label="کد ملی"
              inputMode="numeric"
              inputProps={{ maxLength: 10 }}
              error={!!errors?.nationalCode?.message}
              helperText={errors?.nationalCode?.message}
            />

            <TextField
              label="تاریخ تولد"
              error={!!errors?.birthday?.message}
              helperText={errors?.birthday?.message}
              renderComponent={
                <Controller
                  name="birthday"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value, ref } }) => (
                    <DatePickerProvider>
                      <DatePicker
                        className="persian-num"
                        views={["year", "month", "day"]}
                        openTo="year"
                        defaultValue={new Date()}
                        value={value}
                        ref={ref}
                        onChange={(date) => onChange(date)}
                        sx={{ width: "100%" }}
                        disableFuture
                      />
                    </DatePickerProvider>
                  )}
                />
              }
            />

            <Button
              id="send-verification-identity"
              type="submit"
              loading={verifyBaseAuthMutation?.isPending}
              disabled={!isValid}
              sx={{ mt: "2rem" }}
              size="large"
            >
              <Typography variant="body1" color="neutral.50">
                تایید و ادامه
              </Typography>
            </Button>
          </Stack>
        </Box>
      </Stack>
    </Wrapper>
  );
}
