import { Typography } from "@components/common/typography";
import { Grid, Stack, useTheme } from "@mui/material";
import { TomanIcon } from "@assets/icons/TomanIcon";
import { numUtils } from "@utils/num-helpers";

import { format } from "date-fns-jalali";
import enUS from "date-fns/locale/en-US";
import { useGetLastWithdrawTransaction } from "@services/queries/wallet/queries";
import { WithdrawTransactionItem } from "@services/queries/wallet/types";
import { IconTypography } from "@components/common/icon-typography";
import { DocumentFilter } from "iconsax-react";
import { Fragment } from "react";
import { getPersianEquivalent } from "@utils/common";
import { TransactionsStatus } from "~types/index";

function convertToPersianDate(dateString: string) {
  const date = new Date(dateString);
  // @ts-ignore
  return format(date, "yyyy/MM/dd", { locale: enUS });
}

export const LastTomanWithdrawTransaction = () => {
  const getLastTomanWithdrawTransactionQuery = useGetLastWithdrawTransaction({
    query: [
      {
        field: "actionType",
        operator: "eq",
        value: "withdraw",
      },
      {
        field: "currency",
        operator: "eq",
        value: "IRT",
      },
    ],
  });

  const theme = useTheme();

  return (
    <Grid
      container
      rowGap="0.75rem"
      sx={{ maxHeight: "250px", overflowY: "auto" }}
    >
      <Grid xs={3} item>
        <Stack
          direction="row"
          gap="0.25rem"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="ExtendedBody" color="colorfulGray.700">
            مبلغ /
          </Typography>
          <TomanIcon
            sx={{
              fontSize: { xs: "0.75rem", md: "1rem" },
              fill: (theme) => theme.palette.colorfulGray[500],
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={2} item>
        <Stack
          direction="row"
          gap="0.25rem"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="ExtendedBody" color="colorfulGray.700">
            کارمزد/
          </Typography>
          <TomanIcon
            sx={{
              fontSize: { xs: "0.75rem", md: "1rem" },
              fill: (theme) => theme.palette.colorfulGray[500],
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={3} item>
        <Stack
          direction="row"
          gap="0.25rem"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="ExtendedBody" color="colorfulGray.700">
            دریافتی/
          </Typography>
          <TomanIcon
            sx={{
              fontSize: { xs: "0.75rem", md: "1rem" },
              fill: (theme) => theme.palette.colorfulGray[500],
            }}
          />
        </Stack>
      </Grid>
      <Grid xs={2} item sx={{ textAlign: "center" }}>
        <Typography variant="ExtendedBody" color="colorfulGray.700">
          تاریخ
        </Typography>
      </Grid>
      <Grid xs={2} item sx={{ textAlign: "center" }}>
        <Typography variant="ExtendedBody" color="colorfulGray.700">
          وضعیت
        </Typography>
      </Grid>

      {getLastTomanWithdrawTransactionQuery?.data?.result?.items?.length ===
      0 ? (
        <Grid xs={12}>
          <IconTypography
            sx={{ py: "1.5rem" }}
            title={
              <Typography variant="ExtendedBody" color="colorfulGray.700">
                موردی برای نمایش یافت نشد.
              </Typography>
            }
            icon={
              <DocumentFilter size="3rem" color={theme.palette.primary[50]} />
            }
          />
        </Grid>
      ) : (
        getLastTomanWithdrawTransactionQuery?.data?.result?.items?.map(
          (item: WithdrawTransactionItem) => (
            <Fragment key={`WithdrawTransactionItem-${item?._id}`}>
              <Grid xs={3} item sx={{ textAlign: "center" }}>
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.700"
                >
                  {numUtils.commaformatter.toCurrency(Number(item?.amount))}
                </Typography>
              </Grid>
              <Grid xs={2} item sx={{ textAlign: "center" }}>
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.700"
                >
                  {numUtils.commaformatter.toCurrency(Number(item?.fee))}
                </Typography>
              </Grid>
              <Grid xs={3} item sx={{ textAlign: "center" }}>
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.700"
                >
                  {numUtils.commaformatter.toCurrency(
                    Number(item?.amount) - Number(item?.fee)
                  )}
                </Typography>
              </Grid>
              <Grid xs={2} item sx={{ textAlign: "center" }}>
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.700"
                >
                  {convertToPersianDate(item?.createdAt)}
                </Typography>
              </Grid>
              <Grid xs={2} item sx={{ textAlign: "center" }}>
                <Typography
                  variant="ExtendedBody"
                  color={
                    item?.state === "SUCCESS"
                      ? "info.700"
                      : item?.state === "FAILED"
                      ? "error.500"
                      : "colorfulGray.600"
                  }
                >
                  {getPersianEquivalent(item?.state as TransactionsStatus)}
                </Typography>
              </Grid>
            </Fragment>
          )
        )
      )}
    </Grid>
  );
};
