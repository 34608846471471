import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { ProfileDropdownNavigation } from "@components/shared/profile-dropdown-navigation";
import { StarPattern } from "@components/shared/star-pattern";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Grid, Stack, useTheme } from "@mui/material";
import { useUserStore } from "@store/user";
import { SecurityUser } from "iconsax-react";
import { AuthenticationCard } from "./_components/auth-card";

export default function Authentication() {
  const user = useUserStore((state) => state.user);
  const theme = useTheme();
  const isMdUp = useBreakpoint("md");

  return (
    <Wrapper
      sxContainer={{ pt: { md: "2rem" } }}
      hideSideNavigation
      loading={!user}
      title="احراز هویت"
      isFullWidth={!isMdUp}
      backMode
    >
      <Grid columns={17} container columnSpacing="1.5rem" pb="1rem">
        <Grid sx={{ display: { xs: "none", md: "block" } }} item xs={5}>
          <Stack bgcolor="white" borderRadius="1.125rem">
            <ProfileDropdownNavigation isExitButton listOfDisplayId={[2, 3]} />
          </Stack>
        </Grid>
        <Grid item xs={17} md={12}>
          <Stack
            height="13rem"
            bgcolor="primary.50"
            justifyContent="center"
            alignItems="center"
            sx={{ borderRadius: { md: "1.125rem" } }}
          >
            <Stack
              width="100%"
              maxWidth="500px"
              justifyContent="center"
              alignItems="center"
              position="relative"
            >
              <StarPattern
                sx={{
                  position: "absolute",
                  right: 15,
                  bottom: -5,
                  height: "8.5rem",
                  width: "8.5rem",
                  fill: (theme) => theme.palette.primary[700],
                }}
              />
              <Stack gap="1rem" alignItems="center">
                <SecurityUser
                  size="3.5rem"
                  variant="Bold"
                  color={theme?.palette?.primary[700]}
                />
                <Typography color="primary.700" variant="TitleBold">
                  مسیر احراز هویت
                </Typography>
              </Stack>
              <StarPattern
                sx={{
                  position: "absolute",
                  left: 15,
                  bottom: -5,
                  height: "8.5rem",
                  width: "8.5rem",
                  fill: (theme) => theme.palette.primary[700],
                }}
              />
            </Stack>
          </Stack>

          <Stack gap="1rem" mt="-1.5rem" mx="auto" sx={{ maxWidth: "90%" }}>
            {/* INITIAL */}
            <AuthenticationCard
              title="تایید شماره موبایل"
              access={[
                { id: "DEPOSIT_CURRENCY", title: "واریز ارز" },
                { id: "WITHDRAW_CURRENCY", title: "برداشت ارز" },
              ]}
              steps={[
                {
                  id: "VERIFY_PHONE",
                  title: "تایید شماره همراه",
                  isActive: !!user?.mobileVerification,
                },
              ]}
              isCompleteStage={
                !!user?.IDverification && !!user?.mobileVerification
              }
              isCurrentStage={!!user?.mobileVerification}
            />
            {/* BASE */}
            <AuthenticationCard
              title="مشخصات کاربری"
              access={[
                { id: "DEPOSIT_CURRENCY", title: "واریز ارز" },
                { id: "DEPOSIT_RIAL", title: "واریز ریال" },
                { id: "WITHDRAW_RIAL", title: "برداشت ریال" },
              ]}
              steps={[
                {
                  id: "VERIFY_PHONE",
                  title: "تایید شماره همراه",
                  isActive: !!user?.mobileVerification,
                },
                {
                  id: "PERSONAL_INFORMATION",
                  title: "مشخصات کاربری",
                  isActive: !!user?.IDverification,
                },
              ]}
              isCompleteStage={
                !!user?.IDverification &&
                !!user?.mobileVerification &&
                !!user?.personalAttachmentsVerification
              }
              isCurrentStage={
                !!user?.IDverification && !!user?.mobileVerification
              }
              linkToAuthorize="/v2/dashboard/authentication/base"
            />
            {/* SPECIAL */}
            <AuthenticationCard
              title="مدرک شناسایی"
              access={[
                { id: "DEPOSIT_CURRENCY", title: "واریز ارز" },
                { id: "WITHDRAW_CURRENCY", title: "برداشت ارز" },
                { id: "DEPOSIT_RIAL", title: "واریز ریال" },
                { id: "WITHDRAW_RIAL", title: "برداشت ریال" },
              ]}
              steps={[
                {
                  id: "VERIFY_PHONE",
                  title: "تایید شماره همراه به نام کاربر",
                  isActive: !!user?.mobileVerification,
                },
                {
                  id: "VERIFY_IDENTITY",
                  title: "احراز هویت",
                  isActive: !!user?.IDverification,
                },
                {
                  id: "VERIFY_DOCUMENTS",
                  title: "مدرک شناسایی",
                  isActive: !!user?.personalAttachmentsVerification,
                  isPending: !!user?.personalAttachmentsPending,
                },
              ]}
              disabled={
                (!user?.IDverification && !!user?.mobileVerification) ||
                !!user?.personalAttachmentsPending
              }
              isCurrentStage={
                !!user?.IDverification &&
                !!user?.mobileVerification &&
                !!user?.personalAttachmentsVerification
              }
              loading={!!user?.personalAttachmentsPending}
              linkToAuthorize="/v2/dashboard/authentication/pro"
            />
          </Stack>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
