import { Autocomplete, Box, CircularProgress, Stack } from "@mui/material";
import { DocumentAuthFormSchema } from "../../_validation";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@components/common/typography";
import { Button } from "@components/common/button";
import { ChangeEventHandler, useEffect, useState } from "react";
import { useVerifyBaseAuth } from "@services/queries/authentication/mutations";
import { TextField } from "@components/common/textfield";
import provinces from "@data/provinces.json";
import { toast } from "@utils/toast";
import { useUploadFile } from "@services/queries/upload/mutations";
import { useUserStore } from "@store/user";
import { DeleteOutline } from "@mui/icons-material";
import imageCompression from "browser-image-compression";

interface DocumentAuthorizationFormProps {
  onSuccess: () => void;
}

export const DocumentAuthorizationForm = ({
  onSuccess,
}: DocumentAuthorizationFormProps) => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors, isValid },
  } = useForm<z.output<typeof DocumentAuthFormSchema>>({
    mode: "onTouched",
    resolver: zodResolver(DocumentAuthFormSchema),
  });

  const [isImageLoaded, setImageLoaded] = useState(false);

  const user = useUserStore((state) => state.user);

  const verifyBaseAuthMutation = useVerifyBaseAuth();
  const uploadFileMutation = useUploadFile();

  useEffect(() => {
    if (window?.isWebView) {
      window?.ReactNativeWebView?.postMessage(
        JSON.stringify({ action: "requestFileManager" })
      );
    }
  }, []);

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = async (e) => {
    if (e?.target?.files?.length === 0) {
      return toast.error("فایلی انتخاب نشده است.");
    }

    const file = (e?.target?.files as FileList)[0];

    if (
      !(
        file.type == "image/jpeg" ||
        file.type == "image/jpg" ||
        file.type == "image/png"
      )
    ) {
      return toast.error("فرمت فایل باید jpg یا png یا jpeg باشد.");
    }

    if (file.size >= 20 * 1024 * 1024) {
      return toast.error("حجم فایل بیشتر از 20 مگابایت است.");
    }

    const formData = new FormData();

    const options = {
      maxSizeMB: 1.5,
      maxWidthOrHeight: 2000,
      useWebWorker: true,
    };

    const compressedFile = await imageCompression(file, options);
    const fileToUpload = new File([compressedFile], "compressedFile.jpg", {
      type: compressedFile.type,
    });

    formData.append("file", fileToUpload);

    uploadFileMutation.mutate(formData);
  };

  useEffect(() => {
    if (uploadFileMutation?.isSuccess) {
      setValue("document", uploadFileMutation?.data?.result?.fullUrl, {
        shouldValidate: true,
      });
    }
  }, [uploadFileMutation?.isPending]);

  const onSubmit = (data: z.output<typeof DocumentAuthFormSchema>) => {
    if (!user) return toast.error("کاربر یافت نشد.، لطفا دوباره وارد شوید.");

    verifyBaseAuthMutation.mutate({
      birthday: user?.birthday || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      nationalCode: user?.nationalCode || "",
      address: `${data?.city} - ${data?.address}`,
      nationalCardAddress: data?.document,
    });
  };

  useEffect(() => {
    if (verifyBaseAuthMutation?.isSuccess) {
      onSuccess();
    }
  }, [verifyBaseAuthMutation.isPending]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Stack
        width="90%"
        maxWidth="700px"
        mx="auto"
        py="2rem"
        borderRadius="1.125rem"
        gap="1rem"
        bgcolor="white"
        sx={{
          px: { xs: "1rem", md: "1.5rem" },
        }}
      >
        <Typography variant="body1" pb="1rem" color="primary.800">
          لطفا اطلاعات زیر را مطابق با کارت شناسایی وارد کنید
        </Typography>

        <Autocomplete
          disablePortal
          options={provinces.map((e) => ({ label: e.name, value: e.name }))}
          fullWidth
          noOptionsText="موردی یافت نشد."
          onChange={(e, data) =>
            setValue("city", data?.value || "", { shouldValidate: true })
          }
          renderInput={(params) => (
            <TextField
              label="استان"
              placeholder="استان خود را انتخاب کنید."
              {...params}
              error={!!errors?.city?.message}
              helperText={errors?.city?.message}
            />
          )}
        />

        <TextField
          label="آدرس"
          {...register("address")}
          placeholder="آدرس خود را وارد کنید"
          error={!!errors?.address?.message}
          helperText={errors?.address?.message}
          multiline
          rows={3}
          sx={{ height: "auto", py: "0.75rem" }}
        />

        <Typography py="0.5rem" variant="body2" pb="1rem" color="primary.800">
          لطفا برای احراز هویت فردی خود (کارت ملی هوشمند یا شناسنامه) خود را در
          قسمت پایین آپلود کنید.
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: `2px dashed #ccc`,
            borderRadius: "4px",
            cursor: "pointer",
            position: "relative",
            p: "0.35rem",
          }}
        >
          {!uploadFileMutation?.isPending &&
            (!watch("document") ? (
              <>
                <Box
                  component="input"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="contained-button-file"
                  type="file"
                  onChange={handleFileUpload}
                />
                <Box
                  component="label"
                  sx={{
                    width: "100%",
                    height: "6rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  htmlFor="contained-button-file"
                >
                  <Typography variant="body2" color="primary.700">
                    برای آپلود فایل خود اینجا کلیک کنید.
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                {!isImageLoaded && (
                  <CircularProgress sx={{ my: "2rem" }} size={24} />
                )}
                <Box
                  component="img"
                  src={watch("document")}
                  onLoad={() => setImageLoaded(true)}
                  sx={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "0.25rem",
                    // aspectRatio: "1/1",
                  }}
                />
              </>
            ))}

          {uploadFileMutation?.isPending && (
            <CircularProgress sx={{ my: "2rem" }} size={24} />
          )}

          {watch("document") && (
            <Stack
              position="absolute"
              bottom="1rem"
              right="1rem"
              bgcolor="error.500"
              borderRadius="50%"
              p="0.3rem"
              onClick={() => {
                setValue("document", "", { shouldValidate: true });
              }}
            >
              <DeleteOutline sx={{ color: "neutral.50" }} />
            </Stack>
          )}
        </Box>

        <Button
          id="send-verification-documents"
          type="submit"
          disabled={!isValid}
          loading={verifyBaseAuthMutation?.isPending}
          sx={{ mt: "2rem" }}
          size="large"
        >
          <Typography variant="body1" color="neutral.50">
            تایید و ادامه
          </Typography>
        </Button>
      </Stack>
    </Box>
  );
};
