import { Button } from "@components/common/button";
import Container from "@components/common/container";
import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Collapse, Stack, useTheme } from "@mui/material";
import { useGetWalletItemInfo } from "@services/queries/wallet/queries";
import { WalletItemInfoRes } from "@services/queries/wallet/types";
import { convertToPersianDate, getCoinIconURL } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { ArrangeVertical, ArrowDown2, Receive, Transmit } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BaseCoin } from "~types/index";

export default function SingleWalletPage() {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!state) {
      navigate("/v2/dashboard/wallet");
    }
  }, [state]);

  const getWalletItemInfoQuery = useGetWalletItemInfo(state?.item?.currency);

  const priceEstimate = useMemo(() => {
    if (state?.baseOn === BaseCoin.TOMAN) {
      const priceOfOne =
        state?.item?.balanceTotalEstimate / state?.item?.balanceTotal;
      return priceOfOne;
    } else {
      const priceOfOne =
        state?.item?.balanceTotalEstimateUSDT / state?.item?.balanceTotal;
      return priceOfOne;
    }
  }, [state?.baseOn]);

  const balanceList = useMemo(
    () => [
      {
        title: "دارایی در دسترس",
        balance: state?.item?.balanceAvailableFormatted,
        estimateBalance:
          state?.item?.balanceEstimate?.[
            state?.baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
          ]?.availableString,
      },
      {
        title: "دارایی در معاملات",
        balance: state?.item?.balanceHoldFormatted,
        estimateBalance:
          state?.item?.balanceEstimate?.[
            state?.baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
          ]?.holdString,
      },
      {
        title: "دارایی کل",
        balance: state?.item?.balanceTotalFormatted,
        estimateBalance:
          state?.item?.balanceEstimate?.[
            state?.baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
          ]?.totalString,
      },
    ],
    [state?.item]
  );

  if (!state?.item) return <></>;

  return (
    <Wrapper
      loading={getWalletItemInfoQuery?.isLoading}
      title={
        <Stack direction="row" gap="0.15rem">
          <Typography variant="ExtendedBodyMedium" color="colorfulGray.800">
            {state?.item?.prettyNameLocalized}
          </Typography>
          <Typography variant="ExtendedBody" color="colorfulGray.400">
            ({state?.item?.currency})
          </Typography>
        </Stack>
      }
      sxContainer={{
        flex: 1,
        my: { md: "2rem" },
        py: "1.5rem",
      }}
      hideBottomNavigation
      backMode
    >
      <Stack bgcolor="white" px="1rem" py="2rem" borderRadius="1.125rem">
        {/* Header Start */}
        <Stack alignItems="center" gap="2rem">
          <Stack alignItems="center" gap="0.75rem">
            <Stack
              onClick={() =>
                navigate(`/v2/dashboard/markets/${state?.item?.currency}IRT`)
              }
            >
              <Image
                alt="Coin-Image"
                height="3rem"
                width="3rem"
                sx={{
                  width: { xs: "2.5rem", md: "3rem" },
                  height: { xs: "2.5rem", md: "3rem" },
                }}
                src={getCoinIconURL(state?.item?.currency)}
              />
            </Stack>
            <Stack direction="row" gap="0.15rem">
              <Typography variant="ExtendedBodyMedium" color="colorfulGray.800">
                {state?.item?.prettyNameLocalized}
              </Typography>
              <Typography variant="ExtendedBody" color="colorfulGray.400">
                ({state?.item?.currency})
              </Typography>
            </Stack>
          </Stack>

          <Stack
            zIndex={2}
            gap="0.75rem"
            direction="row"
            sx={{
              bgcolor: "white",
              position: { xs: "fixed", md: "static" },
              bottom: 0,
              left: 0,
              width: "100%",
              maxWidth: { md: "28rem" },
              p: { xs: "1rem", md: 0 },
            }}
          >
            <Button
              fullWidth
              linkStyle={{ width: "100%" }}
              href={`/v2/dashboard/wallet/coin/${state?.item?.currency}/deposit`}
              bgColor="primary.A100"
              textColor="primary.700"
            >
              <Typography
                whiteSpace="nowrap"
                color="primary.700"
                variant="BodyBold"
                smVariant="BodyMedium"
              >
                واریز
              </Typography>
            </Button>
            <Button
              fullWidth
              linkStyle={{ width: "100%" }}
              href={`/v2/dashboard/wallet/coin/${state?.item?.currency}/withdraw`}
              bgColor="primary.A100"
              textColor="primary.700"
            >
              <Typography
                whiteSpace="nowrap"
                color="primary.700"
                variant="BodyBold"
                smVariant="BodyMedium"
              >
                برداشت
              </Typography>
            </Button>
          </Stack>

          <Stack width="100%" gap="1.5rem">
            {balanceList?.map((item) => (
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  variant="ExtendedBodyMedium"
                  smVariant="BodyMedium"
                  color="colorfulGray.800 "
                >
                  {item?.title}
                </Typography>
                <Stack alignItems="flex-end">
                  <Typography
                    variant="ExtendedBodyBold"
                    smVariant="BodyBold"
                    color="colorfulGray.800"
                  >
                    {numUtils.commaformatter.toCurrency(item?.balance)}
                    <Typography
                      component="span"
                      pr="0.25rem"
                      variant="Caption"
                      color="colorfulGray.300"
                    >
                      {state?.item?.currency}
                    </Typography>
                  </Typography>
                  {Number(state?.item?.balanceTotal) !== 0 && (
                    <Typography
                      variant="Body"
                      smVariant="Caption"
                      color="colorfulGray.700"
                    >
                      {numUtils.commaformatter.toCurrency(
                        item?.estimateBalance
                      )}
                      <Typography
                        component="span"
                        pr="0.25rem"
                        variant="Caption"
                        color="colorfulGray.300"
                      >
                        {state?.baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"}
                      </Typography>
                    </Typography>
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Stack>
        {/* Header End */}

        {/* History Start */}
        {!!getWalletItemInfoQuery?.data?.result?.length && (
          <Stack
            sx={{ borderTop: 2, borderColor: "primary.50" }}
            mt="1.5rem"
            py="1.5rem"
            gap="1rem"
          >
            <Typography variant="TitleBold" smVariant="BodyBold">
              تاریخچه
            </Typography>

            {getWalletItemInfoQuery?.data?.result?.map((item) => (
              <HistoryItem baseOn={state?.baseOn} data={item} />
            ))}
          </Stack>
        )}
        {/* History End */}
      </Stack>
    </Wrapper>
  );
}

interface HistoryItemProps {
  baseOn: BaseCoin;
  data: WalletItemInfoRes;
}

const HistoryItem = ({ baseOn, data }: HistoryItemProps) => {
  const isMdUp = useBreakpoint("md");
  const theme = useTheme();

  const [isCollapse, setIsCollapse] = useState(false);

  const renderIcon = (type: "buy" | "sell" | "withdrawal" | "deposit") => {
    // prettier-ignore
    switch (type) {
      case "sell":
        return {icon: <Transmit size="1rem" color={theme.palette.error[500]} />,title: "فروش"};
      case "buy":
        return {icon: <Receive size="1rem" color={theme.palette.info[600]} />,title: "خرید"};
      case "deposit":
        return {icon: <ArrangeVertical size="1rem" color={theme.palette.primary[500]} />,title: "دریافت"};
      case "withdrawal":
        return {icon: <ArrangeVertical size="1rem" color={theme.palette.secondary[600]} />,title: "ارسال"};;
      default:
        return {icon: <></>, title: ""};
    }
  };

  return (
    <Stack
      width="100%"
      pt="1rem"
      pb="1.5rem"
      sx={{
        borderBottom: 1,
        borderColor: "primary.50",
        "&:last-child": { border: "none" },
      }}
    >
      <Stack
        width="100%"
        direction="row"
        justifyContent="space-between"
        gap="0.5rem"
        alignItems="center"
      >
        <Stack
          onClick={() => setIsCollapse(!isCollapse)}
          width="100%"
          gap="0.5rem"
        >
          <Stack
            width="100%"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack>
              <Stack direction="row" gap="0.25rem" alignItems="center">
                {renderIcon(data?.type)?.icon}
                <Typography variant="ExtendedBody" color="neutral.900">
                  {renderIcon(data?.type)?.title}
                </Typography>
              </Stack>
              <Typography variant="Body" color="colorfulGray.500">
                {convertToPersianDate(data?.createdAt)}
              </Typography>
            </Stack>

            <Stack alignItems="flex-end">
              <Typography
                variant="ExtendedBodyBold"
                smVariant="BodyBold"
                color="colorfulGray.800"
              >
                {numUtils.commaformatter.toCurrency(data?.amount)}
              </Typography>
              {data?.funds && (
                <Typography
                  variant="Body"
                  smVariant="Caption"
                  color="colorfulGray.700"
                >
                  {numUtils.commaformatter.toCurrency(
                    data?.funds?.split(" ")[0]
                  )}
                  <Typography
                    component="span"
                    pr="0.25rem"
                    variant="Caption"
                    color="colorfulGray.300"
                  >
                    {data?.funds?.split(" ")[1]}
                  </Typography>
                </Typography>
              )}
            </Stack>
          </Stack>
          {data?.price && (
            <Collapse in={isMdUp || isCollapse}>
              <Stack
                direction="row"
                justifyContent="space-between"
                bgcolor="neutral.50"
                borderRadius="0.625rem"
                py="0.5rem"
                px="0.75rem"
                alignItems="center"
              >
                <Typography variant="Body" color="colorfulGray.800">
                  قیمت هر واحد
                </Typography>
                <Stack alignItems="flex-end">
                  <Typography
                    variant="Body"
                    smVariant="Caption"
                    color="colorfulGray.700"
                  >
                    {numUtils.commaformatter.toCurrency(
                      data?.price?.split(" ")[0]
                    )}
                    <Typography
                      component="span"
                      pr="0.25rem"
                      variant="Caption"
                      color="colorfulGray.300"
                    >
                      {data?.price?.split(" ")[1]}
                    </Typography>
                  </Typography>
                </Stack>
              </Stack>
            </Collapse>
          )}
        </Stack>

        {!isMdUp && (
          <ArrowDown2
            style={{
              transition: "0.3s",
              transform: isCollapse ? "rotate(180deg)" : "rotate(0)",
            }}
            size="1.25rem"
            color={theme.palette.colorfulGray[500]}
          />
        )}
      </Stack>
    </Stack>
  );
};
