import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IOrderPreviewArgs } from "./types";
import { closeOrder, getLimitOrderPreview, sendLimitOrder } from "./apis";
import { updateFavorite } from "./apis";

export function useSendLimitOrder() {
  return useMutation({
    mutationFn: (data: IOrderPreviewArgs) => sendLimitOrder(data),
  });
}

export function useCancelOrderMutation(marketId: string) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (orderId: string) => closeOrder(orderId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["my_order", marketId] });
    },
  });
}

export function useHandleFavorite() {
  // const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (id: string) => updateFavorite(id),
    onSuccess: () => {
      //   queryClient.invalidateQueries({ queryKey: ["market_list"] });
    },
  });
}

export function useSetLimitOrderPreview() {
  return useMutation({
    mutationFn: (data: IOrderPreviewArgs) => getLimitOrderPreview(data),
  });
}
