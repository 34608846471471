import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { ProfileDropdownNavigation } from "@components/shared/profile-dropdown-navigation";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Grid, Stack, SvgIcon } from "@mui/material";
import { CommissionSection } from "./_components/commission-section";
import { LimitedWithdrawalSection } from "./_components/limited-withdrawal-section";
import { Button } from "@components/common/button";
import { useMemo, useState } from "react";
import { LogoutModal } from "@components/shared/logout-modal";
import { Verify } from "iconsax-react";
import { useUserStore } from "@store/user";

export function ProfilePage() {
  const isMdUp = useBreakpoint("md");
  const user = useUserStore((state) => state.user);
  const [isLogoutModal, setIsLogoutModal] = useState(false);

  const authenticationLevel: "BASE" | "NOT_VERIFIED" | "PRO" = useMemo(() => {
    if (
      !!user?.IDverification &&
      !!user?.mobileVerification &&
      !!user?.personalAttachmentsVerification
    )
      return "PRO";

    if (!!user?.IDverification && !!user?.mobileVerification) return "BASE";

    return "NOT_VERIFIED";
  }, [user]);

  return (
    <Wrapper
      backMode
      sxContainer={{ py: "2rem" }}
      hideSideNavigation
      title="پروفایل"
    >
      <Grid columns={17} container columnSpacing="1.5rem">
        <Grid sx={{ display: { xs: "none", md: "block" } }} item xs={5}>
          <Stack bgcolor="white" borderRadius="1.125rem">
            <ProfileDropdownNavigation isExitButton listOfDisplayId={[2, 3]} />
          </Stack>
        </Grid>
        <Grid item xs={17} md={12}>
          <Stack gap="1.5rem">
            {!isMdUp && authenticationLevel !== "PRO" && (
              <Stack
                bgcolor="white"
                width="100%"
                px="0.75rem"
                py="1.5rem"
                borderRadius="1.125rem"
                gap="1.5rem"
              >
                <Stack direction="row" alignItems="center" gap="0.25rem">
                  <Typography>
                    {!!(user?.firstName && user?.lastName)
                      ? `${user?.firstName} ${user?.lastName}`
                      : "کاربر مهمان"}
                  </Typography>
                  <Stack
                    component="span"
                    px="0.5rem"
                    py="0.25rem"
                    bgcolor="error.A100"
                    borderRadius="6.25rem"
                  >
                    <Typography variant="CaptionMedium" color="error.800">
                      {authenticationLevel === "NOT_VERIFIED" && "احراز نشده"}
                      {authenticationLevel === "BASE" && "احراز پایه"}
                    </Typography>
                  </Stack>
                </Stack>

                <Button
                  fullWidth
                  href="/v2/dashboard/authentication"
                  bgColor="primary.A100"
                  textColor="primary.700"
                >
                  <Typography variant="BodyMedium">احراز هویت</Typography>
                </Button>
              </Stack>
            )}

            <CommissionSection />

            <LimitedWithdrawalSection />

            {!isMdUp && (
              <>
                <Button
                  onClick={() => setIsLogoutModal(true)}
                  bgColor="white"
                  textColor="error.500"
                  sx={{ border: 1.5, borderColor: "error.500" }}
                >
                  <Typography variant="BodyBold">
                    خروج از حساب کاربری
                  </Typography>
                </Button>

                <LogoutModal
                  onClose={() => setIsLogoutModal(false)}
                  open={isLogoutModal}
                />
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
    </Wrapper>
  );
}
