import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { CopyIcon } from "@components/shared/copy-icon";
import { Stack, useTheme } from "@mui/material";
import { OTCItemRes } from "@services/queries/financial/types";
import {
  getCoinIconURL,
  getPersianEquivalent,
  toClipboard,
} from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import {
  format,
  formatDistanceToNow,
  isBefore,
  subDays,
} from "date-fns-jalali";
import { faIR } from "date-fns-jalali/locale";
import { useMemo, useState } from "react";
import { OrdersStatus } from "~types/index";

interface OtcCardProps {
  data: OTCItemRes;
}

const formatDate = (dateString: string): string => {
  if (!dateString) return "نامشخص";

  const date = new Date(dateString);
  const oneDayAgo = subDays(new Date(), 1);

  if (isBefore(date, oneDayAgo)) {
    return format(date, "yyyy/MM/dd - HH:mm", { locale: faIR });
  } else {
    return `${formatDistanceToNow(date, { addSuffix: true, locale: faIR })}`;
  }
};

export const OtcCard = ({ data }: OtcCardProps) => {
  const [isTrackingCodeCopied, setIsTrackingCodeCopied] = useState(false);

  const theme = useTheme();

  const side = useMemo(() => {
    if (data) {
      if (data?.to === "IRT") return "sell";
      if (data?.to === "USDT" && data?.from !== "IRT") return "sell";

      return "buy";
    }
    return "نامشخص";
  }, [data]);

  return (
    <>
      <Stack
        component="li"
        px="1rem"
        py="1.5rem"
        borderBottom={1}
        borderColor="primary.50"
        sx={{ "&:last-child": { borderBottom: 0 } }}
      >
        <Stack direction="row" gap="0.5rem">
          <Stack flex={1} gap="0.6rem">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack direction="row" gap="0.5rem">
                <Typography
                  py="0.25rem"
                  px="0.5rem"
                  bgcolor={side === "buy" ? "info.A100" : "error.A100"}
                  color={side === "buy" ? "info.700" : "error.500"}
                  variant="CaptionBold"
                  smVariant="SmallCaptionBold"
                  borderRadius="0.25rem"
                >
                  {side === "buy" ? "خرید" : "فروش"}
                </Typography>
                <Image
                  height="1.5rem"
                  width="1.5rem"
                  alt={side === "buy" ? data?.to :data?.from}
                  src={getCoinIconURL(side === "buy" ? data?.to :data?.from)}
                />
                <Typography
                  smVariant="BodyMedium"
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.800"
                >
                  <Typography
                    component="span"
                    px="0.25rem"
                    color="colorfulGray.500"
                  >
                    {data?.to} /
                  </Typography>
                  {data?.from}
                </Typography>
              </Stack>
              <Typography
                variant="CaptionMedium"
                smVariant="SmallCaptionMedium"
                color="colorfulGray.400"
              >
                {formatDate(data?.createdAt)}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                مقدار پرداختی
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.800"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.paiedAmount))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.5rem"
                  color="colorfulGray.500"
                >
                  {data?.from}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                مقدار دریافتی
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.800"
              >
                {numUtils.commaformatter.toCurrency(
                  Number(data?.receivedAmount)
                )}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.5rem"
                  color="colorfulGray.500"
                >
                  {data?.to}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                قیمت
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.800"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.price))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.5rem"
                  color="colorfulGray.500"
                >
                  {data?.from}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                کارمزد
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.800"
              >
                {numUtils.commaformatter.toCurrency(Number(data?.fee))}
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.5rem"
                  color="colorfulGray.500"
                >
                  {data?.to}
                </Typography>
              </Typography>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                کد رهگیری
              </Typography>
              <Stack
                direction="row"
                gap="0.5rem"
                justifyContent="flex-end"
                borderRadius="0.625rem"
              >
                <Typography
                  textAlign="right"
                  smVariant="BodyBold"
                  variant="ExtendedBodyBold"
                  color="info.600"
                  sx={{
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    maxWidth: { xs: "200px", md: "350px" },
                  }}
                  overflow="auto"
                  onClick={async () =>
                    await toClipboard({
                      text: data?.trackingCode || "",
                      cb: (isCopied) => setIsTrackingCodeCopied(isCopied),
                    })
                  }
                >
                  {data?.trackingCode}
                </Typography>
                <CopyIcon
                  isCopied={isTrackingCodeCopied}
                  iconColor={theme?.palette.info[600]}
                  successIconColor={theme?.palette.info[600]}
                  onChange={(isCopied) => setIsTrackingCodeCopied(isCopied)}
                  text={data?.trackingCode || ""}
                />
              </Stack>
            </Stack>

            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                وضعیت
              </Typography>
              <Typography
                py="0.25rem"
                px="0.5rem"
                bgcolor="neutral.50"
                color={
                  data?.status === "CREATED"
                    ? "info.700"
                    : data?.status === "SUCCESS"
                    ? "success.700"
                    : "error.600"
                }
                variant="CaptionBold"
                smVariant="SmallCaptionBold"
                borderRadius="0.25rem"
              >
                {getPersianEquivalent(data?.status as OrdersStatus)}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
