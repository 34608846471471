import { z } from "zod";

export const SendOTPSchema = z.object({
  mobile: z
    .string()
    .min(11, "شماره تلفن وارد شده معتبر نمی باشد")
    .max(11, "شماره تلفن وارد شده معتبر نمی باشد")
    .regex(/^(09)/, "شماره تلفن وارد شده معتبر نمی باشد"),
});

export const AuthFormSchema = z.object({
  code: z
    .string()
    .min(6, "کد وارد شده باید ۶ رقمی باشد.")
    .max(6, "کد وارد شده باید ۶ رقمی باشد."),
  referer: z.string().optional(),
});

export const TwoFAAuthSchema = z.object({
  code: z
    .string()
    .min(6, "کد وارد شده باید ۶ رقمی باشد.")
    .max(6, "کد وارد شده باید ۶ رقمی باشد."),
});
