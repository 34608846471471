export const TOKEN_COOKIE_NAME = "Token";
export const REFRESH_TOKEN_COOKIE_NAME = "RefreshToken";
export const WORKSPACE_ID_COOKIE_NAME = "WpId";
export const THEME_STORAGE_NAME = "THEME_MODE";

export const DEFAULT_PER_PAGE = 20;

export const persianRegex = /^[\u0600-\u06FF\s]+$/;

export const IGNORE_TOAST_PATHS = [
  "browse",
  "my",
  "report",
  "/api/report",
  "/api/user/whoAmI",
  "/public/auth/refresh",
  "/api/workSpace/my",
  // "/public/auth/otp/login",
  "/api/io/",
  "/api/OtcRequestV2/pair",
  "/api/OtcRequestV2/pair/details",
  "/api/ExCore/place/order/preview",
  "/api/ExCore/myorders",
  "/api/ExCore/orderbook",
  "/api/ExCore/trades",
  "/api/ExCore/account",
  "/api/ExCore/create/wallet",
  "/api/OtcRequestV2/invoice",
  "/api/ExCoreData/markets/landing",
  "/api/ExCoreData/faveCurrency",
  "/api/ExCoreData/topSlider",
  "/api/ExCore/browse/markets",
  "/api/ExCore/account/balances",
  "/api/ExCore/estimateAssets",
  "/api/ExCore/estimateAssets",
  "/api/Deposit/getSumDaily",
  "/api/DepositAuto",
  "/api/WithdrawalLevel/getDailyAndMonthly",
  "/api/WithdrawalRequest/preview",
  "/api/WithdrawAuto/invoice",
  "/api/OtcRequestV2/info",
  "api/WithdrawAuto/v2/allTransactions",
  "api/Asset/getCard",
  "/api/auth/active/twofactor",
  "/api/auth/request/twofactor/activation",
  "/api/auth/deactive/twofactor",
  "/api/ExCore/estimateMywallets",
  "/api/User/accounts/",
  "api/auth/secretcode",
  "v2/all/exchanges",
  "tradingview",
  "Asset/estimate",
  "/uploads",
];

// prettier-ignore
export const banks = [
  { name: "بانک آینده", code: "636214", logo: "ayandeh.svg", bank_code: "062" },
  { name: "بانک اقتصاد نوین", code: "627412", logo: "eghtesad-novin.svg", bank_code: "055" },
  { name: "بانک انصار", code: "627381", logo: "ansar.svg", bank_code: "063" },
  { name: "بانک ایران زمین", code: "505785", logo: "iran-zamin.svg", bank_code: "069" },
  { name: "بانک پارسیان", code: "622106", logo: "parsian.svg", bank_code: "054" },
  { name: "بانک پارسیان", code: "627884", logo: "parsian.svg", bank_code: "054" },
  { name: "بانک پاسارگاد", code: "502229", logo: "pasargad.svg", bank_code: "057" },
  { name: "بانک پاسارگاد", code: "639347", logo: "pasargad.svg", bank_code: "057" },
  { name: "پست بانک ایران", code: "627760", logo: "postbank.svg", bank_code: "021" },
  { name: "بانک تجارت", code: "585983", logo: "tejarat.svg", bank_code: "018" },
  { name: "بانک تجارت", code: "627353", logo: "tejarat.svg", bank_code: "018" },
  { name: "بانک توسعه تعاون", code: "502908", logo: "toseh-taavon.svg", bank_code: "022" },
  { name: "بانک توسعه صادرات", code: "207177", logo: "toseh-saderat.svg", bank_code: "020" },
  { name: "بانک توسعه صادرات", code: "627648", logo: "toseh-saderat.svg", bank_code: "020" },
  { name: "بانک حکمت ایرانیان", code: "636949", logo: "hekmat.svg", bank_code: "065" },
  { name: "بانک خاورمیانه", code: "585949", logo: "khavar-mianeh.svg", bank_code: "078" },
  { name: "بانک دی", code: "502938", logo: "day.svg", bank_code: "066" },
  { name: "بانک رسالت", code: "504172", logo: "resalat.svg", bank_code: "070" },
  { name: "بانک رفاه کارگران", code: "589463", logo: "refah.svg", bank_code: "013" },
  { name: "بانک سامان", code: "621986", logo: "saman.svg", bank_code: "056" },
  { name: "بانک سپه", code: "589210", logo: "sepah.svg", bank_code: "015" },
  { name: "بانک سرمایه", code: "639607", logo: "sarmaye.svg", bank_code: "058" },
  { name: "بانک سینا", code: "639346", logo: "sina.svg", bank_code: "059" },
  { name: "بانک شهر", code: "502806", logo: "shahr.svg", bank_code: "061" },
  { name: "بانک شهر", code: "504706", logo: "shahr.svg", bank_code: "061" },
  { name: "بانک صادرات ایران", code: "603769", logo: "saderat.svg", bank_code: "019" },
  { name: "بانک صادرات ایران", code: "903769", logo: "saderat.svg", bank_code: "019" },
  { name: "بانک صنعت و معدن", code: "627961", logo: "sanat-madan.svg", bank_code: "011" },
  { name: "بانک قرض الحسنه مهر", code: "639370", logo: "mehr-iran.svg", bank_code: "015" },
  { name: "بانک قوامین", code: "639599", logo: "ghavamin", bank_code: "052" },
  { name: "بانک کارآفرین", code: "627488", logo: "karafarin.svg", bank_code: "053" },
  { name: "بانک کشاورزی", code: "603770", logo: "keshavarzi.svg", bank_code: "016" },
  { name: "بانک کشاورزی", code: "639217", logo: "keshavarzi.svg", bank_code: "016" },
  { name: "بانک گردشگری", code: "505416", logo: "gardeshgari.svg", bank_code: "064" },
  { name: "بانک گردشگری", code: "505426", logo: "gardeshgari.svg", bank_code: "064" },
  { name: "بانک مرکزی ایران", code: "636797", logo: "markazi.svg", bank_code: "010" },
  { name: "بانک مسکن", code: "628023", logo: "maskan.svg", bank_code: "014" },
  { name: "بانک ملت", code: "610433", logo: "mellat.svg", bank_code: "012" },
  { name: "بانک ملت", code: "991975", logo: "mellat.svg", bank_code: "012" },
  { name: "بانک ملی ایران", code: "170019", logo: "melli.svg", bank_code: "017" },
  { name: "بانک ملی ایران", code: "603799", logo: "melli.svg", bank_code: "017" },
  { name: "بانک مهر ایران", code: "606373", logo: "mehr-iran.svg", bank_code: "060", },
  { name: "موسسه کوثر", code: "505801", logo: "kosar.svg", bank_code: "073" },
  { name: "موسسه اعتباری ملل", code: "606256", logo: "melall.svg", bank_code: "075" },
  { name: "موسسه اعتباری توسعه", code: "628157", logo: "toseh.svg", bank_code: "051" },
];

export const transferTunnelInfo = [
  { id: "TRC20", label: "TRON", blocks: 1, estimate_time: 5 },
  { id: "ERC20", label: "ETHEREUM", blocks: 12, estimate_time: 6 },
  { id: "BEP20", label: "BNBSMARTCHAIN", blocks: 15, estimate_time: 5 },
  { id: "TON", label: "TON", blocks: 50, estimate_time: 25 },
  { id: "DOGE", label: "DOGECOIN", blocks: 12, estimate_time: 15 },
];
