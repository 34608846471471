const toEnglish = (arg: string | number) => {
  if (typeof arg === "number") arg = arg.toString();
  return arg
    .replace(/[\u0660-\u0669]/g, (c: string) => {
      return (c.charCodeAt(0) - 0x0660).toString();
    })
    .replace(/[\u06f0-\u06f9]/g, (c: string) => {
      return (c.charCodeAt(0) - 0x06f0).toString();
    });
};

const toPersian = (arg: string | number) => {
  if (typeof arg === "number") arg = arg.toString();
  return arg.replace(/\d/g, (d) => "۰۱۲۳۴۵۶۷۸۹"[+d]);
};

const commaformatter = {
  toCurrency: (val: string | number = "") => {
    // return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    // eslint-disable-next-line prefer-const
    let [whole, fraction] = val.toString().split(".");
    whole = whole.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    return `${whole}${fraction !== undefined ? `.${fraction}` : ""}`
      ?.split(".")
      ?.join(".");
  },
  toRaw: (val: string | number) => {
    return val.toString()?.replaceAll(",", "");
  },
};

type FormatOptions =
  | "date"
  | "time"
  | "date-time"
  | "short-date-time"
  | "ISOtime-to-localtime";
type Locale = "en" | "fa-IR";

function convertToPersianDate(
  timestamp: number | string,
  options?: FormatOptions,
  locale: Locale = "fa-IR"
): string {
  const date = new Date(timestamp);
  let formatOptions: Intl.DateTimeFormatOptions = {};

  switch (options) {
    case "date":
      formatOptions = { year: "numeric", month: "long", day: "numeric" };
      break;
    case "time":
      formatOptions = {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };
      break;
    case "date-time":
      formatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: false,
      };
      break;
    case "short-date-time":
      formatOptions = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour12: false,
      };
      break;
    case "ISOtime-to-localtime":
      formatOptions = {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      };
  }

  return new Intl.DateTimeFormat(locale, formatOptions)?.format(date);
}

export const numUtils = {
  toEnglish,
  toPersian,
  commaformatter,
  convertToPersianDate,
};
