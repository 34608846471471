import { Typography } from "@components/common/typography";
import { Stack, SvgIcon } from "@mui/material";
import { MoneyRecive, MoneySend, Receipt1 } from "iconsax-react";
import { useNavigate } from "react-router-dom";

const WithdrawCard = () => {
  const navigate = useNavigate();
  return (
    <Stack
      borderRadius="1.125rem"
      bgcolor="white"
      boxShadow="0px 0px 12px 0px rgba(0, 0, 0, 0.16)"
      sx={{
        height: { sm: "117px", md: "141px" },
      }}
      p="1.5rem"
      direction="row"
      alignItems="center"
      justifyContent="center"
      width="100%"
      gap="2.75rem"
    >
      <Stack>
        <Stack
          onClick={() => navigate("/v2/dashboard/wallet/toman/deposit")}
          sx={{
            cursor: "pointer",
          }}
        >
          <Stack
            borderRadius="50%"
            sx={{
              backgroundColor: (theme) => theme.palette.colorfulGray["A100"],
              width: { xs: "2.5rem", md: "3.75rem" },
              height: { xs: "2.5rem", md: "3.75rem" },
            }}
            alignItems="center"
            justifyContent="center"
          >
            <SvgIcon
              sx={{
                color: (theme) => theme.palette.primary[500],
                width: { sm: "20px", md: "2rem" },
                height: { sm: "20px", md: "2rem" },
              }}
            >
              <MoneyRecive variant="Bold" />
            </SvgIcon>
          </Stack>
          <Typography
            variant="ExtendedBodyBold"
            sx={{
              color: (theme) => theme.palette.primary[500],
              marginTop: "0.5rem",
            }}
            textAlign="center"
            smVariant="BodyMedium"
          >
            واریز
          </Typography>
        </Stack>
      </Stack>
      <Stack
        onClick={() => navigate("/v2/dashboard/wallet/toman/withdraw")}
        sx={{
          cursor: "pointer",
        }}
      >
        <Stack>
          <Stack
            borderRadius="50%"
            sx={{
              backgroundColor: (theme) => theme.palette.colorfulGray["A100"],
              width: { xs: "2.5rem", md: "3.75rem" },
              height: { xs: "2.5rem", md: "3.75rem" },
            }}
            alignItems="center"
            justifyContent="center"
          >
            <SvgIcon
              sx={{
                color: (theme) => theme.palette.primary[500],
                width: { sm: "20px", md: "2rem" },
                height: { sm: "20px", md: "2rem" },
              }}
            >
              <MoneySend variant="Bold" />
            </SvgIcon>
          </Stack>
          <Typography
            variant="ExtendedBodyBold"
            sx={{
              color: (theme) => theme.palette.primary[500],
              marginTop: "0.5rem",
            }}
            textAlign="center"
            smVariant="BodyMedium"
          >
            برداشت
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        <Stack
          sx={{
            cursor: "pointer",
          }}
          onClick={() => navigate("/v2/dashboard/finance")}
        >
          <Stack
            borderRadius="50%"
            sx={{
              backgroundColor: (theme) => theme.palette.colorfulGray["A100"],
              width: { xs: "2.5rem", md: "3.75rem" },
              height: { xs: "2.5rem", md: "3.75rem" },
            }}
            alignItems="center"
            justifyContent="center"
          >
            <SvgIcon
              sx={{
                color: (theme) => theme.palette.primary[500],
                width: { sm: "20px", md: "2rem" },
                height: { sm: "20px", md: "2rem" },
              }}
            >
              <Receipt1 variant="Bold" />
            </SvgIcon>
          </Stack>
          <Typography
            variant="ExtendedBodyBold"
            sx={{
              color: (theme) => theme.palette.primary[500],
              marginTop: "0.5rem",
            }}
            textAlign="center"
            smVariant="BodyMedium"
          >
            سفارشات
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default WithdrawCard;
