import { useInfiniteQuery } from "@tanstack/react-query";
import { LoginHistoryArgs, LoginHistoryRes } from "./types";
import { getMyLoginHistory } from "./apis";
import { DEFAULT_PER_PAGE } from "@constants/index";

export const useGetMyLoginHistory = (data: LoginHistoryArgs) =>
  useInfiniteQuery<{ items: LoginHistoryRes[]; total: number }, void>({
    queryKey: ["login-history"],
    queryFn: ({ pageParam }) =>
      getMyLoginHistory({
        ...data,
        page: Number(pageParam ?? 0),
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.items?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
  });
