import Tabs from "@components/common/tabs";
import { TabItem } from "@components/common/tabs/types";
import { Grid, Paper } from "@mui/material";
import Spot from "../spot/mobile";
import OpenOrders from "./open-orders";
import OrderHistoryTab from "./order-history";
import TradeHistoryTab from "./trade-history";

const TabOrderBook = () => {
  const tabs: TabItem[] = [
    { label: "سفارشات باز", content: <OpenOrders dense /> },
    { label: "تاریخچه سفارشات", content: <OrderHistoryTab dense /> },
    { label: "تاریخچه معاملات", content: <TradeHistoryTab dense /> },
  ];
  return (
    <Grid item xs={12}>
      <Spot />
      <Paper
        elevation={0}
        // sx={{ mt: 2 }}
      >
        <Tabs tabs={tabs} variant="standard" />
      </Paper>
    </Grid>
  );
};

export default TabOrderBook;
