import { TomanIcon } from "@assets/icons/TomanIcon";
import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { CopyIcon } from "@components/shared/copy-icon";
import { Stack, SvgIcon, useTheme } from "@mui/material";
import { TransactionsItemRes } from "@services/queries/financial/types";
import {
  getCoinIconURL,
  getPersianEquivalent,
  toClipboard,
} from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import {
  format,
  formatDistanceToNow,
  isBefore,
  subDays,
} from "date-fns-jalali";
import { faIR } from "date-fns-jalali/locale";
import { Receive, Transmit } from "iconsax-react";
import { useState } from "react";
import { TransactionsStatus } from "~types/index";

interface TransactionsCardProps {
  data: TransactionsItemRes;
}

const formatDate = (dateString: string): string => {
  if (!dateString) return "نامشخص";

  const date = new Date(dateString);
  const oneDayAgo = subDays(new Date(), 1);

  if (isBefore(date, oneDayAgo)) {
    return format(date, "yyyy/MM/dd - HH:mm", { locale: faIR });
  } else {
    return `${formatDistanceToNow(date, { addSuffix: true, locale: faIR })}`;
  }
};

export const TransactionsCard = ({ data }: TransactionsCardProps) => {
  const [isTxIdCopied, setIsTxIdCopied] = useState(false);
  const theme = useTheme();

  return (
    <Stack
      component="li"
      py="1.5rem"
      borderBottom={1}
      borderColor="primary.50"
      sx={{ "&:last-child": { borderBottom: 0 } }}
    >
      <Stack direction="row" gap="1rem">
        <Stack justifyContent="center">
          <Stack
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            sx={{
              width: "2.25rem",
              height: "2.25rem",
              bgcolor: "colorfulGray.A100",
            }}
          >
            <SvgIcon
              sx={{
                fontSize: "1.25rem",
                color: (theme) =>
                  data?.actionType === "withdraw"
                    ? theme.palette.error[500]
                    : theme.palette.success[700],
              }}
            >
              {data?.actionType === "withdraw" ? <Transmit /> : <Receive />}
            </SvgIcon>
          </Stack>
        </Stack>
        <Stack flex={1} gap="0.6rem">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Stack direction="row" gap="0.5rem">
              <Image
                height="1.5rem"
                width="1.5rem"
                alt={data?.currency}
                src={getCoinIconURL(data?.currency)}
              />
              <Typography
                smVariant="BodyMedium"
                variant="ExtendedBodyMedium"
                color="colorfulGray.800"
              >
                {data?.currency}
              </Typography>
            </Stack>
            <Typography
              variant="CaptionMedium"
              smVariant="SmallCaptionMedium"
              color="colorfulGray.400"
            >
              {formatDate(data?.createdAt)}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              smVariant="Caption"
              variant="Body"
              color="colorfulGray.600"
            >
              {data?.currency === "IRT" ? "مبلغ" : "تعداد"}
            </Typography>
            <Typography
              smVariant="BodyBold"
              variant="ExtendedBodyBold"
              color="colorfulGray.800"
            >
              {numUtils.commaformatter.toCurrency(data?.amount)}
              {data?.currency === "IRT" ? (
                <TomanIcon
                  sx={{
                    mx: "0.25rem",
                    mb: "-3px",
                    fill: (theme) => theme.palette.colorfulGray[500],
                    fontSize: "1rem",
                  }}
                />
              ) : (
                <Typography
                  component="span"
                  variant="Caption"
                  smVariant="SmallCaption"
                  px="0.5rem"
                  color="colorfulGray.500"
                >
                  {data?.currency}
                </Typography>
              )}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              smVariant="Caption"
              variant="Body"
              color="colorfulGray.600"
            >
              {data?.currency === "IRT" ? "روش پرداخت" : "شبکه"}
            </Typography>
            <Typography
              smVariant="BodyBold"
              variant="ExtendedBodyBold"
              color="colorfulGray.800"
            >
              {data?.currency === "IRT"
                ? data?.requestType === "IBAN"
                  ? "پایا"
                  : "اتوماتیک"
                : data?.requestType}
            </Typography>
          </Stack>

          {data?.currency !== "IRT" && !!data?.txId?.length && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                TX ID
              </Typography>
              <Stack
                direction="row"
                gap="0.5rem"
                justifyContent="flex-end"
                borderRadius="0.625rem"
              >
                <Typography
                  textAlign="right"
                  smVariant="BodyBold"
                  variant="ExtendedBodyBold"
                  color="info.600"
                  sx={{
                    whiteSpace: "nowrap",
                    cursor: "pointer",
                    maxWidth: { xs: "200px", md: "350px" },
                  }}
                  overflow="auto"
                  onClick={async () =>
                    await toClipboard({
                      text: data?.txId || "",
                      cb: (isCopied) => setIsTxIdCopied(isCopied),
                    })
                  }
                >
                  {data?.txId?.slice(0, 10)}
                  .........
                  {data?.txId?.slice(
                    data?.txId?.length - 10,
                    data?.txId?.length
                  )}
                </Typography>
                <CopyIcon
                  isCopied={isTxIdCopied}
                  iconColor={theme?.palette.info[600]}
                  successIconColor={theme?.palette.info[600]}
                  onChange={(isCopied) => setIsTxIdCopied(isCopied)}
                  text={data?.txId || ""}
                />
              </Stack>
            </Stack>
          )}

          {data?.currency === "IRT" && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                به مقصد
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.800"
              >
                {data?.destination}
              </Typography>
            </Stack>
          )}

          {Number(data?.fee) !== 0 && (
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                smVariant="Caption"
                variant="Body"
                color="colorfulGray.600"
              >
                کارمزد
              </Typography>
              <Typography
                smVariant="BodyBold"
                variant="ExtendedBodyBold"
                color="colorfulGray.800"
              >
                {numUtils.commaformatter.toCurrency(data?.fee)}
                {data?.currency === "IRT" && (
                  <TomanIcon
                    sx={{
                      mx: "0.25rem",
                      mb: "-3px",
                      fill: (theme) => theme.palette.colorfulGray[500],
                      fontSize: "1rem",
                    }}
                  />
                )}
              </Typography>
            </Stack>
          )}

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              smVariant="Caption"
              variant="Body"
              color="colorfulGray.600"
            >
              وضعیت
            </Typography>
            <Typography
              py="0.25rem"
              px="0.5rem"
              bgcolor="neutral.50"
              color={
                data?.state === "SUCCESS"
                  ? "info.700"
                  : data?.state === "FAILED"
                  ? "error.500"
                  : "colorfulGray.600"
              }
              variant="CaptionBold"
              smVariant="SmallCaptionBold"
              borderRadius="0.25rem"
            >
              {getPersianEquivalent(data?.state as TransactionsStatus)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
