import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { Grid, IconButton, useTheme } from "@mui/material";
import { numUtils } from "@utils/num-helpers";

import { IconTypography } from "@components/common/icon-typography";
import { CopyIcon } from "@components/shared/copy-icon";
import { useGetLastWithdrawTransaction } from "@services/queries/wallet/queries";
import {
  getCoinIconURL,
  getPersianEquivalent,
  toClipboard,
} from "@utils/common";
import { DocumentFilter } from "iconsax-react";
import { Fragment, useState } from "react";
import { TransactionsStatus } from "~types/index";

interface Props {
  currency: string;
  localName: string;
}

export const LastCoinWithdrawTransaction = ({ currency, localName }: Props) => {
  const getLastCoinWithdrawTransactionQuery = useGetLastWithdrawTransaction({
    query: [
      {
        field: "currency",
        operator: "eq",
        value: currency,
      },
      {
        field: "actionType",
        operator: "eq",
        value: "withdraw",
      },
    ],
  });

  const theme = useTheme();

  const [isLinkCopied, setIsLinkCopied] = useState<{ [key: string]: boolean }>(
    {}
  );

  const handleCopy = async (txId: string) => {
    await toClipboard({
      text: txId,
      cb: (isCopied) =>
        setIsLinkCopied((prev) => ({ ...prev, [txId]: isCopied })),
    });
  };

  return (
    <Grid
      container
      rowGap="0.75rem"
      sx={{
        overflowY: "auto",
        alignItems: "center",
      }}
    >
      <Grid xs={2} item textAlign="center">
        <Typography variant="ExtendedBody" color="colorfulGray.700">
          رمزارز
        </Typography>
      </Grid>
      <Grid xs={2} item textAlign="center">
        <Typography
          variant="ExtendedBody"
          color="colorfulGray.700"
          textAlign="right"
        >
          تاریخ
        </Typography>
      </Grid>
      <Grid xs={1} item textAlign="center">
        <Typography variant="ExtendedBody" color="colorfulGray.700">
          مقدار
        </Typography>
      </Grid>
      <Grid xs={1} item textAlign="center">
        <Typography variant="ExtendedBody" color="colorfulGray.700">
          شبکه
        </Typography>
      </Grid>
      <Grid xs={4} item textAlign="center">
        <Typography variant="ExtendedBody" color="colorfulGray.700">
          Tx ID
        </Typography>
      </Grid>
      <Grid xs={2} item textAlign="center">
        <Typography variant="ExtendedBody" color="colorfulGray.700">
          وضعیت
        </Typography>
      </Grid>

      {getLastCoinWithdrawTransactionQuery?.data?.result?.items?.length ===
      0 ? (
        <Grid xs={12}>
          <IconTypography
            sx={{ py: "1.5rem" }}
            title={
              <Typography variant="ExtendedBody" color="colorfulGray.700">
                موردی برای نمایش یافت نشد.
              </Typography>
            }
            icon={
              <DocumentFilter size="3rem" color={theme.palette.primary[50]} />
            }
          />
        </Grid>
      ) : (
        getLastCoinWithdrawTransactionQuery?.data?.result?.items?.map(
          (item) => (
            <Fragment key={`WithdrawTransactionItem-${item?._id}`}>
              <Grid xs={2}>
                <Typography
                  variant="BodyBold"
                  color="colorfulGray.700"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <Image
                    src={getCoinIconURL(currency || "")}
                    width="1.4rem"
                    height="1.4rem"
                    alt={currency || ""}
                  />
                  {`${localName}/(${item?.currency})`}
                </Typography>
              </Grid>
              <Grid xs={2} item textAlign="center">
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.700"
                >
                  {numUtils.convertToPersianDate(
                    item?.createdAt,
                    "short-date-time",
                    "en"
                  )}
                </Typography>
              </Grid>
              <Grid xs={1} item textAlign="center">
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.700"
                >
                  {numUtils.commaformatter.toCurrency(item?.amount)}
                </Typography>
              </Grid>
              <Grid xs={1} item textAlign="center">
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.700"
                >
                  {item?.requestType}
                </Typography>
              </Grid>
              <Grid
                xs={4}
                item
                borderBottom={"1px dashed"}
                sx={{
                  overflow: "hidden",
                  color: "info.600",
                  textOverflow: "ellipsis",
                  textWrap: "nowrap",
                  direction: "rtl",
                }}
              >
                <IconButton onClick={() => handleCopy(item?.txId ?? "")}>
                  <CopyIcon
                    isCopied={isLinkCopied[item?.txId ?? ""] || false}
                    onChange={(isCopied) =>
                      setIsLinkCopied((prev) => ({
                        ...prev,
                        [item?.txId ?? ""]: isCopied,
                      }))
                    }
                    text={item?.txId ?? ""}
                    iconColor={theme.palette.info[600]}
                    successIconColor={theme.palette.info[700]}
                  />
                </IconButton>
                <Typography variant="ExtendedBodyMedium" color="info.600">
                  {item?.txId}
                </Typography>
              </Grid>
              <Grid xs={2} item textAlign="center">
                <Typography
                  variant="ExtendedBody"
                  color={
                    item?.state === "CREATED"
                      ? "info.700"
                      : item?.state === "SUCCESS"
                      ? "success.700"
                      : "error.600"
                  }
                >
                  {getPersianEquivalent(item?.state as TransactionsStatus)}
                </Typography>
              </Grid>
            </Fragment>
          )
        )
      )}
    </Grid>
  );
};
