import { useBreakpoint } from "@hooks/useBreakpoint";
import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  Checkbox as MuiCheckbox,
  CheckboxProps as MuiCheckboxProps,
} from "@mui/material";
import { BpCheckedIcon, BpIcon } from "./styled";

interface CustomFormControlLabelProps
  extends Omit<FormControlLabelProps, "control"> {
  register?: any;
}

interface CheckboxProps {
  formLabel?: string;
  isOutline?: boolean;
  formControlProps: CustomFormControlLabelProps;
  checkboxProps?: MuiCheckboxProps;
}

export function Checkbox({
  formLabel,
  isOutline = false,
  formControlProps: formControlProps,
  checkboxProps,
}: CheckboxProps) {
  const isMdUp = useBreakpoint("md");

  const { label, register, ...otherFormControlProps } = formControlProps;

  return (
    <FormControl>
      {formLabel && <FormLabel id="balinex-checkbox">{formLabel}</FormLabel>}
      <FormControlLabel
        control={
          <MuiCheckbox
            disableRipple
            checkedIcon={
              <BpCheckedIcon isMdUp={isMdUp} isOutline={isOutline} />
            }
            icon={<BpIcon isMdUp={isMdUp} />}
            {...checkboxProps}
          />
        }
        label={label}
        {...register}
        {...otherFormControlProps}
      />
    </FormControl>
  );
}
