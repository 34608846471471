import { useBreakpoint } from "@hooks/useBreakpoint";
import { Button } from "../button";
import { Modal, Popover, Stack, useTheme } from "@mui/material";
import { Typography } from "../typography";
import { Sort } from "iconsax-react";
import { useEffect, useState } from "react";
import { useFilter } from "@hooks/useFilter";

interface SelectModalProps {
  defaultValue: { label: string; value: string | number };
  options: { label: string; value: string | number }[];
  disabled?: boolean;
}

export const SelectModal = ({
  defaultValue,
  options,
  disabled,
}: SelectModalProps) => {
  const isMdUp = useBreakpoint("md");
  const theme = useTheme();
  const { setParams, searchParams } = useFilter();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selected, setSelected] = useState<{
    label: string;
    value: string | number;
  }>();

  const handleChangeValue = (item: {
    label: string;
    value: string | number;
  }) => {
    setSelected(item);
    setAnchorEl(null);
    setParams({ name: "filter", value: item?.value?.toString(), reset: true });
  };

  useEffect(() => {
    const isFilterExisted = options?.find(
      (e) => e?.value.toString() === searchParams?.get("filter")
    );
    if (!!isFilterExisted) {
      setParams({ name: "filter", value: isFilterExisted?.value.toString() });
      setSelected(isFilterExisted);
    } else {
      setParams({ name: "filter", value: defaultValue?.value.toString() });
      setSelected(defaultValue);
    }
  }, []);

  const renderComponent = () => (
    <Stack p="0.75rem" bgcolor="white" borderRadius="1.125rem">
      {options?.map((item) => (
        <Stack
          mt="1px"
          key={`${item?.label}-${item?.value}`}
          minWidth="170px"
          onClick={() => handleChangeValue(item)}
          sx={{
            borderRadius: "0.5rem",
            "&:hover": { bgcolor: "primary.A100" },
            bgcolor:
              selected?.value === item?.value ? "primary.A100" : "transparent",
            cursor: "pointer",
            p: "0.75rem",
          }}
        >
          <Typography color="neutral.900" variant="BodyMedium">
            {item?.label}
          </Typography>
        </Stack>
      ))}
    </Stack>
  );

  return (
    <>
      <Button
        disabled={disabled}
        onClick={(e) => setAnchorEl(e?.currentTarget)}
        aria-describedby="select-modal"
        bgColor={isMdUp ? "white" : "transparent"}
        textColor="colorfulGray.700"
        endIcon={<Sort variant="Bold" color={theme?.palette?.primary?.[700]} />}
      >
        {isMdUp && (
          <Typography
            color="primary.700"
            variant="BodyBold"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {selected?.label}
          </Typography>
        )}
      </Button>
      <Modal
        hideBackdrop={isMdUp}
        sx={{
          display: "flex",
          backdropFilter: { xs: "blur(3px)", md: "unset" },
        }}
        onClose={() => setAnchorEl(null)}
        open={Boolean(anchorEl)}
      >
        {isMdUp ? (
          <Popover
            sx={{
              mt: "0.65rem",
              boxShadow: "none",
              ".MuiPaper-root": {
                borderRadius: "1.125rem",
                boxShadow: "0px 0px 22px -12px #00000029",
              },
            }}
            id="select-modal"
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            {renderComponent()}
          </Popover>
        ) : (
          <Stack width="100%" p="1rem" mt="auto" justifyContent="flex-end">
            {renderComponent()}
          </Stack>
        )}
      </Modal>
    </>
  );
};
