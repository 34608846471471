import { Paper, Stack, useTheme } from "@mui/material";
import { IconProps } from "iconsax-react";
import React from "react";

interface HintProps {
  severity: "info" | "warning" | "error";
  IconComponent?: React.ComponentType<IconProps>;
  action?: React.ReactNode;
  content?: React.ReactNode;
}

const Hint = ({ severity, IconComponent, content, action }: HintProps) => {
  const theme = useTheme();

  const severityBgColor = {
    info: theme.palette.info["A100"],
    warning: theme.palette.warning["A100"],
    error: theme.palette.error["A100"],
  };

  const severityTextColor = {
    info: theme.palette.info[700],
    warning: theme.palette.warning[700],
    error: theme.palette.error[700],
  };

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: severityBgColor[severity],
        borderRadius: "0.625rem",
      }}
    >
      <Stack p={2}>
        <Stack spacing={1} direction={"row"}>
          {IconComponent ? (
            <IconComponent
              size={"1.25rem"}
              style={{ flexShrink: 0 }}
              color={severityTextColor[severity]}
            />
          ) : (
            ""
          )}
          <Stack gap={1} sx={{ "& *": { color: severityTextColor[severity] } }}>
            {content}
          </Stack>
        </Stack>
        <Stack alignItems={"flex-end"} mt={1}>
          {action}
        </Stack>
      </Stack>
    </Paper>
  );
};

export default Hint;
