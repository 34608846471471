import { Wrapper } from "@components/layout/wrapper";
import { Stack } from "@mui/material";
import AuthStepper from "../../_components/stepper";
import { PhotoCameraOutlined, FolderSharedOutlined } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { DocumentAuthorizationForm } from "../../_components/document-authorization-form";
import { VideoAuthorizationForm } from "../../_components/video-authorization-form";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "@store/user";
import { toast } from "@utils/toast";

export default function ProAuthentication() {
  const [activeStep, setActiveStep] = useState(0);

  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!!user?.personalAttachmentsPending) {
      toast.warning(
        "مدارک شما در حال بررسی می‌باشد. برای اطلاعات بیشتر با پشتیبانی تماس بگیرید."
      );
      navigate("/v2/dashboard/authentication");
    }
    if (!user?.IDverification) {
      toast.warning("لطفا ابتدا احراز هویت پایه خود را تکمیل کنید.");
      navigate("/v2/dashboard/authentication");
    }
    if (!!user?.personalAttachmentsVerification) {
      // Redirect to auth page
      navigate("/v2/dashboard/authentication");
    }
  }, []);

  return (
    <Wrapper backMode title="احراز هویت ویژه">
      <Stack
        width="100%"
        maxWidth="900px"
        mx="auto"
        py="1rem"
        sx={{ gap: { xs: "1rem", md: "3rem" } }}
      >
        <Stack overflow="hidden">
          <AuthStepper
            activeStep={activeStep}
            steps={[
              {
                id: "MOBILE",
                title: "مدرک شناسایی",
                icon: <FolderSharedOutlined sx={{ color: "neutral.50" }} />,
              },
              {
                id: "VERIFY_MOBILE",
                title: "احراز هویت",
                icon: <PhotoCameraOutlined sx={{ color: "neutral.50" }} />,
              },
            ]}
          />
        </Stack>

        {activeStep === 0 && (
          <DocumentAuthorizationForm onSuccess={() => setActiveStep(1)} />
        )}

        {activeStep === 1 && <VideoAuthorizationForm />}
      </Stack>
    </Wrapper>
  );
}
