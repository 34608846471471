import { IGNORE_TOAST_PATHS } from "@constants/index";
import {
  refreshAuthToken,
  retryLoginWithSecret,
} from "@services/queries/auth/apis";
import { detectEnv } from "@utils/common";
import { getLocalStorageItem, removeLocalStorageItem } from "@utils/storage";
import { toast } from "@utils/toast";
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import type { Response } from "~types/index";

// prettier-ignore
const handleError = ({message,xReqId}:{message: string, xReqId?: string}) => {  
  const defaultMsg = "مشکلی به وجود آمده است."
  toast.error(message || defaultMsg, {
    customIcon: `SUPPORT${xReqId ? `_${xReqId}` : ""}`,
  })
}

const baseURL = import.meta.env.VITE_APP_API_URL || "https://api.hebitex.ir";

const baseApi = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
  // paramsSerializer: {
  //   encode: (params) => qs.stringify(params, { arrayFormat: 'repeat' })
  // }
});

baseApi.interceptors.request.use(async (config) => {
  if (detectEnv() === "WebView") {
    const clientId = await getLocalStorageItem("clientId");
    config.headers["x-request-from"] =
      (await getLocalStorageItem("app-info")) || "";
    if (!!clientId?.length) {
      config.headers["x-client-id"] = clientId;
    }
  }
  // const token = getCookie(TOKEN_COOKIE_NAME);
  // config.hexaders.Authorization = `${token}`;
  return config;
});

baseApi.interceptors.response.use(
  (res: AxiosResponse) => {
    const message = res?.data?.message?.fa || res?.data?.message;
    const url = res?.config?.url;

    if (typeof url !== "string") {
      toast.info(message);
    }

    if (!IGNORE_TOAST_PATHS.some((str) => url!.includes(str))) {
      message && toast.success(message);
    }

    return res;
  },
  (err: AxiosError<Response<null>>) => {
    const { data, status } = err.response!;
    const message = data?.message?.fa || data?.message.en || err?.message || "";
    const url = err?.config?.url;
    const xReqId = err?.response?.headers["x-request-id"];
    const originalReq = err?.config;

    const redirectToLogin = () => {
      if (!["/v2/login"].includes(window.location.pathname)) {
        window.location.href = "/v2/login";
      }
    };

    const handleUnauthorizedOrForbidden = async (status: number) => {
      if (status === 401 && !["/v2/login"].includes(window.location.pathname)) {
        if (!(originalReq as any)._retryCount) {
          (originalReq as any)._retryCount = 0;
        }

        if ((originalReq as any)._retryCount < 2) {
          try {
            (originalReq as any)._retryCount += 1;
            const data = await refreshAuthToken();
            if (data?.success && originalReq) {
              return baseApi(originalReq);
            }
          } catch (error) {
            if (detectEnv() === "WebView") {
              window?.ReactNativeWebView?.postMessage(
                JSON.stringify({ action: "loginWithSecret" })
              );
            } else {
              redirectToLogin();
              console.error("Error while refreshing token", error);
            }
          }
        } else {
          redirectToLogin();
        }
      }
    };

    switch (status) {
      case 400:
        handleError({ message, xReqId });
        break;
      case 401:
        handleUnauthorizedOrForbidden(status);
        // if (!["/api/user/whoAmI"].some((str) => url!.includes(str))) {
        //   handleError({ message, xReqId });
        // }
        break;
      case 403:
        handleError({ message, xReqId });
        break;
      case 404:
      case 500:
      default:
        handleError({ message, xReqId });
        break;
    }

    return Promise.reject(err);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

// prettier-ignore
const request = {
  get: <T>(endpoint: string, headers?: AxiosRequestConfig<any>) => baseApi.get<Response<T>>(endpoint, headers).then(responseBody),
  post: <T>(endpoint: string, body?: {}, headers?: AxiosRequestConfig<any>) => baseApi.post<Response<T>>(endpoint, body, headers).then(responseBody),
  put: <T>(endpoint: string, body?: {}, headers?: AxiosRequestConfig<any>) => baseApi.put<Response<T>>(endpoint, body, headers).then(responseBody),
  delete: <T>(endpoint: string, headers?: AxiosRequestConfig<any>) => baseApi.delete<Response<T>>(endpoint, headers).then(responseBody),
};

export default request;
