import {
  Typography,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Stack,
  SxProps,
} from "@mui/material";
import { numUtils } from "@utils/num-helpers";
import { FocusEventHandler, forwardRef } from "react";
import { InputError } from "../textfield-error";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { replaceHalfSpaceWithSpace } from "@utils/common";

export type TextFieldProps = {
  renderComponent?: JSX.Element;
  action?: React.ReactNode;
  numberInput?: boolean;
  handleHalfSpace?: boolean;
} & MuiTextFieldProps &
  Omit<NumericFormatProps, "size" | "type">;

export const TextField = forwardRef(
  (
    {
      color = "primary",
      fullWidth = true,
      size = "small",
      sx = {},
      label = "",
      error,
      helperText,
      renderComponent,
      inputProps,
      onChange = () => {},
      onBlur = () => {},
      action,
      numberInput,
      allowNegative = false,
      handleHalfSpace,
      ...props
    }: TextFieldProps,
    ref: any
  ) => {
    const handleOnChange:
      | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
      | undefined = (e) => {
      e.target.value = numUtils.commaformatter.toRaw(
        numUtils.toEnglish(e.target.value)
      );

      if (handleHalfSpace)
        e.target.value = replaceHalfSpaceWithSpace(e.target.value);

      if (numberInput && props?.thousandSeparator)
        e.target.value = numUtils.commaformatter.toRaw(e.target.value);

      return onChange(e);
    };
    const handleOnBlur: FocusEventHandler<HTMLInputElement> | undefined = (
      e
    ) => {
      e.target.value = numUtils.toEnglish(e.target.value);

      if (handleHalfSpace)
        e.target.value = replaceHalfSpaceWithSpace(e.target.value);

      if (numberInput && props?.thousandSeparator)
        e.target.value = numUtils.commaformatter.toRaw(e.target.value);

      return onBlur(e);
    };

    // @ts-ignore
    const inputSx: SxProps = () => ({
      pb: "0.25rem",
      ".MuiInputBase-root": {
        pr: "0.5rem",
        "&:not(.Mui-focused) fieldset": {
          border: "none",
          borderRadius: "0.65rem",
        },
        py: "0.1rem",
        bgcolor: "primary.A100",
        borderRadius: "0.65rem",
        color: "neutral.A700",
        ...sx,
      },
    });

    return (
      <Stack width="100%" gap="0.25rem">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          {label && (
            <Typography
              fontWeight={400}
              textAlign="left"
              variant="BodyBold"
              color="primary.800"
            >
              {label}
            </Typography>
          )}
          {action}
        </Stack>

        {/* @ts-ignore */}
        {renderComponent ??
          (numberInput ? (
            <NumericFormat
              // @ts-ignore
              customInput={MuiTextField}
              {...props}
              color={color}
              label=""
              inputProps={{ inputMode: "numeric", ...inputProps }}
              allowNegative={allowNegative}
              fullWidth={fullWidth}
              autoComplete="off"
              getInputRef={ref}
              size={size as any}
              FormHelperTextProps={{ component: "span" }}
              helperText={
                error ? <InputError error={helperText as string} /> : helperText
              }
              sx={inputSx}
              onBlur={handleOnBlur}
              onChange={handleOnChange}
            />
          ) : (
            <MuiTextField
              {...props}
              color={color}
              label=""
              fullWidth={fullWidth}
              ref={ref}
              size={size}
              inputProps={{ ...inputProps }}
              FormHelperTextProps={{ component: "span" }}
              helperText={
                error ? <InputError error={helperText as string} /> : helperText
              }
              sx={inputSx}
              onChange={handleOnChange}
            />
          ))}
      </Stack>
    );
  }
);
