declare global {
  interface Window {
    gtag: any;
    dataLayer: any[];
  }
}

export const GA_TRACKING_ID = "G-E2H9P9CNHC";

export const initGA = () => {
  window.dataLayer = window.dataLayer || [];
  window.gtag = function gtag() {
    window.dataLayer.push(arguments);
  };

  window.gtag("js", new Date());
  window.gtag("config", GA_TRACKING_ID);
};
