import { SvgIcon, useTheme } from "@mui/material";
import { toClipboard } from "@utils/common";
import { Copy, CopySuccess } from "iconsax-react";

interface CopyIconProps {
  text: string;
  successIconColor?: string;
  iconColor?: string;
  isCopied: boolean;
  onChange: (isCopied: boolean) => void;
}

export const CopyIcon = ({
  text,
  successIconColor,
  iconColor,
  onChange,
  isCopied,
}: CopyIconProps) => {
  const theme = useTheme();

  const handleClipboard = async () => {
    try {
      await toClipboard({ text: text, cb: (isCopied) => onChange(isCopied) });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <SvgIcon
      onClick={handleClipboard}
      sx={{
        cursor: "pointer",
      }}
    >
      {isCopied ? (
        <CopySuccess
          size="1.5rem"
          color={successIconColor || theme.palette.success[700]}
        />
      ) : (
        <Copy size="1.5rem" color={iconColor || theme.palette.primary[700]} />
      )}
    </SvgIcon>
  );
};
