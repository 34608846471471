import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { BankCardItem } from "@components/shared/bank-card-item";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, useTheme } from "@mui/material";
import { AddBankButton } from "@components/shared/add-bank-button";
import { useGetMyBankAccount } from "@services/queries/wallet/queries";
import { MyBankAccountItem } from "@services/queries/wallet/types";
import { bankInfo } from "@utils/common";
import { Card } from "iconsax-react";
import { useHandleDeleteBankAccount } from "@services/queries/wallet/mutations";
import { toast } from "@utils/toast";

export const BankPage = () => {
  const isMdUp = useBreakpoint("md");
  const theme = useTheme();

  const getBankAccountQuery = useGetMyBankAccount();
  const deleteBankAccountMutation = useHandleDeleteBankAccount();

  return (
    <Wrapper
      sxContainer={{
        pt: { md: "2rem" },
        flex: 1,
        bgcolor: { xs: "white", md: "transparent" },
      }}
      isFullWidth={!isMdUp}
      isScreenHeight
      title="حساب‌های بانکی"
      backMode
      loading={getBankAccountQuery.isFetching}
    >
      <Stack
        bgcolor="white"
        sx={{ py: { xs: "1rem", md: "1.5rem" }, px: "1.5rem" }}
        borderRadius="1.125rem"
      >
        {isMdUp && (
          <Typography pb="1rem" color="colorfulGray.800" variant="TitleBold">
            حساب‌های بانکی
          </Typography>
        )}

        {/* @ts-ignore */}
        {!!getBankAccountQuery?.data?.items?.length ? (
          <Stack gap="0.75rem">
            {/* @ts-ignore */}
            {getBankAccountQuery?.data?.items
              //   ?.filter((e: MyBankAccountItem) => e?.accType === "card")
              ?.map((item: MyBankAccountItem) => (
                <BankCardItem
                  onDelete={() =>
                    deleteBankAccountMutation?.mutate(item?._id, {
                      onError: () => {
                        toast.error("با خطا مواجه شد.");
                      },
                    })
                  }
                  key={`card-${item?._id}`}
                  title={item.no}
                  isSheba={item?.accType === "account"}
                  imgSrc={`/v2/images/banks-logo/${
                    bankInfo(
                      item?.no,
                      item?.accType === "card" ? "Card" : "Sheba"
                    )?.logo || "melli.svg"
                  }`}
                  alt={
                    bankInfo(
                      item?.no,
                      item?.accType === "card" ? "Card" : "Sheba"
                    )?.name || "حساب بانکی"
                  }
                />
              ))}
          </Stack>
        ) : (
          <Stack gap="1.5rem" alignItems="center">
            <Stack
              borderRadius="50%"
              sx={{
                width: { xs: "5rem", md: "6.75rem" },
                height: { xs: "5rem", md: "6.75rem" },
              }}
              alignItems="center"
              justifyContent="center"
              bgcolor="neutral.50"
            >
              <Card
                variant="Bold"
                color={theme?.palette?.primary[50]}
                size={isMdUp ? "4.4rem" : "3.5rem"}
              />
            </Stack>
            <Typography
              variant="ExtendedBodyMedium"
              smVariant="BodyMedium"
              color="colorfulGray.700"
            >
              هنوز کارت بانکی ثبت نکرده‌اید
            </Typography>
          </Stack>
        )}

        <AddBankButton titleBtn="کارت جدید" />
      </Stack>
    </Wrapper>
  );
};
