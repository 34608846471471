import { useQuery } from "@tanstack/react-query";
import type { Response } from "~types/index";
import type { IUserModel } from "~types/user";
import { whoAmI } from "./apis";

export function useProfile() {
  return useQuery<Response<{ item: IUserModel }>, void>({
    queryKey: ["Profile"],
    queryFn: whoAmI,
    // enabled: !!getCookie(TOKEN_COOKIE_NAME)
  });
}
