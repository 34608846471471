import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  ILeaderBoardArgs,
  ILeaderBoardRes,
  ProfitMembersRes,
  ReferralLevelsRes,
} from "./types";
import {
  getLeaderBoardLastMonth,
  getLeaderBoardTotal,
  getProfitMembers,
  getReferralLevels,
  getTotalProfits,
} from "./apis";
import { DEFAULT_PER_PAGE } from "@constants/index";
import { Response } from "~types/index";

export const useGetLeaderBoardTotal = (data: ILeaderBoardArgs) =>
  useInfiniteQuery<{ items: ILeaderBoardRes[]; total: number }, void>({
    queryKey: ["leader-board-total"],
    queryFn: ({ pageParam }) =>
      getLeaderBoardTotal({
        ...data,
        page: Number(pageParam ?? 0),
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.items?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
    staleTime: 10 * 1000,
  });

export const useGetLeaderBoardLastMonth = (data: ILeaderBoardArgs) =>
  useInfiniteQuery<{ items: ILeaderBoardRes[]; total: number }, void>({
    queryKey: ["leader-board-month"],
    queryFn: ({ pageParam }) =>
      getLeaderBoardLastMonth({
        ...data,
        page: Number(pageParam ?? 0),
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.items?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
    staleTime: 10 * 1000,
  });

export const useGetProfitMembers = (data: ILeaderBoardArgs) =>
  useInfiniteQuery<Response<ProfitMembersRes[]>, void>({
    queryKey: ["profit-member-list"],
    queryFn: ({ pageParam }) =>
      getProfitMembers({
        ...data,
        page: Number(pageParam ?? 0),
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage?.result?.length < DEFAULT_PER_PAGE) {
        return undefined;
      }

      return (lastPageParam as number) + DEFAULT_PER_PAGE;
    },
  });

export function useGetReferralLevels() {
  return useQuery<{ items: ReferralLevelsRes[]; total: number }>({
    queryKey: ["referral-levels"],
    queryFn: getReferralLevels,
  });
}

export function useGetTotalProfits() {
  return useQuery<Response<{ totalProfit: string }>>({
    queryKey: ["total-profits"],
    queryFn: getTotalProfits,
  });
}
