import { AuthLevelCard } from "@components/common/auth-level-card";
import Container from "@components/common/container";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { CircularProgress, Stack } from "@mui/material";
import { useGetEstimateAssets } from "@services/queries/wallet/queries";
import { useMemo, useState } from "react";
import BasketAsset from "./_components/basket-asset";
import ReferralCard from "./_components/refer-card";
import ReportCard from "./_components/report-card";
import SwitchUsdtToIRT from "./_components/switch-usdt-irt";
import { defaultBaseOn, LabelValue } from "./_components/types";
import UserAssetsCard from "./_components/user-assets-card";
import WithdrawCard from "./_components/withdraw-card";

export default function DashboardPage() {
  const [baseOn, setBaseOn] = useState<LabelValue | null>(defaultBaseOn);
  const [isHidePrice, setIsHidePrice] = useState(false);

  const isMdUp = useBreakpoint("md");
  // const useGetMyWalletQuery = useGetMyWallet({ perPage: 200 });
  const useGetEstimateAssetsQuery = useGetEstimateAssets();

  const isBalanceAvailable = useMemo(
    () => useGetEstimateAssetsQuery.data?.result?.IRT?.price || 0 > 0,
    [useGetEstimateAssetsQuery?.data]
  );

  return (
    <Wrapper
      title="داشبورد"
      sxContainer={{ pt: { md: "2rem" }, pb: "2rem" }}
      isFullWidth={!isMdUp}
      actions={
        !isMdUp && <SwitchUsdtToIRT baseOn={baseOn} setBaseOn={setBaseOn} />
      }
    >
      <UserAssetsCard
        baseOn={baseOn}
        setBaseOn={setBaseOn}
        isHidePrice={isHidePrice}
        setIsHidePrice={setIsHidePrice}
        IRTBalance={
          useGetEstimateAssetsQuery.data?.result?.IRT?.priceString || "0"
        }
        USDTBalance={
          useGetEstimateAssetsQuery.data?.result?.USDT?.priceString || "0"
        }
      />

      <Container
        maxWidth="xl"
        sx={{
          px: { xs: "1rem", md: 0 },
          mt: !isBalanceAvailable ? "1rem" : 0,
        }}
      >
        <Stack gap="1rem">
          <AuthLevelCard sxContainer={{ p: { xs: "1rem", md: "2rem" } }} />
          <WithdrawCard />
          {useGetEstimateAssetsQuery.isLoading && (
            <CircularProgress size={20} />
          )}
          {isBalanceAvailable && (
            <>
              <ReportCard
                isMdUp={isMdUp}
                baseOn={baseOn}
                isHidePrice={isHidePrice}
              />
              <BasketAsset
                baseOn={baseOn}
                dataWallet={
                  useGetEstimateAssetsQuery.data?.result?.currencies || []
                }
                isHidePrice={isHidePrice}
              />
            </>
          )}
          <ReferralCard />
        </Stack>
      </Container>
    </Wrapper>
  );
}
