import { Button } from "@components/common/button";
import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Grid, Skeleton, Stack, SvgIcon } from "@mui/material";
import { WalletItem } from "@services/queries/wallet/types";
import { getCoinIconURL } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { ArrowLeft2 } from "iconsax-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BaseCoin } from "~types/index";

interface WalletItemCardProps {
  item: WalletItem;
  isHidePrice?: boolean;
  baseOn: BaseCoin;
  isOtherCoin?: boolean;
  isEstimateLoading?: boolean;
}

export const WalletItemCard = ({
  item,
  isHidePrice,
  baseOn,
  isOtherCoin,
  isEstimateLoading,
}: WalletItemCardProps) => {
  const navigate = useNavigate();
  const isMdUp = useBreakpoint("md");

  const [isModalDetailsOpen, setIsModalDetailsOpen] = useState(false);

  return (
    <Stack
      direction="row"
      width="100%"
      alignItems="center"
      py="1rem"
      sx={{
        "&:not(:last-child)": {
          borderBottom: 1,
          borderBottomColor: "primary.50",
        },
      }}
    >
      <Grid xs={6} md={3} item>
        <Stack
          onClick={() =>
            navigate(`/v2/dashboard/wallet/${item?.currency}`, {
              state: { item, baseOn, isOtherCoin },
            })
          }
          direction="row"
          alignItems="center"
          sx={{ cursor: "pointer", gap: { xs: "0.75rem", md: "1rem" } }}
          flex={1}
        >
          <Image
            alt="Coin-Image"
            height="3rem"
            width="3rem"
            sx={{
              width: { xs: "2rem", md: "3rem" },
              height: { xs: "2rem", md: "3rem" },
            }}
            src={getCoinIconURL(item?.currency)}
          />

          <Stack sx={{ alignItems: { md: "center" } }}>
            <Stack direction="row" gap="0.15rem" flexWrap="wrap">
              <Typography
                variant="ExtendedBody"
                smVariant="Body"
                color="colorfulGray.800"
                sx={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "70%",
                }}
              >
                {item?.prettyNameLocalized}
              </Typography>
              <Typography
                variant="ExtendedBody"
                smVariant="Body"
                color="colorfulGray.400"
              >
                ({item?.currency})
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
      <Grid
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "flex-end",
        }}
        xs={3}
        item
      >
        <Stack sx={{ width: { md: "fit-content" } }} alignItems="flex-end">
          <Typography
            variant="TitleBold"
            smVariant="ExtendedBodyMedium"
            color="colorfulGray.800"
            dir={isHidePrice ? "ltr" : "rtl"}
          >
            {!isHidePrice ? item?.balanceAvailableFormatted : "****"}
            <Typography
              component="span"
              pr="0.25rem"
              variant="Caption"
              color="colorfulGray.300"
            >
              {item?.currency}
            </Typography>
          </Typography>
          {!isOtherCoin && (
            <Typography
              variant="Body"
              smVariant="Caption"
              color="colorfulGray.700"
              dir={isHidePrice ? "ltr" : "rtl"}
            >
              {!isHidePrice
                ? item?.balanceEstimate?.[
                    baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
                  ]?.availableString
                : "****"}
              <Typography
                component="span"
                pr="0.25rem"
                variant="Caption"
                color="colorfulGray.300"
              >
                {baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"}
              </Typography>
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid
        sx={{
          display: { xs: "none", md: "flex" },
          flexDirection: "column",
          alignItems: "flex-end",
        }}
        xs={3}
        item
      >
        <Stack sx={{ width: { md: "fit-content" } }} alignItems="flex-end">
          <Typography
            variant="TitleBold"
            smVariant="ExtendedBodyMedium"
            color="colorfulGray.800"
            dir={isHidePrice ? "ltr" : "rtl"}
          >
            {!isHidePrice ? item?.balanceHoldFormatted : "****"}
            <Typography
              component="span"
              pr="0.25rem"
              variant="Caption"
              color="colorfulGray.300"
            >
              {item?.currency}
            </Typography>
          </Typography>
          {!isOtherCoin && (
            <Typography
              variant="Body"
              smVariant="Caption"
              color="colorfulGray.700"
              dir={isHidePrice ? "ltr" : "rtl"}
            >
              {!isHidePrice
                ? item?.balanceEstimate?.[
                    baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
                  ]?.holdString
                : "****"}
              <Typography
                component="span"
                pr="0.25rem"
                variant="Caption"
                color="colorfulGray.300"
              >
                {baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"}
              </Typography>
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: { md: "flex-end" },
        }}
        xs={8}
        md={3}
        item
      >
        <Stack sx={{ width: { md: "fit-content" } }} alignItems="flex-end">
          <Typography
            variant="TitleBold"
            smVariant="ExtendedBodyMedium"
            color="colorfulGray.800"
            dir={isHidePrice ? "ltr" : "rtl"}
          >
            {!isHidePrice ? item?.balanceTotalFormatted : "****"}
            <Typography
              component="span"
              pr="0.25rem"
              variant="Caption"
              color="colorfulGray.300"
            >
              {item?.currency}
            </Typography>
          </Typography>
          {!isOtherCoin && (
            <Typography
              variant="Body"
              smVariant="Caption"
              color="colorfulGray.700"
              dir={isHidePrice ? "ltr" : "rtl"}
            >
              {!isHidePrice
                ? item?.balanceEstimate?.[
                    baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
                  ]?.totalString
                : "****"}
              <Typography
                component="span"
                pr="0.25rem"
                variant="Caption"
                color="colorfulGray.300"
              >
                {baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"}
              </Typography>
            </Typography>
          )}
        </Stack>
      </Grid>
      <Grid
        display={{
          xs: "none",
          md: "flex",
        }}
        xs={1}
        md={2}
        item
      >
        <Stack
          gap="0.25rem"
          sx={{
            display: {
              xs: "none",
              md: "flex",
            },
            maxWidth: { xs: "auto", md: "100px" },
            ml: "auto",
          }}
        >
          <Button
            size="small"
            fullWidth
            linkStyle={{ width: "100%" }}
            // prettier-ignore
            href={item?.currency !== "IRT" ? `/v2/dashboard/wallet/coin/${item?.currency}/deposit` : "/v2/dashboard/wallet/toman/deposit"}
            bgColor="primary.A100"
            textColor="primary.700"
          >
            <Typography
              whiteSpace="nowrap"
              color="primary.700"
              variant="CaptionMedium"
            >
              واریز
            </Typography>
          </Button>
          <Button
            size="small"
            fullWidth
            linkStyle={{ width: "100%" }}
            // prettier-ignore
            href={item?.currency !== "IRT" ? `/v2/dashboard/wallet/coin/${item?.currency}/withdraw` : "/v2/dashboard/wallet/toman/withdraw"}
            bgColor="primary.A100"
            textColor="primary.700"
          >
            <Typography
              whiteSpace="nowrap"
              color="primary.700"
              variant="CaptionMedium"
            >
              برداشت
            </Typography>
          </Button>
        </Stack>
        {/* <SvgIcon
          onClick={() => setIsModalDetailsOpen(true)}
          sx={{
            ml: "auto",
            display: { xs: "flex", md: "none" },
            color: (theme) => theme.palette.colorfulGray[500],
          }}
        >
          <ArrowLeft2 size="1.25rem" />
        </SvgIcon> */}
      </Grid>

      <Modal
        open={isModalDetailsOpen && !isMdUp}
        onClose={() => setIsModalDetailsOpen(false)}
      >
        <>
          <Stack alignItems="center" gap="2rem" width="100%">
            <Stack alignItems="center" gap="0.75rem">
              <Image
                alt="Coin-Image"
                height="3rem"
                width="3rem"
                src={getCoinIconURL(item?.currency)}
              />

              <Stack direction="row" gap="0.15rem">
                <Typography
                  variant="ExtendedBodyMedium"
                  color="colorfulGray.800"
                >
                  {item?.prettyNameLocalized}
                </Typography>
                <Typography variant="ExtendedBody" color="colorfulGray.400">
                  ({item?.currency})
                </Typography>
              </Stack>
            </Stack>

            <Stack width="100%" gap="1.5rem">
              {[
                {
                  title: "دارایی در دسترس",
                  balance: item?.balanceAvailableFormatted,
                  estimateBalance:
                    item?.balanceEstimate?.[
                      baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
                    ]?.availableString,
                },
                {
                  title: "دارایی در معاملات",
                  balance: item?.balanceHoldFormatted,
                  estimateBalance:
                    item?.balanceEstimate?.[
                      baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
                    ]?.holdString,
                },
                {
                  title: "دارایی کل",
                  balance: item?.balanceTotalFormatted,
                  estimateBalance:
                    item?.balanceEstimate?.[
                      baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"
                    ]?.totalString,
                },
              ]?.map((col) => (
                <Stack direction="row" justifyContent="space-between">
                  <Typography
                    variant="ExtendedBodyMedium"
                    smVariant="BodyMedium"
                    color="colorfulGray.800 "
                  >
                    {col?.title}
                  </Typography>
                  <Stack alignItems="flex-end">
                    <Typography
                      variant="ExtendedBodyBold"
                      smVariant="BodyBold"
                      color="colorfulGray.800"
                    >
                      {numUtils.commaformatter.toCurrency(col?.balance)}
                      <Typography
                        component="span"
                        pr="0.25rem"
                        variant="Caption"
                        color="colorfulGray.300"
                      >
                        {item?.currency}
                      </Typography>
                    </Typography>
                    {!isOtherCoin &&
                      (!isEstimateLoading ? (
                        <Typography
                          variant="Body"
                          smVariant="Caption"
                          color="colorfulGray.700"
                        >
                          {numUtils.commaformatter.toCurrency(
                            col?.estimateBalance
                          )}
                          <Typography
                            component="span"
                            pr="0.25rem"
                            variant="Caption"
                            color="colorfulGray.300"
                          >
                            {baseOn === BaseCoin.TOMAN ? "IRT" : "USDT"}
                          </Typography>
                        </Typography>
                      ) : (
                        <Skeleton
                          variant="rectangular"
                          width={150}
                          height={75}
                        />
                      ))}
                  </Stack>
                </Stack>
              ))}
            </Stack>

            <Stack
              zIndex={2}
              gap="0.75rem"
              direction="row"
              sx={{
                bgcolor: "white",
                width: "100%",
                maxWidth: { md: "28rem" },
              }}
            >
              <Button
                fullWidth
                linkStyle={{ width: "100%" }}
                href={
                  item?.currency !== "IRT"
                    ? `/v2/dashboard/wallet/coin/${item?.currency}/deposit`
                    : "/v2/dashboard/wallet/toman/deposit"
                }
                bgColor="primary.A100"
                textColor="primary.700"
              >
                <Typography
                  whiteSpace="nowrap"
                  color="primary.700"
                  variant="BodyBold"
                  smVariant="BodyMedium"
                >
                  واریز
                </Typography>
              </Button>
              <Button
                fullWidth
                linkStyle={{ width: "100%" }}
                href={
                  item?.currency !== "IRT"
                    ? `/v2/dashboard/wallet/coin/${item?.currency}/withdraw`
                    : "/v2/dashboard/wallet/toman/withdraw"
                }
                bgColor="primary.A100"
                textColor="primary.700"
              >
                <Typography
                  whiteSpace="nowrap"
                  color="primary.700"
                  variant="BodyBold"
                  smVariant="BodyMedium"
                >
                  برداشت
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </>
      </Modal>
    </Stack>
  );
};
