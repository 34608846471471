import { useGetTrades } from "@services/queries/markets/queries";
import { Trades } from "@services/queries/markets/types";
import { useEffect, useState } from "react";
import { SocketTradesData } from "../types";
import useCurrentCurrency from "./useCurrentCurrency";
import { useSocket } from "@context/scoket";

const useGetLastTrade = () => {
  const currentCurrency = useCurrentCurrency();
  const { socket } = useSocket();
  const [lastPriceCoin, setLastPriceCoin] = useState<SocketTradesData>();

  const [recentTrade, setRecentTrade] = useState<Trades[]>([]);

  const { data: latestTrades, isLoading } = useGetTrades(
    currentCurrency?.id as string
  );

  useEffect(() => {
    if (!currentCurrency) return;
    socket.on(`ticker:${currentCurrency?.id}`, (trade: SocketTradesData) => {
      // console.log(trade);
      if (!trade || recentTrade?.length === 0) return;
      const socketData: Trades = {
        price: trade.price,
        size: trade.lastSize,
        time: trade.time,
        side: trade.side,
      };

      setRecentTrade([socketData, ...recentTrade]);
      setLastPriceCoin(trade);
    });

    return () => {
      socket.off(`ticker:${currentCurrency?.id}`);
    };
  }, [currentCurrency, currentCurrency?.id, recentTrade, socket]);

  useEffect(() => {
    if (latestTrades) {
      setRecentTrade(latestTrades.result);
    }
  }, [latestTrades]);

  return { lastPriceCoin, recentTrade, currentCurrency, isLoading };
};

export default useGetLastTrade;
