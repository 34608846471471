import { useGetMarketCurrency } from "@services/queries/markets/queries";
import { IMarket } from "@services/queries/markets/types";
import { useParams } from "react-router-dom";

const useCurrentCurrency = () => {
  const { currency: currencyParams } = useParams();
  const getMarketCurrencyQuery = useGetMarketCurrency(
    currencyParams ? currencyParams : undefined
  );

  const currencies: IMarket[] =
    getMarketCurrencyQuery?.data?.result?.items || [];

  const currentCurrency = currencies?.filter(
    (item) => item?.id === currencyParams
  )[0];

  return currentCurrency;
};

export default useCurrentCurrency;
