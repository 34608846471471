import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, SvgIcon } from "@mui/material";
import { useUserStore } from "@store/user";
import { ArrowLeft2 } from "iconsax-react";
import { useNavigate } from "react-router-dom";

const settingsItems = [
  // {
  //   title: "تغییر رمز",
  //   link: "/v2/dashboard/settings",
  //   disabled: true,
  //   isOutSource: false,
  // },
  {
    title: "کد ورود دو عاملی",
    link: `/v2/dashboard/settings/google-verification`,
    disabled: false,
    isOutSource: false,
  },
  // {
  //   title: "ایمیل",
  //   link: "/v2/dashboard/settings",
  //   disabled: true,
  //   isOutSource: false,
  // },
  {
    title: "تاریخچه ورود",
    link: "/v2/dashboard/settings/login-history",
    disabled: false,
    isOutSource: false,
  },
];

export const SettingsPage = () => {
  const isMdUp = useBreakpoint("md");
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  return (
    <Wrapper
      sxContainer={{
        pt: { md: "2rem" },
        flex: 1,
        bgcolor: { xs: "white", md: "transparent" },
      }}
      isFullWidth={!isMdUp}
      isScreenHeight
      title="تنظیمات"
      backMode
    >
      <Stack
        sx={{
          gap: { xs: 0, md: "1.5rem" },
          p: { xs: "1rem", md: 0 },
        }}
      >
        {settingsItems?.map((item) => {
          return (
            <Stack
              onClick={() => {
                if (item?.link) {
                  if (item?.isOutSource) {
                    return (window.location.href = item?.link?.replaceAll(
                      "WORKSPACE",
                      user?.currentWorkSpaceId || ""
                    ));
                  }

                  return navigate(item?.link);
                }
              }}
              bgcolor="white"
              px="0.75rem"
              direction="row"
              sx={{
                cursor: "pointer",
                borderRadius: { xs: 0, md: "0.625rem" },
                py: { xs: "1.25rem", md: "1.5rem" },
              }}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography
                color="colorfulGray.800"
                smVariant="Body"
                variant="ExtendedBodyBold"
              >
                {item?.title}
              </Typography>
              <SvgIcon
                sx={{
                  fontSize: { xs: "1rem", md: "1.5rem" },
                  color: (theme) => theme.palette.colorfulGray[500],
                }}
              >
                <ArrowLeft2 />
              </SvgIcon>
            </Stack>
          );
        })}
      </Stack>
    </Wrapper>
  );
};
