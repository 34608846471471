import { createTheme } from "@mui/material";

// Fonts
import RabiBold from "@assets/fonts/woff2/Ravi-Bold.woff2";
import YekanBakhBold from "@assets/fonts/woff2/YekanBakh-Bold.woff2";
import YekanBakhRegular from "@assets/fonts/woff2/YekanBakh-Regular.woff2";
import YekanBakhSemiBold from "@assets/fonts/woff2/YekanBakh-SemiBold.woff2";

interface PaletteColorStructure {
  50?: string;
  100?: string;
  200?: string;
  300?: string;
  400?: string;
  500?: string;
  600?: string;
  700?: string;
  800?: string;
  900?: string;
  A100?: string;
  A200?: string;
  A400?: string;
  A700?: string;
}

declare module "@mui/material/styles/createPalette" {
  interface PaletteColor {
    50: string;
    100: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    A100: string;
    A200: string;
    A400: string;
    A700: string;
  }
  interface Palette {
    neutral: PaletteColorStructure;
    colorfulGray: PaletteColorStructure;
  }
  interface PaletteOptions {
    neutral?: PaletteColorStructure;
    colorfulGray?: PaletteColorStructure;
  }
}

declare module "@mui/material/Alert/Alert" {
  interface AlertPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/LinearProgress/LinearProgress" {
  interface LinearProgressPropsColorOverrides {
    neutral: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    SuperExtendHeadlineBold: React.CSSProperties;
    SuperExtendHeadlineMedium: React.CSSProperties;
    SuperExtendHeadline: React.CSSProperties;
    ExtendHeadlineBold: React.CSSProperties;
    ExtendHeadlineMedium: React.CSSProperties;
    ExtendHeadline: React.CSSProperties;
    HeadlineBold: React.CSSProperties;
    HeadlineMedium: React.CSSProperties;
    Headline: React.CSSProperties;
    ExtendTitleBold: React.CSSProperties;
    ExtendTitleMedium: React.CSSProperties;
    ExtendTitle: React.CSSProperties;
    TitleBold: React.CSSProperties;
    TitleMedium: React.CSSProperties;
    Title: React.CSSProperties;
    ExtendedBodyBold: React.CSSProperties;
    ExtendedBodyMedium: React.CSSProperties;
    ExtendedBody: React.CSSProperties;
    BodyBold: React.CSSProperties;
    BodyMedium: React.CSSProperties;
    Body: React.CSSProperties;
    CaptionBold: React.CSSProperties;
    CaptionMedium: React.CSSProperties;
    Caption: React.CSSProperties;
    SmallCaptionBold: React.CSSProperties;
    SmallCaptionMedium: React.CSSProperties;
    SmallCaption: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    SuperExtendHeadlineBold?: React.CSSProperties;
    SuperExtendHeadlineMedium?: React.CSSProperties;
    SuperExtendHeadline?: React.CSSProperties;
    ExtendHeadlineBold?: React.CSSProperties;
    ExtendHeadlineMedium?: React.CSSProperties;
    ExtendHeadline?: React.CSSProperties;
    HeadlineBold?: React.CSSProperties;
    HeadlineMedium?: React.CSSProperties;
    Headline?: React.CSSProperties;
    ExtendTitleBold?: React.CSSProperties;
    ExtendTitleMedium?: React.CSSProperties;
    ExtendTitle?: React.CSSProperties;
    TitleBold?: React.CSSProperties;
    TitleMedium?: React.CSSProperties;
    Title?: React.CSSProperties;
    ExtendedBodyBold?: React.CSSProperties;
    ExtendedBodyMedium?: React.CSSProperties;
    ExtendedBody?: React.CSSProperties;
    BodyBold?: React.CSSProperties;
    BodyMedium?: React.CSSProperties;
    Body?: React.CSSProperties;
    CaptionBold?: React.CSSProperties;
    CaptionMedium?: React.CSSProperties;
    Caption?: React.CSSProperties;
    SmallCaptionBold?: React.CSSProperties;
    SmallCaptionMedium?: React.CSSProperties;
    SmallCaption?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    SuperExtendHeadlineBold: true;
    SuperExtendHeadlineMedium: true;
    SuperExtendHeadline: true;
    ExtendHeadlineBold: true;
    ExtendHeadlineMedium: true;
    ExtendHeadline: true;
    HeadlineBold: true;
    HeadlineMedium: true;
    Headline: true;
    ExtendTitleBold: true;
    ExtendTitleMedium: true;
    ExtendTitle: true;
    TitleBold: true;
    TitleMedium: true;
    Title: true;
    ExtendedBodyBold: true;
    ExtendedBodyMedium: true;
    ExtendedBody: true;
    BodyBold: true;
    BodyMedium: true;
    Body: true;
    CaptionBold: true;
    CaptionMedium: true;
    Caption: true;
    SmallCaptionBold: true;
    SmallCaptionMedium: true;
    SmallCaption: true;
  }
}

const createTypography = (
  type: "Bold" | "SemiBold" | "Regular",
  size: string
) => ({
  fontFamily: "YekanBakh" + type,
  fontSize: size,
  fontWeight: type === "Bold" ? 700 : type === "SemiBold" ? 400 : 400,
  lineHeight: 1.6,
});

export const lightTheme = createTheme({
  direction: "rtl",
  palette: {
    mode: "light",
    primary: {
      A100: "#EDECF8", // A100 = 10
      "50": "#DBD9F0",
      "100": "#C9C6E9",
      "200": "#A5A1DA",
      "300": "#938ED3",
      "400": "#7068C4",
      "500": "#4E45B2",
      "600": "#3E378D",
      "700": "#2D2868",
      main: "#2D2868",
      "800": "#1E1B45",
      "900": "#0F0D22",
    },
    secondary: {
      A100: "#FEF5F0", // A100 = 10
      "50": "#FCE4DA",
      "100": "#FCD7C7",
      "200": "#FACAB4",
      "300": "#FABDA1",
      "400": "#F8A885",
      "500": "#F6956A",
      "600": "#F37A43",
      main: "#F37A43",
      "700": "#EA540F",
      "800": "#AF3F0B",
      "900": "#752A07",
    },
    neutral: {
      A100: "#F9F9F9", // A100 = 10
      "50": "#F2F2F2",
      "100": "#CECECE",
      "200": "#BDBDBD",
      "300": "#ADADAD",
      "400": "#949494",
      "500": "#7C7C7C",
      "600": "#5B5B5B",
      "700": "#494949",
      "800": "#373737",
      "900": "#202020",
      A700: "#0E0E0E", // A700 = Black
    },
    colorfulGray: {
      A100: "#EFEEF4", // A100 = 10
      "50": "#D6D4E1",
      "100": "#C0BFD2",
      "200": "#ACAAC2",
      "300": "#9794B3",
      "400": "#76749C",
      "500": "#565485",
      "600": "#3E3D56",
      "700": "#2C2C37",
      "800": "#201E27",
      "900": "#121016",
    },
    error: {
      A100: "#FBEFEF", // A100 = 10
      "50": "#F6D7D5",
      "100": "#EFB9B7",
      "200": "#E99B99",
      "300": "#E27C7A",
      "400": "#DB5E5A",
      "500": "#D13632",
      main: "#D13632",
      "600": "#A82C27",
      "700": "#892321",
      "800": "#691B19",
      "900": "#491311",
    },
    success: {
      A100: "#F1F6F0", // A100 = 10
      "50": "#DCEADB",
      "100": "#CAE0C9",
      "200": "#B7D6B6",
      "300": "#A6CCA3",
      "400": "#8ABC87",
      "500": "#70AD6A",
      "600": "#4D9846",
      "700": "#3D7A37",
      main: "#3D7A37",
      "800": "#2F5B2A",
      "900": "#1B3518",
    },
    info: {
      A100: "#EBF4F9", // A100 = 10
      "50": "#CCE4F0",
      "100": "#B3D6E9",
      "200": "#99C8E2",
      "300": "#80BBDB",
      "400": "#5BA6CE",
      "500": "#3391C5",
      "600": "#0076B6",
      main: "#0076B6",
      "700": "#005E92",
      "800": "#01466D",
      "900": "#00293F",
    },
    warning: {
      A100: "#FEF7E4", // A100 = 10
      "50": "#FDEFC8",
      "100": "#FCEBB5",
      "200": "#FBE7A2",
      "300": "#FADE7D",
      "400": "#F8CE44",
      main: "#F8CE44",
      "500": "#D3B03C",
      "600": "#AD9130",
      "700": "#887026",
      "800": "#63521C",
      "900": "#3E3411",
    },
  },
  typography: {
    fontFamily: "YekanBakhRegular, sans-serif",
    // Typography
    SuperExtendHeadlineBold: createTypography("Bold", "4rem"),
    SuperExtendHeadlineMedium: createTypography("SemiBold", "4rem"),
    SuperExtendHeadline: createTypography("Regular", "4rem"),
    ExtendHeadlineBold: createTypography("Bold", "3rem"),
    ExtendHeadlineMedium: createTypography("SemiBold", "3rem"),
    ExtendHeadline: createTypography("Regular", "3rem"),
    HeadlineBold: createTypography("Bold", "2rem"),
    HeadlineMedium: createTypography("SemiBold", "2rem"),
    Headline: createTypography("Regular", "2rem"),
    ExtendTitleBold: createTypography("Bold", "1.375rem"),
    ExtendTitleMedium: createTypography("SemiBold", "1.375rem"),
    ExtendTitle: createTypography("Regular", "1.375rem"), 
    TitleBold: createTypography("Bold", "1.125rem"),
    TitleMedium: createTypography("SemiBold", "1.125rem"),
    Title: createTypography("Regular", "1.125rem"),
    ExtendedBodyBold: createTypography("Bold", "1rem"),
    ExtendedBodyMedium: createTypography("SemiBold", "1rem"),
    ExtendedBody: createTypography("Regular", "1rem"),
    BodyBold: createTypography("Bold", "0.875rem"),
    BodyMedium: createTypography("SemiBold", "0.875rem"),
    Body: createTypography("Regular", "0.875rem"),
    CaptionBold: createTypography("Bold", "0.75rem"),
    CaptionMedium: createTypography("SemiBold", "0.75rem"),
    Caption: createTypography("Regular", "0.75rem"),
    SmallCaptionBold: createTypography("Bold", "0.625rem"),
    SmallCaptionMedium: createTypography("SemiBold", "0.625rem"),
    SmallCaption: createTypography("Regular", "0.625rem"),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        * {
          font-family: 'YekanBakhRegular', sans-serif;
        }
        html,
        body {
          min-height: 100dvh;
          background-color: #EFEEF4 !important;
        }
        ::-webkit-input-placeholder { 
          font-size: 0.875rem;
          font-family: "YekanBakhBold"
          color: #ADADAD;
        }
        ::-moz-placeholder {
          font-size: 0.875rem;
          font-family: "YekanBakhBold"
          color: #ADADAD;
        }
        :-ms-input-placeholder { 
          font-size: 0.875rem;
          font-family: "YekanBakhBold"
          color: #ADADAD;
        }
        :-moz-placeholder {
          font-size: 0.875rem;
          font-family: "YekanBakhBold"
          color: #ADADAD;
        }
        .MuiDateCalendar-root * {
          -moz-font-feature-settings: "ss02";
          -webkit-font-feature-settings: "ss02";
          font-feature-settings: "ss02";
        }
        @font-face {
          font-family: 'RaviFontBold';
          font-style: normal;
          font-weight: 700;
          src: local('RaviFontBold'), local('Ravi-Bold'), url(${RabiBold}) format('woff2');
        }
        @font-face {
          font-family: 'YekanBakhBold';
          font-style: normal;
          font-weight: 700;
          src: local('YekanBakhBold'), local('YekanBakh-Bold'), url(${YekanBakhBold}) format('woff2');
        }
        @font-face {
          font-family: 'YekanBakhRegular';
          font-style: normal;
          font-weight: 400;
          src: local('YekanBakhRegular'), local('YekanBakh-Regular'), url(${YekanBakhRegular}) format('woff2');
        }
        @font-face {
          font-family: 'YekanBakhSemiBold';
          font-style: normal;
          font-weight: 600;
          src: local('YekanBakhSemiBold'), local('YekanBakh-SemiBold'), url(${YekanBakhSemiBold}) format('woff2');
        }
      `,
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          maxHeight: "250px", // Set the max height as per your requirement
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginInline: "0.25rem",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderWidth: "2px !important",
        },
      },
    },
  },
});
