import { GetAssetsRes } from "@services/queries/wallet/types";

export enum BaseCoin {
  TOMAN = "TOMAN",
  USDT = "USDT",
}

export interface LabelValue {
  label: string;
  value: string | number;
}

export const defaultBaseOn = {
  label: "تومان",
  value: BaseCoin?.TOMAN,
};

export interface BaseOnProps {
  baseOn: LabelValue | null;
  setBaseOn: React.Dispatch<React.SetStateAction<LabelValue | null>>;
  isHidePrice: boolean;
  setIsHidePrice: React.Dispatch<React.SetStateAction<boolean>>;
  IRTBalance: string;
  USDTBalance: string;
}
