import { SvgIcon, SvgIconProps } from "@mui/material";

export const PercentPattern = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="169"
        height="104"
        viewBox="0 0 169 104"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.5 66C39.0259 65.6483 47.3935 63.3703 55.9652 63.0833C68.5027 62.6635 81.0784 63 93.6219 63C101.905 63 109.525 62.3032 117.5 60"
          stroke="#FCD7C7"
          strokeWidth="22.5"
          strokeLinecap="square"
        />
        <path
          d="M37.25 90L39.0731 94.9269L44 96.75L39.0731 98.5731L37.25 103.5L35.4269 98.5731L30.5 96.75L35.4269 94.9269L37.25 90Z"
          fill="#F37A43"
        />
        <path
          d="M159.5 63L161.931 69.5692L168.5 72L161.931 74.4308L159.5 81L157.069 74.4308L150.5 72L157.069 69.5692L159.5 63Z"
          fill="#EA540F"
        />
        <path
          d="M12.5 0L15.7411 8.75891L24.5 12L15.7411 15.2411L12.5 24L9.25891 15.2411L0.5 12L9.25891 8.75891L12.5 0Z"
          fill="#F37A43"
        />
      </svg>
    </SvgIcon>
  );
};
