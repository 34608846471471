import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Box, Stack } from "@mui/material";
import { OtcMethod } from "../../_types";
import { Button } from "@components/common/button";
import { ProgressTimerWithLabel } from "@components/common/progress-timer-with-label";
import { Coin } from "~types/index";
import { usePairSidesInvoice } from "@services/queries/otc/queries";
import { useEffect } from "react";
import { addCommas } from "@persian-tools/persian-tools";
import { useCreateOtcRequest } from "@services/queries/otc/mutations";
import { useFormContext } from "react-hook-form";

interface ApproveOtcRequestModalProps {
  open: boolean;
  onClose: () => void;
  method: OtcMethod;
  payment_amount: number;
  fromCoin: Coin;
  toCoin: Coin;
}

export const ApproveOtcRequestModal = ({
  onClose,
  open,
  method,
  payment_amount,
  fromCoin,
  toCoin,
}: ApproveOtcRequestModalProps) => {
  const getPairSidesInvoiceQuery = usePairSidesInvoice(
    {
      from: fromCoin?._id,
      to: toCoin?._id,
      paiedAmount: payment_amount,
    },
    open
  );
  const { setValue } = useFormContext();
  const createOtcRequestMutation = useCreateOtcRequest();

  const handleSubmitOtcRequest = () => {
    if (getPairSidesInvoiceQuery.isSuccess) {
      const { fee, from, paiedAmount, price, receivedAmount, to } =
        getPairSidesInvoiceQuery.data?.result;
      createOtcRequestMutation.mutate({
        fee: Number(fee),
        from,
        paiedAmount,
        price,
        receivedAmount,
        to,
      });
    }
  };

  useEffect(() => {
    if (open && getPairSidesInvoiceQuery?.isSuccess) {
      const interval_otc_invoice = setInterval(() => {
        open && getPairSidesInvoiceQuery?.refetch();
      }, (import.meta.env.VITE_OTC_FETCH_INTERVAL_IN_SEC || 10) * 1000);
      return () => {
        clearInterval(interval_otc_invoice);
      };
    }
  }, [getPairSidesInvoiceQuery.isPending, open]);

  useEffect(() => {
    if (createOtcRequestMutation.isSuccess) {
      setValue("payment", "");
      setValue("receive", "");
      onClose();
    }
  }, [createOtcRequestMutation?.isPending]);

  return (
    <Modal onClose={onClose} title="جزییات خرید" open={open}>
      <Stack
        gap="1.5rem"
        width="100%"
        sx={{
          borderRadius: { xs: "2rem 0 2rem 0" },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="primary.600" variant="body2" fontWeight={500}>
            پرداخت شده
          </Typography>
          <Typography
            className="persian-num"
            color="primary.600"
            variant="body2"
            dir="ltr"
          >
            {getPairSidesInvoiceQuery?.data?.result?.paiedAmountString || ""}
            <Box
              component="span"
              fontSize="0.65rem"
              mr="0.3rem"
              fontWeight={500}
            >
              {(
                getPairSidesInvoiceQuery?.data?.result?.from || ""
              ).toUpperCase()}
            </Box>
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="primary.600" variant="body2" fontWeight={500}>
            دریافت شده
          </Typography>
          <Typography
            className="persian-num"
            color="primary.600"
            variant="body2"
            dir="ltr"
          >
            {getPairSidesInvoiceQuery?.data?.result?.receivedAmountString || ""}
            <Box
              component="span"
              fontSize="0.65rem"
              mr="0.3rem"
              fontWeight={500}
            >
              {(getPairSidesInvoiceQuery?.data?.result?.to || "").toUpperCase()}
            </Box>
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="primary.600" variant="body2" fontWeight={500}>
            به قیمت
          </Typography>
          <Typography
            className="persian-num"
            color="primary.600"
            variant="body2"
            dir="ltr"
          >
            {addCommas(getPairSidesInvoiceQuery?.data?.result?.price || "")}{" "}
            <Box
              component="span"
              fontSize="0.65rem"
              mr="0.3rem"
              fontWeight={500}
            >
              {method === OtcMethod.Buy
                ? getPairSidesInvoiceQuery?.data?.result?.from ||
                  "".toUpperCase()
                : getPairSidesInvoiceQuery?.data?.result?.to ||
                  "".toUpperCase()}
            </Box>
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="primary.600" variant="body2" fontWeight={500}>
            کارمزد
          </Typography>
          <Typography
            className="persian-num"
            color="primary.600"
            variant="body2"
            dir="ltr"
          >
            {getPairSidesInvoiceQuery?.data?.result?.feeString || "0"}
            <Box
              component="span"
              fontSize="0.65rem"
              mr="0.3rem"
              fontWeight={500}
            >
              {(getPairSidesInvoiceQuery?.data?.result?.to || "").toUpperCase()}
            </Box>
          </Typography>
        </Stack>

        <Stack gap="1rem">
          <Button
            id={
              method === OtcMethod.Buy ? "confirm-otc-buy" : "confirm-otc-sell"
            }
            loading={
              getPairSidesInvoiceQuery?.isFetching ||
              createOtcRequestMutation?.isPending
            }
            variant="contained"
            fullWidth
            onClick={handleSubmitOtcRequest}
          >
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
              gap="1rem"
            >
              <Typography variant="body1" color="neutral.50">
                تایید و {method === OtcMethod.Buy ? "خرید" : "فروش"}
              </Typography>
              <ProgressTimerWithLabel
                minute={import.meta.env.VITE_OTC_FETCH_INTERVAL_IN_SEC || 10}
                resetDeps={getPairSidesInvoiceQuery?.isFetching}
              />
            </Stack>
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
