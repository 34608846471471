import { TomanIcon } from "@assets/icons/TomanIcon";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, useTheme } from "@mui/material";
import { numUtils } from "@utils/num-helpers";
import { Tether, WalletAdd } from "iconsax-react";
import { forwardRef, useImperativeHandle, useState } from "react";
import { ActionToChargeBalance } from "../action-to-charge-balance";
import { motion } from "framer-motion";
import { NeedAuthorize } from "../need-authorize";
import { Modal } from "@components/common/modal";
import { BaseCoin } from "~types/index";

interface BalanceDetailsListProps {
  list: { title: string; value: string }[];
  isHidePrice?: boolean;
  baseOn?: BaseCoin;
  isNeededAuthorize?: boolean;
}

export const BalanceDetailsList = forwardRef(
  (
    { list, isHidePrice, baseOn, isNeededAuthorize }: BalanceDetailsListProps,
    ref
  ) => {
    const theme = useTheme();

    const isMdUp = useBreakpoint("md");

    const [isOpenModal, setIsOpenModal] = useState(false);

    useImperativeHandle(ref, () => ({
      openModal: () => {
        setIsOpenModal(true);
      },
    }));

    const renderComponent = () => (
      <>
        {list?.map((item, i) => (
          <Stack direction="row" p="0.75rem" justifyContent="space-between">
            <Stack direction="row" alignItems="center" gap="0.35rem">
              <WalletAdd
                size="1.25rem"
                variant="Bold"
                color={theme?.palette?.colorfulGray?.[500]}
              />
              <Typography variant="ExtendedBody" smVariant="Body">
                {item?.title}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" gap="0.25rem">
              <Typography
                variant="ExtendedBodyMedium"
                smVariant="BodyMedium"
                color="colorfulGray.700"
              >
                {!isHidePrice
                  ? numUtils.commaformatter.toCurrency(item?.value)
                  : "********"}
              </Typography>
              {baseOn === BaseCoin.TOMAN ? (
                <TomanIcon
                  sx={{
                    width: "0.9rem",
                    fill: theme?.palette?.colorfulGray?.[500],
                  }}
                />
              ) : (
                <Tether
                  size="0.9rem"
                  color={theme?.palette?.colorfulGray?.[500]}
                />
              )}
            </Stack>
          </Stack>
        ))}
      </>
    );

    if (isMdUp) return renderComponent();

    return (
      <Modal onClose={() => setIsOpenModal(false)} open={isOpenModal}>
        <Stack
          width="100%"
          bgcolor="white"
          borderRadius="1.125rem"
          gap="0.75rem"
        >
          <Stack>{renderComponent()}</Stack>
          {/* If Tether is chosen we have no "isNeededAuthorize" but in inner page check it */}
          {(baseOn !== BaseCoin?.USDT && !isNeededAuthorize) ? (
            <NeedAuthorize baseOn={baseOn as BaseCoin} />
          ) : (
            <Stack direction="row" width="100%" gap="0.5rem">
              <ActionToChargeBalance baseOn={baseOn as BaseCoin} />
            </Stack>
          )}
        </Stack>
      </Modal>
    );
  }
);
