import { TomanIcon } from "@assets/icons/TomanIcon";
import { Button } from "@components/common/button";
import Hint from "@components/common/hint";
import { Logo } from "@components/common/logo";
import { SwitchTab } from "@components/common/switch-tab";
import { Typography } from "@components/common/typography";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, useTheme } from "@mui/material";
import { useGetSpecificWalletItem } from "@services/queries/wallet/queries";
import { useUserStore } from "@store/user";
import { motion } from "framer-motion";
import {
  ArrowDown2,
  Eye,
  EyeSlash,
  MoneyAdd,
  SecurityUser,
  Tether,
} from "iconsax-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { BaseCoin } from "~types/index";
import { ActionToChargeBalance } from "./_components/action-to-charge-balance";
import { BalanceDetailsList } from "./_components/balance-details-list";
import { ListCurrencyAndWallet } from "./_components/list-currency-and-wallet";
import { useNavigate } from "react-router-dom";

const topSectionHeightInMobile = 242;

interface LabelValue {
  label: string;
  value: string | number;
}

const defaultBaseOn = {
  label: "تومان",
  value: BaseCoin?.TOMAN,
};

export default function WalletPage() {
  const theme = useTheme();
  const isMdUp = useBreakpoint("md");
  const navigate = useNavigate();

  const user = useUserStore((state) => state?.user);

  const bottomSection = useRef<HTMLDivElement>(null);
  const modalRef = useRef({ openModal: () => {} });

  const [isHidePrice, setIsHidePrice] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const [baseOn, setBaseOn] = useState<LabelValue | null>(defaultBaseOn);

  const getSpecificWalletItem = useGetSpecificWalletItem({
    coinId: baseOn?.value === BaseCoin.TOMAN ? "IRT" : "USDT",
    staleTime: 15 * 1000,
  });

  useEffect(() => {
    if (bottomSection.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsPinned(true);
          } else {
            setIsPinned(false);
          }
        },
        { threshold: 1, rootMargin: "15px" }
      );
      observer.observe(bottomSection.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [bottomSection]);

  const isNeededAuthorize = useMemo(() => {
    if (baseOn?.value === BaseCoin.TOMAN) {
      return !!user?.IDverification && !!user?.mobileVerification;
    } else {
      return (
        (!!user?.mobileVerification && !user?.IDverification) ||
        (!!user?.IDverification &&
          !!user?.mobileVerification &&
          !!user?.personalAttachmentsVerification)
      );
    }
  }, [user, baseOn]);

  return (
    <Wrapper
      sxContainer={{ pt: { md: "2rem" } }}
      isNoPaddingModeContainer
      title={
        !isPinned ? (
          "کیف پول"
        ) : (
          <motion.div
            initial={{ transform: "scale(0)", opacity: 0 }}
            animate={{ transform: "scale(1)", opacity: 1 }}
          >
            <Stack
              onClick={() => setIsHidePrice(!isHidePrice)}
              direction="row"
              alignItems="center"
              gap="0.2rem"
            >
              <Typography variant="ExtendedBodyBold" color="colorfulGray.700">
                {!isHidePrice
                  ? getSpecificWalletItem?.data?.result
                      ?.balanceAvailableFormatted
                  : "********"}
              </Typography>
              {baseOn?.value === BaseCoin.TOMAN ? (
                <TomanIcon
                  onClick={() => setIsHidePrice(!isHidePrice)}
                  sx={{
                    width: "1rem",
                    fill: theme?.palette?.colorfulGray?.[500],
                  }}
                />
              ) : (
                <Tether
                  size="1.2rem"
                  color={theme?.palette?.colorfulGray?.[500]}
                />
              )}
            </Stack>
          </motion.div>
        )
      }
      withSearch={!isMdUp}
      onClickBackArrow={() => navigate("/v2/dashbaoard")}
      actions={
        !isMdUp ? (
          <Stack
            position="relative"
            direction="row"
            gap="0.5rem"
            alignItems="center"
          >
            <SwitchTab
              onChange={(data) => setBaseOn(data)}
              defaultTab={baseOn}
              list={[
                {
                  label: "تومان",
                  value: BaseCoin?.TOMAN,
                },
                {
                  label: "USDT",
                  value: BaseCoin?.USDT,
                },
              ]}
            />
          </Stack>
        ) : undefined
      }
      loading={getSpecificWalletItem?.isLoading}
    >
      <Stack sx={{ gap: { md: "1.75rem" }, flex: 1 }}>
        <Stack
          sx={{
            flexDirection: { xs: "column", md: "row" },
            position: { xs: "sticky", md: "unset" },
            borderRadius: { md: "1.125rem" },
            bgcolor: { xs: "primary.600", md: "white" },
            px: "1.5rem",
            height: { xs: `${topSectionHeightInMobile}px`, md: "unset" },
            py: { xs: "3rem", md: "1.5rem" },
            zIndex: 0,
            top: { xs: "57px" },
          }}
          overflow="hidden"
          gap="0.75rem"
        >
          <Stack flex={1} gap="0.75rem">
            <Stack
              gap="0.25rem"
              alignItems="center"
              justifyContent="center"
              sx={{ flexDirection: { xs: "column", md: "row" } }}
            >
              <Stack
                alignItems="center"
                direction="row"
                gap="0.25rem"
                onClick={() => modalRef.current.openModal()}
                sx={{
                  order: { xs: 2, md: "unset" },
                }}
              >
                <Typography
                  variant="ExtendedBodyMedium"
                  smVariant="BodyMedium"
                  sx={{
                    color: { xs: "white", md: "colorfulGray.700" },
                  }}
                >
                  قدرت خرید
                </Typography>
                <ArrowDown2 size="1.2rem" color="white" />
              </Stack>
              <Stack direction="row" gap="0.25rem" alignItems="center">
                <Typography
                  onClick={() => setIsHidePrice(!isHidePrice)}
                  variant="ExtendTitleBold"
                  smVariant="HeadlineBold"
                  sx={{
                    color: { xs: "white", md: "colorfulGray.700" },
                    cursor: "pointer",
                  }}
                >
                  {!isHidePrice
                    ? getSpecificWalletItem?.data?.result
                        ?.balanceAvailableFormatted
                    : "********"}
                </Typography>
                {baseOn?.value === BaseCoin.TOMAN ? (
                  <TomanIcon
                    onClick={() => setIsHidePrice(!isHidePrice)}
                    sx={{
                      width: "1.2rem",
                      fill: {
                        xs: "white",
                        md: theme?.palette?.colorfulGray?.[500],
                      },
                    }}
                  />
                ) : (
                  <Tether
                    size="1.2rem"
                    color={
                      isMdUp ? theme?.palette?.colorfulGray?.[500] : "white"
                    }
                  />
                )}
              </Stack>

              {!isMdUp && (
                <motion.div
                  style={{ order: 3 }}
                  initial={{ transform: "translateY(100px)" }}
                  animate={{ transform: "translateY(0)" }}
                >
                  <Button
                    href="/v2/dashboard/wallet/toman/deposit"
                    bgColor="white"
                    textColor="primary.700"
                    sx={{
                      mt: "1.8rem",
                      "&> div": { gap: "0.25rem", alignItems: "center" },
                    }}
                  >
                    <MoneyAdd
                      size="1.5rem"
                      variant="Bold"
                      color={theme?.palette?.primary?.[700]}
                    />
                    <Typography variant="BodyMedium" color="primary.700">
                      شارژ حساب
                    </Typography>
                  </Button>
                </motion.div>
              )}
            </Stack>

            <BalanceDetailsList
              ref={modalRef}
              isHidePrice={isHidePrice}
              baseOn={baseOn?.value as BaseCoin}
              isNeededAuthorize={isNeededAuthorize}
              list={[
                {
                  title: "کل دارایی",
                  value:
                    getSpecificWalletItem?.data?.result
                      ?.balanceTotalFormatted || "",
                },
                {
                  title: "دارایی بلوکه شده",
                  value:
                    getSpecificWalletItem?.data?.result?.balanceHoldFormatted ||
                    "",
                },
                {
                  title: "دارایی قابل برداشت",
                  value:
                    getSpecificWalletItem?.data?.result
                      ?.balanceAvailableFormatted || "",
                },
              ]}
            />
          </Stack>

          {isMdUp && (
            <Stack flex={1} gap="0.75rem">
              <Stack
                justifyContent="flex-end"
                direction="row"
                gap="0.88rem"
                alignItems="center"
              >
                {isHidePrice ? (
                  <Eye
                    onClick={() => setIsHidePrice(!isHidePrice)}
                    style={{ cursor: "pointer" }}
                    variant="Outline"
                    size="1.5rem"
                    color={theme?.palette?.colorfulGray?.[500]}
                  />
                ) : (
                  <EyeSlash
                    onClick={() => setIsHidePrice(!isHidePrice)}
                    style={{ cursor: "pointer" }}
                    variant="Outline"
                    size="1.5rem"
                    color={theme?.palette?.colorfulGray?.[500]}
                  />
                )}
                <SwitchTab
                  onChange={(data) => setBaseOn(data)}
                  defaultTab={baseOn}
                  list={[
                    {
                      label: "تومان",
                      value: BaseCoin?.TOMAN,
                    },
                    {
                      label: "USDT",
                      value: BaseCoin?.USDT,
                    },
                  ]}
                />
              </Stack>

              <Stack gap="1rem" height="100%" justifyContent="flex-end">
                {baseOn?.value !== BaseCoin?.USDT && !isNeededAuthorize ? (
                  <Hint
                    severity="warning"
                    IconComponent={SecurityUser}
                    content={
                      <Typography variant="Body" color="warning.900">
                        برای واریز و برداشت{" "}
                        {baseOn?.value === BaseCoin.TOMAN ? "تومان" : "تتر"} ،
                        نیاز به احراز هویت است.
                      </Typography>
                    }
                    action={
                      <Button
                        href="/v2/dashboard/authentication"
                        bgColor="white"
                        textColor="primary.700"
                        sx={{ border: 1.5, borderColor: "primary.700" }}
                        startIcon={<SecurityUser size="1.25rem" />}
                      >
                        <Typography variant="BodyMedium">احراز هویت</Typography>
                      </Button>
                    }
                  />
                ) : (
                  <ActionToChargeBalance baseOn={baseOn?.value as BaseCoin} />
                )}
              </Stack>
            </Stack>
          )}

          <Logo
            sx={{
              display: { md: "none" },
              fill: (theme) => theme?.palette?.primary?.[700],
              position: "absolute",
              bottom: "-15%",
              right: "-20%",
              width: "17rem",
              height: "17rem",
              zIndex: -1,
            }}
          />
        </Stack>

        <Stack ref={bottomSection} zIndex={2}>
          <Stack
            className="no-scroll"
            bgcolor="white"
            p="1.5rem"
            pb="0"
            mb="1.5rem"
            sx={{
              transition: "0.3s",
              borderRadius: {
                xs: isPinned ? 0 : "1.125rem",
                md: "1.125rem",
              },
              position: "sticky",
              top: "57px",
              height: { xs: "calc(100dvh - 57px)", md: "auto" },
              overflow: { xs: isPinned ? "auto" : "hidden", md: "unset" },
              zIndex: 2,
              mt: { xs: "-1rem", md: 0 },
            }}
            gap="1rem"
          >
            <ListCurrencyAndWallet
              baseOn={baseOn?.value as BaseCoin}
              isHidePrice={isHidePrice}
            />
          </Stack>
        </Stack>
      </Stack>
    </Wrapper>
  );
}
