import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Close, ManageSearchOutlined, Search } from "@mui/icons-material";
import {
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  SvgIcon,
} from "@mui/material";
import { getCoinIconURL } from "@utils/common";
import { ChangeEventHandler, useEffect, useState } from "react";
import type { Coin } from "~types/index";
import { OtcMethod } from "../../_types";

interface SelectCoinModalProps {
  title: string;
  onClose: () => void;
  open: boolean;
  searchable?: boolean;
  list: Coin[];
  onSelectCoin: (item: Coin) => void;
  method?: OtcMethod;
  side?: "from" | "to";
}

export const SelectCoinModal = ({
  title,
  onClose,
  open,
  list,
  searchable,
  onSelectCoin,
  method,
  side,
}: SelectCoinModalProps) => {
  const [value, setValue] = useState("");
  const [listCoins, setListCoins] = useState<Coin[]>([]);

  useEffect(() => {
    setListCoins(list);
  }, [list]);

  // prettier-ignore
  const handleOnChangeSearch:ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = (e) => {
    const val = e.target.value
    setValue(val)

    if(!val?.length) return setListCoins(list)

    const regex = new RegExp(val, "ig");
    const tempList = list?.filter(e=>
      e?._id?.search(regex) !== -1 ||e?.prettyName?.search(regex) !== -1 || e?.prettyNameLocalized?.search(regex) !== -1
    )
    setListCoins(tempList)
  };

  return (
    <Modal title={title} open={open} onClose={onClose}>
      <Stack width="100%" sx={{ minHeight: { xs: "50vh", md: "unset" } }}>
        {searchable && (
          <TextField
            autoFocus
            InputProps={{
              sx: { height: "40px" + "!important" },
              startAdornment: (
                <InputAdornment position="start">
                  <Search
                    sx={{
                      color: "neutral.400",
                    }}
                  />
                </InputAdornment>
              ),
            }}
            placeholder="جستجو"
            value={value}
            onChange={handleOnChangeSearch}
          />
        )}

        <List disablePadding sx={{ maxHeight: "350px", overflow: "auto" }}>
          {!!value?.length && !listCoins?.length ? (
            <Stack alignItems="center" py="2rem" gap="1rem">
              <ManageSearchOutlined
                sx={{ fontSize: "5rem", color: "neutral.400" }}
              />
              <Typography
                variant="body1"
                color="neutral.600"
                fontWeight={500}
                textAlign="center"
              >
                موردی یافت نشد
              </Typography>
            </Stack>
          ) : (
            listCoins?.map((item) => (
              <ListItem
                key={`coin-${item?._id}`}
                disablePadding
                sx={{ mb: "0.5rem", gap: "1rem" }}
              >
                {/* prettier-ignore */}
                <ListItemButton
                    sx={{ 
                      borderRadius: "0.5rem",
                      "&:hover": { bgcolor: "primary.A100" }
                    }}
                    id={`amount${side === "from"? '1': "2"}-${method === OtcMethod.Buy ? "buy" : "sell"}-${item?._id}`}
                    onClick={() => onSelectCoin(item)}
                  >
                    <Image
                      id={`amount${side === "from"? '1': "2"}-${method === OtcMethod.Buy ? "buy" : "sell"}-${item?._id}`}
                      src={getCoinIconURL(item._id)}
                      width="1.5rem"
                      height="1.5rem"
                      alt={item._id}
                      sx={{ mr: "1rem" }}
                    />
                    <ListItemText id={`amount${side === "from"? '1': "2"}-${method === OtcMethod.Buy ? "buy" : "sell"}-${item?._id}`} primary={item.prettyNameLocalized} />
                  </ListItemButton>
              </ListItem>
            ))
          )}
        </List>
      </Stack>
    </Modal>
  );
};
