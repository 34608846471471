import {
  Slider as MuiSlider,
  SliderProps as MuiSliderProps,
  ThemeProvider,
  useTheme,
} from "@mui/material";

interface SliderProps extends MuiSliderProps {
  side: "Buy" | "Sell";
}

const initialMarks = [
  {
    value: 0,
  },
  {
    value: 25,
  },
  {
    value: 50,
  },
  {
    value: 75,
  },
  {
    value: 100,
  },
];

export const Slider = ({
  side,
  disabled,
  marks = initialMarks,
  ...otherProps
}: SliderProps) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={{ ...theme, direction: "ltr" }}>
      <MuiSlider
        {...otherProps}
        disabled={disabled}
        color={side === "Buy" ? "success" : "error"}
        marks={marks}
        sx={{
          py: "0.75rem",
          my: "0.75rem",
          filter: disabled ? "grayscale(1)" : "grayscale(0)",
          ".MuiSlider-thumb": {
            border: "2px solid",
            mr: "-20px",
            borderColor: side === "Buy" ? "success.500" : "error.500",
            backgroundColor: "neutral.50",
            height: "1.5rem",
            width: "1.5rem",
            "&::before": {
              width: "70%",
              height: "70%",
              backgroundColor: side === "Buy" ? "success.500" : "error.500",
            },
          },
          ".MuiSlider-mark": {
            backgroundColor: "neutral.200",
            height: "0.5rem",
            width: "0.5rem",
            borderRadius: "50%",
            opacity: 1,
            "&.MuiSlider-markActive": {
              backgroundColor: side === "Buy" ? "success.500" : "error.500",
            },
          },
          ".MuiSlider-track": {
            height: "0.15rem",
          },
          ".MuiSlider-rail": {
            backgroundColor: "neutral.200",
          },

          ".MuiSlider-valueLabel": {
            py: 0,
            pt: "2px",
            px: "0.5rem",
            backgroundColor: side === "Buy" ? "success.500" : "error.500",
            color: "neutral.50",
            fontSize: "0.75rem",
            borderRadius: "0.25rem",
          },
        }}
      />
    </ThemeProvider>
  );
};
