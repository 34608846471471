import { Box, Divider, Stack } from "@mui/material";
import { Typography } from "@components/common/typography";
import { FormatQuoteOutlined, InfoTwoTone } from "@mui/icons-material";
import { VideoRecorder } from "../video-recorder";
import { useUserStore } from "@store/user";

export const VideoAuthorizationForm = () => {
  const user = useUserStore((state) => state?.user);

  return (
    <Stack
        width="90%"
        maxWidth="700px"
        mx="auto"
        py="2rem"
        borderRadius="1.125rem"
        gap="1rem"
        bgcolor="white"
        sx={{
          px: { xs: "1rem", md: "1.5rem" },
        }}
      >
      <Stack gap="1rem">
        <Stack direction="row" gap="0.75rem" alignItems="center">
          <InfoTwoTone sx={{ color: "primary.500" }} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography variant="Caption" color="colorfulGray.700">
            کاربرگرامی، اجازه دسترسی به دوربین را با فشردن دکمه "بازکردن دوربین"
            بدهید.
          </Typography>
        </Stack>
        <Stack direction="row" gap="0.75rem" alignItems="center">
          <InfoTwoTone sx={{ color: "primary.500" }} />
          <Divider orientation="vertical" variant="middle" flexItem />
          <Typography variant="Caption" color="colorfulGray.700">
            پس دسترسی، مقابل دوربین قرار بگیرید و پس از کلیک روی دکمه "ضبط
            ویدیو" متن را با صدای رسا بخوانید.
          </Typography>
        </Stack>
      </Stack>

      <Stack
        mt="2rem"
        direction="row"
        alignItems="center"
        mx="auto"
        gap="0.5rem"
        position="relative"
        width="fit-content"
        px="1.65rem"
      >
        <FormatQuoteOutlined
          sx={{
            position: "absolute",
            top: "-0.5rem",
            left: 0,
            color: "primary.500",
          }}
        />
        <Typography
          className="persian-num"
          variant="body1"
          fontWeight={700}
          pb="1rem"
          color="neutral.600"
          textAlign="center"
        >
          <Box component="span" display="inline-block">اینجانب</Box>
          <Box component="span" display="inline-block" mx="0.25rem" color="secondary.500">
            {user?.firstName || ""} {user?.lastName || ""}
          </Box>
          <Box component="span" display="inline-block">
            با مطالعه کامل قوانین و مقررات، درخواست افتتاح حساب در بالینکس را
            دارم.
          </Box>
        </Typography>
        <FormatQuoteOutlined
          sx={{
            transform: "rotateZ(180deg)",
            position: "absolute",
            bottom: "0.5rem",
            right: 0,
            color: "primary.500",
          }}
        />
      </Stack>

      <VideoRecorder />
    </Stack>
  );
};
