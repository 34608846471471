import { useMutation, useQueryClient } from "@tanstack/react-query";
import { verifyIDVerification } from "./apis";
import { verifyIDVerificationArgs } from "./types";

export function useVerifyBaseAuth() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: verifyIDVerificationArgs) => verifyIDVerification(data),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ["Profile"] });
    },
  });
}
