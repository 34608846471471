import { styled } from "@mui/material/styles";

interface CheckedIconProps {
  isOutline?: boolean;
  isMdUp?: boolean;
}

interface IconProps {
  isMdUp?: boolean;
}

export const BpIcon = styled("span")<IconProps>(({ theme, isMdUp }) => ({
  borderRadius: 5,
  width: !isMdUp ? 18 : 21.5,
  height: !isMdUp ? 18 : 21.5,
  backgroundColor: "transparent",
  border: `1px solid ${theme.palette.primary.main}`,
  "input:disabled ~ &": {
    boxShadow: "none",
    border: `1px solid ${theme.palette.colorfulGray[200]}`,
  },
}));

export const BpCheckedIcon = styled(BpIcon)<CheckedIconProps>(
  ({ theme, isOutline, isMdUp }) => ({
    backgroundColor: isOutline ? "transparent" : theme.palette.primary.main,
    "&::before": {
      display: "block",
      width: !isMdUp ? 18 : 21.5,
      height: !isMdUp ? 18 : 21.5,
      backgroundImage: `url(data:image/svg+xml,%3Csvg%20%20%20%20%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20%20%20%20%20%20%20width%3D%2220%22%20%20%20%20%20%20%20%20height%3D%2220%22%20%20%20%20%20%20%20%20viewBox${
        isMdUp
          ? `%3D%220%202%2020%2020%22%20%20%20%20%20%20%20%20`
          : `%3D%222%204%2020%2020%22%20%20%20%20%20%20%20%20`
      }fill%3D%22none%22%20%20%20%20%20%20%3E%3Cpath%20%20%20%20%20%20%20%20%20%20d%3D%22m7.75%2012%202.83%202.83%205.67-5.66%22%20%20%20%20%20%20%20%20%20%20stroke%3D%22%23${
        isOutline ? theme.palette.primary.main.replace("#", "") : "fff"
      }%22%20%20%20%20%20%20%20%20%20%20stroke-width%3D%221.5%22%20%20%20%20%20%20%20%20%20%20stroke-linecap%3D%22round%22%20%20%20%20%20%20%20%20%20%20stroke-linejoin%3D%22round%22%20%20%20%20%20%20%20%20%3E%3C%2Fpath%3E%3C%2Fsvg%3E)`,
      content: '""',
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: isOutline ? "transparent" : theme.palette.colorfulGray[200],
      color: theme.palette.colorfulGray[200],
      "&::before": {
        display: "block",
        width: !isMdUp ? 18 : 21.5,
        height: !isMdUp ? 18 : 21.5,
        backgroundImage: `url(data:image/svg+xml,%3Csvg%20%20%20%20%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%20%20%20%20%20%20%20width%3D%2220%22%20%20%20%20%20%20%20%20height%3D%2220%22%20%20%20%20%20%20%20%20viewBox${
          isMdUp
            ? `%3D%220%202%2020%2020%22%20%20%20%20%20%20%20%20`
            : `%3D%222%204%2020%2020%22%20%20%20%20%20%20%20%20`
        }fill%3D%22none%22%20%20%20%20%20%20%3E%3Cpath%20%20%20%20%20%20%20%20%20%20d%3D%22m7.75%2012%202.83%202.83%205.67-5.66%22%20%20%20%20%20%20%20%20%20%20stroke%3D%22%23${
          isOutline ? theme.palette.colorfulGray[200]!.replace("#", "") : "fff"
        }%22%20%20%20%20%20%20%20%20%20%20stroke-width%3D%221.5%22%20%20%20%20%20%20%20%20%20%20stroke-linecap%3D%22round%22%20%20%20%20%20%20%20%20%20%20stroke-linejoin%3D%22round%22%20%20%20%20%20%20%20%20%3E%3C%2Fpath%3E%3C%2Fsvg%3E)`,
        content: '""',
      },
    },
  })
);
