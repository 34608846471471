import { Button } from "@components/common/button";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack, useTheme } from "@mui/material";
import { useUserStore } from "@store/user";

interface TransactionCommissionModalProps {
  modalMode?: boolean;
  onClose?: () => void;
}

export const TransactionCommissionModal = ({
  modalMode,
  onClose,
}: TransactionCommissionModalProps) => {
  const user = useUserStore((state) => state.user);
  const renderComponent = () => (
    <Stack gap="1.5rem">
      {modalMode && (
        <Typography variant="Body" textAlign="center" color="colorfulGray.500">
          کارمزد در بالینکس براساس حجم معاملات ۳۰ روز گذشته شما به صورت پله‌ای
          محاسبه می‌شود
        </Typography>
      )}

      {/* <Stack gap="0.5rem" maxWidth="300px" mx="auto" width="100%">
        <Stack direction="row" gap="0.25rem" alignItems="center" mx="auto">
          <Typography
            variant="TitleBold"
            sx={{
              color: "colorfulGray.700",
              cursor: "pointer",
            }}
          >
            {numUtils.commaformatter.toCurrency(230000000)}
          </Typography>
          <TomanIcon
            sx={{
              width: "1.2rem",
              fill: theme?.palette?.colorfulGray?.[500],
            }}
          />
        </Stack>

        <Typography variant="Body" color="neutral.900" textAlign="center">
          حجم معاملات شما در ۳۰ روز گذشته
        </Typography>

        <LinearProgress
          sx={{
            ".MuiLinearProgress-barColorPrimary": {
              bgcolor: "primary.400",
            },
          }}
          variant="determinate"
          value={30}
        />
      </Stack> */}

      {/* Commission */}
      <Stack
        direction="row"
        gap="0.75rem"
        mx="auto"
        mt="1rem"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
          px: { md: "1.5rem" },
        }}
      >
        <Stack
          p="0.75rem"
          border={1}
          borderColor="colorfulGray.A100"
          width="100%"
          borderRadius="0.625rem"
          minWidth="15rem"
          gap="0.75rem"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb="1.5rem"
          >
            <Typography variant="Body" color="colorfulGray.700">
              کارمزد در بازار تتری
            </Typography>
            <Stack
              component="span"
              px="0.75rem"
              py="0.3rem"
              bgcolor="primary.A100"
              borderRadius="6.25rem"
            >
              <Typography variant="CaptionMedium" color="primary.600">
                {user?.levelInfo[0].name}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body" color="colorfulGray.700">
              کارمزد سفارش گذار
            </Typography>
            <Typography variant="BodyBold" color="colorfulGray.700">
              {Number(((user?.levelInfo[0]?.makerWage ?? 0) * 100).toFixed(10))}
              %
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body" color="colorfulGray.700">
              کارمزد سفارش بردار
            </Typography>
            <Typography variant="BodyBold" color="colorfulGray.700">
              {Number(
                ((user?.levelInfo?.[0]?.takerWage ?? 0) * 100).toFixed(10)
              )}
              %
            </Typography>
          </Stack>
        </Stack>
        <Stack
          p="0.75rem"
          border={1}
          borderColor="colorfulGray.A100"
          width="100%"
          borderRadius="0.625rem"
          minWidth="15rem"
          gap="0.75rem"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            mb="1.5rem"
          >
            <Typography variant="Body" color="colorfulGray.700">
              کارمزد در بازار تومانی
            </Typography>
            <Stack
              component="span"
              px="0.75rem"
              py="0.3rem"
              bgcolor="primary.A100"
              borderRadius="6.25rem"
            >
              <Typography variant="CaptionMedium" color="primary.600">
                {user?.levelInfo[1].name}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body" color="colorfulGray.700">
              کارمزد سفارش گذار
            </Typography>
            <Typography variant="BodyBold" color="colorfulGray.700">
              {Number(((user?.levelInfo[1]?.makerWage ?? 0) * 100).toFixed(10))}
              %
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="Body" color="colorfulGray.700">
              کارمزد سفارش بردار
            </Typography>
            <Typography variant="BodyBold" color="colorfulGray.700">
              {Number(
                ((user?.levelInfo?.[1]?.takerWage ?? 0) * 100).toFixed(10)
              )}
              %
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      {/* Footer */}
      {/* <Typography variant="Body" color="colorfulGray.700" textAlign="center">
        برای اطلاعات بیشتر به صفحه
        <Typography
          variant="BodyMedium"
          component="span"
          display="inline-block"
          px="0.25rem"
          color="primary.500"
        >
          کارمزد‌ها
        </Typography>
        سر بزنید
      </Typography> */}
    </Stack>
  );

  if (modalMode)
    return (
      <Modal onClose={onClose} title="کارمزد معاملات" open={true}>
        <Stack px="0.75rem" gap="1.5rem">
          {renderComponent()}

          <Button onClick={onClose} variant="contained">
            <Typography variant="BodyMedium">متوجه شدم</Typography>
          </Button>
        </Stack>
      </Modal>
    );

  return renderComponent();
};
