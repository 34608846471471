import { useProfile } from "@services/queries/auth/queries";
import { useEffect } from "react";
import AuthLayout from "../_components/auth-layout";
import { SignUpForm } from "./_components/signup-form";
import { useNavigate } from "react-router-dom";
import { detectEnv } from "@utils/common";

export default function LoginPage() {
  const profile = useProfile();
  const navigate = useNavigate();

  useEffect(() => {
    if (profile?.isSuccess) {
      navigate("/v2/dashboard");
      if (!profile?.isPending && detectEnv() === "WebView") {
        window?.ReactNativeWebView?.postMessage(
          JSON.stringify({ action: "finishedWhoAmI" })
        );
      }
    }
  }, [profile?.isPending]);

  return (
    <>
      <AuthLayout>
        <SignUpForm />
      </AuthLayout>
    </>
  );
}
