import { ContainerProps, Container as MuiContainer } from "@mui/material";

interface IContainerProps extends ContainerProps {
  isNoPaddingMode?: boolean;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}

const Container = ({
  isNoPaddingMode,
  maxWidth = "lg",
  children,
  sx,
  ...props
}: IContainerProps) => {
  return (
    <MuiContainer
      sx={isNoPaddingMode ? { px: { xs: 0, md: "1rem" }, ...sx } : { ...sx }}
      maxWidth={maxWidth}
      {...props}
    >
      {children}
    </MuiContainer>
  );
};

export default Container;
