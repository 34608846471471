import { useGoftino } from "@services/goftino";
import { initGA } from "@services/google-analytics";
import { useEffect, useLayoutEffect } from "react";
import { AppRouter } from "@router/index";

// Styles
import "swiper/css";
import { useLocation } from "react-router-dom";
import { useSentry } from "@services/sentry";
import { detectEnv } from "@utils/common";
import useUTMService from "@services/utm";

function App() {
  useGoftino();
  import.meta.env.PROD && useSentry();

  useUTMService();
  useEffect(() => {
    detectEnv();
    // initializeFirebase();
    initGA();
    // initGTM();
  }, []);

  const location = useLocation();

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  return <AppRouter />;
}

export default App;
