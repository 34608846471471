import { SwitchTab } from "@components/common/switch-tab";
import { Wrapper } from "@components/layout/wrapper";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { listTabs } from "../../_constants";
import { IconTypography } from "@components/common/icon-typography";
import { GiftIcon } from "@assets/icons/GiftIcon";
import { Typography } from "@components/common/typography";
import { TextField } from "@components/common/textfield";
import { Button } from "@components/common/button";
import { useCheckGiftCodeMutation } from "@services/queries/gift/mutations";
import { GiftRedeemSchema } from "../../_validations";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "@utils/toast";
import { useState } from "react";
import { Modal } from "@components/common/modal";
import { Verify, Wallet } from "iconsax-react";

export const GiftRedeem = () => {
  const isMdUp = useBreakpoint("md");
  const navigate = useNavigate();
  const theme = useTheme();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const checkGiftCodeMutation = useCheckGiftCodeMutation();

  const {
    register,
    formState: { isValid, errors },
    handleSubmit,
  } = useForm<z.output<typeof GiftRedeemSchema>>({
    mode: "all",
    resolver: zodResolver(GiftRedeemSchema),
  });
  GiftRedeemSchema;

  const onSubmit = ({ code }: z.output<typeof GiftRedeemSchema>) => {
    if (!code) return toast.error("کدی وارد نشده است.");

    checkGiftCodeMutation.mutate(code, {
      onSuccess: () => setIsSuccessModalOpen(true),
    });
  };

  return (
    <Wrapper
      sxContainer={{
        p: "1.5rem",
        flex: 1,
        bgcolor: { xs: "white", md: "transparent" },
      }}
      isFullWidth={!isMdUp}
      isScreenHeight
      title="دریافت هدیه"
      backMode
    >
      <Stack
        sx={{
          px: { xs: 0, md: "1.5rem" },
          py: { xs: 0, md: "3.75rem" },
          bgcolor: "white",
          borderRadius: { md: "1.125rem" },
        }}
      >
        <Stack
          maxWidth="350px"
          sx={{ gap: { xs: "2.75rem", md: "2rem" } }}
          mx="auto"
        >
          {/* <SwitchTab
            sx={{ width: "100%", display: { xs: "flex", md: "none" } }}
            onChange={(data) =>
              navigate(
                `/v2/dashboard/gift/${data?.value?.toString()?.toLowerCase()}`
              )
            }
            defaultTab={{
              label: "دریافت هدیه",
              value: "REDEEM",
            }}
            list={listTabs}
          /> */}

          <Stack alignItems="center" sx={{ gap: "1.5rem" }}>
            <Typography
              variant="HeadlineBold"
              smVariant="ExtendedBodyBold"
              textAlign="center"
              color="primary.600"
            >
              هدیه‌ات رو دریافت کن!
            </Typography>
            <IconTypography
              width={isMdUp ? "9rem" : "6rem"}
              bgColor="colorfulGray.A100"
              icon={
                <GiftIcon
                  sx={{
                    position: "absolute",
                    fontSize: { xs: "6.5rem", md: "9.5rem" },
                  }}
                />
              }
              title={
                <Typography
                  variant="ExtendedBody"
                  smVariant="Caption"
                  sx={{ maxWidth: { xs: "300px", md: "unset" } }}
                  textAlign="center"
                  color="colorfulGray.700"
                >
                  کد نوشته روی کارت هدیه رو در باکس زیر وارد کن و هدیه‌ات رو به
                  کیف پولت منتقل کن
                </Typography>
              }
            />
          </Stack>

          <Stack gap="1rem" component="form" onSubmit={handleSubmit(onSubmit)}>
            <TextField
              {...register("code")}
              inputProps={{ maxLength: 12 }}
              placeholder=""
              label="کد هدیه"
              error={!!errors?.code?.message}
              helperText={errors?.code?.message}
            />
            <Stack>
              <Typography color="colorfulGray.700" variant="Caption">
                موجودی هدیه به صورت خودکار به کیف‌پولتان منتقل می‌شود.
              </Typography>
              <Typography color="colorfulGray.700" variant="Caption">
                کد هدیه یکبار مصرف است و بعد از یکبار استفاده دیگر معتبر نیست.
              </Typography>
            </Stack>
            <Button
              type="submit"
              loading={checkGiftCodeMutation.isPending}
              disabled={!isValid}
            >
              <Typography variant="BodyBold">دریافت هدیه</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>

      <Modal
        open={isSuccessModalOpen}
        title="دریافت هدیه"
        onClose={() => setIsSuccessModalOpen(false)}
      >
        <Stack width="100%" gap="1.5rem" maxWidth="350px">
          <IconTypography
            bgColor="success.A100"
            icon={
              <Verify
                color={theme?.palette?.success?.[700]}
                variant="Bold"
                size="2rem"
              />
            }
            title={
              <Typography
                color="success.900"
                variant="ExtendedBody"
                smVariant="Body"
                textAlign="center"
              >
                کارت هدیه‌تان به کیف پولتان منتقل شد
              </Typography>
            }
          />

          <Button
            fullWidth
            variant="outlined"
            startIcon={<Wallet variant="Outline" size="1.25rem" />}
            onClick={() => {
              setIsSuccessModalOpen(false);
              navigate("/v2/dashboard/wallet");
            }}
          >
            <Typography variant="BodyMedium">رفتن به کیف پول</Typography>
          </Button>
        </Stack>
      </Modal>
    </Wrapper>
  );
};
