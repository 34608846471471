import React from "react";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { List, ListItem, Stack } from "@mui/material";

interface TermsModalProps {
  onClose: () => void;
  open: boolean;
}

const TermsModal = ({ open, onClose }: TermsModalProps) => {
  return (
    <Modal open={open} onClose={onClose} title="قوانین و مقررارت">
      <Stack
        sx={{
          overflowY: "auto",
          width: "100%",
          height: "85dvh",
          maxWidth: "700px",
        }}
      >
        <Typography>
          شرکت حامی فناوران تیسا (مسئولیت محدود) به شناسه ملی ۱۴۰۱۰۸۲۸۲۱۷ در این
          سند به بیان ضوابط و مقررات استفاده از خدمات بالینکس می‌پردازد. بالینکس
          یک صرافی آنلاین است که با هدف ارائه خدمات در حوزه رمز ارز به کاربران
          طراحی شده و برابر با شرایط و ضوابط ذیل نسبت به ارائه خدمات به کاربران
          اقدام می‌نماید. شرایط و قوانین حاضر به‌عنوان یک قرارداد قطعی و
          لازم‌الاجرا با رعایت مواد ۱۰ و ۲۱۹ قانون مدنی بر روابط بین کاربران و
          شرکت حاکم خواهد بود. ثبت نام کاربران در سایت/اپلیکیشن بالینکس به منزله
          پذیرش و قبول تمامی مفاد این سند است، لذا ضروری‌ست پیش از ثبت نام و
          استفاده از خدمات شرکت، این شرایط و ضوابط را به دقت مطالعه فرمایید.
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>ماده یک) اصطلاحات و تعاریف</Typography>
        </Stack>
        <Typography>
          واژگان و اصطلاحاتی که در شرایط و قوانین حاضر مورد استفاده قرار
          گرفته‌اند، دارای معانی به شرح ذیل می‌باشند:
        </Typography>
        <Typography>
          ۱) شرکت: منظور شرکت حامی فناوران تیسا (مسئولیت محدود) به شناسه ملی
          ۱۴۰۱۰۸۲۸۲۱۷ است.
        </Typography>
        <Typography>
          ۲) بالینکس: منظور وبسایت بالینکس به آدرس https://www.balinex.com است
          که مالکیت آن تماماً متعلق به شرکت بوده و شرکت از طریق آن به ارائه
          خدمات می‌پردازد. بالینکس یک سامانه تبادل دارایی‌های دیجیتال است و
          امکان خرید و فروش رمزارز را برای کاربر فراهم می‌کند.
        </Typography>
        <Typography>
          ۳) کاربر: شخصی حقیقی است که با وارد نمودن شماره همراه خود و قبول مفاد
          این سند در وبسایت بالینکس ثبت نام کرده است.
        </Typography>
        <Typography>
          ۴) حساب کاربری: منظور حسابی است که کاربر با وارد نمودن شماره همراه خود
          و ثبت نام در بالینکس ایجاد می‌نماید.
        </Typography>
        <Typography>
          ۵) رمزارز: کریپتوکارنسی یا رمزارز به دسته‌ای از پول و ارزهای دیجیتال
          گفته می‌شود که در آن‌ها از رمزنگاری (کریپتوگرافی و کدگذاری شده) برای
          تراکنش‌های مالی استفاده شده ‌است.
        </Typography>
        <Typography>
          ۶) رمزارز پذیرفته‌شده: منظور رمزارزی است که در بالینکس لیست شده و مورد
          پذیرش شرکت برای خرید و فروش می‌باشد.
        </Typography>
        <Typography>
          ۷) کیف پول: کیف پول برنامهای‌ نرم‌افزاریست که محل ذخیره‌سازی، واریز و
          برداشت ارز در شبکه زنجیره بلاکچین است.
        </Typography>
        <Typography>
          ۸) کد ریفرال/ کد معرف: کدی است که کاربر می تواند با استفاده از آن شخص
          یا اشخاص دیگری را برای ایجاد حساب کاربری به بالینکس دعوت کند.
        </Typography>
        <Typography>
          ۹) فورس ماژور: منظور از فورس ماژور، وقوع هرگونه حادثه خارجی، غیر قابل
          دفع و غیر قابل پیش‌بینی است که مستند به فعل یا ترک فعل هیچ‌یک از طرفین
          (شرکت و کاربر) نباشد. از جمله مصادیق آن می‌توان به سیل، زلزله،
          آتش‌سوزی، جنگ، اعتصاب، بیماری‌های واگیردار، قطعی سراسری اینترنت، بروز
          اختلالات در شبکه‌های بالادست و زیرساختی از جمله شرکت‌های خدمات‌دهنده
          سرور، اینترنت ایران و یا اینترنت جهانی قطع سراسری برق و یا دیگر حوادث
          این چنینی اشاره نمود.
        </Typography>
        <Typography>
          ۱۰) دارایی‌های فکری: دارایی‌های فکری مانند متون، طرح‌های گرافیکی، عکس،
          علائم تجاری، لوگو، کد‌های نرم‌افزار، اطلاعات مشترکان، محتوای بالینکس،
          ساختار و نحوه بیان و تنظیم مطالب است که متعلق به شرکت می‌باشد
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>ماده دو) مقررات عمومی</Typography>
        </Stack>
        <Typography>
          ۱) بالینکس با پایبندی کامل به قوانین و مقررات جمهوری اسلامی ایران
          فعالیت می‌نماید. در صورت تخلف کاربر از قوانین جمهوری اسلامی ایران،
          تمامی مسئولیت آن بر عهده وی بوده و بالینکس مجاز به مسدود نمودن حساب
          کاربری فرد متخلف می‌باشد.
        </Typography>
        <Typography>
          ۲) هیچ گونه تبادل ارزی اعم از خرید و فروش دلار یا سایر پول یا ارزهای
          کاغذی در بستر بالینکس صورت نمی‌گیرد.
        </Typography>
        <Typography>
          ۳) کاربر با ثبت ‌نام و ساخت حساب کاربری در بالینکس می‌پذیرد که شرایط و
          قوانین این سند را به صورت کامل و دقیق مطالعه نموده و با اراده آزاد آن
          را قبول کرده است. لذا در آینده هر گونه اعتراض و یا ایرادی از سوی کاربر
          در این خصوص بالا اعتبار است.
        </Typography>
        <Typography>
          تبصره: هرگونه،ویدئو، علامت، عدد، کلمه، تصویر یا نشان الکترونیک که
          مثبِت هویت امضاکننده باشد و به این سند یا سایر اسناد صادره در این
          زمینه ملحق شود به‌منزله امضای الکترونیک و همانند امضای دست‌نویس واجد
          اعتبار خواهد بود و امضاکننده را به مفاد سند ملتزم می‌‌نماید.
        </Typography>
        <Typography>
          ۴) شرکت، خدمات ذیل را به کاربران ارائه می‌دهد:
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
            disablePadding
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              ارائه بستر خرید و فروش دارایی‌های دیجیتال میان کاربران در بازارهای
              اسپات و پیشرفته.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              تبدیل یک رمز ارز پذیرفته شده به تتر یا تومان و برعکس با نرخ تبدیل
              معین و کارمزد معین و حداقل و حداکثر معین
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              فروش رمز ارز پذیرفته شده به کاربر و دریافت مبلغ معینی وجه رایج از
              وی در بازار OTC (تبدیل سریع).
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              خرید رمز ارز پذیرفته شده از کاربر و پرداخت مبلغ معینی وجه رایج به
              وی در بازار اسپات .
            </ListItem>
          </List>
        </Typography>
        <Typography>
          ۵) نرخ تبدیل رمز ارزهای مختلف توسط بالینکس تعیین می‌شوند و در روی سایت
          توسط کلیه کابران قابل مشاهده است.
        </Typography>
        <Typography>
          ۶) نرخ خرید و فروش رمز ارزها در بازارهای اسپات بالینکس توسط کاربران
          مشخص می شود.
        </Typography>
        <Typography>
          ۷) هرگونه کارمزد، کمیسیون و هزینه دیگر که سیستم بلاکچین و یا رمز ارزها
          به کاربر تحمیل نماید، فقط بر عهده کاربر خواهد بود و کاربر موظف به
          بررسی این موارد قبل از سفارش می باشد و شرکت هیچ گونه مسئولیتی راجع به
          این گونه هزینه ها بر عهده ندارد.
        </Typography>
        <Typography>
          ۸) انتخاب رمزارزها برای لیست شدن و پذیرفته شدن در بالینکس، حسب صلاحدید
          و تصمیم شرکت صورت میپذیرد. شرکت مجاز و محق می باشد که در هر زمانی لیست
          رمزارزهای پذیرفته شده را کاهش یا افزایش دهد.
        </Typography>
        <Typography>
          ۹) بالینکس هیچگونه ارتباطی با سایت های قمار و شرط بندی ندارد و در صورت
          استفاده کاربران از خدمات بالینکس برای این گونه سایت ها، حساب کاربری
          ایشان مسدود و پیگیری قانونی از سوی شرکت صورت خواهد گرفت.
        </Typography>
        <Typography>
          ۱۰) در صورتی که بر اثر فعالیت های غیر قانونی یا خرابکارانه کاربران به
          شرکت، محصولات آن و یا کاربران آن زیانی وارد شود، شرکت می تواند از
          مجاری قانونی نسبت به جبران زیان وارد شده اقدام نماید.
        </Typography>
        <Typography>
          ۱۱) کلیه دارایی فکری متصور در خصوص بالینکس انحصاراً و بدون هیچ‌گونه
          قید و شرطی در مالکیت شرکت بوده و حق انحصاری بهره‌برداری از آن متعلق به
          شرکت است. هر گونه نقض این مالکیت موجب پیگرد حقوقی و کیفری خواهد بود.
        </Typography>
        <Typography>
          ۱۲) در صورت حدوث فورس‌ماژور و یا وقوع اتفاقاتی از قبیل تعلیق خدمات به
          علت اقدام غیر قانونی کاربران یا اشخاص ثالث، شرکت مسئولیتی بر عهده
          نخواهد داشت.
        </Typography>
        <Typography>
          ۱۳) در صورت وقوع خطا در خدمات بالینکس، تیم بالینکس تمام تلاش خود را
          جهت رفع مشکل و از سرگیری خدمات خواهد کرد. با این حال در صورتی که خطا
          منتسب به بالینکس نباشد شرکت مسئولیتی نخواهد داشت.
        </Typography>
        <Typography>
          ۱۴) در صورت شناسایی و تشخیص رفتار غیرمتعارف و یا غیرواقعی و یا
          غیرقانونی کاربر در رابطه با فعالیت در بالینکس، شرکت ضمن اخطار به
          کاربر، نسبت به مسدود سازی حساب وی اقدام می نماید. رفع مسدودی حساب
          کاربری، تنها با احراز هویت مجدد و فعالیت صحیح کاربر بنا به تشخیص شرکت
          انجام می گردد. بدیهی است در صورتی که رفتار مذکور غیر قانونی باشد، شرکت
          حق پیگیری حقوقی و قانونی موضوع را خواهد داشت.
        </Typography>
        <Typography>
          ۱۵) بالینکس جهت حفاظت از دارایی کاربران، پروتکل‌های ذیل را اتخاذ
          می‌نماید:
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
            disablePadding
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              دسترسی به قابلیت های کلید سامانه مانند ورود به سامانه، برداشت از
              کیف پول و تغییر رمز عبور، به فرآیند تأیید دو مرحله‌ای (Two-Factor
              Authentication)
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              تمامی اطلاعات مبادله شده بین کاربر و سامانه با استفاده از
              پروتکل‌های SSL رمزنگاری می‌شوند، به‌گونه‌ای که هیچ امکانی برای
              سرقت اطلاعات وجود ندارد.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              تمام داده‌های مهم و مربوط به هویت کاربران به صورت رمزنگاری شده
              ذخیره می‌شوند، به طوری که در صورت هرگونه نفوذ غیرمجاز به سرور،
              نفوذگر قادر به سوءاستفاده از اطلاعات نخواهد بود.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              دریافت مجوز از تمامی نهادهای نظارتی معتبر از جمله سازمان نظام صنفی
              رایانه‌ای کشور
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              همکاری با پلیس فتا و سایر نهادهای نظارتی
            </ListItem>
          </List>
        </Typography>
        <Typography>
          ۱۶) براساس قوانین بالینکس، مسئولیت ارسال هرگونه رمزارز که توسط بالینکس
          پشتیبانی نمی‌شود با خود کاربر بوده و زمان بازیابی آن نیز مشخص نیست.
          ممکن است این عملیات تا ماه‌ها به طول انجامد و بالینکس در قبال بازیابی
          توکن‌هایی که کاربران به اشتباه به این پلتفرم ارسال نموده‌اند هیچ گونه
          مسئولیتی نخواهد داشت.
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>
            ماده سه) شرایط استفاده از بالینکس برای کاربران
          </Typography>
        </Stack>
        <Typography>
          ۱) حداقل سن برای ثبت نام، احراز هویت و استفاده از خدمات شرکت 18 سال
          تمام می باشد. کاربر باید واجد اهلیت قانونی باشد.
        </Typography>
        <Typography>
          ۲) برای ثبت نام و ساختن حساب کاربری، طی نمودن مراحل احراز هویت در
          بالینکس ضروری است. در صورت وجود هرگونه تخلف در احراز هویت، مسئولیت آن
          تماماً برعهده کاربر متخلف است. این حق برای شرکت محفوظ است که در صورت
          شک در هویت کاربر برای تصمیم گیری نهایی، اسناد و مدارک مازادی را از وی
          حتی پس از فعال سازی حساب کاربری درخواست نماید.
        </Typography>
        <Typography>
          ۳) مراحل تایید هویت کاربران در بالینکس در سه سطح کاربری انجام می‌شود:
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
            disablePadding
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              احراز نشده: در این مرحله کاربر شماره موبایل به نام خود را وارد می
              کند.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              احراز هویت پایه: با وارد کردن نام و نام خانوادگی، کدملی و تاریخ
              تولد اطلاعات هویتی کابران با ثبت احوال مطابقت داده می شود، لازم
              است مشخصات وارد شده عینا مشابه کارت ملی باشد.
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              احراز آدرس محل سکونت
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              احراز هویت ویژه: به منظور برداشت دارایی ها (رمز ارزها یا ریالی)،
              ارسال کارت ملی جدید و یا تصویر صفحه اول شناسنامه جدید وضبط وارسال
              ویدیو 15 ثانیه که در آن کاربر اعلام می‌کند که شرایط و ضوابط سایت
              بالینکس رو مطالعه و پذیرفته است. برای برداشت و تایید از سمت
              پشتیبانی الزامیست. همچنین در این مرحله کاربران باید آدرس خود را
              وارد نمایند و در صورت نیاز به مکاتبات پستی مبنا همین آدرس قرار می
              گیرد، در صورت تغییر در آدرس محل سکونت می‌بایستی این قسمت تغییر
              یابد؛ در غیر اینصورت مسئولیت عدم دریافت مکاتبات با کاربر می‌باشد.
            </ListItem>
          </List>
        </Typography>
        <Typography>
          ۴) در صورت بروز هرگونه ابهام یا مشکل در معاملات و یا نقض قوانین در
          معاملات کاربران، شرکت مجاز و محق است آن معامله را باطل کرده و دارایی
          هر یک از طرفین معامله را به حساب خود بازگرداند.
        </Typography>
        <Typography>
          ۵) در صورتی که در نتیجه فعالیت غیرقانونی کاربر خسارتی به شرکت وارد شود
          کاربر مسئول جبران کلیه خسارات وارده است. همچنین شرکت می‌تواند حساب
          کاربری کاربر را نیز مسدود سازد.
        </Typography>
        <Typography>
          ۶) حساب کاربری پس از تایید مدارک از سوی شرکت برای دریافت خدمات کامل،
          فعال می شود.
        </Typography>
        <Typography>
          ۷) احراز هویت برای استفاده از خدمات بالینکس ضروری بوده و کاربران
          موظفند اطلاعات صحیح خود را بارگذاری نمایند. بدیهی است در صورت وجود
          هرگونه تخلف در فرایند احراز هویت، مسئولیت به عهده‌ی شخص متخلف بوده و
          بالینکس حق توقف ارائه خدمات به کاربر مذبور و پیگیری حقوقی موضوع را
          خواهد داشت.
        </Typography>
        <Typography>
          ۸) سطوح احراز هویت در بالینکس متفاوت است و کاربر با تکمیل هر یک از
          سطوح احراز هویت به امکانات و خدمات بیشتری دسترسی پیدا خواهد کرد. کاربر
          می‌تواند مدارک لازم و ویژگی‌های سطوح کاربری یا سطوح مختلف احراز هویت
          را در پلتفرم بالینکس مشاهده نماید.
        </Typography>
        <Typography>
          ۹) حساب کاربری قائم به شخص بوده و قرار دادن حساب کاربری خود در اختیار
          اشخاص دیگر در هر قالبی (حساب بانکی اجاره ای، اطلاعات کاربری و...)
          ممنوع می باشد. در صورت احراز این موارد حساب کاربری فرد خاطی مسدود شده
          و هرگونه عواقب ناشی از این امر بر عهده کاربر می باشد.
        </Typography>
        <Typography>
          ۱۰) شماره حساب اعلام شده به سایت و همچنین آدرس کیف پول ها جهت برداشت
          رمزارز می بایست متعلق به کاربری که ثبت نام و احراز هویت کرده بوده باشد
          و کاربران مجاز به دادن آدرس کیف پول متعلق به اشخاص دیگر نخواهند بود.
        </Typography>
        <Typography>
          ۱۱) تصمیم راجع به موفق یا ناموفق بودن احراز هویت فقط در اختیار شرکت
          خواهد بود و شرکت می تواند یک احراز هویت را به تشخیص خود قبول یا رد
          نماید.
        </Typography>
        <Typography>
          ۱۲) کاربر موظف است قبل از ورود به درگاه و انجام هرگونه تراکنش، پیش
          فاکتور ارائه شده را با دقت مطالعه کند و با آگاهی کامل سفارش و قوانین
          را تایید و وارد درگاه شود. در صورت اعتراض کاربر به کسر کارمزد یا
          ناآگاهی از میزان آن، این دست از ادعاها/شکایات مورد پذیرش شرکت نبوده و
          شرکت هیچ مسئولیتی در این باره ندارد.
        </Typography>
        <Typography>
          ۱۳) در صورت هرگونه اشتباه کاربر در ثبت اطلاعات مربوط به تبادل صورت
          گرفته یا اطلاعات مربوط به کیف پول، امکان لغو، برگشت وجه یا ارز امکان
          پذیر نبوده و شرکت در خصوص اشتباهات یا سهل انگاری کاربران هیچگونه
          مسئولیتی بر عهده ندارد.
        </Typography>
        <Typography>
          ۱۴) کاربر تایید می نماید که از ریسک‌های مربوط به سرمایه‌گذاری در حوزه
          دارایی های دیجیتال مطلع بوده و با علم به این موضوع اقدام به معامله
          می‌نماید و شرکت مسئولیتی در خصوص سود و زیان حاصل از معاملات او در
          بالینکس ندارد.
        </Typography>
        <Typography>
          ۱۵) به منظور امنیت و صحت تراکنش ها، استفاده از هرگونه ابزار غیر مجاز،
          ربات و فعالیت ترید مشکوک به هر نحوی که برای بالینکس محرز شود، ممنوع می
          باشد. در صورت استفاده کاربر از این موارد و تشخیص شرکت، تمامی تراکنش
          های مربوط به این عملیات، غیر قابل قبول و لغو خواهند شد و فقط اصل
          دارایی ورودی کاربر (در صورت منفی نشدن دارایی) به ایشان واریز و حساب
          ایشان غیر فعال می شود. کاربر موظف است در صورت منفی شدن دارایی در حساب
          کاربری، نسبت به جبران و تسویه اقدام نماید.
        </Typography>
        <Typography>
          ۱۶) در صورت فوت یا حجر کاربر، بالینکس این حق را دارد که به محض اطلاع
          از آن نسبت به واریز وجوه ریالی کاربر نزد شرکت، به حساب اعلام شده ایشان
          در زمان ثبت نام اقدام نموده و در خصوص دارائی رمز ارز نیز این حق و
          اختیار به شرکت واگذار گردید که نسبت به تبدیل آن به ریال معادل قیمت روز
          و واریز آن به حساب فوق اقدام نماید. گفتنی است که ملاک زمانی محاسبه
          قیمت فروش دارائی ارز دیجیتال کاربر، براساس ارائه اسناد مثبته توسط
          ذینفع و تایید اصالت آن توسط واحد حقوقی بالینکس است. کاربر با علم به
          مقررات این بند نسبت به افتتاح حساب کاربری در بالینکس اقدام نموده و
          کاربر، اشخاص ثالث، ورثه یا قائم مقام قانونی وی حق اعتراض و یا ادعایی
          در آینده در این خصوص را نخواهند داشت.
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>
            ماده چهار) مقررات مربوط به انجام معامله
          </Typography>
        </Stack>
        <Typography>
          ۱) به منظور جلوگیری از هرگونه تخلف در زمینه مالی و بانکی، مسئولیت
          انجام هر یک از تراکنش ها، بر عهده شخص کاربر می باشد. فرض بر این است که
          کاربر از امنیت حساب خود اطمینان دارد و اطلاعات حساب کاربری خود را در
          اختیار دیگران قرار نمی دهد.
        </Typography>
        <Typography>
          ۲) کارمزد دریافتی از سوی شرکت نهایی و قطعی بوده و در هیچ صورتی به
          کاربر عودت داده نمی شود.
        </Typography>
        <Typography>
          ۳) درخواست برداشت ریال از حساب کاربری در سریع‌ترین زمان ممکن پس از ثبت
          درخواست، مورد بررسی قرار خواهد گرفت. زمان واریز پول به حساب کاربران بر
          اساس محدودیت‌های انتقال وجه بین بانکی، متفاوت خواهد بود.
        </Typography>
        <Typography>
          ۴) اگر شرکت تحت هر عنوان سهواً و یا من غیر حق، وجوه یا رمزارزی را به
          حساب کاربر منظور یا در محاسبات خود یا نرخ ارائه شده هر نوع اشتباهی
          نماید، کاربر این اجازه را با قبول شرایط و قوانین به شرکت می دهد که در
          هر زمان مجاز و مختار است راساً و مستقلاً و بدون انجام هیچ گونه تشریفات
          اداری و یا قضائی و بدون نیاز به دریافت اجازه مجدد از کاربر، در راستای
          رفع اشتباه و برداشت از حساب‌های وی اقدام نماید و تشخیص شرکت نسبت به
          وقوع اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب معتبر خواهد بود و
          کاربر حق هرگونه اعتراض و ادعایی را در خصوص نحوه عملکرد شرکت از هر جهت
          از خود ساقط می نماید.
        </Typography>
        <Typography>
          ۵) بنا به دستور نهادهای قضایی مانند پلیس فتا، ممکن است بر حسب نیاز
          برداشت رمزارز یا ریال به مدت 24 تا 72 ساعت کاری پس از زمان واریز از
          طریق درگاه شتابی، محدود گردد.
        </Typography>
        <Typography>
          ۶) اگر بالینکس تحت هر عنوان اشتباهاً یا من غیر حق، وجوه یا دارایی
          دیجیتالی را به حساب کاربر منظور یا در محاسبات خود هر نوع اشتباهی
          نماید، هر زمان مجاز و مختار است که مستقلاً و بدون انجام هیچ گونه
          تشریفات اداری و قضائی و بدون نیاز به دریافت اجازه کتبی از صاحب حساب
          نسبت به رفع اشتباه و برداشت از حساب‌ ایشان اقدام نماید. تشخیص وقوع
          اشتباه یا پرداخت بدون حق و لزوم برداشت از حساب با صلاحدید شرکت خواهد
          بود.
        </Typography>
        <Typography>
          ۷) در صورت بروز هرگونه مشکل یا ابهام در هر یک از معاملات، شرکت حامی
          فناوران تیسا (بالینکس) حق دارد مستقلاً آن معامله را ابطال و دارایی‌های
          هر یک از طرفین را به حساب خودشان بازگرداند و کاربر حق هیچگونه اعتراض و
          ادعایی را در خصوص نحوه عملکرد بالینکس نخواهد داشت.
        </Typography>
        <Typography>
          ۸) پایین بودن عمق بازار در مارکت‌های کوچک ممکن است باعث ‌شود گاهی
          اوقات، قیمت معاملات در یک بازار، بیشتر یا کمتر از قیمت واقعی رمزارزها
          نشان داده شود. این موضوع به این دلیل است که برخی کاربران به خاطر
          ناآشنا بودن با بازار رمزارزها، سفارش‌های خود را با قیمت اشتباه ثبت
          می‌کنند. به علاوه در بسیاری از موارد، کاربران به خاطر تفکیک نکردن
          سفارش محدود از سفارش حد ضرر یا ثبت قیمت اشتباه، باعث ایجاد تغییرات
          قیمت غیرمنطقی و به اصطلاح رنج شدید قیمتی می‌شوند که این مسئله در نهایت
          به ضرر این افراد تمام خواهد شد. با وجود هشدارهای بالینکس در زمان تعیین
          قیمت غیرمتعارف، ارسال سفارش با قیمت اشتباه به صورت مکرر توسط کاربران
          اتفاق می‌افتد. به همین خاطر گزینه لغو معاملات یا سفارشات از سوی
          بالینکس در نظر گرفته شده است. به عبارت دیگر، بر اساس تعداد معاملات
          انجام شده در رنج قیمت مورد نظر، حجم معاملات و اختلاف قیمت ایجاد شده با
          قیمت لحظه‌ای بازار، ممکن است همه یا بخشی از معاملات انجام شده لغو شود.
          البته این امکان وجود دارد که هیچ‌یک از این معاملات لغو نشود. با این
          ویژگی احتمال بازگشت قسمتی از ضرر و زیان ناشی از ارسال سفارش غیرمتعارف
          به فردی که اشتباه کرده وجود دارد و چون تغییرات قیمت واقعی نبوده و
          معامله صرفاً به دلیل اشتباه در قیمت‌گذاری انجام شده، افرادی که با
          قیمت‌های غیرواقعی معامله‌ کرده‌اند در این مورد محق نیستند. کاربر
          می‌پذیرد که بالینکس برای رعایت عدالت در معاملات، مرجع رسیدگی و
          تصمیم‌گیری در این‌باره خواهد بود. از آنجا که معاملات لغو شده تأثیر خود
          را در نمودار خواهند گذاشت و لغو معاملات به هیچ عنوان باعث اصلاح آن
          نخواهد شد، به‌همین‌ خاطر در نمودار شمعی نمایش داده می‌شود و سایه‌های
          بلندی شکل می‌گیرد که صرفا به دلایل توضیح داده شده است. بر همین اساس
          نمودار بالینکس مرجع نمایش قیمت واقعی معاملات نیست. قوانین لغو سفارش در
          قیمت غیرمتعارف با افزایش عمق بازار و سطح دانش کاربری لغو خواهد شد.
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>ماده پنج) سلب مسئولیت شرکت</Typography>
        </Stack>
        <Typography>
          ۱) هر گونه پیامدهای مالیاتی ناشی از تراکنش های کاربر با شرکت و یا میان
          کاربران به عهده‌ی خود کاربر خواهد بود و شرکت هیچ گونه مسئولیتی نمی
          پذیرد.
        </Typography>
        <Typography>
          ۲) شرکت هیچ گونه مسئولیتی راجع به تاخیر، زمان بندی سایت مقصد در دریافت
          و یا تراکنش ناموفق ایجاد شده در انجام سفارش به علت نقص یا مشکل یا
          تعمیرات سیستم رمز ارز یا بانک پذیرنده نمی پذیرد.
        </Typography>
        <Typography>
          ۳) کاربر سایت می پذیرد که بالینکس صرفاً واسطه انجام تبادل دارایی های
          دیجیتال یا خرید و فروش رمزارز است و هیچ گونه مسئولیت دیگری ندارد.
        </Typography>
        <Typography>
          ۴) بالینکس در مقابل واریز توکن یا کوین بر بستر یا بلاکچین اشتباه یا
          کوین هایی که در بالینکس پشتیبانی نمی شود هیچ گونه مسئولیتی نداشته و با
          توجه به مکانیسم ذخیره سازی سرد امکان استخراج این توکن ها با استفاده از
          ولت ثالث وجود ندارد. لذا مسئولیت هرگونه واریز اشتباه با خود کاربر بوده
          و کاربر حق هیچ گونه شکایتی را نخواهد داشت.
        </Typography>
        <Typography>
          ۵) در صورت هرگونه سوء استفاده از کد ریفرال (کد معرف) بالینکس، تمامی
          مسئولیت آن به عهده کاربر بوده و شرکت هیچ مسئولیتی در قبال هرگونه سوء
          استفاده از سیستم ریفرال و کد دعوت خود ندارد. در موارد مذکور بالینکس حق
          ابطال کد معرف و مسدودی حساب را خواهد داشت.
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>
            ماده شش) مقررات مسدودی حساب کاربری
          </Typography>
        </Stack>
        <Typography>
          در صورت مشاهده هر کدام از موارد زیر، حساب کاربری کاربر مسدود خواهد شد:
          <List
            sx={{
              listStyleType: "disc",
              listStylePosition: "inside",
            }}
            disablePadding
          >
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              ارایه دستور کتبی از محاکم قضایی و نهادهای دولتی،
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              عدم رعایت شرایط و قوانین این سند،
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              عدم پایبندی به قوانین جمهوری اسلامی ایران،
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              در صورت ورود خسارات ناشی از دسترسی غیر قانونی شخص ثالث به سرور از
              طریق حساب کاربری کاربر،
            </ListItem>
            <ListItem
              sx={{
                display: "list-item",
              }}
            >
              هرگونه رفتار خرابکارانه که موجب ورود خسارت مادی یا معنوی به شرکت
              گردد. تشخیص این امر با صرف صلاحدید شرکت صورت می گیرد.
            </ListItem>
          </List>
        </Typography>
        <Typography>
          در کلیه موارد فوق، حق شرکت برای پیگیری قانونی تخلفات کاربر از مراجع
          ذیصلاح محفوظ است.
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>ماده هفت) حریم خصوصی</Typography>
        </Stack>
        <Typography>
          سند سیاست حریم خصوصی در این خصوص لازم‌الاجراست و جزء لاینفک این
          قرارداد است. برای مشاهده شرایط حریم خصوصی لطفا به بخش مربوطه مراجعه
          فرمایید.
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>ماده هشت) حل و فصل اختلاف</Typography>
        </Stack>
        <Typography>
          در صورت بروز هرگونه اختلاف فیمابین شرکت و کاربر، طرفین حتی الامکان
          موضوع را از طریق مذاکره حل و فصل می‌نمایند. در این خصوص کاربر متعهد به
          همکاری با شرکت و ارائه اطلاعات لازم و عنداللزوم حضور در جلسات حضوری یا
          آنلاین می‌باشد. در صورت عدم حصول توافق از این طریق، هر یک از طرفین
          مختار خواهد بود که به مراجع قضایی ذی‌صلاح مراجعه نماید.
        </Typography>

        <Stack direction="row" gap="0.5rem" alignItems="center" my="1rem">
          <Typography fontWeight={700}>ماده نه) تغییرات سند حاضر</Typography>
        </Stack>
        <Typography></Typography>
        <Typography>
          ۱) شرکت مجاز و محق است که در مفاد این سند در هر زمان تغییراتی را به
          صورت کلی و یا جزئی اعمال نماید. بدیهی ا‌ست این تغییرات از طریق مقتضی
          به اطلاع کاربران خواهد رسید. در صورتی که پس از اطلاع‌رسانی، کاربر به
          استفاده خود از خدمات بالینکس ادامه دهد، این امر به منزله پذیرش کلیه
          تغییرات از سوی او است.
        </Typography>
        <Typography>
          ۲) هرگونه اخطار و اطلاعیه مربوط به بالینکس از جمله اطلاعیه موضوع بند ۱
          همین ماده، به ایمیل و یا شماره تماس اعلامی کاربران هنگام ثبت ‌‌نام
          ارسال و یا از طریق حساب کاربری آنها به آنها اطلاع‌رسانی خواهد شد.
        </Typography>
        <Typography>
          ۳) در مواردی که شرایط و مقررات حاضر، صریحاً یا ضمناً حاوی حکمی نباشد،
          مراتب تابع عرف رایج در این کسب و کار می‌باشد.
        </Typography>
      </Stack>
    </Modal>
  );
};

export default TermsModal;
