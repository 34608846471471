import { Button } from "@components/common/button";
import { CodeInputs } from "@components/common/code-inputs";
import { TextField } from "@components/common/textfield";
import { Typography } from "@components/common/typography";
import { zodResolver } from "@hookform/resolvers/zod";
import { useFilter } from "@hooks/useFilter";
import { useIntervalTimer } from "@hooks/useIntervalTimer";
import { Collapse, IconButton, Stack, SvgIcon } from "@mui/material";
import { useSendMobile, useVerifyOtp } from "@services/queries/auth/mutations";
import { motion } from "framer-motion";
import { ArrowDown2, ArrowLeft, Edit2, Timer1 } from "iconsax-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";
import AuthLayout from "../_components/auth-layout";
import { AuthFormSchema } from "../_validation";

declare global {
  interface Window {
    isLoggedIn: boolean;
    isNewUser: boolean;
  }
}

export default function OTPForm() {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { searchParams } = useFilter();
  const mobile = searchParams.get("mobile");
  const data =
    searchParams.get("data") &&
    JSON.parse(decodeURIComponent(atob(searchParams.get("data") || "{}")));

  const newUser = state?.resData?.newUser || data?.newUser;
  const isTwoAuth =
    state?.resData?.twoFactorAuthEnabled || data?.twoFactorAuthEnabled;
  const expireAt = data?.expireAt;

  const { register, handleSubmit, setValue, getValues, watch } = useForm<
    z.output<typeof AuthFormSchema>
  >({
    mode: "onTouched",
    resolver: zodResolver(AuthFormSchema),
  });

  const { generateTimer, remainedTime } = useIntervalTimer();

  const [isRefererCode, setIsReferer] = useState(newUser);

  useEffect(() => {
    if (!state?.mobile && !mobile) {
      navigate("/v2/login");
    }
  }, [location, state?.mobile]);

  const sendMobileMutation = useSendMobile({
    isSignUpMode: newUser ? true : false,
  });
  const verifyOtpMutation = useVerifyOtp();

  const handleOTPResend = () => {
    sendMobileMutation.mutate(
      { mobile: state?.mobile || mobile },
      {
        onSuccess(data) {
          generateTimer(data?.result?.expireAt || expireAt);
        },
      }
    );
  };

  const getReferralCode = (): string => {
    const referer =
      getValues("referer") ||
      state?.referer ||
      searchParams.get("referer") ||
      "";

    if (referer?.length) {
      return referer;
    }

    return "";
  };

  const isReferralFromLink =
    !!searchParams.get("referer")?.length || state?.referer?.length;

  const handleOTPTrigger = () => {
    verifyOtpMutation.mutate(
      {
        code: getValues("code"),
        mobile: state?.mobile || mobile,
        referralCode: getReferralCode(),
      },
      {
        onSuccess(data) {
          if (isTwoAuth) {
            navigate("/v2/ga", {
              state: {
                mobile: state?.mobile || mobile,
                isTwoAuth,
                token: data?.result?.twoFaLoginToken,
              },
            });
          } else if (newUser) {
            window.isLoggedIn = true;
            window.isNewUser = true;
            navigate("/v2/dashboard/authentication");
          } else {
            window.isLoggedIn = true;
            window.isNewUser = false;
            navigate("/v2/dashboard");
          }
        },
      }
    );
  };

  useEffect(() => {
    if ("OTPCredential" in window) {
      const ac = new AbortController();
      navigator.credentials
        .get({
          // @ts-ignore
          otp: { transport: ["sms"] },
          signal: ac.signal,
        })
        .then((otp) => {
          // @ts-ignore
          const code = (otp as Credential & { code: string })?.code;
          if (!!code && code?.length === 6) {
            setValue("code", code);
          }
        })
        .catch((err) => {
          console.error("Error:", err);
        });

      return () => {
        ac.abort();
      };
    }
  }, []);

  useEffect(() => {
    const code = getValues("code");
    if (!newUser && code?.length === 6) {
      handleOTPTrigger();
    }
  }, [watch("code")]);

  useEffect(() => {
    if (state?.mobile || mobile) {
      generateTimer(state?.resData?.expireAt || expireAt);
    }
  }, [state]);

  const onSubmit = () => {
    handleOTPTrigger();
  };

  return (
    <AuthLayout>
      <Stack
        mx="1.5rem"
        width="100%"
        height="100%"
        justifyContent="space-between"
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Stack>
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
            mt="2.75rem"
          >
            <Typography variant="ExtendedBodyMedium">
              {newUser ? "ثبت نام" : "ورود"}
            </Typography>
            <IconButton
              onClick={() => navigate("/v2/login", { replace: true })}
            >
              <SvgIcon
                sx={{
                  color: (theme) => theme.palette.colorfulGray[500],
                }}
              >
                <ArrowLeft />
              </SvgIcon>
            </IconButton>
          </Stack>
          <Stack
            alignItems="flex-start"
            sx={{
              marginTop: { xs: "2.75rem", md: "2rem" },
            }}
          >
            <Typography variant="BodyMedium">
              کد تایید به شماره {state?.mobile || mobile} ارسال شد
            </Typography>
            <Button
              variant="text"
              onClick={() => navigate("/v2/login", { replace: true })}
              textColor="secondary.500"
              sx={{
                px: 0,
              }}
              startIcon={<Edit2 size="1.25rem" variant="Bold" />}
            >
              <Typography variant="CaptionMedium">ویرایش شماره</Typography>
            </Button>
          </Stack>
          <Stack
            sx={{
              marginTop: { xs: "2.75rem", md: "2rem" },
            }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            height="40px"
          >
            <Typography variant="BodyBold">کد تایید را وارد کنید</Typography>
            <Stack direction="row" gap={0.5} alignItems="center">
              {remainedTime ? (
                <motion.div
                  initial={{ opacity: 0, transform: "translateX(-100px)" }}
                  animate={{ opacity: 1, transform: "translateX(0)" }}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.25rem",
                  }}
                >
                  <Typography pt="4px" variant="BodyMedium">
                    {remainedTime || "00:00"}
                  </Typography>
                  <SvgIcon
                    sx={{
                      color: (theme) => theme.palette.colorfulGray[500],
                    }}
                  >
                    <Timer1 variant="Bold" />
                  </SvgIcon>
                </motion.div>
              ) : (
                <Button
                  variant="text"
                  textColor="primary.500"
                  onClick={handleOTPResend}
                >
                  <Typography variant="BodyMedium">ارسال کد مجدد</Typography>
                </Button>
              )}
            </Stack>
          </Stack>
          <Stack mt="0.5rem">
            <CodeInputs count={6} onChange={(code) => setValue("code", code)} />
          </Stack>
          {newUser && (
            <>
              <Stack
                alignItems="flex-start"
                sx={{
                  marginTop: { xs: "2.75rem", md: "2rem" },
                }}
              >
                <Button
                  variant="text"
                  textColor="secondary.500"
                  onClick={() => setIsReferer(!isRefererCode)}
                  startIcon={
                    <ArrowDown2
                      variant="Bold"
                      style={{
                        transform: isRefererCode ? "rotate(180deg)" : "",
                        transition: "all 0.3s ease-in",
                      }}
                    />
                  }
                  sx={{
                    px: 0,
                  }}
                >
                  <Typography variant="BodyMedium" color="secondary.500">
                    کد دعوت دارید؟
                  </Typography>
                  <Typography
                    variant="BodyMedium"
                    sx={{
                      color: (theme) =>
                        theme.palette.colorfulGray[600] + "!important",
                    }}
                  >
                    (اختیاری)
                  </Typography>
                </Button>
              </Stack>
              <Collapse in={isRefererCode}>
                <TextField
                  {...register("referer")}
                  placeholder="جای گذاری کد دعوت (اختیاری)"
                  defaultValue={getReferralCode()}
                  // value={getReferralCode()}
                  disabled={isReferralFromLink}
                />
              </Collapse>
            </>
          )}
        </Stack>
        {newUser && (
          <Stack my="2rem">
            <Button
              type="submit"
              disabled={watch("code")?.length !== 6}
              loading={verifyOtpMutation?.isPending}
              id="register-new-user"
            >
              <Typography>ثبت نام</Typography>
            </Button>
          </Stack>
        )}
      </Stack>
    </AuthLayout>
  );
}
