import { SvgIcon, SvgIconProps } from "@mui/material";

const DepositRialIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="62"
        height="61"
        viewBox="0 0 62 61"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="8.23693"
          y="36.4425"
          width="45.5257"
          height="16.8199"
          rx="2.13051"
          fill="#FABA16"
          stroke="#62676E"
          stroke-width="1.12132"
        ></rect>
        <path
          d="M48.8463 41.8836L49.3293 42.1683L48.8463 41.8836C48.6734 42.1769 48.5822 42.5112 48.5822 42.8517V46.183C48.5822 46.4722 48.648 46.7577 48.7746 47.0177L49.7376 48.995C49.9915 49.5163 49.6118 50.1236 49.0319 50.1236H17.0481C16.4621 50.1236 16.0827 49.5047 16.3486 48.9825L17.2472 47.2176C17.3836 46.9497 17.4548 46.6533 17.4548 46.3527V43.0179C17.4548 42.6917 17.3711 42.371 17.2117 42.0864L16.4641 40.7516C16.171 40.2284 16.5492 39.5831 17.1489 39.5831H48.8286C49.436 39.5831 49.8133 40.2434 49.5048 40.7667L48.8463 41.8836Z"
          fill="#ffffff"
          stroke="#62676E"
          stroke-width="1.12132"
        ></path>
        <rect
          x="7.01772"
          y="33.5498"
          width="45.5257"
          height="20.4081"
          rx="2.13051"
          transform="rotate(-15 7.01772 33.5498)"
          fill="#FABA16"
          stroke="#62676E"
          stroke-width="1.12132"
        ></rect>
        <path
          d="M12.1565 37.0646C11.7693 36.6281 11.977 35.9367 12.5406 35.7857L46.8437 26.5942C47.4142 26.4413 47.9428 26.9496 47.8124 27.5257L47.3146 29.724C47.2463 30.0257 47.2524 30.3395 47.3325 30.6383L48.451 34.8126C48.5187 35.0652 48.6376 35.3011 48.8004 35.5058L50.8499 38.0825C51.2017 38.5249 50.9847 39.183 50.4387 39.3293L15.8807 48.5891C15.3293 48.7368 14.8096 48.2652 14.9032 47.7021L15.4018 44.7049C15.4464 44.4364 15.4331 44.1615 15.3627 43.8987L14.2434 39.7214C14.1668 39.4355 14.0247 39.1714 13.8283 38.95L12.1565 37.0646Z"
          fill="#ffffff"
          stroke="#62676E"
          stroke-width="1.12132"
        ></path>
        <circle
          cx="31.8046"
          cy="37.2396"
          r="3.70037"
          transform="rotate(-15 31.8046 37.2396)"
          fill="#FCD535"
          stroke="#62676E"
          stroke-width="1.12132"
        ></circle>
        <path
          d="M16.0379 15.2923L12.3937 19.0928C12.2701 19.2217 12.0848 19.2861 11.9613 19.2861C11.776 19.2861 11.6525 19.2217 11.5289 19.0928L7.88473 15.2923C7.69943 15.099 7.63767 14.8413 7.69943 14.5837C7.82296 14.326 8.00826 14.1972 8.25533 14.1972H9.42888V7.81995C9.42888 7.49786 9.73771 7.17578 10.0465 7.17578H13.8143C14.1231 7.17578 14.4319 7.49786 14.4319 7.81995V14.1972H15.6055C15.8526 14.1972 16.0996 14.326 16.1614 14.5837C16.2232 14.8413 16.2232 15.099 16.0379 15.2923Z"
          fill="#FCD535"
        ></path>
        <path
          d="M26.8025 15.2923L23.1583 19.0928C23.0348 19.2217 22.8495 19.2861 22.7259 19.2861C22.5406 19.2861 22.4171 19.2217 22.2936 19.0928L18.6494 15.2923C18.4641 15.099 18.4023 14.8413 18.4641 14.5837C18.5876 14.326 18.7729 14.1972 19.02 14.1972H20.1935V7.81995C20.1935 7.49786 20.5024 7.17578 20.8112 7.17578H24.5789C24.8878 7.17578 25.1966 7.49786 25.1966 7.81995V14.1972H26.3701C26.6172 14.1972 26.8643 14.326 26.926 14.5837C26.9878 14.8413 26.9878 15.099 26.8025 15.2923Z"
          fill="#FCD535"
        ></path>
      </svg>
    </SvgIcon>
  );
};

export default DepositRialIcon;
