import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  handleAddBankAccount,
  createDepositAuto,
  handleJibitDeposit,
  handlePreviewWithdraw,
  handleApproveWithdraw,
  handlePreviewTomanWithdraw,
  createWalletNetwork,
  handleVerifyWithdraw,
  handleResendWithdrawOtp,
  handleApproveWithdrawToman,
  handleDeleteBankAccount,
} from "./api";
import { toast } from "@utils/toast";

export const useHandleJibitDepositMutation = () => {
  return useMutation({
    mutationFn: handleJibitDeposit,
  });
};

export const useHandleAddBankAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: handleAddBankAccount,
    onSuccess: () => {
      toast.success("حساب بانکی با موفقیت افزوده شد.");
      queryClient.invalidateQueries({ queryKey: ["my_bank_account"] });
    },
  });
};

export const useHandleDeleteBankAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: handleDeleteBankAccount,
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["my_bank_account"] }),
  });
};

export const useHandleCreateDepositAuto = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDepositAuto,
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ["deposit-auto"],
      }),
  });
};

export const useHandlePreviewWithdraw = () => {
  return useMutation({
    mutationFn: handlePreviewWithdraw,
  });
};

export const useHandlePreviewTomanWithdraw = () => {
  return useMutation({
    mutationFn: handlePreviewTomanWithdraw,
  });
};

export const useHandleApproveWithdraw = () => {
  return useMutation({
    mutationFn: handleApproveWithdraw,
  });
};

export const useHandleApproveWithdrawToman = () => {
  return useMutation({
    mutationFn: handleApproveWithdrawToman,
  });
};

export const useCreateWalletNetwork = () => {
  return useMutation({
    mutationFn: createWalletNetwork,
  });
};

export const useHandleVerifyWithdraw = () => {
  return useMutation({
    mutationFn: handleVerifyWithdraw,
  });
};

export const useHandleResendWithdrawOtp = () => {
  return useMutation({
    mutationFn: handleResendWithdrawOtp,
  });
};
