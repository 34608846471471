import { z } from "zod";
import { numUtils } from "@utils/num-helpers";

interface MarketOrderArgs {
  amountMin: number;
  amountMax: number;
  priceCoin: string;
}
export const MarketOrderSchema = ({
  priceCoin,
  amountMax,
  amountMin,
}: MarketOrderArgs) => {
  return z.object({
    amount: z
      .string()
      .refine((val) => Number(val) >= amountMin, {
        message: `مقدار ${priceCoin} نمی تواند کمتر از ${numUtils.commaformatter.toCurrency(
          amountMin
        )} باشد.`,
      })
      .refine((val) => Number(val) <= amountMax, {
        message: `مقدار ${priceCoin} نمی تواند بیشتر از ${numUtils.commaformatter.toCurrency(
          amountMax
        )} باشد.`,
      }),
    // total: z.string().refine((val) => Number(val) > 0, {
    //   message: `لطفا قیمت و مقدار را وارد نمایید`,
    // }),
  });
};
