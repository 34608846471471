import { Button } from "@components/common/button";
import { IconTypography } from "@components/common/icon-typography";
import { Image } from "@components/common/image";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack, useTheme } from "@mui/material";
import {
  HandleVerifyWithdrawRes,
  WalletItem,
} from "@services/queries/wallet/types";
import { getCoinIconURL, toClipboard } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { Calendar, Verify } from "iconsax-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface ResultWithdrawCoinModalProps {
  onClose: () => void;
  data: { result: HandleVerifyWithdrawRes; coin: WalletItem };
  fee: number;
}

export const ResultWithdrawCoinModal = ({
  onClose,
  data: { result, coin },
  fee,
}: ResultWithdrawCoinModalProps) => {
  const theme = useTheme();

  const navigate = useNavigate()

  const [isTXIdCopied, setIsTXIdCopied] = useState(false);

  const handleClipboard = async (text: string) => {
    try {
      await toClipboard({ text });
      setIsTXIdCopied(true);
      setTimeout(() => {
        setIsTXIdCopied(false);
      }, 1000);
    } catch (error) {}
  };

  return (
    <Modal open={true} title="انتقال رمز‌ارز" onClose={onClose}>
      <Stack width="100%" gap="1.25rem">
        <IconTypography
          bgColor="success.A100"
          icon={
            <Verify
              color={theme?.palette?.success?.[700]}
              variant="Bold"
              fontSize="1.97rem"
            />
          }
          title={
            <Typography color="success.900" variant="Body">
              انتقال رمز‌تان با موفقیت انجام شد
            </Typography>
          }
        />

        <Stack direction="row" justifyContent="space-between">
          <Typography color="colorfulGray.600" variant="Body">
            رمز ارز
          </Typography>
          <Stack direction="row" gap="0.5rem">
            <Typography variant="BodyMedium" color="colorfulGray.700">
              {`${coin?.prettyNameLocalized}(${result?.curreny})`}
            </Typography>
            <Image
              src={getCoinIconURL(result?.curreny)}
              width="1.5rem"
              height="1.5rem"
              alt={result?.curreny}
            />
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="colorfulGray.600" variant="Body">
            مقدار بعد از کسر کارمزد
          </Typography>
          <Typography variant="BodyMedium" color="colorfulGray.700">
            {numUtils.commaformatter.toCurrency(Number(result?.amount) - fee)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="colorfulGray.600" variant="Body">
            تاریخ
          </Typography>
          <Typography variant="BodyMedium" color="colorfulGray.700">
            1401/03/23 - 09:20
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography color="colorfulGray.600" variant="Body">
            شبکه
          </Typography>
          <Typography variant="BodyMedium" color="colorfulGray.700">
            {result?.network}
          </Typography>
        </Stack>
        {/* <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography color="colorfulGray.600" variant="Body">
            Tx ID
          </Typography>
          <Button
            onClick={()=> handleClipboard(result?.id)}
            variant="text"
            underline
            textColor="info.600"
            startIcon={
              <></>
            }
          >
            <Typography variant="BodyMedium" color="colorfulGray.700">
              {result?.id}
            </Typography>
          </Button>
        </Stack> */}
        <Button
          onClick={() =>
            navigate("/v2/dashboard/financial/transactions")
          }
          variant="outlined"
          size="medium"
          startIcon={<Calendar size="1.5rem" />}
        >
          تازیخچه برداشت رمز ارز
        </Button>
      </Stack>
    </Modal>
  );
};
