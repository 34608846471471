import { SwitchTab } from "@components/common/switch-tab";
import { Stack } from "@mui/material";
import { BaseCoin, BaseOnProps } from "../types";

const SwitchUsdtToIRT = ({ baseOn, setBaseOn }: Partial<BaseOnProps>) => {
  return (
    <Stack direction="row" gap="0.5rem" alignItems="center" zIndex={2}>
      <SwitchTab
        onChange={(data) => setBaseOn && setBaseOn(data)}
        defaultTab={baseOn}
        list={[
          {
            label: "تومان",
            value: BaseCoin?.TOMAN,
          },
          {
            label: "USDT",
            value: BaseCoin?.USDT,
          },
        ]}
      />
    </Stack>
  );
};

export default SwitchUsdtToIRT;
