import { Button } from "@components/common/button";
import { Image } from "@components/common/image";
import { InfiniteScroll } from "@components/common/infinite-scroll";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack } from "@mui/material";
import {
  useGetLeaderBoardLastMonth,
  useGetLeaderBoardTotal,
} from "@services/queries/referral/queries";
import { numUtils } from "@utils/num-helpers";
import { useMemo, useState } from "react";

interface ILeaderBoardModalProps {
  open: boolean;
  onClose: () => void;
}

export const LeaderBoardModal = ({ onClose, open }: ILeaderBoardModalProps) => {
  const [mode, setMode] = useState<"Month" | "All">("Month");

  const getLeaderBoardTotal = useGetLeaderBoardTotal({});

  const leaderBoardTotalList = useMemo(
    () => getLeaderBoardTotal?.data?.pages?.flatMap((e) => e?.items) || [],
    [getLeaderBoardTotal?.isFetchingNextPage, getLeaderBoardTotal?.isFetching]
  );

  const getLeaderBoardLastMonth = useGetLeaderBoardLastMonth({});

  const leaderBoardLastMonthList = useMemo(
    () => getLeaderBoardLastMonth?.data?.pages?.flatMap((e) => e?.items) || [],
    [
      getLeaderBoardLastMonth?.isFetchingNextPage,
      getLeaderBoardLastMonth?.isFetching,
    ]
  );

  return (
    <Modal
      loading={
        getLeaderBoardTotal?.isLoading || getLeaderBoardLastMonth?.isLoading
      }
      maxWidth="500px"
      onClose={onClose}
      open={open}
    >
      <>
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb="1rem"
        >
          <Typography
            color="colorfulGray.700"
            smVariant="BodyBold"
            variant="ExtendedBodyBold"
          >
            برترین دعوت کنندگان
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            px="0.15rem"
            py="0.12rem"
            bgcolor="colorfulGray.A100"
            borderRadius="0.85rem"
          >
            <Button
              onClick={() => setMode("Month")}
              size="small"
              bgColor={mode === "Month" ? "white" : "transparent"}
              textColor="primary.700"
            >
              <Typography variant="CaptionMedium" color="primary.700">
                ماه گذشته
              </Typography>
            </Button>
            <Button
              onClick={() => setMode("All")}
              size="small"
              bgColor={mode === "All" ? "white" : "transparent"}
              textColor="primary.700"
            >
              <Typography variant="CaptionMedium" color="primary.700">
                مجموع
              </Typography>
            </Button>
          </Stack>
        </Stack>

        {/* List */}
        <Stack
          component="ul"
          width="100%"
          maxHeight="600px"
          overflow="auto"
          pr="0.75rem"
          mr="-0.75rem"
        >
          {!!(mode === "All" ? leaderBoardTotalList : leaderBoardLastMonthList)
            ?.length ? (
            <InfiniteScroll
              fetchMore={async () => {
                mode === "All"
                  ? !getLeaderBoardTotal?.isFetchingNextPage &&
                    getLeaderBoardTotal?.hasNextPage
                    ? await getLeaderBoardTotal.fetchNextPage()
                    : await 0
                  : !getLeaderBoardLastMonth?.isFetchingNextPage &&
                    getLeaderBoardLastMonth?.hasNextPage
                  ? await getLeaderBoardLastMonth.fetchNextPage()
                  : await 0;
              }}
            >
              {(mode === "All"
                ? leaderBoardTotalList
                : leaderBoardLastMonthList
              )?.map((item, index) => (
                <Stack
                  key={index}
                  component="li"
                  direction="row"
                  width="100%"
                  justifyContent="space-between"
                  p="0.75rem"
                  borderRadius="0.75rem"
                  mt="0.5rem"
                  sx={{
                    transform: `scale(${
                      index === 0
                        ? 1
                        : index === 1
                        ? 0.975
                        : index === 2
                        ? 0.95
                        : 0.95
                    })`,
                    border: [0, 1, 2]?.includes(index) ? 1 : 1,
                    borderBottom: [0, 1, 2]?.includes(index) ? 5 : 1,
                    borderColor: [0, 1, 2]?.includes(index)
                      ? "colorfulGray.700"
                      // : index === 3
                      // ? "primary.500"
                      : "primary.A100",
                    // bgcolor: index === 3 ? "primary.A100" : "transparent",
                  }}
                >
                  <Stack direction="row" gap="1rem" alignItems="center">
                    {[0, 1, 2]?.includes(index) ? (
                      <Image
                        src={`/v2/images/cups/cup-${index + 1}.svg`}
                        alt="cup"
                        sx={{ mb: "-0.25rem" }}
                      />
                    ) : (
                      <Stack alignItems="center">
                        <Typography
                          variant="Body"
                          color={
                            index === 3
                              ? "colorfulGray.700"
                              : "colorfulGray.500"
                          }
                        >
                          رتبه
                        </Typography>
                        <Typography variant="BodyBold" color="colorfulGray.800">
                          {item?._id}
                        </Typography>
                      </Stack>
                    )}

                    <Stack>
                      <Typography
                        variant="Body"
                        dir="ltr"
                        color="colorfulGray.700"
                      >
                        {item?.memberMaskedMobileNo}
                      </Typography>
                      <Typography variant="BodyBold" color="colorfulGray.800">
                        {numUtils?.commaformatter?.toCurrency(
                          item?.totalProfit
                        )}
                      </Typography>
                    </Stack>
                  </Stack>

                  {item?.referralCount && <Typography variant="Body" color="colorfulGray.700">
                    {item?.referralCount} دوست
                  </Typography>}
                </Stack>
              ))}
            </InfiniteScroll>
          ) : (
            <></>
          )}
        </Stack>
      </>
    </Modal>
  );
};
