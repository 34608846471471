import { Button } from "@components/common/button";
import { Modal } from "@components/common/modal";
import { Typography } from "@components/common/typography";
import { Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const SuccessVideoUploadedModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  return (
    <Modal onClose={onClose} open={true}>
      <Stack p="1rem" width="100%">
        <Typography
          textAlign="center"
          variant="body1"
          fontWeight={500}
          color="primary.600"
          pb="1.5rem"
        >
          ویدیو شما با موفقیت آپلود شد و در حال بررسی می باشد.
        </Typography>
        <Button
          id="send-verification-video"
          fullWidth
          onClick={() => {
            onClose();
            navigate("/v2/dashboard/wallet");
          }}
          sx={{ mt: "1rem" }}
        >
          <Typography variant="body1" color="neutral.50">
            بازگشت به کیف پول
          </Typography>
        </Button>
      </Stack>
    </Modal>
  );
};
