import { Button } from "@components/common/button";
import { Wrapper } from "@components/layout/wrapper";
import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { ReceiveForm } from "./_component/receive-form";
import { FormProvider, useForm } from "react-hook-form";
import { PaymentForm } from "./_component/payment-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { OtcFormSchema } from "./_validation";
import { OtcMethod } from "./_types";
import { OtcSlider } from "./_component/otc-slider";
import { toast } from "@utils/toast";
import type { Coin } from "~types/index";
import { getCoinIconURL } from "@utils/common";
import { SelectCoinModal } from "./_component/select-coin-modal";
import { usePairDetails, usePairSides } from "@services/queries/otc/queries";
import { addCommas, removeCommas } from "@persian-tools/persian-tools";
import { ApproveOtcRequestModal } from "./_component/approve-otc-request-modal";
import { numUtils } from "@utils/num-helpers";
import useTabFocus from "@hooks/useInTab";

export default function OtcPage() {
  const [method, setMethod] = useState<OtcMethod>(OtcMethod.Buy);
  const [fromSideList, setFromSideList] = useState<Coin[]>([]);
  const [toSideList, setToSideList] = useState<Coin[]>([]);
  const [fromCoin, setFromCoin] = useState<Coin>();
  const [toCoin, setToCoin] = useState<Coin>();
  const [openModal, setOpenModal] = useState<"from" | "to" | null>(null);
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);

  const formMethods = useForm<z.output<typeof OtcFormSchema>>({
    mode: "onTouched",
    resolver: zodResolver(OtcFormSchema),
  });

  const onSubmit = ({ payment, receive }: z.output<typeof OtcFormSchema>) => {
    if (!payment || !receive)
      return toast.error("مقدار پرداختی شما نمی‌تواند خالی باشد.");

    const balance = Number(fromCoinDetails?.available) || 0;

    if (balance === 0)
      return toast.error("لطفا موجودی پرداختی خود را شارژ کنید.");

    if (removeCommas(payment) > balance)
      return toast.error("مقدار وارد شده از موجودی شما بیشتر است.");

    const maxAmount = getPairDetails?.data?.result?.maxAmount || Infinity;
    const minAmount = getPairDetails?.data?.result?.minAmount || 0;

    if (removeCommas(payment) > maxAmount)
      return toast.error(
        `شما نمی‌توانید بیشتر از حداکثر مقدار مجاز (${numUtils.commaformatter.toCurrency(
          maxAmount
        )}) ${fromCoin?.prettyNameLocalized} پرداخت کنید`
      );

    if (removeCommas(payment) < minAmount)
      return toast.error(
        `شما نمی‌توانید کمتر از حداقل مقدار مجاز (${numUtils.commaformatter.toCurrency(
          minAmount
        )}) ${fromCoin?.prettyNameLocalized} پرداخت کنید`
      );

    setIsApproveModalOpen(true);
  };

  const getPairSides = usePairSides();
  const getPairDetails = usePairDetails(
    {
      from: fromCoin?._id || "",
      to: toCoin?._id || "",
    },
    isApproveModalOpen
  );

  useEffect(() => {
    if (getPairSides?.isSuccess) {
      setFromSideList(getPairSides?.data?.result);
      // @ts-ignore
      setToSideList(getPairSides?.data?.result?.[0]?.items);
      setFromCoin(getPairSides?.data?.result?.[0]);
      setToCoin(getPairSides?.data?.result?.[0]?.items?.[0]);
    }
  }, [getPairSides?.isPending]);

  const toggleChangeMethod = (method: OtcMethod) => {
    setMethod(method);
    setFromSideList((prev) => {
      setToSideList(prev);
      return method === OtcMethod.Buy ? toSideList : fromSideList;
    });
    setToSideList((prev) => {
      setFromSideList(prev);
      return method === OtcMethod.Buy ? fromSideList : toSideList;
    });
    setFromCoin((prev) => {
      setToCoin(prev);
      return method === OtcMethod.Buy ? toCoin : fromCoin;
    });
    setToCoin((prev) => {
      setFromCoin(prev);
      return method === OtcMethod.Buy ? fromCoin : toCoin;
    });
  };

  // prettier-ignore
  const handleSelectFromSide = (coin: Coin) => {
    setOpenModal(null);
    if (method === OtcMethod?.Buy) {
      const toSideList = coin?.items?.filter((e) => e._id !== "IRT");
      if (!toSideList?.length) {
        return toast.error(
          `کاربر گرامی‌، معامله ${toCoin?.prettyNameLocalized} بر پایه ${coin?.prettyNameLocalized} امکان پذیر نمی‌باشد.`
        );
      }
      const isPrevCoinExisted = toSideList?.find((e) => e._id === toCoin?._id);
      if(!isPrevCoinExisted) {
        toast.warning(`ارز ${toCoin?.prettyNameLocalized} بر پایه ${coin?.prettyNameLocalized} امکان پذیر نمی‌باشد.`)
      }
      setToSideList(toSideList);
      setToCoin(isPrevCoinExisted || toSideList?.[0]);
      setFromCoin(coin);
    } else {
      setFromCoin(coin);
    }
  };

  // prettier-ignore
  const handleSelectToSide = (coin: Coin) => {
    setOpenModal(null);
    if (method === OtcMethod?.Sell) {
      const fromSideList = coin?.items?.filter((e) => e._id !== "IRT");
      if (!fromSideList?.length) {
        return toast.error(
          `کاربر گرامی‌، معامله ${fromCoin?.prettyNameLocalized} بر پایه ${coin?.prettyNameLocalized} امکان پذیر نمی‌باشد.`
        );
      }
      const isPrevCoinExisted = fromSideList?.find((e) => e._id === fromCoin?._id);
      if(!isPrevCoinExisted) {
        toast.warning(`ارز ${toCoin?.prettyNameLocalized} بر پایه ${coin?.prettyNameLocalized} امکان پذیر نمی‌باشد.`)
      }
      setFromSideList(fromSideList);
      setFromCoin(isPrevCoinExisted || fromSideList?.[0]);
      setToCoin(coin);
    } else {
      setToCoin(coin);
    }
  };

  const handleSliderInput = (percent: number) => {
    const balance =
      Number(getPairDetails?.data?.result?.accounts?.[0]?.available) || 0;
    const payment = (balance * percent) / 100;

    // prettier-ignore
    if (percent !== 100) {
      formMethods?.setValue("payment",payment?.toFixed(getPairDetails?.data?.result?.fromRoundScale).toString());
    }else {
      formMethods?.setValue("payment",fromCoinDetails?.availableFormatted);
    }

    const price = getPairDetails?.data?.result?.price;
    if (price) {
      const receive =
        method === OtcMethod.Buy ? payment / price : payment * price;

      formMethods?.setValue(
        "receive",
        (receive || 0)
          ?.toFixed(getPairDetails?.data?.result?.toRoundScale)
          .toString()
      );
    }
  };

  useEffect(() => {
    if (fromCoin && toCoin) getPairDetails?.refetch();
    formMethods.setValue("payment", "");
    formMethods.setValue("receive", "");
  }, [method, fromCoin, toCoin]);

  useEffect(() => {
    if (getPairDetails?.isSuccess) {
      const interval_otc = setInterval(() => {
        !isApproveModalOpen && getPairDetails?.refetch();
      }, (import.meta.env.VITE_OTC_FETCH_INTERVAL_IN_SEC || 10) * 1000);
      return () => {
        clearInterval(interval_otc);
      };
    }
  }, [getPairDetails.isPending]);

  const fromCoinDetails = getPairDetails?.data?.result?.accounts?.find(
    (e) => e.currency === fromCoin?._id
  );

  return (
    <Wrapper
      backMode
      sxContainer={{ pt: "2rem" }}
      loading={getPairSides?.isLoading}
      title="معامله آسان"
    >
      <FormProvider {...formMethods}>
        <Stack sx={{ direction: "ltr" }} borderRadius="0.25rem" height="100%">
          <Box
            bgcolor="white"
            py="2rem"
            borderRadius="1.125rem"
            component="form"
            noValidate
            onSubmit={formMethods.handleSubmit(onSubmit)}
            maxWidth="540px"
            width="100%"
            mx="auto"
            display="flex"
            flexDirection="column"
            sx={{
              px: { xs: "0.75rem", md: "1.5rem" },
              justifyContent: { xs: "space-between" },
            }}
          >
            <Stack>
              <Stack direction="row" width="100%" mb="1.5rem">
                <Button
                  id="switch-otc-to-buy"
                  fullWidth
                  size="small"
                  sx={{ px: 0 }}
                  onClick={() => toggleChangeMethod(OtcMethod.Buy)}
                  variant={method === OtcMethod.Buy ? "contained" : "text"}
                >
                  خرید
                </Button>
                <Button
                  id="switch-otc-to-sell"
                  fullWidth
                  size="small"
                  sx={{ px: 0 }}
                  onClick={() => toggleChangeMethod(OtcMethod.Sell)}
                  variant={method === OtcMethod.Sell ? "contained" : "text"}
                >
                  فروش
                </Button>
              </Stack>

              <PaymentForm
                method={method}
                dataDetails={getPairDetails?.data?.result}
                balance={fromCoinDetails?.availableFormatted || ""}
                unit_en={fromCoin?.name || ""}
                unit_fa={fromCoin?.prettyNameLocalized || ""}
                unit_icon={getCoinIconURL(fromCoin?._id || "")}
                onSelectCoin={() => setOpenModal("from")}
              />

              <Stack px="1rem" mr="-5px">
                <OtcSlider
                  balance={Number(fromCoinDetails?.available) || 0}
                  // disabled={!(fromCoinDetails?.total || 0)}
                  onChange={handleSliderInput}
                  method={method}
                />
              </Stack>

              <ReceiveForm
                method={method}
                dataDetails={getPairDetails?.data?.result}
                amount={addCommas(
                  getPairDetails?.data?.result?.priceString ||
                    // ?.toFixed(
                    //   method === OtcMethod.Buy
                    //     ? getPairDetails?.data?.result?.fromRoundScale
                    //     : getPairDetails?.data?.result?.toRoundScale
                    // )
                    ""
                )}
                unit_en={toCoin?.name || ""}
                approximatePriceFaName={
                  method === OtcMethod.Buy
                    ? toCoin?.prettyNameLocalized || ""
                    : fromCoin?.prettyNameLocalized || ""
                }
                base_fa_name={
                  method === OtcMethod.Buy
                    ? fromCoin?.prettyNameLocalized || ""
                    : toCoin?.prettyNameLocalized || ""
                }
                unit_icon={getCoinIconURL(toCoin?._id || "")}
                onSelectCoin={() => setOpenModal("to")}
              />
            </Stack>

            <Button
              id={method === OtcMethod.Buy ? "otc-buy" : "otc-sell"}
              type="submit"
              fullWidth
              variant="contained"
              size="large"
              bgColor={method === OtcMethod.Buy ? "success.main" : "error.main"}
              sx={{ mt: "2rem" }}
            >
              {method === OtcMethod.Buy ? "ثبت سفارش خرید" : "ثبت سفارش فروش"}
            </Button>
          </Box>
        </Stack>

        <SelectCoinModal
          method={method}
          title="پرداخت می‌کنم"
          searchable={method === OtcMethod.Sell}
          side="from"
          open={openModal === "from"}
          list={fromSideList}
          onSelectCoin={(coin) => handleSelectFromSide(coin)}
          onClose={() => setOpenModal(null)}
        />
        <SelectCoinModal
          method={method}
          title="دریافت می‌کنم"
          searchable={method === OtcMethod.Buy}
          side="to"
          open={openModal === "to"}
          list={toSideList}
          onSelectCoin={(coin) => handleSelectToSide(coin)}
          onClose={() => setOpenModal(null)}
        />

        <ApproveOtcRequestModal
          fromCoin={fromCoin as Coin}
          toCoin={toCoin as Coin}
          payment_amount={removeCommas(formMethods.getValues("payment") || "")}
          method={method}
          onClose={() => setIsApproveModalOpen(false)}
          open={isApproveModalOpen}
        />
      </FormProvider>
    </Wrapper>
  );
}
