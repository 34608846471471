import { Swiper, SwiperSlide } from "swiper/react";
import { FilterItem, IValueFilter } from "../../_constants";
import { Checkbox } from "@components/common/checkbox";
import { Typography } from "@components/common/typography";
import { useBreakpoint } from "@hooks/useBreakpoint";
import { Stack } from "@mui/material";

interface SwiperFilterProps {
  filterList: FilterItem[];
  filters: IValueFilter[];
  setFilters: React.Dispatch<React.SetStateAction<IValueFilter[]>>;
  handleOpenFilterModal: () => void;
}

export const SwiperFilter: React.FC<SwiperFilterProps> = ({
  filterList,
  filters,
  setFilters,
  handleOpenFilterModal,
}) => {
  const isMdUp = useBreakpoint("md");

  const renderComponent = () => (
    <>
      <SwiperSlide style={{ width: "auto" }}>
        <Checkbox
          formControlProps={{
            label: (
              <Typography variant="BodyMedium" color="primary.700">
                فیلترها
              </Typography>
            ),
            onChange: handleOpenFilterModal,
            sx: {
              bgcolor: "primary.50",
              px: "0.62rem",
              borderRadius: "0.625rem",
              mx: 0,
            },
          }}
          checkboxProps={{
            sx: { visibility: "hidden", width: 0, px: 0 },
          }}
          isOutline={false}
        />
      </SwiperSlide>
      {filterList?.map((item, i) => {
        if (item?.children?.length) {
          return item.children.map((child) => (
            <SwiperSlide
              key={`${item.prefix}${child.value?.id}`}
              style={{ width: "auto" }}
            >
              <Checkbox
                key={`${child.value?.id}`}
                formControlProps={{
                  label: (
                    <Typography variant="BodyMedium" color="primary.700">
                      {child.title}
                    </Typography>
                  ),
                  onChange: (_, checked) => {
                    setFilters((prev) => {
                      if (checked) {
                        return [
                          ...prev,
                          {
                            id: `${item.prefix}${child.value?.id}`,
                            filter: child?.value?.filter,
                          },
                        ];
                      } else {
                        return prev.filter(
                          (filter) =>
                            filter?.id !==
                            `${item.prefix}${child.value?.id || ""}`
                        );
                      }
                    });
                  },
                  sx: {
                    bgcolor: filters
                      ?.map((e) => e?.id)
                      .includes(`${item.prefix}${child?.value?.id || ""}`)
                      ? "primary.50"
                      : "neutral.50",
                    pr: "0.62rem",
                    borderRadius: "0.625rem",
                    mx: 0,
                  },
                  checked: filters
                    ?.map((e) => e?.id)
                    .includes(`${item.prefix}${child?.value?.id || ""}`),
                }}
                isOutline={false}
              />
            </SwiperSlide>
          ));
        } else {
          return (
            <SwiperSlide key={`${item?.prefix}-MAIN`} style={{ width: "auto" }}>
              <Checkbox
                formControlProps={{
                  label: (
                    <Typography variant="BodyMedium" color="primary.700">
                      {item?.title}
                    </Typography>
                  ),
                  onChange: handleOpenFilterModal,
                  sx: {
                    bgcolor: filters
                      ?.map((e) => e?.id)
                      .includes(`${item.prefix}`)
                      ? "primary.50"
                      : "neutral.50",
                    px: "0.62rem",
                    borderRadius: "0.625rem",
                    mx: 0,
                  },
                }}
                checkboxProps={{
                  sx: { visibility: "hidden", width: 0, px: 0 },
                }}
                isOutline={false}
              />
            </SwiperSlide>
          );
        }
      })}
    </>
  );

  if (isMdUp)
    return (
      <Stack direction="row" flexWrap="wrap" gap="0.62rem">
        {renderComponent()}
      </Stack>
    );

  return (
    <Swiper slidesPerView="auto" spaceBetween={8}>
      {renderComponent()}
    </Swiper>
  );
};
