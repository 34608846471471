import { Button } from "@components/common/button";
import { Image } from "@components/common/image";
import { Typography } from "@components/common/typography";
import { Stack } from "@mui/material";
import { useCancelSpecificOrderMutation } from "@services/queries/financial/mutations";
import { OrderItemRes } from "@services/queries/financial/types";
import { getCoinIconURL, getPersianEquivalent } from "@utils/common";
import { numUtils } from "@utils/num-helpers";
import { CloseSquare } from "iconsax-react";
import { OrdersStatus } from "~types/index";

export const CancelOrderModal = ({
  data,
  onClose,
  onSuccess,
}: {
  data: OrderItemRes;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const cancelSpecificOrderMutation = useCancelSpecificOrderMutation();

  return (
    <Stack gap="1rem" width="100%">
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <Typography
          py="0.25rem"
          px="0.25rem"
          bgcolor="error.A100"
          color="error.500"
          variant="CaptionBold"
          smVariant="SmallCaptionBold"
          borderRadius="0.25rem"
        >
          {data?.side === "buy" ? "خرید" : "فروش"}
        </Typography>

        <Stack alignItems="center" gap="0.3rem">
          <Stack direction="row">
            <Image
              src={getCoinIconURL(data?.baseCurrency)}
              alt={data?.baseCurrency}
              width="2.25rem"
              height="2.25rem"
              sx={{ mr: "-0.5rem", zIndex: 2 }}
            />
            <Image
              src={getCoinIconURL(data?.quoteCurrency)}
              alt={data?.quoteCurrency}
              width="2.25rem"
              height="2.25rem"
              sx={{ ml: "-0.5rem" }}
            />
          </Stack>
          <Typography variant="BodyMedium" color="colorfulGray.500">
            <Typography
              component="span"
              px="0.25rem"
              variant="BodyMedium"
              color="colorfulGray.800"
            >
              {data?.quoteCurrency} /
            </Typography>
            {data?.baseCurrency}
          </Typography>
        </Stack>
      </Stack>

      <Stack gap="0.5rem">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="Body" color="colorfulGray.600">
            قیمت ثبت شده
          </Typography>
          <Typography
            smVariant="BodyBold"
            variant="ExtendedBodyBold"
            color="colorfulGray.700"
          >
            {numUtils.commaformatter.toCurrency(Number(data?.price))}
            <Typography
              component="span"
              variant="Caption"
              smVariant="SmallCaption"
              px="0.25rem"
              color="colorfulGray.500"
            >
              {data?.quoteCurrency}
            </Typography>
          </Typography>
        </Stack>

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            smVariant="Caption"
            variant="Body"
            color="colorfulGray.600"
          >
            وضعیت
          </Typography>
          <Typography
            py="0.25rem"
            px="0.5rem"
            bgcolor="neutral.50"
            color="colorfulGray.600"
            variant="CaptionBold"
            smVariant="SmallCaptionBold"
            borderRadius="0.25rem"
          >
            {getPersianEquivalent(data?.status as OrdersStatus)}
          </Typography>
        </Stack>
      </Stack>

      <Stack direction="row" gap="0.75rem">
        <Button
          onClick={() =>
            cancelSpecificOrderMutation.mutate(data?._id, {
              onSuccess: () => {
                onSuccess();
              },
            })
          }
          loading={cancelSpecificOrderMutation.isPending}
          fullWidth
          bgColor="error.500"
          startIcon={<CloseSquare variant="Bold" size="1.25rem" />}
        >
          <Typography variant="BodyMedium">لغو سفارش</Typography>
        </Button>
        <Button
          onClick={onClose}
          fullWidth
          bgColor="primary.A100"
          textColor="primary.700"
        >
          <Typography variant="BodyMedium">انصراف</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};
