import { ReferralLevelsRes } from "@services/queries/referral/types";

const sortReferralLevels = (
  referralLevels: ReferralLevelsRes[]
): ReferralLevelsRes[] => {
  return referralLevels?.sort(
    (a, b) => parseInt(a.minimumInvites) - parseInt(b.minimumInvites)
  );
};

export const getReferralLevel = (
  referralLevels: ReferralLevelsRes[],
  numFriends: number
) => {
  const sortedLevels = sortReferralLevels(referralLevels);

  return (
    sortedLevels?.findLast(
      (level) => numFriends >= parseInt(level?.minimumInvites)
    ) || null
  );
};

export const getNextMilestone = (
  referralLevels: ReferralLevelsRes[],
  numFriends: number
) => {
  const sortedLevels = sortReferralLevels(referralLevels);
  const currentLevel = getReferralLevel(sortedLevels, numFriends);

  const nextLevel = currentLevel
    ? sortedLevels.find(
        (level) =>
          parseInt(level?.minimumInvites) >
          parseInt(currentLevel?.minimumInvites)
      ) || sortedLevels[sortedLevels.length - 1]
    : sortedLevels[0];
  return nextLevel;
};

export const generateDynamicText = (
  referralLevels: ReferralLevelsRes[],
  numFriends: number
) => {
  const sortedLevels = sortReferralLevels(referralLevels);
  const nextMilestone = getNextMilestone(sortedLevels, numFriends);
  const referralLevel = getReferralLevel(sortedLevels, numFriends);
  const friendsNeeded = parseInt(nextMilestone?.minimumInvites) - numFriends;
  return `با معرفی ${friendsNeeded} نفر دیگر از دوستان خود کارمزد ${nextMilestone?.feePercentage} درصدی از دوستان خود هدیه بگیرید.`;
};
