import request from "@services/axios";
import {
  MyBankAccountItem,
  CompanyBankAccountItem,
  GetMyWalletArgs,
  WalletItem,
  SumDailyDepositRes,
  GatewayDepositJibitRes,
  GatewayDepositJibitArgs,
  AccountBankArgs,
  DepositAutoRes,
  DailyMonthlyWithdrawRes,
  HandlePreviewWithdrawArgs,
  HandlePreviewWithdrawRes,
  HandleApproveWithdrawArgs,
  HandleApproveWithdrawRes,
  HandlePreviewTomanWithdrawArgs,
  HandlePreviewTomanWithdrawRes,
  CreateWalletNetworkRes,
  HandleVerifyWithdrawRes,
  HandleApproveWithdrawTomanArgs,
  WalletItemInfoRes,
  GetLastWithdrawTransactionArgs,
  GetLastWithdrawTransactionRes,
  GetEstimateWalletRes,
  FeeListItemRes,
  GetAssetsRes,
} from "./types";
import { DEFAULT_PER_PAGE } from "@constants/index";

export const getMyWallet = async (data: GetMyWalletArgs) => {
  return await request.post<{ items: WalletItem[]; total: number }>(
    `/api/ExCore/mywallets/${data?.page ?? 0}/${
      data?.perPage ?? DEFAULT_PER_PAGE
    }`,
    data
  );
};

export const getEstimateWallet = async () =>
  await request.get<GetEstimateWalletRes[]>("/api/ExCore/estimateMywallets");

export const getMyBankAccount = async () => {
  return await request.post<{ items: MyBankAccountItem[]; total: number }>(
    "/api/User/accounts/browse/0/100"
  );
};

export const getCompanyBankAccount = async () => {
  return await request.post<{ items: CompanyBankAccountItem[]; total: number }>(
    "/api/BankAccount/browse/0/100"
  );
};

export const getSumDailyDeposit = async () => {
  return await request.get<SumDailyDepositRes>("/api/Deposit/getSumDaily");
};

export const getFeeDepositList = async () => {
  return await request.get<FeeListItemRes>("/api/DepositAuto/feeList");
};

export const handleJibitDeposit = async (data: GatewayDepositJibitArgs) => {
  return await request.post<GatewayDepositJibitRes>("/api/deposit", data);
};

export const handleAddBankAccount = async (data: AccountBankArgs) => {
  return await request.post<MyBankAccountItem>("/api/User/accounts", data);
};

export const handleDeleteBankAccount = async (id: string) => {
  return await request.delete<any>(`/api/User/accounts/${id}`);
};

export const getDepositAuto = async () => {
  return await request.get<DepositAutoRes>("/api/DepositAuto");
};

export const createDepositAuto = async () => {
  return await request.post<DepositAutoRes>("/api/DepositAuto/request");
};

export const getDailyMonthlyWithdraw = async (currencyId?: string) => {
  return await request.get<DailyMonthlyWithdrawRes>(
    `/api/WithdrawalLevel/getDailyAndMonthly${
      currencyId ? `/${currencyId}` : ""
    }`
  );
};

export const handlePreviewWithdraw = async (
  data: HandlePreviewWithdrawArgs
) => {
  return await request.post<HandlePreviewWithdrawRes>(
    "/api/WithdrawalRequest/preview",
    data
  );
};

export const handlePreviewTomanWithdraw = async (
  data: HandlePreviewTomanWithdrawArgs
) => {
  return await request.post<HandlePreviewTomanWithdrawRes>(
    "/api/WithdrawAuto/invoice",
    data
  );
};

export const handleApproveWithdrawToman = async (
  data: HandleApproveWithdrawTomanArgs
) => {
  return await request.post<boolean>(
    `/api/WithdrawAuto/accept/${data?.id}`,
    data
  );
};

export const handleApproveWithdraw = async (
  data: HandleApproveWithdrawArgs
) => {
  return await request.post<HandleApproveWithdrawRes>(
    "/api/ExCore/create/withrawal",
    data
  );
};

export const handleVerifyWithdraw = async (data: {
  id: string;
  otp: string;
}) => {
  return await request.post<HandleVerifyWithdrawRes>(
    "/api/ExCore/verify/withrawal",
    data
  );
};

export const handleResendWithdrawOtp = async (id: string) => {
  return await request.put<HandleApproveWithdrawRes>(
    "/api/ExCore/resend/withrawal/otp",
    { id }
  );
};

export const getLastWithdrawTransaction = async (
  data: GetLastWithdrawTransactionArgs
) =>
  await request.post<GetLastWithdrawTransactionRes>(
    `/api/WithdrawAuto/v2/allTransactions/${data?.page || 0}/${
      data?.perPage || 5
    }`,
    {
      query: data?.query,
      sort: [
        {
          field: "createdAt",
          operator: "des",
        },
      ],
      populate: [],
    }
  );

export const createWalletNetwork = async (id: string) =>
  await request.post<CreateWalletNetworkRes>("/api/ExCore/create/wallet", {
    currencyId: id,
  });

export const getWalletItemInfo = async (id: string) =>
  await request.get<WalletItemInfoRes[]>(`/api/OtcRequestV2/info/${id}`);

export const getSpecificWalletItem = async ({ coinId }: { coinId: string }) =>
  await request.get<WalletItem>(`/api/ExCore/mywallets/${coinId}`);

export const getEstimateAssets = async () =>
  await request.get<GetAssetsRes>(`/api/ExCore/v2/estimateAssets`);
