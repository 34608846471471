import { Button } from "@components/common/button";
import { Typography } from "@components/common/typography";
import { List, ListItem, ListItemText, Stack, useTheme } from "@mui/material";
import { Chainlink, ReceiptText, Refresh, TickCircle } from "iconsax-react";

interface AuthenticationCardProps {
  title: string;
  isCurrentStage?: boolean;
  isCompleteStage?: boolean;
  disabled?: boolean;
  linkToAuthorize?: string;
  loading?: boolean;
  access: { id: string; title: string }[];
  steps: {
    id: string;
    title: string;
    isActive: boolean;
    isPending?: boolean;
  }[];
}

export const AuthenticationCard = ({
  access,
  title,
  isCurrentStage,
  isCompleteStage,
  disabled,
  linkToAuthorize,
  loading,
  steps,
}: AuthenticationCardProps) => {
  const theme = useTheme();
  return (
    <Stack
      p="0.75rem"
      bgcolor="white"
      width="100%"
      borderRadius="1.125rem"
      justifyContent="space-between"
      alignItems="flex-end"
      direction="row"
      sx={{ opacity: disabled ? 0.5 : 1 }}
    >
      <Stack gap="0.25rem">
        <Stack direction="row" gap="0.5rem">
          {!isCompleteStage && !!isCurrentStage ? (
            <TickCircle size="1.5rem" color={theme.palette.secondary[600]} />
          ) : (
            <Stack
              width="2.5rem"
              height="2.5rem"
              bgcolor="colorfulGray.400"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              my="auto"
            >
              {loading ? (
                <Refresh color="white" size="1.25rem" />
              ) : (
                <ReceiptText size="1.25rem" color="white" />
              )}
            </Stack>
          )}
          <Stack>
            <Typography
              fontWeight={600}
              color="colorfulGray.700"
              smVariant="BodyBold"
              variant="ExtendedBodyBold"
            >
              {title}
            </Typography>
            <Stack gap="1rem" mb="0.5rem">
              <List disablePadding>
                {access?.map((item) => (
                  <ListItem disablePadding sx={{ px: "0.5rem" }} key={item.id}>
                    <Stack direction="row" gap="0.5rem" alignItems="center">
                      <Chainlink
                        color={theme?.palette?.colorfulGray?.[200]}
                        size="0.5rem"
                        variant="Bold"
                      />
                      <ListItemText primary={item.title} />
                    </Stack>
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Stack>
        </Stack>

        <Stack
        // justifyContent="space-between"
        // flexWrap="wrap"
        // direction={isMdUp ? "column" : "row"}
        >
          {/* <Stack gap="1rem">
            <Typography
              sx={{ display: { md: "none" } }}
              color="colorfulGray.600"
              variant="body2"
            >
              مراحل این سطح
            </Typography>
            <List disablePadding>
              {steps?.map((step) => (
                <ListItem disablePadding key={step.id}>
                  <Stack
                    mb="1rem"
                    direction="row"
                    gap="0.5rem"
                    alignItems="center"
                  >
                    {step?.isPending ? (
                      <Refresh
                        size="1.35rem"
                        className="rotate"
                        color={theme?.palette?.secondary?.[500]}
                      />
                    ) : (
                      <Stack
                        // bgcolor={step?.isActive ? "success.700" : "neutral.200"}
                        bgcolor="neutral.200"
                        width="1.5rem"
                        height="1.5rem"
                        borderRadius="50%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <DocumentLike
                          variant="Bold"
                          color="white"
                          size="0.85rem"
                        />
                      </Stack>
                    )}
                    <ListItemText primary={step.title} />
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Stack> */}
        </Stack>
      </Stack>

      {!isCompleteStage && !isCurrentStage && !disabled && (
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disabled={disabled}
          href={!isCurrentStage && !disabled ? linkToAuthorize : undefined}
        >
          تکمیل احراز هویت
        </Button>
      )}

      {/* {!isCompleteStage &&
        (!isCurrentStage ? (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={disabled}
            href={!isCurrentStage && !disabled ? linkToAuthorize : undefined}
          >
            {`ارتقا به سطح ${title}`}
          </Button>
        ) : (
          <Stack
            direction="row"
            height="38px"
            width="100%"
            bgcolor="success.100"
            position="relative"
            alignItems="center"
            borderRadius="0.75rem"
          >
            <Stack
              sx={{
                position: "absolute",
                left: 0,
                top: "50%",
                transform: "translate(-40%, -50%)",
                color: "success.500",
              }}
              bgcolor="success.700"
              width="2.35rem"
              height="2.35rem"
              borderRadius="50%"
              alignItems="center"
              justifyContent="center"
            >
              <DocumentLike variant="Bold" color="white" size="1.35rem" />
            </Stack>
            <Typography
              width="100%"
              textAlign="center"
              variant="body2"
              fontWeight={500}
              color="primary.700"
            >
              سطح شما
            </Typography>
          </Stack>
        ))} */}
    </Stack>
  );
};
